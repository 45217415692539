import React from "react"; 
import Table from "../TableWithPagination";
import Loader from "../ProgressCircle";
import { withStyles } from "../../core/components/Styles";
import SearchBar from "../SearchBar";
import FabButton from "../../core/components/FabButton";

// Custom Components

// import DataList from "../DataList";
// import AddJobModal from "../AddJobModal";

// import contractorItemProps from "./contractorItemProps";




const headers = [
  {
    name: "name",
    label: "Name"
  },
  {
    name:"email",
    label:"Email"
  },
  {
    name: "phone",
    label: "Phone"
  },
  {
    name:"actions",
    label:"Actions"
  }
];
 





const columnProps = [
  {
    name: "name"
  },
  {
    name:"email"
  },
  {
    name:"phone"
  },

]

const styles = theme => ({
  root: {
    flexGrow: 1,
    // overflow: "hidden",
    flexDirection:"column",
    display: "flex",
  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  }
});

const ManagersDashboard = ({
  classes,
  managersActions,
  getSearch, 
  load,
  totalCount,
  page,
  managers,
  searchRef,
  clearSearch,
  getManagers,
  openAddManagerForm,
  q
}) => {

  return (
    <div className={classes.root}>
        <SearchBar 
          placeholder="Search manager by email, name, phone"
          onChange={getSearch}
          query={q}
          onClear={clearSearch}
          inputRef={searchRef}
        />

        { load && <Loader />}

        <Table
          list={managers}
          totalCount={totalCount}
          actions={managersActions}
          handlePageChange={getManagers}
          load={load}
          page={page}
          headers={headers}
          columnProps={columnProps}
        />

      <FabButton onClick={openAddManagerForm} />   
    </div>
  );
};



export default withStyles(styles)(ManagersDashboard);
