import React, {Component, Fragment} from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from 'core/components/Button';
 
class PopUpMenu extends Component {
  state = {
    anchorEl: null,
  };
  btnsRef = {};

  handleClick = event => {
    event.stopPropagation();
    event.preventDefault();
    if(this.props.onClick) this.props.onClick(event);
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  selectItem = (item,option, optionIndex) => e => {
    this.props.onSelect(item,option, optionIndex);
    this.handleClose(e) 
  }

  render() {
    const { anchorEl } = this.state;
    const { buttonProps, text, item, options, selectedOptionProp, isSelectedOption } = this.props;
    const open = Boolean(anchorEl);

    return (
      <Fragment>
        {text && <Button
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onMouseDown={this.handleClick}
          variant="outlined"
          {...buttonProps}
        >
          {text}
        </Button>}
        
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          {
            options && options.map((option, index) => {
              return (
                <MenuItem
                  key={`option-${option.value}`}
                  onClick={this.selectItem(item,option, index)}
                  ref={(node) => this.btnsRef[index] = node}
                  selected={isSelectedOption(option.value,item[selectedOptionProp])}
                >
                {option.label}
              </MenuItem>
              )
            })
          }

        </Menu>
      </Fragment>
    );
  }
}

export default PopUpMenu;
