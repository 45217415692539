// JOBS

export const ADD_NEW_JOB = "ADD_NEW_JOB";
export const REMOVE_JOB = "REMOVE_JOB";
export const SET_JOBS = "SET_JOBS";
export const UPDATE_JOB = "UPDATE_JOB";

// // For JOBS and CONTRACTORS Earning Table 

// export const SET_JOB_ITEM = "SET_JOB_ITEM";
// export const SET_CLEANING_TYPE = "SET_CLEANING_TYPE";

// Loader Types
export const SHOW_LOADER = "SHOW_LOADER";
export const TOGGLE_LOADER = "TOGGLE_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

// pricelist 
export const SET_PRICE_LIST = "SET_PRICE_LIST";
export const REMOVE_PRICE_LIST = "REMOVE_PRICE_LIST";
// DOMESTIC CLEANING TYPES
export const STORE_DOMESTIC_TYPES = "STORE_DOMESTIC_TYPES";
export const CLEAR_DOMESTIC_TYPES = "CLEAR_DOMESTIC_TYPES";
export const STORE_DOMESTIC_PRICE_LIST = "STORE_DOMESTIC_PRICE_LIST";
export const CLEAR_DOMESTIC_PRICE_LIST = "CLEAR_DOMESTIC_PRICE_LIST";
// Commercial Cleaning Types
export const STORE_COMMERCIAL_OPTS = "STORE_COMMERCIAL_OPTS";
export const REMOVE_COMMERCIAL_OPTS = "REMOVE_COMMERCIAL_OPTS";

// frequencies
export const SET_FREQUENCIES = "SET_FREQUENCIES";
export const REMOVE_FREQUENCIES = "REMOVE_FREQUENCIES";

// Carpet Options
export const SET_CARPET_CLEANING_OPTS = "SET_CARPET_CLEANING_AREAS";
export const REMOVE_CARPET_CLEANING_OPTS = "REMOVE_CARPET_CLEANING_OPTS";

// Contractor_Modal 

export const SET_JOB_ID = "SET_JOBID";
export const REMOVE_JOB_ID = "REMOVE_JOBID";
export const SHOW_OFFER_CONTRACTOR_MODAL = "SHOW_OFFER_CONTRACTOR_MODAL";

export const SET_DETAILS = "SET_DETAILS";
export const SET_ADMIN_INFO = "SET_ADMIN_INFO";
export const SET_ORDER_HISTORY = "SET_ORDER_HISTORY";


// formData
export const LOAD_FORM_DATA = "LOAD_FORM_DATA";
export const RESET_FORM_DATA = "RESET_FORM_DATA";
export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";
// Todo

export const SET_CURRENT_TODO = "SET_CURRENT_TODO";

// Popup

export const SET_MSG_AND_SHOW = "SET_MSG_AND_SHOW";

export const RESET_MSG = "RESET_MSG";


export const OPEN_PASS_CHANGE_MODAL = "OPEN_PASS_CHANGE_MODAL";
export const CLOSE_PASS_CHANGE_MODAL = "CLOSE_PASS_CHANGE_MODAL";



// Event data

export const LOAD_EVENT_DATA = "LOAD_EVENT_DATA";
export const RESET_EVENT_DATA = "RESET_EVENT_DATA";
// Employees
// export const SHOW_EMPLOYEE_MODAL = "SHOW_EMPLOYEE_MODAL";
// export const CLOSE_EMPLOYEE_MODAL = "CLOSE_EMPLOYEE_MODAL";