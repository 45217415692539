import React from "react";
import moment from "moment-timezone";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { muted } from "theme/colors";
import { withStyles } from "@material-ui/core/styles";
import { capitalizeFirstLetterOnly } from "../../utils/capitalize";
import { isEmpty } from "lodash";

const styles = theme => ({
  mutedHeading: {
    color: muted,
    fontWeight: 300
  }
});

const EmployeeDetailsModal = ({
  classes,
  isDetailsModalOpen,
  employeeItemObj = {},
  handleDetailsModal
}) => {

  
  return (
      <Dialog fullWidth open={isDetailsModalOpen} onClose={handleDetailsModal}>
        <DialogTitle id="job-modal-title">Employee Details</DialogTitle>
        <DialogContent>
          {employeeItemObj.businessName && (
            <Grid container alignItems="center" spacing={16}>
              <Grid item xs>
                <Typography
                  variant="subheading"
                  className={classes.mutedHeading}
                >
                  Company Name
                </Typography>
                <span>{employeeItemObj.businessName}</span>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            alignContent="center"
            alignItems="center"
            spacing={16}
          >
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Employee Name
              </Typography>
              <span>{capitalizeFirstLetterOnly(employeeItemObj.fullName)}</span>
            </Grid>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Email
              </Typography>
              <span>{employeeItemObj.email}</span>
            </Grid>
          </Grid>

          <Grid
            container
            alignContent="center"
            alignItems="center"
            spacing={16}
          >
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Shift Start Time
              </Typography>
              <span>
                {moment(employeeItemObj.jobShiftStartTime, "hh:mm:ss").format(
                  "hh:mm A"
                )}
              </span>
            </Grid>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Shift End Time
              </Typography>
              <span>
                {moment(employeeItemObj.jobShiftEndTime, "hh:mm:ss").format(
                  "hh:mm A"
                )}
              </span>
            </Grid>
          </Grid>

          <Grid
            container
            alignContent="center"
            alignItems="center"
            spacing={16}
          >
            {Array.isArray(employeeItemObj.workDays) &&
              employeeItemObj.workDays.length && (
                <Grid item xs>
                  <Typography
                    variant="subheading"
                    className={classes.mutedHeading}
                  >
                    Work Days
                  </Typography>
                  <Grid item container direction="column">
                    {employeeItemObj.workDays.map((day, i) => (
                      <Grid item key={i} xs>
                        {capitalizeFirstLetterOnly(day)}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Phone
              </Typography>
              <span>{employeeItemObj.phone}</span>
            </Grid>
          </Grid>

          <Grid container alignItems="center" spacing={16}>
            {/* {employeeItemObj.createdBy && (
              <Grid item xs>
                <Typography
                  variant="subheading"
                  className={classes.mutedHeading}
                >
                  Created By
                </Typography>
                <span>{employeeItemObj.createdBy}</span>
              </Grid>
            )} */}
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Created on
              </Typography>
              <span>
                {moment(employeeItemObj.createdat).format("DD MMM YYYY")}
              </span>
            </Grid>
          </Grid>

          <Grid container alignItems="center" spacing={16}>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Active
              </Typography>
              <span>{employeeItemObj.isActive ? "Yes" : "No"}</span>
            </Grid>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Verified
              </Typography>
              <span>
                {employeeItemObj.isVerified ? "Yes" : "No"}
              </span>
            </Grid>
          </Grid>

          <Grid container alignItems="center" spacing={16}>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Suspended
              </Typography>
              <span>{employeeItemObj.isSuspended ? "Yes" : "No"}</span>
            </Grid>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Deleted
              </Typography>
              <span>{employeeItemObj.isDeleted ? "Yes" : "No"}</span>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDetailsModal} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
};

export default withStyles(styles)(EmployeeDetailsModal);
