import React from "react";
import { isEmpty } from "lodash";
import Grid from "core/components/Grid";
import { Form } from "redux-form";
import { withStyles } from "core/components/Styles";
import { ExtendedAdvanceSelect, MaterialTextField, FormInputField } from "../FormField";
import Loader from "../ProgressCircle";
import ZButton from "core/components/Button";
import { required, email } from "../FormField/Validations";
import { MainLayout } from "../../layouts";
import { handleManualEmailFormValues } from "../../helpers/inputs";

const styles = theme => ({
  inputMarginTop_10: {
    marginTop: 10
  }
});

const ManualEmailDashboard = (props) => {
  const {
    classes,
    jobList,
    userList,
    searchJob,
    searchUser,
    emailTypes,
    submitData,
    sendingEmail,
    handleSubmit,
    gettingInitialData,
    handleSelectedJob,
    handleSelectedUser,
    handleSelectedEmailType
  } = props;
  const { formValues } = props;
  const { 
    emailRelatedTo,
    isJobInputEnabled, 
    isUserInputEnabled 
  } = handleManualEmailFormValues(formValues);

  return (
    <MainLayout pageName = "Emails">
      {(gettingInitialData || sendingEmail) && <Loader position="fixed" zIndex={999} />}
      <Form onSubmit={handleSubmit(submitData)}>
        <Grid container justify="center" alignContent="center" >
          <Grid container item justify="space-around" spacing={8}>
            <Grid item xs={12} className={classes.inputMarginTop_10}>
              <FormInputField
                label="Email Type"
                type="text"
                id="emailType"
                name="emailType"
                component={ExtendedAdvanceSelect}
                onChange={handleSelectedEmailType}
                options={emailTypes}
                backgroundColor="transparent"
                placeholderValue="Select Email Type"
                validate={[required]}
                material
                disableUnderline
              />
            </Grid>
            <Grid item xs={12} className={classes.inputMarginTop_10}>
              <FormInputField
                async
                label="Select Job Id"
                type="text"
                id="job"
                name="job"
                component={ExtendedAdvanceSelect}
                onChange={handleSelectedJob}
                options={jobList}
                loadOptions={searchJob}
                defaultOptions={jobList}
                validate={emailRelatedTo === "jobs" ? [required] : []}
                placeholderValue="Search Job By Id"
                backgroundColor="transparent"
                material
                disableUnderline
                isDisabled={emailRelatedTo === "users" || isUserInputEnabled}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputMarginTop_10}>
              <FormInputField
                async
                label="Select User"
                type="text"
                id="user"
                name="user"
                component={ExtendedAdvanceSelect}
                options={userList}
                loadOptions={searchUser}
                onChange={handleSelectedUser}
                defaultOptions={userList}
                placeholderValue="Search by Name, email or phone"
                backgroundColor="transparent"
                material
                disableUnderline
                isDisabled={emailRelatedTo === "jobs" || isJobInputEnabled}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputMarginTop_10}>
              <FormInputField
                label="Receiver Email"
                type="text"
                id="email"
                name="email"
                placeholder="Enter Email Address"
                component={MaterialTextField}
                validate={[required,email]}
                disableUnderline
              />
            </Grid>
          </Grid>

          <Grid item xs={6} className={classes.inputMarginTop_10}>
            <ZButton fullWidth type="submit" gradient color="primary">
              Send Email
            </ZButton>
          </Grid>
        </Grid>
      </Form>
    </MainLayout>
  );
};

export default withStyles(styles)(ManualEmailDashboard);
