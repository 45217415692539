import { LOAD_FORM_DATA, RESET_FORM_DATA, UPDATE_FORM_DATA } from "../types";


export const loadFormData = (payload) => ({
  type:LOAD_FORM_DATA,
  payload
})

export const resetFormData = () => ({
  type:RESET_FORM_DATA
})


export const updateFormData = (payload) => ({
  type:UPDATE_FORM_DATA,
  payload
})