import React, { PureComponent } from "react";
import UpdateEventForm from "../components/EventForm";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { setMsgAndShow } from "../store/actions/popup";
import { resetFormData } from "../store/actions/formData";
import { updateEvent, removeEvent } from "../helpers/events";
import { cloneDeep } from "lodash";

class UpdateEventFormContainer extends PureComponent {

  state = {
    processing:false
  }

  closeForm = () => {
    this.props.closeModal();
    this.props.reset();
    this.props.resetFormData();
  }
  
  submitData = async (formValues) => {
    try {

      this.setState({
        processing:true
      })

      const { eventId, ...restEventData } = cloneDeep(formValues);
      restEventData.type = restEventData.type.value;
      const res = await updateEvent(`${process.env.REACT_APP_API_WITH_PREFIX}admin/events/edit/${eventId}`,{...restEventData});
      this.setState({
        processing:false
      })
      this.closeForm();
      if(this.props.onSuccess){
        this.props.onSuccess(res.event);
      }
      this.props.setMsgAndShow("Unavailability details updated");
    } catch (error) {

      let errMsg = "Unable to update unavailability details";
      const { response } = error;

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        processing:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  }


  removeEvent =  async () => {
    try {
      this.setState({
        processing:true
      })

      const { eventId } = this.props.formValues;

      const res = await removeEvent(`${process.env.REACT_APP_API_WITH_PREFIX}admin/events/remove/${eventId}`)
      this.setState({
        processing:false
      })
      this.closeForm();
      if(this.props.onRemove){
        this.props.onRemove(res.event);
      }

      this.props.setMsgAndShow("Removed unavailability");

    } catch (error) {
      let errMsg = "Unable to remove unavailability";
      const { response } = error;

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        processing:false
      })
      this.props.setMsgAndShow(errMsg);
    }

  }

  eventTypesOptions = [
    {
      label:"Unavailability",
      value:"unavailability"
    }
  ]
  render(){

    return (
      <UpdateEventForm 
        {...this.props}
        processing={this.state.processing}
        submitData={this.submitData}
        closeForm={this.closeForm}
        title="Unavailability details"
        mode="edit"
        eventTypesOptions={this.eventTypesOptions}
        removeEvent={this.removeEvent}
      />
    )
  }
}

const WithForm = reduxForm({
  form: "UpdateEventForm",
  destroyOnUnmount: true,
  enableReinitialize:true,
  keepDirtyOnReinitialize:true
})(UpdateEventFormContainer);

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow:(message) => dispatch(setMsgAndShow(message)),
  resetFormData:() => dispatch(resetFormData())
})


const mapStateToProps = (state) => {
    let formValues = {};
    if(state.form.UpdateEventForm && state.form.UpdateEventForm.values){
      formValues = state.form.UpdateEventForm.values;
    }
    return {
      formValues,
      initialValues:state.formData.formData
    };

}


export default connect(mapStateToProps,mapDispatchToProps)(WithForm)