import React, { Component } from "react";
import axios from "utils/http";
import _ from "lodash";
import { connect } from "react-redux";
// import socket, { createRoom } from "socket"
import { setAdminInfo } from "../store/actions/admin";
// import { toast } from "react-toastify";
import Storage from "../utils/Storage";
import Login from "../components/Login";
import { setMsgAndShow } from "../store/actions/popup";
import deviceInfo from "../utils/detectDevice";


class LoginContainer extends Component {
  state = {
    user: {},
  }

  handleChange = (prop) => (e) => {
    const user = _.cloneDeep(this.state.user);

    user[prop] = e.target.value;
    
    this.setState({
      user
    })
  }

  handleLogin = async ()=> {
    const { user } = this.state;

    if(_.isEmpty(user)){
      return alert("Please enter your credentials");
    }
    try {
      const { data } = await axios.post("user/login",{...user, deviceInfo});


      let { userInfo, authToken } = data;

      if(userInfo.userType === 'consumer' || userInfo.userType === "contractor" || userInfo.userType === "employee"){
        this.props.setMsgAndShow("UNAUTHORIZED")
        return this.props.history.replace('/login');
      }

      this.props.setAdminInfo(userInfo);
      Storage.set('adminInfo', JSON.stringify(userInfo))
      Storage.set('auth_token', authToken)
      this.props.history.replace('/');
      
    } catch (error) {
      const { response } = error;
      this.props.setMsgAndShow(response  ? response.data.message : "Error while logging");
    }
  }

  render(){
    return (
      <Login 
        handleChange={this.handleChange}
        handleLogin={this.handleLogin}
        {...this.state}
      />
    )
  }
}


const mapDispatchToProps = (dispatch) => ({
  setAdminInfo: (adminInfo) => dispatch(setAdminInfo(adminInfo)),
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg))
})

export default connect(null, mapDispatchToProps)(LoginContainer);