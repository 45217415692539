import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles"; 
// import _ from "lodash";
import logo from "../../files/images/logo.jpg";

const styles = theme => ({
  root: {
    backgroundColor: "#f9f9f9",
    height: "100vh",
    width: "100%"
  },
  mainData: {
    padding: 20
  },
  mainDiv: {
    width: "100%"
  },
  title: {
    marginTop: 20
  },
  mainCard: {
    width: 400
  },
  loginBtn: {
    marginTop: 10,
    backgroundColor: "#4a4a4a",
    color: "white"
  },
  textCenter: {
    textAlign: "center"
  },
  secondItem: {
    marginTop: 30
  }
});



const MainPage = ({ classes }) => {
  
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Card raised={true}>
        <CardContent className={classes.mainCard}>
          <Grid
            container
            className={classes.mainData}
            justify="center"
            alignContent="center"
            alignItems="center"
            direction="column"
          >
            <img src={logo} alt="logo" />
            <span className={classes.title}>Zoklean Dashboard</span>
          </Grid>
          <Grid className={classes.secondItem}>
            <Link to="/login">
              <Button component="div" fullWidth className={classes.loginBtn}>
                Login
              </Button>
            </Link>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}



export default withStyles(styles)(MainPage);
