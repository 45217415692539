import React, { Component } from "react";
import axios from "utils/http";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { isEmpty } from "lodash";
import { setMsgAndShow } from "store/actions/popup";
import { loadFormData } from "../store/actions/formData";
import ManualEmailDashboard from "../components/ManualEmailDashboard";
import { getOptionsList } from "../helpers/emailDashboardHelpers";

class ManualEmailDashboardContainer extends Component {
  state = {
    gettingInitialData: false,
    sendingEmail: false,
    jobList: [],
    userList: []
  };

  emailTypes = [];

  componentDidMount() {
    this.addData();
  }

  addData(){
    this.setState({ gettingInitialData: true });
    this.props.loadFormData({});
    this.getEmailTypeList();
    this.getJobs();
    this.getUsers();
    this.setState({ gettingInitialData: false });
  }

  getEmailTypeList = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_WITH_PREFIX}admin/mail/get_email_types`);
      if (res && res.data && res.data.status === "OK") {
        if(!isEmpty(res.data.emailTypes) && res.data.emailTypes.length > 0){
          this.emailTypes = res.data.emailTypes;
        }
      }
    } catch (error) {
      console.log("List Error : ", error); // eslint-disable-line
    }
  };

  getJobs = async()=>{
    const { setMsgAndShow } = this.props;
    let popUpMsg = "Error occured";
    let showPopUp = true;
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_WITH_PREFIX}admin/orders/search`);      
      if (res && res.data && res.data.status === "OK") {
        const jobs = res.data.orders || [];
        const jobList = getOptionsList(jobs, "displayId", "_id");
        this.setState({ jobList }); 
        showPopUp = false;
      }
    } catch (error) {
      const { response } = error;
      popUpMsg = (response && response.data && response.data.message) || popUpMsg;
    }
    if(showPopUp) setMsgAndShow(popUpMsg);
  };

  getUsers = async()=> {
    const { setMsgAndShow } = this.props;
    let popUpMsg = "Error occured";
    let showPopUp = true;
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_WITH_PREFIX}admin/users/search`);
      if (res && res.data && res.data.status === "OK") {
        const users = res.data.users || [];
        const userList = getOptionsList(users, "fullName", "_id");
        this.setState({ userList }); 
        showPopUp = false;
      }
    } catch (error) {
      const { response } = error;
      popUpMsg = (response && response.data && response.data.message) || popUpMsg;
    }
    if(showPopUp) setMsgAndShow(popUpMsg);
  };

  searchJob = async (jobId) => {
    const params = {
      q: jobId && jobId.trim()
    }
    let jobList = [];
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_WITH_PREFIX}admin/orders/search`, {
        params
      });
      if (res && res.data && res.data.status === "OK") {
        const jobs = res.data.orders || [];
        jobList = getOptionsList(jobs, "displayId", "_id");
      }
    } catch (error) {
      console.log("Error ",error); // eslint-disable-line
    }    
    return jobList;
  };

  searchUser = async (q) => {
    const params = {
      q: (q && q.trim()) || undefined
    };
    let userList = [];
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_WITH_PREFIX}admin/users/search`, {
        params
      });
      if (res && res.data && res.data.status === "OK") {
        const users = res.data.users || [];
        userList = getOptionsList(users, "fullName", "_id");
      }
    } catch (error) {
      console.log("Error ",error); // eslint-disable-line
    }    
    return userList;
  };

  handleSelectedJob = (job)=> {
    if(!isEmpty(job)){
      this.props.change("email",job.customerEmail);
    }
  };

  handleSelectedUser = (user)=> {
    if(!isEmpty(user)){
      this.props.change("email",user.email);
    }
  }

  handleSelectedEmailType = (emailType)=> {
    const { change, formValues } = this.props;
    if( !isEmpty(emailType) && !isEmpty(formValues) ){
      const { relatedTo } = emailType;
      const { job, user, email } = formValues;
      if(relatedTo === "jobs"){
        change("user","");
        if( !( email === (job && job.customerEmail) ) ){
          change("email","");
        }
      }
      if(relatedTo === "users"){
        change("job","");
        if( !( email === (user && user.email) ) ){
          change("email","");
        }
      }
    }
  }

  submitData = async(formData)=> {
    let popUpMsg = "Error occured. Please try again later.";
    const { setMsgAndShow, loadFormData, reset } = this.props;
    this.setState({sendingEmail: true});
    try {
      if(!isEmpty(formData)){
        const { email, emailType, job } = formData;
        const params = {
          email,
          type: emailType.value
        };

        const user = formData.user || undefined;
        let res = undefined;
        switch (emailType.relatedTo) {
        case "jobs":
          res = this.handleJobsRelatedEmails(job, params);
          break;
        case "users":
          res = this.handleUsersRelatedEmails(user, params);          
          break;
        default: break;
        }

        res = await res;
        const { status, message } = res;
        if(status === "Error"){
          throw new Error(message);
        } 

        popUpMsg = message;
        reset();
        loadFormData({});
      }
    } catch (error) {
      popUpMsg = error.message;
      console.log("error is here ",error);  // eslint-disable-line
    }
    this.setState({sendingEmail: false});
    setMsgAndShow(popUpMsg);
  };

  handleJobsRelatedEmails = async(job, paramObj)=> {
    const response = {
      status: "Error"
    };
    try {
      const { _id:jobId } = job;
      const res = await axios.post(`${process.env.REACT_APP_API_WITH_PREFIX}admin/mail/${jobId}/job`,{
        ...paramObj
      });
      if(res && res.data && res.data.status === "OK"){
          response.status = res.data.status;
          response.message = "Mail Sent Successfully.";
    
      }
    } catch (error) {
      const { response: resObj } = error;
      response.message = (resObj && resObj.data && resObj.data.message) || "Error occured";
    }
    return response;
  };

  handleUsersRelatedEmails = async(user, paramObj)=> {
    const response = {
      status: "Error"
    };
    try {
      if(user && user._id){
        paramObj.userId = user._id;
      }

      const res = await axios.post(`${process.env.REACT_APP_API_WITH_PREFIX}admin/mail/users`, paramObj);
      if(res && res.data && res.data.status === "OK"){
        response.status = res.data.status;
        response.message = "Mail Sent Successfully.";
      }
    } catch (error) {
      const { response: resObj } = error;
      response.message = (resObj && resObj.data && resObj.data.message) || "Error occured";
    }
    return response;
  };

  componentWillUnmount(){
    this.emailTypes = undefined;
  }

  render() {
    return (
      <ManualEmailDashboard 
        {...this.props}
        {...this.state}
        submitData = {this.submitData}
        searchJob = {this.searchJob}
        searchUser = {this.searchUser}
        handleSelectedEmailType = {this.handleSelectedEmailType}
        handleSelectedJob = {this.handleSelectedJob}
        handleSelectedUser = {this.handleSelectedUser}
        emailTypes = {this.emailTypes}
      />
    );
  }
}

const WithFormComponent = reduxForm({
  form: "ManualEmailForm",
  destroyOnUnmount: true
})(ManualEmailDashboardContainer);

const mapStateToProps = state => ({
  formValues: (
    state &&
    state.form &&
    state.form.ManualEmailForm &&
    state.form.ManualEmailForm.values
  ) || {}
});

const mapDispatchToProps = dispatch => ({
  loadFormData: payload => dispatch(loadFormData(payload)),
  setMsgAndShow: message => dispatch(setMsgAndShow(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(WithFormComponent);
