import React, { Component } from 'react';
import Loader from "./components/ProgressCircle";
import ChangePassModal from "./containers/ChangePasswordModalContainer";
// import socket, { createRoom } from "socket"
// import { addNewJob, removeJob} from "store/actions/jobs";
import { setMsgAndShow, resetMsg} from "store/actions/popup";
import { appRoutes, SwitchRoutes } from "./routes";
import Storage from "./utils/Storage";
import { connect } from "react-redux";

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { Close } from "mdi-material-ui";
import axios from 'utils/http';
import { setCarpetCleaningOpts, setFrequencies, setPriceList, storeCommercialOpts, storeDomesticPriceList, storeDomesticTypes} from "./store/actions/app";
// import _ from "lodash";
import { setAdminInfo } from "./store/actions/admin";

class App extends Component {

  componentDidMount(){
    this.checkLogin();
    this.getCleaningOptions();
  }

  checkLogin = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_PREFIX}user/is_logged_in`)
      
      const { isLoggedIn, userInfo} = res.data;


      if(isLoggedIn){

          if(userInfo.userType === "customer" || userInfo.userType === "contractor" || userInfo.userType === "employee"){
            this.props.setMsgAndShow("UNAUTHORIZED")
            return window.location.href('/login')
          }
          this.props.setAdminInfo(userInfo);
          Storage.set("adminInfo", JSON.stringify(userInfo));

      } else {        
        if(window.location.pathname !== "/login" && window.location.pathname !== "/signup" && window.location.pathname !== "/" ) {
          window.location.href = "/login";
        } 
        Storage.remove("adminInfo");
      }
    } catch (error) {
      // if(window.location.pathname !== "/login" && window.location.pathname !== '/' && window.location.pathname !== '/signup') {
      //   Storage.remove("adminInfo");
      //   window.location.href = "/login";
      // }


    }
  }


  getPricingList = async () => {
    let priceList = {};
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}cleaning/get_pricing_list`);
      if(res.data.status === "OK"){
        priceList = res.data.pricingList;
      }



      this.props.setPriceList(priceList);
      // this.props.storeDomesticPriceList(priceList);
      // this.props.setDomesticPricingList(domesticPricingList);
    } catch (error) {
      console.log("ERROR IN PRICINGLIST", error);
    } 
  }

  getCleaningTypes = async ()=>{
    let cleaningTypes;
    try {
      const dataResult = await axios.get(`${process.env.REACT_APP_API_BASE_URL}cleaning/get_cleaning_types`);
      if(dataResult && dataResult.data && dataResult.statusText === "OK"){
         cleaningTypes = dataResult.data.types || [];
      }

      // this.props.setCleaningType(cleaningTypeObj);
      this.props.storeDomesticTypes(cleaningTypes)
    } catch (error) {
      console.log("ERROR IN getCleaningTypes", error);
    }
  };

  getCommercialOptionLists = async()=>{
    const commercialOptionListsObj = {
      floorTypes:[],
      frequencyTypes:[],
      cleaningServices:[],
      equipmentTypes:[],
      employeeOpts:[]
    };
    try {
      const freqRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}cleaning/get_commercial_frequency_types`);
      const serviceRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}cleaning/get_commercial_service_types`);
      const floorRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}cleaning/get_commercial_floor_types`);
      const equipRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}cleaning/get_commercial_equipment_types`);
      const empRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}cleaning/get_commercial_employee_options`);
      
      if( freqRes && freqRes.data && freqRes.data.status === "OK" ){
        commercialOptionListsObj.frequencyTypes = [ ...freqRes.data.commercialFrequencyTypes ];
      }
      if( serviceRes && serviceRes.data && serviceRes.data.status === "OK" ){
        commercialOptionListsObj.cleaningServices = [ ...serviceRes.data.commercialOnlyServices ];
      }
      if( floorRes && floorRes.data && floorRes.data.status === "OK" ){
        commercialOptionListsObj.floorTypes= [ ...floorRes.data.flooringTypes ];
      }
      if( equipRes && equipRes.data && equipRes.data.status === "OK" ){
        commercialOptionListsObj.equipmentTypes = [ ...equipRes.data.equipmentTypes ];
      }
      if( empRes && empRes.data && empRes.data.status === "OK" ){
        commercialOptionListsObj.employeeOpts = [ ...empRes.data.employeeOptionsList ];
      }
      // this.props.setCommercialOptionLists(commercialOptionListsObj);
      this.props.storeCommercialOpts(commercialOptionListsObj)
    } catch (error) {
      console.log("Error in getCommercialOptionLists", error);
    } 
  }

  getCleaningOptions = async () => {
    try {
      this.getPricingList();
      this.getCommercialOptionLists();
      this.getCleaningTypes();
      this.getCarpetOptions();
      this.getFrequencies();
    } catch (error) {
      console.log("Error", error);
    }
  }

  getCarpetOptions = async () => {
    try {

      let cleaningAreas = [];
      let addOns = [];
      const cleaningAreasRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}cleaning/carpet_cleaning_areas`);
      const addOnsRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}cleaning/carpet_add_ons`);


      if(Array.isArray(addOnsRes.data.list) && addOnsRes.data.list.length){
        const addOnsList = addOnsRes.data.list;
        addOns = addOnsList.map((addOn) => ({ label:addOn.name,value:addOn._id}))
      }

      if(Array.isArray(cleaningAreasRes.data.list) && cleaningAreasRes.data.list.length){
        const cleaningAreaList = cleaningAreasRes.data.list;
        cleaningAreas = cleaningAreaList.map((cleaningArea) => ({label:cleaningArea.name,value:cleaningArea._id}));
      }

      const carpetOptionsObj = {
        addOns,
        cleaningAreas
      }
      // carpetOptionsObj.addOns = addOnsRes.data.list;
      // carpetOptionsObj.cleaningAreas = cleaningAreasRes.data.list;


      this.props.setCarpetCleaningOpts(carpetOptionsObj);
    } catch (error) {
      console.log("Error while getting carpet options", error);
    }
  }

  getFrequencies = async () => {
    try {
      let frequencies = [];
      let freqOpts = [];
      const frequenciesRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}cleaning/frequencies`);

      const frequencyList = frequenciesRes.data.list;

      if(Array.isArray(frequencyList) && frequencyList.length){
        frequencies = frequencyList.map((freq) => {
          freqOpts.push({
            label:freq.label,
            value:freq.value
          })
          return {
            label:freq.label,
            value:freq._id,
            mappedTo:freq.value,
            period:freq.period,
            frequency:freq.frequency
          }
        })
      }

      this.props.setFrequencies({frequencies, freqOpts});
    } catch (error) {
      console.log("Error while getting frequencies", error);
    }
  }


  handlePopUp = () => {
     this.props.resetMsg()
  }
  render() {

    const { popupObj, showRootLoader, adminInfo:{userType} } = this.props;
    return (
      <div>
        {/* <AppRoutes /> */}

        <SwitchRoutes routes={appRoutes} userType={userType} redirectTo="/"/>

        {showRootLoader && <Loader />}

        <ChangePassModal />
        <Snackbar
          anchorOrigin={{ 
            vertical: popupObj.vertical,
            horizontal: popupObj.horizontal
          }}
          open={popupObj.isPopupOpen}
          onClose={this.handlePopUp}
          ContentProps={{
            'aria-describedby': 'popup-msg',
          }}
          autoHideDuration={6000}
          action={
          <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handlePopUp}
            >
              <Close />
            </IconButton>
          }
          message={<span id="popup-msg">{popupObj.msg}</span>}
          
        />
      </div>
    );
  } 
}



const mapStateToProps = (state) => ({
  popupObj: state.popup,
  adminInfo:state.admin.adminInfo,
  showRootLoader:state.app.showLoader
})

const mapDispatchToProps = (dispatch) => ({
  setAdminInfo: (adminInfo) => dispatch(setAdminInfo(adminInfo)),
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg)),
  resetMsg: () => dispatch(resetMsg()),
  storeDomesticPriceList: (list) => dispatch(storeDomesticPriceList(list)),
  storeDomesticTypes: (types) => dispatch(storeDomesticTypes(types)),
  storeCommercialOpts: (opts) => dispatch(storeCommercialOpts(opts)),
  setCarpetCleaningOpts:(opts) => dispatch(setCarpetCleaningOpts(opts)),
  setFrequencies:(list) => dispatch(setFrequencies(list)),
  setPriceList:(list) => dispatch(setPriceList(list)),
})
export default connect(mapStateToProps, mapDispatchToProps)(App);
