
import JobsDashboardPage from "../pages/JobsDashboardPage.js";
import EditJobPage from "../pages/EditJobPage";
import PriceListsPage from "../pages/PriceListsPage";
import ContractorsScene from "../containers/ContractorsDashboardContainer";
import FlaggedContractorsPage from "../pages/FlaggedContractorsPage";
import MainScene from "../containers/MainPageContainer";
import LoginScene from "../containers/LoginContainer";
import EmployeesPage from "../containers/EmployeeDashboardContainer";
import UsersPage from "../pages/UsersPage";
import ReviewsScene from "../containers/ReviewsContainer";
import CompletedJobsScene from "../containers/CompletedJobsContainer";
import PayoutsScene from "../containers/PayoutsContainer";
import ManualEmailDashboardPage from "../containers/ManualEmailDashboardContainer";
import ManagersDashboardPage from "../pages/ManagersDashboardPage";
import TiersPage from "../pages/TiersPage";
import EventsPage from '../pages/EventsPage';
import ContractorsEarningsPage from "../pages/ContractorsEarningsPage";
import ContractorCompletedJobsPage from "../pages/ContractorCompletedJobsPage";

const appRoutes = [
  {
    path:"/",
    component:MainScene,
    exact:true
  },
  {
    path:"/jobs",
    component:JobsDashboardPage,
    exact:true,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/jobs/:jobId",
    component:EditJobPage,
    // exact:true,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/flagged_contractors",
    component:FlaggedContractorsPage,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/contractors",
    component:ContractorsScene,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/contractors_earnings",
    component:ContractorsEarningsPage,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/employees",
    component:EmployeesPage,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/login",
    component:LoginScene,
  },
  {
    path:"/users",
    component:UsersPage,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/reviews",
    component:ReviewsScene,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/completed_jobs",
    component:ContractorCompletedJobsPage,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/payouts",
    component:PayoutsScene,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/price_lists",
    component:PriceListsPage,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/emails",
    component:ManualEmailDashboardPage,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/managers",
    component:ManagersDashboardPage,
    authorizedUser:["admin"],
  },
  {
    path:"/tiers",
    component:TiersPage,
    authorizedUser:["admin", "manager"],
  },
  {
    path:"/unavailabilities",
    component:EventsPage,
    authorizedUser:["admin", "manager"],
  },
]
export default appRoutes;
