import React, { PureComponent } from 'react'
import axios from "utils/http";
import { reduxForm } from "redux-form";
import { setMsgAndShow } from "../store/actions/popup";
import { connect } from "react-redux";
import { cloneDeep} from "lodash";
import removeProp from "../utils/removeProp";
import confirmResponse from "../utils/confirmResponse";
import EditPriceListForm from "../components/PriceListForm";
import { resetFormData } from "../store/actions/formData";
import { ZOK_PRICE_LIST_EXISTS } from "../variables/errorCode";


export class AddPriceListModalContainer extends PureComponent {
  state = {
    processing:false
  }
  cToken = undefined;

  submitData = async (formValues) => {
    try {
      this.setState({
        processing:true,
      })
      let dataToSend = cloneDeep(formValues);
      const { frequency, type,priceListId, couponsAllowed,  ...restData } = dataToSend;
      dataToSend = {
        ...restData,
        frequency: frequency.value,
        type:restData.listType === "domestic" ? type.value : "carpet",
        couponsAllowed: Boolean(parseInt(couponsAllowed))
      }
      dataToSend = removeProp(dataToSend,["hiddenZipcodes","zipcode"]);
      await axios.put(`${process.env.REACT_APP_API_WITH_PREFIX}admin/price_lists/edit/${priceListId}`,{...dataToSend, cToken:this.cToken});
      this.props.setMsgAndShow("Price list updated successfully")
      this.cToken = undefined;
      this.setState({
        processing:false
      })
      this.closeForm();
      if(this.props.onSuccess){
        this.props.onSuccess();
      }

      
    } catch (error) {
      const { response } = error;
      let showError = true;
      let errorMessage = "Unable to add price list!";
      if(response.status = 409 && response.data.code === ZOK_PRICE_LIST_EXISTS){
        showError = false;
        this.handleConflict(response);
      } else if(response && response.data && response.data.message){
        errorMessage = response.data.message;
      }
      this.setState({
        processing:false,
      })
      if(showError){
        this.props.setMsgAndShow(errorMessage);
      }
    }
  }
  handleConflict = async (response) => {
    try {

      const {  zipcodes } = response.data;
      const confirmed = await confirmResponse( 
        "Do you want to continue ?", 
        `Price lists for ${zipcodes.join(",")} will be overridden`
      );
      if(!confirmed) return;
      this.cToken = response.data.cToken;
      this.props.handleSubmit(this.submitData)();
    } catch (error) {
      const { response } = error;
      let errorMessage = "Unable to add price list!";
      if(response && response.data && response.data.message){
        errorMessage = response.data.message;
      }
      this.props.setMsgAndShow(errorMessage);
    }
  }
  closeForm = () => {
    this.props.reset();
    this.props.closeModal();
    this.props.resetFormData();
  }

  addZipcode = () => {
    const { formValues, array } = this.props;
    if(!formValues || !formValues.zipcode){
      alert("Please add a valid zipcode!");
      return;
    }

    const { zipcode, zipcodes = [] } = formValues;
    let joinedList = [];
    if(zipcodes && zipcodes.length){
     joinedList = [...zipcodes, zipcode];
    } else {
      joinedList.push(zipcode);
    }
    this.props.change("hiddenZipcodes", joinedList.join(","));
    array.push("zipcodes",formValues.zipcode);
    this.props.clearFields(false,false,"zipcode");

  }

  onZipcodeDelete = (zipcode) => {

    const { formValues } = this.props;

    if(!formValues || !formValues.hiddenZipcodes){
      return;
    }
    const { hiddenZipcodes } = formValues;
    const zipcodes = hiddenZipcodes.split(",").filter((zi) => zi !== zipcode).join(",");
    this.props.change("hiddenZipcodes",zipcodes);
  }

  onListTypeChange = () => {
    this.props.change("type", null);
  }
  render() {
    return (
      <EditPriceListForm 
        {...this.props}
        {...this.state}
        processing={this.state.processing}
        submitData={this.submitData}
        closeForm={this.closeForm}
        title="Edit Price List"
        addZipcode={this.addZipcode}
        onZipcodeDelete={this.onZipcodeDelete}
        onListTypeChange={this.onListTypeChange}
      />
    )
  }
}



const mapStateToProps = (state) => {
  let formValues = {};

  if(state.form.EditPriceListForm){
    formValues = state.form.EditPriceListForm.values;
  } 

  return {
    formValues,
    initialValues: state.formData.formData,
    frequencies:state.app.cleaningOpts.freqOpts,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  resetFormData:() => dispatch(resetFormData())
})


const WithForm = reduxForm({
  form: "EditPriceListForm",
  destroyOnUnmount: true,
  enableReinitialize:true,
})(AddPriceListModalContainer);

export default connect(mapStateToProps,mapDispatchToProps)(WithForm);