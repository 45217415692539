import React from "react";
import { Form } from "redux-form";
import Grid from "../../core/components/Grid";
import Dialog from "../../core/components/Dialog";
import DialogActions from "../../core/components/DialogActions";
import DialogContent from "../../core/components/DialogContent";
import DialogTitle from "../../core/components/DialogTitle";
import ZTypography from "core/components/Typography";
import ZButton from "core/components/Button";
import Loader from "../ProgressCircle";
import { MaterialTextField, FormInputField } from "../FormField";
import { formatPhoneNumber, normalizePhone } from "../FormField/formatters";
import { required, matchPasswords, email } from "../FormField/Validations";



const ManagerFormModal = ({
  isModalOpen,
  closeModal,
  handleSubmit,
  submitData,
  mode = "add",
  loadLoader,
}) => {

  const isEditMode = mode === "edit";
  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      fullWidth
    >

      {loadLoader && <Loader position="fixed" zIndex={999} /> }
      <Form
        onSubmit={handleSubmit(submitData)}
      >
        <DialogTitle disableTypography>
          <ZTypography color="secondary" gradient bold fontSize={25}>
            {isEditMode ? "Update Manager" : "Add Manager"}
          </ZTypography>
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <FormInputField
                  name="fname"
                  label="First Name"
                  component={MaterialTextField}
                  // shrink
                  placeholder="John"
                  validate={[required]}
                  disableUnderline  
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputField
                  label="Last Name"
                  name="lname"
                  // shrink
                  component={MaterialTextField}
                  placeholder="Doe"
                  validate={[required]}
                  disableUnderline  
                />
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <FormInputField
                  label="Phone" 
                  name="phone"
                  placeholder="(999) 999-9999"
                  component={MaterialTextField}
                  validate={[required]}
                  disableUnderline                   
                  format={formatPhoneNumber}
                  parse={normalizePhone}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputField
                  name="email"
                  label="Email"
                  component={MaterialTextField}
                  placeholder="abc@test.com"
                  validate={[required, email]}
                  disableUnderline  
                />
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={6} >
                <FormInputField
                  name="password"
                  label="Password"
                  type="password"
                  component={MaterialTextField}
                  placeholder="Enter Password"
                  validate={isEditMode ? null : [required]}
                  label="Password"
                  disableUnderline  
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputField
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  component={MaterialTextField}
                  placeholder="Confirm Password"
                  disableUnderline  
                  validate={isEditMode ? null : [required, matchPasswords]}
                />
              </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <ZButton type="submit" color="primary" gradient>
            {isEditMode ? "Update Manager " : "Add Manager"} 
          </ZButton>
          <ZButton onClick={closeModal}>Cancel</ZButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}


export default ManagerFormModal;