import React from "react";
import Grid from "@material-ui/core/Grid";

const CleaningAreaOption = props => {
  const { option } = props;
  return (
    <Grid item xs>
      <span>
        {option.name}
      </span>
        {" "}
        --- 

      <span>
       {" "}
         {option.count} 
      </span>

    </Grid>
  );
};


export default CleaningAreaOption;