import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { MuiThemeProvider } from '@material-ui/core/styles';
import "./index.css";
import "assets/icons/style.css"
import "assets/css/helpers.css";

import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";
import store from "./store/store";
import theme from "./theme/theme"
ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
// registerServiceWorker();
