import React, { Component, Fragment } from 'react'; 
import axios from "../utils/http";
import _ from "lodash";
import { connect } from "react-redux";
import { setMsgAndShow } from "../store/actions/popup";
import Users from "../components/Users"


class UsersDashboardContainer extends Component {
  constructor(props){
    super(props);
    this.debounceFun = _.debounce(this.search, 250);
    this.state = {
      users:[],
      isCustomerFormOpen: false,
      totalCount: 0,
      perPage: 10,
      currPage:1,
      load: false,
      searchLoad: false,
      q:"",
      page:0,
    }

    this.inputRef = React.createRef();

  }
  
  searchValue = "";

  componentDidMount(){
    this.addData();
  }

  addData = async () => {
    const {currPage, perPage } = this.state;
    try {
      this.setState({
        load:true
      })
      const usersRes = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/list_users`,{
        params:{
         page: currPage,
         limit: perPage
        }
      })

      if(usersRes.data.status === "OK"){
        const { users, total, page, perPage: limit } = usersRes.data
        this.setState({ 
          users,
          totalCount: total,
          currPage:page,
          perPage: limit,
          load: false,
          page: page - 1,
        });
      }
    } catch (error) {
      const errorMsg = "Unable to get list!";
      this.setState({
        load:false
      })
      this.props.setMsgAndShow(errorMsg)
    }
  }

  getSearch = (e) => {   
    this.debounceFun(e.target.value);
  }

  clearSearch = ()=> {
    this.inputRef.current.value = "";
    this.search();
  }

  search = async (q) => {   

    this.searchValue = this.inputRef.current.value; 

    const { perPage,} = this.state;
    this.setState({searchLoad: true});

    if(q){
      q = q.trim().toLowerCase();
    }

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/list_users`,{
          params:{
            q: q,
            limit: perPage,
            page: 1
          }
        }
      );

      const { users, total, page, perPage: limit } = data;
      this.setState({
        users: users,
        q: q,
        totalCount: total,
        currPage:page,
        perPage: limit,
        page: page - 1,
        searchLoad: false
      })
      
    } catch (error) {
      const { response } = error;
      this.setState({
        searchLoad:false
      })
      this.props.setMsgAndShow(response ? response.data.message : "Error while getting Jobs list")
    }
  }

  makeUserInactive = async  (user) => {
    let { users } = this.state;
    const userId = user._id;

    try {
      this.setState({
        load:true
      })
     const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}admin/users/inactive`,{
        userId,
      });

      if(res.data.status === "OK"){
        const { user: newUser } = res.data;
        users = users.map((user) => user._id === userId ? newUser : user);
        this.setState({ users, load:false });
      }
    } catch (error) {
      let errorMsg = "Unable to process request!";
      const { response } = error;
      if(response && response.data && response.data.message){
        errorMsg = response.data.message;
      }
      this.setState({
        load:false
      })
      setMsgAndShow(errorMsg);
    }
  }


  makeUserActive =  async (user) => {

    let { users } = this.state;
    const { setMsgAndShow } = this.props;
    const userId = user._id

    try {

      this.setState({
        load:true
      })
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}admin/users/active`,{
         userId,
       })
 
       if(res.data.status === "OK"){
         const { user: newUser } = res.data;
         users = users.map((user) => user._id === userId ? newUser : user);
         this.setState({ users, load:false });
       }
     } catch (error) {
        let errorMsg = "Unable to process request!";
        const { response } = error;
        if(response && response.data && response.data.message){
          errorMsg = response.data.message;
        }
        this.setState({
          load:false
        })
        setMsgAndShow(errorMsg);
     }
  }

  verifyUser = async (user) => {
    let { users} = this.state;
    const { setMsgAndShow } = this.props;
    const userId = user._id
    try {

      this.setState({
        load:true
      })
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}admin/users/verify`,{
         userId,
       })
 
       if(res.data.status === "OK"){
         const { user: newUser } = res.data;
         users = users.map((user) => user._id === userId ? newUser : user);
         this.setState({ users, load:false });
       }
     } catch (error) {
       let errorMsg = "Unable to process request!";
       const { response } = error;
       if(response && response.data && response.data.message){
         errorMsg = response.data.message;
       }
       this.setState({
         load:false
       })
       setMsgAndShow(errorMsg);
     }
  }

  unVerifyUser = async (user) => {
    let { users } = this.state;
    const { setMsgAndShow } = this.props;
    const userId = user._id

    try {

      this.setState({
        load:true
      })
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}admin/users/unverify`,{
         userId,
       })
 
       if(res.data.status === "OK"){
         const { user: newUser } = res.data;
         users = users.map((user) => user._id === userId ? newUser : user);
         this.setState({ users, load:false });
       }
     } catch (error) {
      let errorMsg = "Unable to process request!";
      const { response } = error;
      if(response && response.data && response.data.message){
        errorMsg = response.data.message;
      }
      this.setState({
        load:false
      })
      setMsgAndShow(errorMsg);
     }
  }


  openCustomerForm = () => {
    this.setState({
      isCustomerFormOpen: true
    })
  }

  closeCustomerForm = () => {
    this.props.resetForm("AddCustomer");
    this.setState({
      isCustomerFormOpen: false
    })
  }

  submitSuccess = (customer) => {
    const { customers } = this.state;
    customers.push(customer);
    this.setState({
      customers
    })
  }
  getUsers = async (pageNumber) => {
    const { setMsgAndShow } = this.props;
    try {
      this.setState({
        load:true
      })
      const { perPage } = this.state;
      const usersRes = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/list_users`,{
        params:{
         page: pageNumber,
         limit: perPage,
         q:this.searchValue,
        }
      })

      if(usersRes.data.status === "OK"){
        const { users, total, page,  perPage: limit } = usersRes.data
        this.setState({ 
          users,
          totalCount: total,
          currPage:page,
          perPage: limit,
          load: false,
          page: page - 1,
        });
      }
    } catch (error) {
      const { response } = error;
      this.setState({
        load:false
      })
      setMsgAndShow(response ? response.data.message : "Error");
    }
  }

  actions = [
    {
      label:"Verify",
      onClick: this.verifyUser,
      checkForDisabled:true,
      disableBtn:(obj) => obj && obj.isVerified
    },
    {
      label:"Unverify",
      checkForDisabled:true,
      onClick: this.unVerifyUser,
      disableBtn:(obj) => obj && !obj.isVerified
    },
    {
      label:"Make Active",
      onClick: this.makeUserActive,
      checkForDisabled: true,
      disableBtn:(obj) => obj && obj.isActive
    },
    {
      label:"Make Inactive",
      onClick: this.makeUserInactive,
      checkForDisabled: true,
      disableBtn:(obj) => obj && !obj.isActive
    },
  ]
  render() {

    return (
      <Fragment>
        <Users 
          actions={this.actions} 
          openCustomerModal={this.openCustomerForm}
          closeCustomerForm={this.closeCustomerForm}
          submitSuccess={this.submitSuccess}
          getUsers={this.getUsers}
          getSearch={this.getSearch}

          searchValue={this.searchValue}
          clearSearch={this.clearSearch}
          searchInput={this.inputRef}

          {...this.state}
        />
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg))
})


export default connect(mapStateToProps, mapDispatchToProps)(UsersDashboardContainer);
