import moment from "moment-timezone";


export default (date, showOriginalDate) => {
  const startDate = moment(date);

  let dayToReturn;
  const diff = startDate.diff(new Date(), "days")
  
  switch(diff){

    case 0:
    dayToReturn = "Today";
    break;

    case -1:
    dayToReturn = "Yesterday";
    break;

    case 1:
    dayToReturn = "Tomorrow";
    break;

    default:
      if( diff < -1){

        if(showOriginalDate){
          dayToReturn = startDate.format("DD MMM, YYYY");
        } else {
          dayToReturn = `${Math.abs(diff)} days ago`

        }
      } else {
        dayToReturn = `in ${Math.abs(diff)} days`
      }
    }

  


  return dayToReturn

  // console.log(formattedDate);
  // let dateToReturn = null;
  // switch(formattedDate){

  //   case "2 days ago":
  //   dateToReturn = "Yesterday"
  //   break;

  //   default:
  //   dateToReturn = moment(date).format("MMM D")
  // }
}