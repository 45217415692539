
import {withStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";

const styles = {
  root: {
    color: "#49d3b9",
    "&$checked": {
      color: "#49d3b9",
    },
  },
  checked: {},
};

const GreenCheckbox = (props) => <Checkbox color="default" {...props} />;

export default withStyles(styles)(GreenCheckbox);