import React, { PureComponent } from "react";
import { Typography } from '@material-ui/core';
import Grid from "core/components/Grid";
import ZButton from "core/components/Button";
import Chip from "core/components/Chip";
import { withStyles } from "core/components/Styles";
const styles = (theme) => ({
  zipcodeItem:{
    margin: theme.spacing.unit / 2,
  }
})


class ZipcodesSelectorField extends PureComponent {
  deleteZipcode = (index) => () => {
    const val = this.props.fields.get(index);
    this.props.fields.remove(index);

    if(this.props.onDelete){
      this.props.onDelete(val);
    }
  }

  render(){
    const { fields, classes } = this.props;
    return (
        <Grid>
          { fields && fields.map((_, index) => {
            return (
              <Chip
                key={`zip-key-${index}`}
                label={fields.get(index)}
                onDelete={this.deleteZipcode(index)}
                className={classes.zipcodeItem}
              />
            )
          })}
        </Grid>
    )
  }
}



export default withStyles(styles)(ZipcodesSelectorField);