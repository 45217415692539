import React from 'react';
import Dialog from "core/components/Dialog";
import DialogTitle from "core/components/DialogTitle";
import DialogContent from "core/components/DialogContent";
import { Form, FieldArray, Field } from "redux-form";
import Loader from "../ProgressCircle";
import ZButton from "core/components/Button";
import ZTypography from "core/components/Typography";
import Grid from "core/components/Grid";
import DialogActions from "core/components/DialogActions";
import {
  // AdvanceSelectCompanyProfile,
  ExtendedAdvanceSelect,
  FormInputField,
  MaterialTextField,
  ZipcodesInputField,
  BasicInput,
  RadioSelectBtn,
  // MaterialCheckBox
} from "../FormField";

import {
  required,
  number,
  notNegativeOrZero
} from "../FormField/Validations";
import { withStyles } from "core/components/Styles";
import { Typography } from '@material-ui/core';
import { cleaningFrequencyTypes, cleaningTypes } from "./data";
import { ORDER_DISCOUNT_ALLOWED_OTPS, ORDER_PRICE_LIST_TYPES } from '../../variables/formData';
const styles = theme => ({
  modalCont: {},
  inputMarginTop_10: {
    marginTop: 10
  },
  cleaningTypeHeading: {
    marginRight: 0,
    marginTop: 0,
    marginBottom: 4,
    fontSize: 12,
    color: "#929292",
    lineHeight: "18px"
  },
  title: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  selectorCont: {
    border: "1px solid #4a4a4a",
    maxWidth: 150,
    fontSize: "20px",
    borderRadius: ".3rem",
    overflow: "hidden",
    margin: 0,
    marginTop: 5,
    padding: 0,
    "& .dec, .inc": {
      background: "#f8f8f8",
      width: "30%",
      cursor: "pointer",
      textAlign: "center",
      padding: "0.3rem 0.5rem",
      display: "inline-block"
    },
    "& span:first-child": {
      borderRight: "1px solid #4a4a4a"
    },
    "& span:last-child": {
      borderLeft: "1px solid #4a4a4a"
    }
  },
  inputSelect: {
    paddingTop: 0,
    "& [class*=fieldWrapper]": {
      height: 46,
      "& > span": {
        right: 0,
        color: "#c4c4c4",
        "& img": {
          width: "1.3rem",
          height: "1.3rem",
          opacity: 0.7
        }
      },
      "& > input": {
        color: "#646464",
        fontWeight: 500,
        lineHeight: "21px",
        fontSize: 14,
        height: 46,
        border: "solid 1px #d8d8d8",
        background: "#ffffff",
        padding: "5px 18px", //"20px 56px 20px 20px",
        "&::placeholder ": {
          color: "#b7b7b7"
        }
      }
    }
  },
  disp: {
    width: "40%",
    textAlign: "center",
    display: "inline-block"
  },
  propertyDetailsHeading: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  textCenter: {
    textAlign: "center"
  },
  addOnStyle: {
    backgroundColor: "blue"
  },
  setInCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputMarginTop_4: {
    marginTop: 4
  },
  noTopPadding: {
    paddingTop: "2px !important"
  },
  actualCostMsg: {
    fontSize: 12,
    color: "#929292"
  },
  additionalBookingsList: {
    marginTop: 15,
  },
  textLabel: {
    color: "#929292",
    fontSize: 12,
    marginBottom: 5
  },
  textSubLabel: {
    color: "#929292",
    fontSize: 10,
    // lineHeight: 18px;
  },
  inputRow: {
    marginTop: 5
  },
  inputField:{
    marginBottom:10
  }
  // costStyle:{
  //   display: "flex",
  //   alignItems: "center",
  //   height: 33
  // }
});

const PriceListForm = ({
  handleSubmit,
  submitData,
  open,
  closeForm,
  classes,
  title,
  addZipcode,
  onZipcodeDelete,
  processing,
  formValues,
  frequencies,
  onListTypeChange
}) => {

  const isDomesticList = formValues && formValues.listType === "domestic";
  return (
    <Dialog
      open={open}
      onClose={closeForm}
      fullWidth
      maxWidth="md"
    >
      {(processing) && <Loader position="fixed" zIndex={999} />}
      <Form onSubmit={handleSubmit(submitData)}>
        <DialogTitle id="form-dialog-title">
          <ZTypography color="secondary" gradient bold fontSize={25}>
            {title}
          </ZTypography>
        </DialogTitle>
        <DialogContent>
          <Grid className={classes.inputField}>

            <Typography className={classes.textLabel}>
              List Type
            </Typography>

            <Grid>
              <Field
                component={RadioSelectBtn}
                name="listType"
                validate={[required]}
                inRow
                options={ORDER_PRICE_LIST_TYPES}
                onOptionChange={onListTypeChange}
              />
            </Grid>
          </Grid>
          <Grid>
            <Typography className={classes.textLabel}>
              Zipcodes
            </Typography>
            <Grid container spacing={16} alignItems="center" >
              <Grid item xs={12} md={3}>
                <FormInputField
                  name="zipcode"
                  type="number"
                  id="zipcode"
                  placeholder="Enter zipcode"
                  component={MaterialTextField}
                  validate={[notNegativeOrZero]}
                  disableUnderline
                />
              </Grid>
              <Grid item  >
                <ZButton fullWidth gradient color="primary" onClick={addZipcode}>
                  Add Zipcode
                </ZButton>
              </Grid>
            </Grid>
            <FormInputField
              name="hiddenZipcodes"
              type="hidden"
              component={BasicInput}
              validate={[required]}
            />

            <FieldArray
              name="zipcodes"
              component={ZipcodesInputField}
              validate={[required]}
              onDelete={onZipcodeDelete}
            />


          </Grid>


          <Grid container justify="space-between" spacing={8} className={classes.inputRow}>
            {
              isDomesticList ?
              <Grid item xs={12} md={6}>
                <FormInputField
                  label="Cleaning Type"
                  id="cleaning-type"
                  name="type"
                  component={ExtendedAdvanceSelect}
                  options={cleaningTypes}
                  validate={[required]}
                  placeholderValue="Select Cleaning Type."
                  backgroundColor="transparent"
                  material
                  disableUnderline
                // menuPlacement="top" // To open the list to top direction

                />
              </Grid>
              : null
            }
            <Grid item xs={12} md={6} >
              <FormInputField
                label="Frequency"
                id="frequency"
                name="frequency"
                component={ExtendedAdvanceSelect}
                options={isDomesticList ? cleaningFrequencyTypes : frequencies}
                validate={[required]}
                placeholderValue="Select Frequency"
                backgroundColor="transparent"
                material
                disableUnderline
              />
            </Grid>
          </Grid>


          {
            isDomesticList ?
              <>
              <Grid container alignItems="center" spacing={16} className={classes.inputRow}>
                <Grid item>
                  <Typography className={classes.textLabel}>Base Cost ($)</Typography>
                  {/* <Typography className={classes.textLabel}>(includes 1 bath & 1 bed) </Typography> */}
                </Grid>
                <Grid item>
                  <FormInputField
                    type="number"
                    name="baseCost"
                    placeholder="Base Cost"
                    component={MaterialTextField}
                    validate={[required, number, notNegativeOrZero]}
                    disableUnderline
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={16} className={classes.inputRow}>
            <Grid item>
              <Typography className={classes.textLabel}>Per Bed Cost ($)</Typography>
            </Grid>
            <Grid item>
              <FormInputField
                type="number"
                name="bedCost"
                placeholder="Bed Cost"
                component={MaterialTextField}
                // onKeyPress={onAdminCostKeyChange
                validate={[required, number, notNegativeOrZero]}
                disableUnderline
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center" spacing={16} className={classes.inputRow}>
            <Grid item>
              <Typography className={classes.textLabel}>Per Bath Cost ($)</Typography>
            </Grid>
            <Grid item>
              <FormInputField
                type="number"
                name="bathCost"
                placeholder="Bath Cost"
                component={MaterialTextField}
                // onKeyPress={onAdminCostKeyChange
                validate={[required, number, notNegativeOrZero]}
                disableUnderline
              />
            </Grid>
          </Grid>
          </>
              : null

          }



          <Grid container alignItems="center" spacing={16} className={classes.inputRow}>
            <Grid item>
              <Typography className={classes.textLabel}>Discount (%)</Typography>
            </Grid>
            <Grid item>
              <FormInputField
                type="number"
                name="discount"
                placeholder="Discount"
                component={MaterialTextField}
                validate={[required, number]}
                disableUnderline
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={16} className={classes.inputRow}>
            <Grid item>
              <Typography className={classes.textLabel}>Additional Item Cost ($) </Typography>
              <Typography className={classes.textSubLabel}>(per item)</Typography>
            </Grid>
            <Grid item>
              <FormInputField
                type="number"
                name="addOnPrice"
                placeholder="Additional Item Cost"
                component={MaterialTextField}
                validate={[required, number]}
                disableUnderline
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center" spacing={16} className={classes.inputRow}>
            <Grid item>
              <Typography className={classes.textLabel}> Property Cost ($) </Typography>
              <Typography className={classes.textSubLabel}> (per {isDomesticList ? "500" : "300"} sq. ft) </Typography>
            </Grid>
            <Grid item>
              <FormInputField
                type="number"
                name="propertyPricing.cost"
                placeholder="Property Cost"
                component={MaterialTextField}
                validate={[required, number]}
                disableUnderline
              />
            </Grid>
          </Grid>

          {
            isDomesticList ?
            <Grid container alignItems="center" spacing={16} className={classes.inputRow}>
              <Grid item>
                <Typography className={classes.textLabel}> Skip Property </Typography>
                <Typography className={classes.textSubLabel}> (Sq. fts to skip charge) </Typography>
              </Grid>
              <Grid item>
                <FormInputField
                  type="number"
                  name="propertyPricing.skip"
                  placeholder="Skip Sq. fts "
                  component={MaterialTextField}
                  validate={[required, number]}
                  disableUnderline
                />
              </Grid>
            </Grid>
            : null

          }

          <Grid className={classes.inputRow}>
            <Grid item>
              <Typography className={classes.textLabel}> Allow discount coupons ?</Typography>
            </Grid>
            <Grid>

              <Field
                component={RadioSelectBtn}
                name="couponsAllowed"
                validate={[required]}
                inRow
                options={ORDER_DISCOUNT_ALLOWED_OTPS}
              // onOptionChange={onOrderTypeChange}
              />

            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ZButton color="primary" onClick={closeForm} >
            Cancel
          </ZButton>
          <ZButton type="submit" gradient color="primary">
            Submit
          </ZButton>

        </DialogActions>
      </Form>
    </Dialog>
  )
}


const WithStylesPriceListForm = withStyles(styles)(PriceListForm);
export default WithStylesPriceListForm
