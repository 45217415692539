import React, {Component, Fragment} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
  
class PopUpMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    event.stopPropagation();
    event.preventDefault();
    if(this.props.onClick) this.props.onClick(event);
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  selectItem = (option, index, aditionalParams) => e => {
    if(option.onClick) option.onClick(this.props.item, index, aditionalParams);
    this.handleClose(e) 
  }

  render() {
    const { anchorEl } = this.state;
    const { options, icon, buttonProps, item } = this.props;
    const open = Boolean(anchorEl);

    return (
      <Fragment>
        {icon && <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onMouseDown={this.handleClick}
          {...buttonProps}
        >
          {icon}
        </IconButton>}
        
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          {options && options.map((option, index) => {


            if(option.checkForDisabled){

                if(option.disableBtn){
                  return (
                    <MenuItem
                      key={option.label}
                      disabled={option.disableBtn(item)}
                      onClick={this.selectItem(option, index, [option.secondParam ? option.secondParam : null])}
                    >
                      {option.label}
                    </MenuItem>

                  )
                }

              return (

                <MenuItem
                  key={option.label}
                  disabled={item[option.propToCheck] || item[option.secondPropToCheck]}
                  onClick={this.selectItem(option, index, [option.secondParam ? option.secondParam : null])}
                >
                  {option.label}
                </MenuItem>
              )
            }

            return (
              <MenuItem
                key={option.label}
                onClick={this.selectItem(option, index, [option.secondParam ? option.secondParam : null])}
              >
                {option.label}
              </MenuItem>
          )
          })}
        </Menu>
      </Fragment>
    );
  }
}

export default PopUpMenu;
