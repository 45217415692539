import React from "react";
import Dialog from "core/components/Dialog";
import DialogTitle from "core/components/DialogTitle";
import DialogContent from "core/components/DialogContent";
import { Form, Field } from "redux-form";
import Loader from "../ProgressCircle";
import ZButton from "core/components/Button";
import ZTypography from "core/components/Typography";
import Grid from "core/components/Grid";
import DialogActions from "core/components/DialogActions";
import {
  MaterialTextField,
  FormInputField,
  ExtendedAdvanceSelect,
  MaterialCheckBox
} from "../FormField";
import TimePicker from "../TimePicker2";
import DatePicker from "../DatePicker";
import { withStyles } from "core/components/Styles";
import cn from "classnames";
import {
  required,
} from "../FormField/Validations";



const styles = {
  inputSelect:{
    // paddingTop: 0,
		"& .field-wrapper":{
      height: 46,
      "& > span":{
        // right: 0,
        color: "#c4c4c4",
        "& img":{
          width: "1.3rem",
          height: "1.3rem",
          opacity: 0.7
        }
      },
      "& > input":{
        color: "#646464",
        fontWeight: 500,
        lineHeight: "21px",
        fontSize: 14,
        height: 46,
        border: "solid 1px #d8d8d8",
        background: "#ffffff",
        // padding: "5px 18px", //"20px 56px 20px 20px",
        "&::placeholder ":{
          color: "#b7b7b7"
        }
      }
    },
  },
  fieldHeading: {
    marginRight: 0,
    marginTop: 0,
    marginBottom: 4,
    fontSize: 12,
    color: "#929292",
    lineHeight: "18px"
  },
  addOnStyle:{
    backgroundColor:"blue"
  },
  inputCont:{
    marginTop:8
  },
  removeBtn:{
    color:"red"
  }
}

const EventForm = ({
  open,
  closeForm,
  submitData,
  handleSubmit,
  processing,
  title,
  eventTypesOptions,
  classes,
  removeEvent,
  formValues = {},
  mode = "add"
}) => {


  const { isAllDay, eventId } = formValues;

  return (
    <Dialog
    open={open}
    onClose={closeForm}
    fullWidth
    // maxWidth="md"
  >
    {(processing) && <Loader position="fixed" zIndex={999} />}
    <Form onSubmit={handleSubmit(submitData)}>
      <DialogTitle id="form-dialog-title">
        <ZTypography color="secondary" gradient bold fontSize={25}>
          {title}
        </ZTypography>
      </DialogTitle>
      <DialogContent>
          
          {/* <Grid>
            <FormInputField
              label="Name"
              name="name"
              placeholder="Enter event name"
              component={MaterialTextField}
              validate={[required]}
              disableUnderline
              disabled
            />
          </Grid>
          <Grid>
            <FormInputField 
              label="Type"
              name="type"
              component={ExtendedAdvanceSelect}
              options={eventTypesOptions}
              backgroundColor="transparent"
              material
              disableUnderline
              validate={[required]}
              isDisabled
              // closeMenuOnSelect={false}
              menuPlacement="top" // To open the list to top direction
            />
          </Grid> */}

          <Grid className={cn(classes.inputSelect, classes.inputCont)}>
            <p className={classes.fieldHeading}>Date</p>
            <Field
              name="date"
              component={DatePicker}
              validate={[required]}
              placeholder="Select date" 
              isNotMinDate
              // getDate={getDateChange}
              addOnStyle={classes.addOnStyle}
            />
          </Grid>
        {
          !isAllDay
          &&
          <Grid className={classes.inputCont}>
            <Grid container justify="space-between" wrap="nowrap" spacing={16} className={classes.inputSelect}>
              <Grid item>
                <p className={classes.fieldHeading}>Start time</p>
                <Field
                  name="startTime"
                  component={TimePicker}
                  validate={[required]}
                  placeholder="Start time" 
                  addOnStyle={classes.addOnStyle}
                />
              </Grid>

              <Grid item>
                <p className={classes.fieldHeading}>End Time</p>
                <Field
                  name="endTime"
                  component={TimePicker}
                  validate={[required]}
                  placeholder="End time" 
                  addOnStyle={classes.addOnStyle}
                />
              </Grid>
            </Grid>

          </Grid>
        }

        <Grid>
          <Field
            name="isAllDay"
            type="checkbox"
            component={MaterialCheckBox}
            color="primary"
            label={<p>All day</p>}
          />
        </Grid>




      </DialogContent>
      <DialogActions>
        <ZButton type="submit" gradient color="primary">
          {
            mode === "add" ? 
            "Submit"
            :
            "Update"
          }
        </ZButton>
        {
          mode === "edit"
          &&
          <ZButton color="primary" onClick={removeEvent} className={classes.removeBtn}>
            Remove
          </ZButton>
        }

        <ZButton color="primary" onClick={closeForm} >
          Cancel
        </ZButton>

      </DialogActions>
    </Form> 
  </Dialog>
  )
}


export default withStyles(styles)(EventForm);