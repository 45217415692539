import React from "react";
// import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
import Table from "../TableWithPagination";
import Loader from "../ProgressCircle";
import { withStyles } from "@material-ui/core/styles";
import FabButton from "core/components/FabButton";
// import { capitalizeFirstLetterOnly } from "../../utils/capitalize";
import SearchBar from "../SearchBar";
import Select from "core/components/Select";
import MenuItem from 'core/components/MenuItem';
import FormControl from "core/components/FormControl";
import Grid from "core/components/Grid";
import InputLabel from "core/components/InputLabel";
// import FormControlLabel from "core/components/FormControlLabel";
// import { isEqual } from "lodash";
// import DataList from "../DataList";
// import AddJobModal from "../AddJobModal";

// import jobItemProps from "./jobItemProps";

const headers = [
  {
    name:"listType",
    label:"List Type"
  },
  {
    name: "type",
    label: "Cleaning Type"
  },
  {
    name:"frequency",
    label:"Frequency"
  },
  {
    name:"zipcodes",
    label:"Zip Codes",
  },
  {
    name: "actions",
    label: "Actions"
  }
];


// const frequencies = {
//   1:"Just Once",
//   2:"Every Week",
//   3:"Every Two Weeks",
//   4:"Every Month"
// }


const styles = theme => ({
  root: {
    flexGrow: 1,
    // overflow: "hidden",
    flexDirection:"column",
    display: "flex",
  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth:"100%",
    width:"100%"
  },
  padTop: {
    paddingTop: 10
  },
  setMargin_10:{
    marginLeft: 20
  },
  topSpace:{
    marginTop: 18
  },
  disableMargin: {
    margin: 0
  }
});


const PriceListsDashboard = ({
  gettingPriceLists,
  totalCount,
  priceLists,
  getPriceListByPage,
  classes,
  openAddForm,
  priceListActions,
  processing,
  handleCleaningTypeChange,
  handleFrequencyChange,
  frequencyType,
  listType,
  cleaningType,
  onSearchChange,
  zipcodeSearch,
  clearSearch,
  searchRef,
  handelPageChange,
  tablePage,
  frequencies,
  getTableColumnsData,
  handleListTypeChange,
}) => {

  const columnProps = getTableColumnsData();
  return (
    <div className={classes.root}>
      {(gettingPriceLists || processing) && <Loader/>}
      <Grid item container alignItems="center" spacing={16}>
            <Grid item md={3}>
              <SearchBar 
                placeholder="Search price list by zip code"
                onChange={onSearchChange}
                query={zipcodeSearch}
                onClear={clearSearch}
                inputRef={searchRef}
              />
            </Grid>
            <Grid item md={1} >
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="cleaning-type">
                  List Type
                </InputLabel>
                <Select
                  value={listType}
                  onChange={handleListTypeChange}
                  displayEmpty
                  inputProps={{
                    id: 'list-type',
                  }}
                  // className={classes.setMargin_10}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="domestic">Domestic</MenuItem>
                  <MenuItem value="carpet">Carpet</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {
              listType !== "carpet" ?
                <Grid item md={1} >
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink htmlFor="cleaning-type">
                      Cleaning Type
                    </InputLabel>
                    <Select
                      value={cleaningType}
                      onChange={handleCleaningTypeChange}
                      displayEmpty
                      inputProps={{
                        id: 'cleaning-type',
                      }}
                      // className={classes.setMargin_10}
                    >
                      <MenuItem value="">All</MenuItem>
                      <MenuItem value="standard">Standard</MenuItem>
                      <MenuItem value="deep">Deep</MenuItem>
                      <MenuItem value="move_in_out">Move In/Out</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                : null
              
            }

            <Grid item md={1}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="frequency">
                  Frequency
                </InputLabel>
                <Select
                  value={frequencyType}
                  onChange={handleFrequencyChange}
                  displayEmpty
                  inputProps={{
                    id: 'frequency',
                  }}
                  // className={classes.setMargin_10}
                >
                  <MenuItem value="">All</MenuItem>
                  {frequencies && frequencies.map((f, index) => {
                    return (
                      <MenuItem key={`freq-item-${index}`} value={f.mappedTo.toString()}>{f.label}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        <Table
          list={priceLists}
          totalCount={totalCount}
          actions={priceListActions}
          handlePageChange={handelPageChange}
          page={tablePage}
          headers={headers}
          columnProps={columnProps}
          className={classes.topSpace}
        />

      <FabButton onClick={openAddForm} />
    </div>
  );
};


export default withStyles(styles)(PriceListsDashboard);