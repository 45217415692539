import React, { Component } from "react";
import axios from "utils/http";
import { debounce, isEmpty } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "../../core/components/Grid";
import Typography from "../../core/components/Typography";
import Table from "../TableWithPagination";
import SearchBar from "../SearchBar";
import { setMsgAndShow } from "store/actions/popup";
import { capitalizeFirstLetterOnly } from "../../utils/capitalize";
import { createOrderFormData } from "../../helpers/orders";
import Loader from "../ProgressCircle";
import removeProp from "../../utils/removeProp";
import { withStyles } from "../../core/components/Styles";
import { setPriceList, storeDomesticPriceList } from "../../store/actions/app";
import { getPricingList } from "../../sdk/helpers/pricingListAndOrderInfo";
import { connect } from "react-redux";
const headers = [
  {
    // name: "isChecked",
    // label: ""
  },
  {
    name: "cleaningAddress",
    label: "Cleaning Address"
  },
  {
    name: "last_booking_order_cost",
    label: "Last Booking Cost ($)"
  },
];

const columnProps = [
  {
    isSubmitButton: true,
    buttonText: "Select"
  },
  {
    name: "cleaningAddress"
  },
  {
    name: "lastBookingOrderCost"
  },
];


const styles = {
  noOrdersText:{
    fontSize:20
  }
}
class SelectBookingModal extends Component {

  state = { 
    loadingOrders:false,    
    q:"",
    currPage:1,
    perPage:10,
    orders:[],
    totalOrders:0,
    fillingOrderDetails: false ,
    page:0,
  };

  orderData = null;

  componentDidUpdate(prevProps){
    if( prevProps.showBookingSelectModal !== this.props.showBookingSelectModal && this.props.showBookingSelectModal){
      this.getOrders(this.state.currPage);
    } 
  }

  getOrders = async (pageNumber) => {

    const { selectedCustomer, setMsgAndShow } = this.props;
    if( isEmpty(selectedCustomer) ) return;
    const { _id: customerId } = selectedCustomer;

    let updateObj = { loadingOrders: false };
    let { perPage, q } = this.state;
    const params = {
      page: pageNumber,
      limit: perPage,
      q
    };
    this.setState({ loadingOrders: true });

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/get_customer_orders/${customerId}`,{ params }
      );
      
      const { orders, total, page, perPage } = data;
      updateObj = {
        orders,
        totalOrders: total,
        currPage:page,
        perPage,
        page: page - 1,
        ...updateObj
      };
      
    } catch (error) {
      const { response } = error;
      setMsgAndShow(response && response.data && response.data.message || "Error while getting Previous Bookings");
    }
    this.setState(updateObj);
  }

  getBookingsSearch = (e) => { 
    this.debounceFun(e.target.value);
  }

  search = async (q) => {
    q = q.trim().toLowerCase();
    this.setState({ q });
    this.getOrders(this.state.currPage);
  }

  debounceFun = debounce(this.search, 250);

  getOrderDetails = async (jobId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_WITH_PREFIX}admin/jobs/${jobId}`);
      const { order } = res.data;
      this.orderData = order;
      return true;
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  getPricingListForOrder = async (jobId) => {
      try {
        const res = await getPricingList({jobId});
        if(res && res.priceList){
          this.props.setPriceList(res.priceList);
        }

      } catch (error) {
        this.props.setMsgAndShow("Error occurred while getting pricing list.");
      }
  }

  fillOrderDetails = async ()=> {

    try {
      this.props.reset();

    const orderDetails = this.orderData;
    const { 
      commercialOpts, 
      domesticOpts, 
      carpetOpts,
      frequencies,
      change, 
      selectedCustomer, 
      setSelectedCustomerInCustomersList 
    } = this.props;

    if(!isEmpty(orderDetails)){
      await this.getPricingListForOrder(orderDetails._id);
  
      const config = { 
        commercialOpts, 
        domesticOpts, 
        carpetOpts,
        frequencies,
        mapCostToAdminCost: false,
        addAdminDiscount:false
      };
      // console.log("Raw orderdetails ",orderDetails);
      
      const orderFormData = createOrderFormData( orderDetails, {}, config );
      // console.log("generated formdata ",orderFormData);
      
      const propsToRemove = [ "jobId", "displayId", "date", "time", "orderCost", "appliedDiscount" ];

      if(orderFormData.addOnWindows){
        orderFormData.addOnServices = orderFormData.addOnWindows;
        propsToRemove.push("addOnWindows");
      }

      removeProp( orderFormData, propsToRemove );
      // console.log("processed formdata ",orderFormData);
      Object.keys(orderFormData).forEach( ( key )=> change( key, orderFormData[key] ));
      this.setState({
        fillingOrderDetails:false
      })
      setSelectedCustomerInCustomersList(selectedCustomer);
    }
    } catch (error) {
      throw error;
    }
    
  }

  handleSelectedBooking = (order) => async()=> {
    this.setState({ fillingOrderDetails: true });
    const { lastBookingId: jobId } = order;
    try {
      await this.getOrderDetails(jobId);
      await this.fillOrderDetails();
    } catch (error) {
      this.props.setMsgAndShow("Error occurred while getting Booking details.");
    }
    this.closeBookingsModal();
  }

  closeBookingsModal = ()=> {
    this.orderData = null;
    this.setState({  
      q:"",
      currPage:1,
      perPage:10,
      orders:[],
      totalOrders:0,
    },() => {
      this.props.closeBookingSelectModal();

    });
  };

  render() {
    
    const { showBookingSelectModal, getJobDetails, classes } = this.props;
    const { loadingOrders,orders, totalOrders, fillingOrderDetails, page } = this.state;
    // const selectedBookingActions = [
    //   {
    //     label: "Get Details",
    //     onClick: getJobDetails
    //   }
    // ];


    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={showBookingSelectModal}
        onClose={this.closeBookingsModal}
      >
        <DialogTitle>
          Select details from any previous booking
        </DialogTitle>
        <DialogContent>
          {
            orders && orders.length ?
            <Grid>
              <SearchBar
                placeholder="Search Booking by cleaning address"
                onChange={this.getBookingsSearch}
              />
              <Table
                list={orders}
                totalCount={totalOrders}
                // actions={selectedBookingActions}
                headers={headers}
                columnProps={columnProps}
                handlePageChange={this.getOrders}
                handlePassButton={this.handleSelectedBooking}
                page={page}
              />
            </Grid>
            :
            (
            !loadingOrders ?
            <Typography align="center" className={classes.noOrdersText}>
              No booking available
            </Typography>
            : null
            )
            
          }
          { (fillingOrderDetails || loadingOrders) && <Loader position="fixed" zIndex={999} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeBookingsModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


const WithStyles = withStyles(styles)(SelectBookingModal);


const mapDispatchToProps = (dispatch) => ({
  storeDomesticPriceList:(list) => dispatch(storeDomesticPriceList(list)),
  setPriceList:(list) => dispatch(setPriceList(list)),
  setMsgAndShow:(message) => dispatch(setMsgAndShow(message)),
})
export default connect(undefined,mapDispatchToProps)(WithStyles);
