import React, { PureComponent } from 'react'
import Grid from "core/components/Grid";
import IconButton from "core/components/IconButton";
import { Close } from 'mdi-material-ui';
import { withStyles } from "core/components/Styles";
import { Field } from "redux-form";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import { MaterialCheckBox } from '../FormField';
import { required } from "../FormField/Validations";


const styles = theme => ({
  modalCont: {},
  inputMarginTop_10: {
    marginTop: 10
  },
  cleaningTypeHeading: {
    marginRight: 0,
    marginTop: 0,
    marginBottom: 4,
    fontSize: 12,
    color: "#929292",
    lineHeight: "18px"
  },
  title: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  selectorCont: {
    border: "1px solid #4a4a4a",
    maxWidth: 150,
    fontSize: "20px",
    borderRadius: ".3rem",
    overflow: "hidden",
    margin: 0,
    marginTop: 5,
    padding: 0,
    "& .dec, .inc": {
      background: "#f8f8f8",
      width: "30%",
      cursor: "pointer",
      textAlign: "center",
      padding: "0.3rem 0.5rem",
      display: "inline-block"
    },
    "& span:first-child": {
      borderRight: "1px solid #4a4a4a"
    },
    "& span:last-child": {
      borderLeft: "1px solid #4a4a4a"
    }
  },
  inputSelect:{
    paddingTop: 0,
		"& .field-wrapper":{
      height: 46,
			"& > span":{
				right: 0,
				color: "#c4c4c4",
        "& img":{
          width: "1.3rem",
          height: "1.3rem",
          opacity: 0.7
        }
			},
			"& > input":{
        color: "#646464",
        fontWeight: 500,
        lineHeight: "21px",
				fontSize: 14,
				height: 46,
				border: "solid 1px #d8d8d8",
				background: "#ffffff",
				padding: "5px 18px", //"20px 56px 20px 20px",
				"&::placeholder ":{
					color: "#b7b7b7"
				}
			}
		},
	},
  disp: {
    width: "40%",
    textAlign: "center",
    display: "inline-block"
  },
  propertyDetailsHeading: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  textCenter: {
    textAlign: "center"
  },
  addOnStyle:{
    backgroundColor:"blue"
  },
  setInCenter:{
    display:"flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputMarginTop_4:{
    marginTop: 4
  },
  noTopPadding:{
    paddingTop: "2px !important"
  },
  // costStyle:{
  //   display: "flex",
  //   alignItems: "center",
  //   height: 33
  // }
});

class MultiBookings extends PureComponent {

  removeField = (fieldIndex) => () => {
    const { onRemoveField, fields } = this.props;
    if(onRemoveField){
      onRemoveField(fieldIndex);
    } else {
      fields.remove(fieldIndex);
    }

  }
  render(){
    const { classes, fields, showTick = true} = this.props;
    return (

      <Grid item xs={12} md={8} className={classes.inputMarginTop_10}> 
        <p className={classes.cleaningTypeHeading}>Choose Date and Time of Bookings</p>
        <Grid>
          {
            fields && fields.map((f, index) => (
              <Grid
                container
                className={classes.inputMarginTop_4}
                // justify="space-between"
                alignItems="center"
                spacing={8}
                key={`add-booking-${index}`}
              >
                <Grid item>
                  {
                    showTick &&
                    <Field 
                      name={`${f}.selected`}
                      component={MaterialCheckBox}
                      type="checkbox"
                      // validate={[required]}
                      // checked={true}
                    />
                  }
                </Grid>
                <Grid item xs={12} md={5} className={classes.inputSelect}>
                  <p className={classes.cleaningTypeHeading}>Date</p>
                  <Field
                    name={`${f}.date`}
                    component={DatePicker}
                    validate={[required]}
                    placeholder="Select date" 
                    addOnStyle={classes.addOnStyle}
                  />
                </Grid>
                <Grid item xs={12} md={5} className={classes.inputSelect}>
                  <p className={classes.cleaningTypeHeading}>Time</p>
                  <Field
                    name={`${f}.time`}
                    component={TimePicker}
                    validate={[required]}
                    placeholder="Select time" 
                    addOnStyle={classes.addOnStyle}
                  />
                </Grid>
                <Grid item >
                  <IconButton onClick={this.removeField(index)}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            ))

          }


        </Grid>
      </Grid>
    )
  }
}


export default withStyles(styles)(MultiBookings);
