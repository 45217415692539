import React from "react";
// import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {withStyles} from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import { Close } from "mdi-material-ui";
import FormHelperText from "../../core/components/FormHelperText";

const styles = {
  error: {
    color: "red",
    fontSize: 12,
  },
  warning: {
    color: "orange",
    fontSize: 12
  },
  rightIconCont:{
    flexDirection:"row"
  },
  clearBtn:{
    height:24,
    width:24,
    marginRight:8,
  },
  clearIcon:{
    height:20,
    width:20,
    color:"#000000de"
  }
};

// const keyDown = 


const handleEnter = e => {
    if (e.keyCode === 13) {
      const target = e.target;
      const form = target.form;
      if (Array.prototype && form && target) {
        let index = Array.prototype.indexOf.call(form, target);
        let idx = form.elements[index + 1].tabIndex;
        while (idx < 0) {
          index++;
          idx = form.elements[index + 1].tabIndex;
        }
        form.elements[index + 1].focus();
        e.preventDefault();
      }
    }
}

const MaterialTextField = ({
  classes, 
  input, 
  onClick, 
  onClear,
  meta, 
  rightIcon, 
  leftIcon, 
  placeholder, 
  size,
  name,
  label,
  shrink,
  defaultValue,
  onChange,
  styleClasses,
  errorMsg,
  onKeyDown,
  disableUnderline,
  showClearBtn,
  ...rem
}) => {

  const { multiline } = rem;

  return (
  
    <div>
      {input ? 
        (<TextField
          {...input}
          {...rem}
          id={name}
          name={name}
          label={label}
          onClick={onClick}
          classes={styleClasses}
          onKeyDown={ e => {
            if (e.keyCode === 13 && !multiline) {
              const target = e.target;
              const form = target.form;
              if (Array.prototype && form && target) {
                let index = Array.prototype.indexOf.call(form, target);
                let idx = form.elements[index + 1].tabIndex;
                while (idx < 0) {
                  index++;
                  idx = form.elements[index + 1].tabIndex;
                }
                form.elements[index + 1].focus();
                e.preventDefault();
              }
            }
          }}
          placeholder={placeholder}
          margin="normal"
          fullWidth
          InputLabelProps={shrink ? {
            shrink: true,
          }: null}
          readOnly

          InputProps={{
            startAdornment:(
              leftIcon &&
              <InputAdornment position="start" className={classes.leftIconCont}>
    
                {leftIcon}
              </InputAdornment>
            ),
            endAdornment:(
              <InputAdornment position="end" className={classes.rightIconCont}>
                  {
                    showClearBtn 
                    &&
                    <IconButton
                      onClick={onClear}
                      className={classes.clearBtn}
                    >
                      <Close className={classes.clearIcon} />
                    </IconButton>

                  }
                {rightIcon}
              </InputAdornment>
            ),
            disableUnderline,
            
          }}
        />) : 
        (<TextField
          {...rem}
          id={name}
          label={label}
          name={name}
          onClick={onClick}
          onChange={onChange}
          classes={styleClasses}
          onKeyDown={onKeyDown ? onKeyDown : handleEnter}
          InputLabelProps={shrink ? {
            shrink: true,
          }: null}

          placeholder={placeholder}
          defaultValue={defaultValue}
          margin="normal"
          fullWidth
          error={Boolean(errorMsg)}
          InputProps={{
            startAdornment:(
              leftIcon &&
              <InputAdornment position="start" className={classes.leftIconCont}>
    
                {leftIcon}
              </InputAdornment>
            ),
            endAdornment:(
              <InputAdornment position="end" className={classes.rightIconCont}>
                  {
                    showClearBtn 
                    &&
                    <IconButton
                      onClick={onClear}
                      className={classes.clearBtn}
                    >
                      <Close className={classes.clearIcon} />
                    </IconButton>
                  }
                {rightIcon}
              </InputAdornment>
            ),
            disableUnderline,
            
          }}
        />)}
      {
        meta && meta.touched &&
          ((meta.error && <span className={classes.error}>{meta.error}</span>) ||
          (meta.warning && <span className={classes.warning}>{meta.warning}</span>))
      }

      { errorMsg 
        &&
        <FormHelperText className={classes.error}>{errorMsg}</FormHelperText>
      }
    </div>
  );
};



export default withStyles(styles)(MaterialTextField);