import { cloneDeep } from "lodash";
import { LOAD_FORM_DATA, RESET_FORM_DATA, UPDATE_FORM_DATA } from "../types";


const defaultState = {
  formData:null
}

const reducer = (state = defaultState, action) => {
  let finalState = {};

  switch(action.type){
    case LOAD_FORM_DATA:
      finalState = cloneDeep(state);
      finalState.formData = cloneDeep(action.payload);
      break;
    case RESET_FORM_DATA:
      finalState = cloneDeep(state);
      finalState.formData = null;
      break;
    
    case UPDATE_FORM_DATA:
      finalState = cloneDeep(state);
      finalState.formData = {
        ...finalState.formData,
        ...action.payload,
      }
      break;

    default:
      finalState = state;
  }

  return finalState;
}


export default reducer;