import React from "react"; 
import { withStyles } from "@material-ui/core/styles";

import SearchBar from "../SearchBar";
import Loader from "../ProgressCircle";
import Header from "../../containers/HeaderContainer";
import SideDrawer from "../SideDrawer";
import MainContent from "../MainContent";
import Table from "../TableWithPagination";
import Grid from "../../core/components/Grid";
import ExportListBtn from "../ExportListBtn";
import { normalizePhone} from "../FormField/formatters";

const styles = theme => ({
  root: {
  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  }
});

const headers = [
  {
    name: "customerName",
    label: "Name"
  },
  {
    name:"userType",
    label:"Type"
  },
  {
    name: "email",
    label: "Email"
  },
  {
    name: "phone",
    label: "Phone"
  },
  {
    name: "serviceAddress",
    label: "Address"
  },
  {
    name:"isActive",
    label:"Active"
  },
  {
    name:"isVerified",
    label:"Verified"
  },
  {
    name: "actions",
    label: "Actions"
  }
];

const columnProps = [
  {
    fnameProp: "fname",
    lnameProp: "lname",
    isName: true
  },
  {
    name:"userType"
  },
  {
    name: "email"
  },
  {
    name: "phone",
    isPhone:true,
    formatter: (phone) => phone ? normalizePhone(phone) : phone
  },
  {
    cityProp: "city",
    stateProp: "state",
    zipcodeProp: "zipcode",
    addressProp: "address",
    name: "serviceAddress",
    isAddress: true
  },
  {
    name:"isActive",
    isBoolean: true,
    isTrue:"Yes",
    isFalse:"No"
  },
  {
    name:"isVerified",
    isBoolean: true,
    isTrue:"Yes",
    isFalse:"No"
  },
];

const Customers = ({
  classes,
  users,
  actions,
  openCustomerModal,
  totalCount,

  getSearch,
  getUsers,
  load,
  searchLoad,


  searchValue,
  clearSearch,
  searchInput,
  page,
}) => {
  return (
    <div className={classes.root}>
      {(load || searchLoad) && <Loader/>}
        <Grid container spacing={16} alignItems="center" justify="space-between">
          <Grid item md={3}  sm={12}>
            <SearchBar 
              placeholder="Search user by name, phone, email"
              onChange={getSearch}
              query={searchValue}
              onClear={clearSearch}
              inputRef={searchInput}
            />

          </Grid>
          <Grid item>
            <ExportListBtn 
              requestUrl={`${process.env.REACT_APP_API_WITH_PREFIX}admin/users/list/export`}
              params={{
                q:searchValue
              }}
              
            />
          </Grid>
        </Grid>
        <Table
          list={users}
          headers={headers}
          columnProps={columnProps}
          totalCount={totalCount}
          actions={actions}
          handlePageChange={getUsers}
          page={page}
        />
          

      
    </div>
  );
};

export default withStyles(styles)(Customers);
