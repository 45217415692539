import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import EmployeeList from "./EmployeeList";


const AssignJobMenu = ({
 open,
 btnRef,
 handleMenuClose,
 employeeList,
 jobItemObj,
 actions,
 title,
 classes,
 errMsg,
 assignJob,
 jobActions
}) => {
  const anchorEl = btnRef || document.getElementById('root')
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      disablePortal
      className={classes.listPopUp}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          id="menu-list-grow"
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleMenuClose}>
              <Card className={classes.popupCard}>
                <CardContent>
                  {title ? <Typography variant="subheading" gutterBottom>
                    {title} {`(${employeeList.length})`}
                  </Typography> : null }
                
                  {employeeList && <EmployeeList 
                    employeeList={employeeList}
                    assignJob={assignJob}
                    jobItemObj={jobItemObj}
                  />}

                  {!employeeList || employeeList.length === 0 ? <p className={classes.textCenter}>{errMsg}</p> : null}
                  
                  {jobActions && jobActions.map((action, key)=>
                    <Grid className={classes.actionBtn} key={`job-menu-${key}`}>
                      <Button onClick={action.onClick} fullWidth className={classes.textCaptalize}>{action.label}</Button>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}


const styles = theme => ({
  listPopUp: {
    marginTop: 20,
    marginBottom: 20,
    zIndex: 99
  },
  popupCard: {
    borderTopColor: "#4d73a8",
    borderTop: "2px solid",
    borderRadius: 0
  },
  freeEmployeeList: {
    marginTop: 10
  },
  actionBtn: {
    marginTop: 20
  },
  textCaptalize:{
    textTransform: "capitalize"
  },
  textCenter:{
    textAlign: "center",
  }
})

export default withStyles(styles)(AssignJobMenu);