const isBackspaceOrDelKey = (e) => {
  var result = false;
  try {
    var charCode = (e.which) ? e.which : e.keyCode;
    if (charCode == 8 || charCode == 46) {
      result = true;
    }
  } catch (err) {
    //console.log(err);
  }
  return result;
}


export default isBackspaceOrDelKey;