import React, { Component } from 'react';
import TiersDashboard from "../components/TiersDashboard";
import AddTierForm from "./AddTierFormContainer";
import EditTierForm from "./EditTierFormContainer";
import { connect } from "react-redux";
import { setMsgAndShow } from "../store/actions/popup";
import axios from "utils/http";
import { debounce } from "lodash";
import confirmResponse from "../utils/confirmResponse";
import swal from "../utils/sweetalert";
import { loadFormData } from "../store/actions/formData";
import { ZOK_TIER_IN_USE } from "../variables/errorCode";
import getTotalPagesCount from '../utils/getTotalPagesCount';
import { getList as getTiersList } from '../helpers/tiers';

class TiersDashboardContainer extends Component {

  state = {
    processing:false,
    gettingTiers:false,
    tiersList:[],
    totalCount:0,
    tierQuery:"",
    page:1,
    tablePage:0,
    openAddForm:false,
    openEditModal: false,
  }
  limit = 10;
  searchRef = React.createRef();
  searchTier = (searchValue) => {
    this.setState({
      tierQuery:searchValue,
      page:1
    }, this.getTiersList)
  }
  lazySearch = debounce(this.searchTier,250);

  getTiersList = async (pageNumber = 1,isLoaderActive) => {
    try {
      let { tierQuery:q } = this.state;
      if(!isLoaderActive){
        this.setState({
          gettingTiers:true,
        })

      }

      const res = await getTiersList(pageNumber,q,this.limit);

      const { 
        list,
        perPage,
        total,
        page: receivedPage,
      } = res;

      this.limit = perPage;
      this.setState({
        gettingTiers:false,
        tiersList:list,
        totalCount: total,
        page:receivedPage,
        tablePage:receivedPage - 1
      })
    } catch (error) {
      const { response } = error;
      let errorMessage = "Unable to get tiers list!";
      if(response.data && response.data.message){
        errorMessage = response.data.message;
      }
      this.setState({
        gettingTiers:false
      })
      this.props.setMsgAndShow(errorMessage);
    }
  }
  componentDidMount(){
    this.getTiersList();
  }

  handlePageChange  = (page) => {

   this.getTiersList(page);
  }

  onSearchChange = (e) => {

    this.lazySearch(e.target.value);
  }

  clearSearch = () => {
    this.setState({
      page:1,
    }, () => {    
      this.searchRef.current.value = "";
      this.searchTier("");
    })

  }

  openAddTierModal = () => {
    this.setState({
      openAddForm:true,
    })
  }
  closeAddModal = () => {
    this.setState({
      openAddForm:false
    })
  }


  editTier = (tierData) => {
    this.props.loadFormData(tierData);
    this.setState({
      openEditModal:true
    })
  }

  handleConflict = async (tierData) => {
    try {

      const res = await swal({
      title: "Tier is in use!",
      text: "To remove this tier you need to remove all the contractors assigned to this tier",
      type: "error",
      icon:"error",
      showConfirmButton:true,
      showCancelButton: false,
      confirmButtonText: 'View Contractors'
     })

     const showList = res.isConfirmed;


     if(showList){
        this.props.history.push(`/contractors?filter=tier&v=${encodeURIComponent(tierData.name)}`);
     }
    } catch (error) {
      const { response } = error;
      let errorMessage = "Unable to add price list!";
      if(response && response.data && response.data.message){
        errorMessage = response.data.message;
      }
      this.props.setMsgAndShow(errorMessage);
    }
  }
  removeTier = async (tierData) => {
    try {
      this.setState({
        processing:true
      })

      await axios.delete(`${process.env.REACT_APP_API_WITH_PREFIX}admin/tiers/remove/${tierData._id}`);
      this.setState({
        processing:false
      })

      this.updateList()
    } catch (error) {
      const { response } = error;
      let showError = true;
      let errorMessage = "Unable to remove tier!";

      if(response.status = 409 && response.data && response.data.code === ZOK_TIER_IN_USE){
        showError = false;
        this.handleConflict(tierData)
      } else if(response && response.data && response.data.message){
        errorMessage = response.data.message;
      }
      this.setState({
        processing:false
      })

      if(showError){
        this.props.setMsgAndShow(errorMessage);
      }
    }
  }
  updateList = async () => {
    try {
      let { page,tierQuery:q } = this.state;
      this.setState({
        gettingTiers:true,
      })

    
      const res = await getTiersList(page,q,this.limit);
      const { 
        list,
        perPage,
        total,
        page: receivedPage,
      } = res;

      this.limit = perPage;

      const totalPages = getTotalPagesCount(total,perPage);


      if(page > totalPages){
         this.getTiersList(totalPages,true);
      } else {
        this.setState({
          gettingTiers:false,
          tiersList:list,
          totalCount: total,
          page:receivedPage,
          tablePage:receivedPage - 1
        })

      }
    } catch (error) {
      const { response } = error;
      let errorMessage = "Unable to get tiers list!";
      if(response.data && response.data.message){
        errorMessage = response.data.message;
      }
      this.props.setMsgAndShow(errorMessage);
    }

  }
  closeEditModal = () => {
    this.setState({
      openEditModal:false
    })
  }
  tierActions = [
    {
      label: "Update",
      onClick: this.editTier, 
    },
    {
      label:"Remove",
      onClick: this.removeTier,
    },
  ]
  render(){
    return (
      <>
        <TiersDashboard 
          {...this.state}
          tierActions={this.tierActions}
          handlePageChange={this.handlePageChange}
          searchRef={this.searchRef}
          onSearchChange={this.onSearchChange}
          clearSearch={this.clearSearch}
          openAddForm={this.openAddTierModal}
        />
        <AddTierForm
          open={this.state.openAddForm} 
          closeModal={this.closeAddModal}
          onSuccess={this.updateList}
        />

        <EditTierForm 
          open={this.state.openEditModal}
          closeModal={this.closeEditModal}
          onSuccess={this.updateList}
        />
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  loadFormData: (formData) => dispatch(loadFormData(formData))
});

export default connect(
  undefined,
  mapDispatchToProps
)(TiersDashboardContainer);