import { isEmpty } from "lodash";
import {capitalizeFirstLetterOnly} from "./capitalize";

export function getJobTrackingDetails (jobItemObj){
  let jobOrigin = "";
  let deviceUsedForBooking = {};
  let referrer = "";
  if(!isEmpty(jobItemObj)){
    const { geoData, deviceInfo, referrer:jobReferrer} = jobItemObj;
    referrer =  jobReferrer && jobReferrer;
    if(geoData && !isEmpty(geoData)){
      const {city,region,country} = geoData;
      jobOrigin =  `${city ? city : ""} ${region? region+"," : ""} ${country ? country : ""}`;
    }
    if(!isEmpty(deviceInfo)){
      const {deviceName="", os:deviceOs="", type:deviceType=""}  = deviceInfo;
      deviceUsedForBooking.deviceName = deviceName && deviceName;  
      deviceUsedForBooking.deviceOs = deviceOs && deviceOs;  
      deviceUsedForBooking.deviceType = deviceType && capitalizeFirstLetterOnly(deviceType);  
      if(!referrer && deviceOs){
        referrer = (deviceOs === "iPhone OS" || deviceOs ===  "iOS" || deviceOs === "Android" ) ? "Zoklean App" : "Zoklean Web"; 
      }
    }
  }
  return { jobOrigin, deviceUsedForBooking, referrer };
}
