import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ReviewItem from "../ReviewItem";
import { FaStar, FaRegStar } from "react-icons/fa";
import Rating from "react-rating";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardBackspace } from "mdi-material-ui"
import { withStyles } from "@material-ui/core/styles";

import Header from "../../containers/HeaderContainer";
import SideDrawer from "../SideDrawer";
import MainContent from "../MainContent";
import Loader from "../ProgressCircle";



const styles = () => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex"
  },
})





const Reviews = (props) => {
  const { classes, reviews, contractorName, load, contractorRatings, goBack } = props;
  return (
    <Grid className={classes.root}>
      <Header name="Reviews" />
      <SideDrawer />
      <MainContent>
        {load && <Loader />}

      {
        contractorName ? 
        <Grid>
          <Grid container alignItems="center" spacing={16}>
            <Grid item>
              <Tooltip title="Go Back">
                  <IconButton onClick={goBack}>
                    <KeyboardBackspace/>
                  </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
               <Typography variant="subheading">Reviews for {contractorName}</Typography>
            </Grid>

            <Grid item>
              <Rating
                stop={5}
                initialRating={contractorRatings}
                fractions={10}
                emptySymbol={<FaRegStar size={16}/>}
                fullSymbol={<FaStar color="#6610f2" size={16} />}
                readonly
              />
            </Grid>
          </Grid>

          <Grid>
          {
            reviews && reviews.length > 0 ? 
            reviews.map((r, i) => <ReviewItem reviewItem={r} key={`review-item-${i}`} />) : null
          }

          </Grid>

        </Grid> : <Typography variant="body2">No Data</Typography>
      }
      </MainContent>
    </Grid>
  )
}


export default withStyles(styles)(Reviews)