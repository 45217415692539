import axios from "utils/http";


export const getList = async ({
  limit,
  page,
  frequency,
  cleaningType,
  zipcode,
  listType,
}) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_WITH_PREFIX}admin/price_lists/list`,{
        params:{
        limit,
        page,
        f:frequency,
        cT:cleaningType,
        lT:listType,
        zipcode
        }
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}