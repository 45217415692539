import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import cn from "classnames";
import Dialog from "@material-ui/core/Dialog";
import GoogleMap from "../GoogleMap";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Loader from "../ProgressCircle";
import { muted } from "theme/colors";
import { withStyles } from "@material-ui/core/styles";
import { setMsgAndShow } from "../../store/actions/popup";
import { capitalizeFirstLetterOnly } from "../../utils/capitalize"
import axios from "utils/http";
import renderTimeStamp from "utils/renderDate";


const styles = theme => ({
  mutedHeading: {
    color: muted,
    fontWeight: 300
  },
  mapCont: {
    width: "100%"
  },
  altText: {
    textAlign: "center",
    fontWeight: 300 
  },
  loaderCont:{
    position:"relative"
  },
  loader:{
    position:'relative',
    height:40,
    width:40,
  },
  boldText:{
    fontSize:16,
    fontWeight:700,
  },
  infoCont:{
    marginTop:24,
  },
  dangerText:{
    color:"#CF0303",
  },
  breakWord:{
    wordBreak:"break-word"
  },
  noteItem:{
    padding:"10px 0px 10px 0px",
    borderBottom:"1px solid #E8E8E8"
  },
  noteText:{
    marginTop:5,
    color:'#2A2A2A',
    wordBreak:"break-word"
  },
  noteCreatedBy:{
    color:"#00A3FF",
    fontSize:12,
  },
  noteCreatedAt:{
    color:muted,
    fontSize:10,
    textTransform:"uppercase"
  }
})




class ContractorDetailsModal extends PureComponent {

  state = {
    comments:[],
    loadingComments:false,
  }
  componentDidUpdate(prevProps){
    const { isContractorDetailsModalOpen:oldIsModalOpen } = prevProps;
    const { isContractorDetailsModalOpen:newOldModalOpen,contractorObj:{businessId}  } = this.props;

    if(oldIsModalOpen !== newOldModalOpen && newOldModalOpen){
        this.getComments(businessId);
    
    }
  }


  getComments = async (contractorId) => {
    try {
      this.setState({
        loadingComments:true,
      })
      const res = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/contractor/comments/list`,{
          params: {
            contractorId
          }
        }
      );

      const { comments } = res.data;
      this.setState({
        comments,
        loadingComments:false,
      })
    } catch (error) {
      const { response } = error;
      this.setState({
        loadingComments:false,
      })
      this.props.setMsgAndShow(response && response.data && response.data.message ? response.data.message : "Error while getting notes")
    }
  }
  render(){
    const {
      classes,
      isContractorDetailsModalOpen,
      contractorObj = {},
      handleContractorModal
     } = this.props;

    const { comments, loadingComments } = this.state;
    const {
      fname,
      lname,
      // ownerPhone,
      businessPhone,
      businessId,
      businessName,
      businessType,
      userEmail,
      // userId,
      // createdat,
      numberOfEmployees,
      street,
      unit,
      city,
      state,
      zipcode,
      lat,
      lng,
      radius,
      plan,
      ownerPhone,
      // ratings,
      isUnderTrial,
      trialStartsOn,
      trialEndsIn,
      isApproved,
      isSuspended,
      reasonForSuspension,
      status,
      reasonForDisqualification
    } = contractorObj;
    
    const center =  { lat,lng }
    const isDisqualified = status && status.toLowerCase() === "disqualified";

    return (
      <Dialog
        fullWidth
        open={isContractorDetailsModalOpen}
        onClose={handleContractorModal}
      >
        <DialogTitle id="job-modal-title">Contractor Details</DialogTitle>
        <DialogContent>
          <Grid container alignContent="center" alignItems="center" spacing={16}>
            <Grid item xs >
              <Typography variant="subheading" className={classes.mutedHeading}>
                Owner's Info
              </Typography>
              <Grid item container direction="column">
                <Grid item>
                  Name: {`${fname} ${lname}`}
                </Grid>
                <Grid item>
                  Email: {userEmail}
  
                </Grid>
                <Grid item>
                  Phone: {ownerPhone}
  
                </Grid>
              </Grid>
            </Grid>
  
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Business Name:-
              </Typography>
              <span>{businessName}</span>
            </Grid>
          </Grid>
  
          <Grid container alignContent="center" alignItems="center" spacing={16}>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Business ID
              </Typography>
              <span>{businessId}</span>
            </Grid>
  
            
          </Grid>
  
          <Grid container alignContent="center" alignItems="center" spacing={16}>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Business Phone
              </Typography>
              <span>{businessPhone}</span>
            </Grid>
  
            
          </Grid>
  
          <Grid container alignContent="center" alignItems="center" spacing={16}>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Business Address
              </Typography>
              <Grid item container direction="column">
                <Grid item xs>
                  Unit: {unit}
                </Grid>
                <Grid item xs>
                  Street: {street}
                </Grid>
                <Grid item xs>
                  City: {city}
                </Grid>
                <Grid item xs>
                  State: {state}
                </Grid>
                <Grid item xs>
                  Zipcode: {zipcode}
                </Grid>
              </Grid>
            </Grid>
  
            
          </Grid>
  
          <Grid container alignContent="center" alignItems="center" spacing={16}>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Business Type:-
              </Typography>
              <span>{businessType ? businessType.join(",") : null}</span>
            </Grid>
            
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                No. of employees:-
              </Typography>
              <span>{numberOfEmployees}</span>
            </Grid>
  
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Plan:-
              </Typography>
              <span>{capitalizeFirstLetterOnly(plan)}</span>
            </Grid>
          </Grid>
          
          {/* <Grid container alignContent="center" alignItems="center" spacing={16}>
            
          </Grid> */}
  
          <Grid container alignContent="center" alignItems="center" spacing={16}>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Under Trail:-
              </Typography>
              <span>{isUnderTrial ? "Yes" : "No"}</span>
            </Grid>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Trail Start's on:-
              </Typography>
                {/* <span>Today</span> */}
              <span>
                {
                  // Development code =>  
                  // (trialStartsOn !== undefined) && moment( trialStartsOn.slice(0,10) ).format("DD MMM YYYY")
                  
                  // Production code => 
                  (trialStartsOn !== undefined) && moment( trialStartsOn).format("DD MMM YYYY")
  
                  // Optional
                  // +" "+
                  // (trialStartsOn !== undefined) && moment(trialStartsOn.slice(10,trialStartsOn.length), "hh:mm:ss").format("hh:mm A")
                }
              </span>
            </Grid>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Days Remaining:-
              </Typography>
              <span>{trialEndsIn}</span>
            </Grid>
          </Grid>
  
          { isApproved ?
              <Grid>
                <Typography variant="subheading" className={classes.mutedHeading}>
                  Approved:-
                </Typography>
                <span>Yes</span>
              </Grid> 
              : null
            }


            <div
              style={{
                position: "relative",
                marginBottom: 20,
                marginTop: 8
              }}
            >
              <Typography variant="subheading" className={classes.mutedHeading}>
                Service Area Radius
              </Typography>
              {
               (lng && lat) 
                ? <GoogleMap
                    width={"100%"}
                    height={200}
                    center={center}
                    circle={{
                      radius: radius * 1609.344,
                      center: center
                    }}
                  />
                : <span > Not Available </span>
              }
            </div> 
        { isSuspended ?
          <Grid className={classes.infoCont}>
            <Typography variant="title" className={cn(classes.boldText, classes.dangerText)}>
              Suspended
            </Typography>

            <Typography className={cn(classes.dangerText, classes.breakWord)}>
              {reasonForSuspension}
            </Typography>
          </Grid>

          : null
        }
  
        { 
          isDisqualified ?
          <Grid className={classes.infoCont}>
            <Typography variant="title" className={cn(classes.boldText, classes.dangerText)}>
              Disqualified
            </Typography>
            <Typography className={cn(classes.dangerText, classes.breakWord)}>
              {reasonForDisqualification}
            </Typography>
          </Grid>
          : null
        }

        <Grid className={classes.infoCont}>
          <Typography variant="title" className={classes.boldText}>
            Notes
          </Typography>
          <Grid>

            {loadingComments && <Loader size={24} position="relative" className={classes.loader}/>}


            { 
              comments && comments.length ? 
              comments.map((commentObj, index) => {
                return (
                  <Grid key={`comment-item-${index}`} className={classes.noteItem}>
                    <Grid container justify="space-between">
                      <Typography className={classes.noteCreatedBy}>
                        {commentObj.createdBy}
                      </Typography>
                      <Typography className={classes.noteCreatedAt}>
                        {renderTimeStamp(commentObj.createdAt, true)}
                      </Typography>
                    </Grid>
                    <Typography className={classes.noteText}>
                      {commentObj.comment}
                    </Typography>

                  </Grid>
                )
              })
              : 
              <Typography className={classes.noteText}>
                No notes available
              </Typography>
            }
          </Grid>
        </Grid>


        </DialogContent>
  
        <DialogActions>
          <Button onClick={handleContractorModal} color="primary">
            Okay
          </Button>
        </DialogActions>
        
      </Dialog>
    );

  }
}




const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (message) => dispatch(setMsgAndShow(message))
})


const WithStylesComponent = withStyles(styles)(ContractorDetailsModal);


export default connect(undefined,mapDispatchToProps)(WithStylesComponent);
