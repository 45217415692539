import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { setMsgAndShow } from "../store/actions/popup";
import axios from "utils/http";
import { debounce } from "lodash";
import ManagersDashboard from "../components/ManagersDashboard";
import AddManagerForm from "./AddManagerModalContainer";
import EditManagerForm from "./EditManagerModalContainer";
import { loadFormData, resetFormData } from "../store/actions/formData";
import getTotalPagesCount from "../utils/getTotalPagesCount";
import {  getList as getManagersList } from "../helpers/managers";


class ManagersDashboardContainer extends PureComponent {
  state = {
    managers:[],
    load:false,
    totalCount: 0,
    perPage: 10,
    currPage:1,
    page:0,
    q:"",
    isAddFormModalOpen:false,
    isEditFormModalOpen:false,
  }

  searchInput = "";
  searchRef = React.createRef();
  searchList = (searchValue) => {
    this.setState({
      q:searchValue,
    }, this.getManagers);
  }
  lazySearch = debounce(this.searchList,250);

  componentDidMount(){
    this.getManagers();
  }

  getManagers =  async (pageNumber, showLoader = true) => {

    try {

      if(showLoader){
        this.setState({
          load: true
        })
      }
      let { perPage, q } = this.state;

      const data = await getManagersList({
        page:pageNumber,
        limit:perPage,
        q
      })

      const { managers,total, page,  perPage: limit } = data;

      this.setState({
        managers,
        totalCount: total,
        currPage:page,
        perPage: limit,
        load: false,
        page: page - 1
      })
    } catch (error) {
      let errorMessageToShow = "Sorry an error occurred!";
      const { response }= error;
      if(response && response.data && response.data.message){
        errorMessageToShow = response.data.message;
      }

      this.setState({
        load:false,
      })
      this.props.setMsgAndShow(errorMessageToShow)
    }
  }


  clearSearch = () => {
    this.searchRef.current.value = "";
    this.setState({
      q:"",
    }, this.getManagers);
  }

  removeManager = async (manager) => {
    try {
      this.setState({
        load:true,
      })

      const managerId = manager._id
      await axios.delete(`${process.env.REACT_APP_API_PREFIX}admin/managers/remove/${managerId}`)
      this.updateList(true);
    } catch (error) {
      const { response } = error;
      let errorMessageToShow = "Sorry an error occurred!";
      if(response && response.data && response.data.message){
        errorMessageToShow = response.data.message;
      }

      this.setState({
        load:false,
      })
      this.props.setMsgAndShow(errorMessageToShow)
    }
  }

  updateManagerDetails = (obj) => {
    const name = obj.name;
    const [fname, lname ] = name.split(" ");

    const managerData = {
      ...obj,
      fname,
      lname,
    }

    delete managerData.name;
    delete managerData.createdOn;

    this.props.loadFormData(managerData);
    this.setState({
      isEditFormModalOpen:true,
    })
  }



  getSearch = (e) => this.lazySearch(e.target.value);  
  
  handleAddModalClose = () => {
    this.setState({
      isAddFormModalOpen:false,
    })
  }

  openAddManagerForm = () => {
    this.setState({
      isAddFormModalOpen:true
    })
  }

  updateList = async  (isLoaderActive) => {

    try {

      if(!isLoaderActive){
        this.setState({
          load:true
        })
      }
      const {       
        currPage,
        perPage,
        q
      } = this.state;

      const data = await getManagersList({
        page:currPage,
        limit:perPage,
        q
      })

      const {  managers,total, page, perPage: limit } = data;

      const totalPages = getTotalPagesCount(total, limit);

      if(currPage > totalPages){
        this.getManagers(totalPages, false);
      } else {
        this.setState({
          managers,
          totalCount: total,
          currPage:page,
          perPage: limit,
          load: false,
          page: page - 1
        })

      }
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        load:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  }

  handleEditModal = () => {
    this.setState({
      isEditFormModalOpen:false,
    })
    this.props.resetFormData()
  }


  managersActions = [
    {
      label: "Update Details",
      onClick: this.updateManagerDetails,
    },
    {
      label:"Remove",
      onClick:this.removeManager,
    }
  ];

  render(){

    return (
      <>
        <ManagersDashboard
          managersActions={this.managersActions}
          getManagers={this.getManagers}
          clearSearch={this.clearSearch}
          searchRef={this.searchRef}
          getSearch={this.getSearch}
          openAddManagerForm={this.openAddManagerForm}
          {...this.state}
        />
        <AddManagerForm 
          isModalOpen={this.state.isAddFormModalOpen}
          handleModalClose={this.handleAddModalClose}
          onRequestSuccess={this.updateList}
        />

        <EditManagerForm
          isModalOpen={this.state.isEditFormModalOpen}
          handleModalClose={this.handleEditModal}
          onRequestSuccess={this.updateList}
          mode="edit"
        />

      </>
    )
  }
}


const mapDispatchToProps = dispatch => ({
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  loadFormData: (data) => dispatch(loadFormData(data)),
  resetFormData:() => dispatch(resetFormData())
});


export default connect(undefined,mapDispatchToProps)(ManagersDashboardContainer);