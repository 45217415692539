import { Typography } from "@material-ui/core";
import React from "react";
import Grid from "../../core/components/Grid";
import UserAvatar from "../../core/components/UserAvatar";
import { withStyles } from "../../core/components/Styles";
import createImageURL from "../../utils/createImageURL";

const styles = {
  cleanerInfo:{
    padding:8,
    borderRadius:5,
    border:"1px solid #bdbdbd",
  }
}

const getInitials = (name) => {
  let initials = name ? name.trim() : '';
  if (initials) {
    const splitName = initials.split(" ");
    const splittedInitials = splitName.map(name => name !== 'undefined' ? name.toUpperCase().charAt(0) : '');
    initials = splittedInitials.join("");
  }

  return initials;
}
const AssignedCleaner = ({cleaner, classes }) => {
  return (
    <Grid className={classes.cleanerInfo}>
      <Grid container alignItems="center" spacing={8}>
        <Grid item>
          <UserAvatar 
            user={{
              img:createImageURL(cleaner.profileImage),
              initials:getInitials(`${cleaner.fname} ${cleaner.lname}`)
            }} 
          />
        </Grid>
        <Grid item>
          <Grid>
            <Typography>
              {`${cleaner.fname} ${cleaner.lname}`}
            </Typography>
            <Typography variant="caption"> 
              {cleaner.email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}


export default withStyles(styles)(AssignedCleaner);