import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { setMsgAndShow } from "../store/actions/popup";
import socket from "socket";
import axios from "utils/http";
import _ from "lodash";
import moment from "moment-timezone";
import removeProp from "../utils/removeProp";
import JobsDashboard from "../components/JobsDashboard";
import AssignJobMenu from "../components/AssignJobMenu";
import AddJobModal from "../components/AddJobModal";
import JobDetailsModal from "../components/JobDetailsModal";
import JobActionModal from "../components/JobActionModal";
import { setJobs, removeJob, updateJob } from "../store/actions/jobs";
import { setJobId,  showContractorModal } from "../store/actions/contractorModal";
import { loadFormData, resetFormData } from "../store/actions/formData";
import SelectContractorContainer from "../containers/SelectContractorsContainer";
import GetOTPModal from "../components/GetOTPModal";
import confirmResponse from "../utils/confirmResponse";
import getTotalPagesCount from "../utils/getTotalPagesCount";
import { getList as getOrdersList } from "../helpers/orders";
import { ZOK_NO_PAYMENT_METHOD } from "../variables/errorCode";
// import EditJobContainer from "../components/AddJobModal/EditJobModalContainer";

class JobsDashboardContainer extends Component {
  constructor(props){
    super(props);
    this.dropDownAnchor = null;
    this.assignedEmployees = [];
    this.jobAction = null;
    this.debounceFun = _.debounce(this.search, 250)  
    this.state = {
      show: "requests",
      isJobDetailsModalOpen: false,
      isCancelJobModalOpen: false,
      isModalOpen: false,
      isRejectLoaderOn: false,
      // orders: [],
      
      doNotShowJobBtns: false,
      acceptedJobOrders: [],
      todaysJobOrders: [],
      newJobOrders: [],
      employeeList: [],
      
      jobItemIndex: "",
      jobItemObj: {},
      
      open: false,
      btnRef: null,
      
      totalCount: 0,
      perPage: 10,
      currPage:1,
      load: false,

      jobCompleted: false,
      openLoader: false,
      reason:"",
      modalText:"",
      q:"",
      status: "",
      formMode:"add",
      showEditJobModal:false,
      startDate: "",
      endDate: "",
      showDateRangeInput: false,
      optionName: "",
      page:0,
      isOTPModalOpen:false,
      jobId:"",
    };

    this.inputRef = React.createRef();

  }

  searchValue = "";

  componentDidMount() {
    this.addData();
  }

  orderId = undefined;
  // componentDidUpdate = async (prevProps, nextProps) => {
  //   const { formValues, contractorInfo } = prevProps;

  //   if (formValues.type !== this.props.formValues.type) {
  //     this.props.change("addOnServices", null);
  //   }
  //   if (
  //     JSON.stringify(contractorInfo) !==
  //     JSON.stringify(this.props.contractorInfo)
  //   ) {
  //     this.addData();
  //   }
  // };

  addData = async () => {
    this.setState({
      load: true
    })
    try {

      const data  = await getOrdersList({
        page:1,
        limit:10
      });

      const { orders, total, page, perPage: limit } = data;
      this.setState({
        orders,
        totalCount: total,
        currPage:page,
        perPage: limit,
        load: false,
        page: page - 1

      })
      this.props.setJobs(orders, "allJobs");
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        load:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  };

  updateList = async () => {
    this.setState({
      load: true
    })
    try {
      const { 
        perPage, 
        currPage, 
        q,        
        startDate, 
        endDate,
        status,
      } = this.state;
      const data  = await getOrdersList({
        page:currPage,
        q,
        startDate,
        endDate,
        limit:perPage,
        status
      });
      const { orders, total, page, perPage: limit } = data;
      const totalPages = getTotalPagesCount(total,limit);

      if(currPage > totalPages){
        this.getOrders(totalPages, true);
      } else {
        this.setState({
          totalCount: total,
          currPage:page,
          perPage: limit,
          load: false,
          page:page - 1
        })
        this.props.setJobs(orders, "allJobs");
      }

    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        load:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  }

  getSearch = (e) => {   
    this.debounceFun(e.target.value);
  }

  clearSearch = ()=> {
    this.inputRef.current.value = "";
    this.search();
  }

  search = async (q) => {

    this.searchValue = this.inputRef.current.value;   
    const { perPage, startDate, endDate, status} = this.state;
    try {
      this.setState({load: true});
      const data = await getOrdersList({
        q,
        status,
        page:1,
        limit:perPage,
        startDate,
        endDate
      })

      const { orders, total, page, perPage: limit } = data;
      this.setState({
        q: q,
        currPage: page,
        totalCount: total,
        page:page - 1,
        perPage: limit,
        load: false
      })
      this.props.setJobs(orders, "allJobs");
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message
      }
      this.props.setMsgAndShow(errMsg)
    }
  }


  submitData = async (obj, dispatch, props) => {
    let formValues = _.cloneDeep(obj);
    let detailsObj = {};
    let serviceAddress = {};
    const addOns = {};

    const {
      addOnServices,
      city,
      state,
      email,
      zipcode,
      address,
      firstName,
      lastName,
      phone,
      cleaningType,
      propertyArea,
      propertyType,
      etc
    } = formValues;

    if (Array.isArray(addOnServices)) {
      addOnServices.forEach(
        service => (addOns[service.name] = service.quantity)
      );
    } else {
      addOns[addOnServices.name] = {};
      addOns[addOnServices.name][addOnServices.nameLabel] =
        addOnServices.planType;
      addOns[addOnServices.name][addOnServices.valueName] = addOnServices.value;
    }

    formValues = removeProp(formValues, [
      "addOnServices",
      "city",
      "state",
      "email",
      "address",
      "firstName",
      "lastName",
      "phone",
      "cleaningType",
      "propertyArea",
      "propertyType",
      "etc"
    ]);

    if (formValues.type === "commercial") {
      formValues = removeProp(formValues, ["bedrooms", "bathrooms"]);
    }

    if (!_.isEmpty(propertyType)) {
      serviceAddress = {
        city,
        state,
        zipcode,
        fname: firstName,
        lname: lastName,
        phone,
        email,
        address,
        propertyArea,
        propertyType: propertyType.value
      };
    } else {
      serviceAddress = {
        city,
        state,
        zipcode,
        fname: firstName,
        lname: lastName,
        phone,
        email,
        address,
        propertyArea: propertyArea.value
      };
    }

    detailsObj.orderDetails = {
      ...formValues,
      cleaningType: cleaningType.value,
      propertyArea: propertyArea.value,
      data: { etc: parseInt(etc, 10) },
      serviceAddress,
      addOns,
      isOpen: false,
      contractorId: this.props.contractorInfo.businessDetails._id
    };

    detailsObj.userInfo = {
      data: {
        fname: firstName,
        lname: lastName,
        phone
      },
      userType: "customer",
      username: email,
      email
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/add_order`,
        { ...detailsObj }
      );

      if (this.state.show === "acceptedJobs") {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_PREFIX}cleaning_order/get_orders`,
            {
              params: {
                contractorId: this.props.contractorInfo.businessDetails._id
              }
            }
          );

          const { orders } = data;

          this.setState({
            acceptedJobOrders: orders
          });
        } catch (error) {
          console.log(error);
        }
      }

      if (this.state.show === "todaysJobs") {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_PREFIX}cleaning_order/get_orders`,
            {
              params: {
                contractorId: this.props.contractorInfo.businessDetails._id,
                date: new Date()
              }
            }
          );

          const { orders } = data;

          this.setState({
            todaysJobOrders: orders
          });
        } catch (error) {
          console.log(error);
        }
      }

      this.handleClose();
      this.props.reset("OrderForm");
    } catch (error) {
      console.log(error);
    }
  };

  decreaseByOne = type => () => {
    let value = this.props.formValues[type];
    value = value === 1 ? value : value - 1;
    this.props.change(type, value);
  };

  increaseByOne = type => () => {
    let value = this.props.formValues[type];
    value = value + 1;
    this.props.change(type, value);
  };

  changeList = listName => async () => {
    if (listName === "acceptedJobs") {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_PREFIX}cleaning_order/get_orders`,
          {
            params: {
              contractorId: this.props.contractorInfo.businessDetails._id
            }
          }
        );

        const { orders } = data;

        if (orders.length > 0) {
          this.setState({
            show: listName,
            acceptedJobOrders: orders
          });
        } else {
          this.setState({
            show: listName
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else if (listName === "todaysJobs") {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_PREFIX}cleaning_order/get_orders?`,
          {
            params: {
              contractorId: this.props.contractorInfo.businessDetails._id,
              date: new Date()
            }
          }
        );

        const { orders } = data;

        if (orders.length > 0) {
          this.setState({
            show: listName,
            todaysJobOrders: orders
          });
        } else {
          this.setState({
            show: listName
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.setState({
        show: listName
      });
    }
  };

  openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false
    });
    this.props.resetForm("OrderForm");
  };

  toggleJobModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }

  closeEditModal = ()=> {
    this.setState({
      showEditJobModal: false
    });
    this.props.resetForm("EditOrderForm");
  };

  handleMenuClose = () => {
    this.setState({
      open: false,
      jobItemIndex: "",
      reason:""
    });
  };

  acceptJob = jobItem => async () => {
    const updateObj = {
      orderId: jobItem._id,
      isOpen: false,
      contractorId: this.props.contractorInfo.businessDetails._id,
      jobTimeline: {
        accepted: moment().format()
      },
      orderStatus: "Accepted"
    };

    const updateActivityObj = {
      orderId: jobItem._id,
      type: "accepted",
      updateObj: {
        contractorId: this.props.contractorInfo.businessDetails._id
      }
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/update`,
        {
          ...updateObj
        }
      );
      axios.post(
        `${
          process.env.REACT_APP_API_PREFIX
        }cleaning_order/update_order_activity`,
        { ...updateActivityObj }
      );

      socket.emit("removeJob", jobItem._id);
      this.props.setMsgAndShow(
        `Job Id '${jobItem._id}' is moved to accepted jobs`
      );
    } catch (error) {
      this.props.setMsgAndShow(error.response.data.msg);
    }
  };

  rejectJob = jobItem => async () => {
    const updateObj = {
      orderId: jobItem._id,
      rejectedBy: this.props.contractorInfo.businessDetails._id
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/reject_order`,
        {
          ...updateObj
        }
      );

      this.props.removeJob(jobItem._id, "newJobs");
      this.props.setMsgAndShow(`Job Id '${jobItem._id}' was rejected`);
    } catch (error) {
      this.props.setMsgAndShow(error.response.data.msg);
    }
  };

  assignJob = e => { 
    const { target } = e;
    const { value, checked } = target;
    const jobItemObj = _.cloneDeep(this.state.jobItemObj);
    if (checked) {
      jobItemObj.assignedEmployeeIds.push(value);
      this.setState({
        jobItemObj
      });
    } else {
      const employeeIndex = jobItemObj.assignedEmployeeIds.indexOf(value);
      if (employeeIndex > -1) {
        jobItemObj.assignedEmployeeIds.splice(employeeIndex, 1);
        this.setState({
          jobItemObj
        });
      }
    }
  };

  confirmAssign = async () => {
    let { jobItemObj, acceptedJobOrders, todaysJobOrders, show } = this.state;
    let msgForPopUp;
    const jobId = jobItemObj._id;
    const updateObj = {
      orderId: jobId,
      assignedEmployeeIds: jobItemObj.assignedEmployeeIds
    };

    if (
      !jobItemObj.jobTimeline.assigned &&
      jobItemObj.assignedEmployeeIds.length > 0
    ) {
      updateObj.jobTimeline = {
        assigned: moment().format()
      };

      updateObj.orderStatus = "Assigned";
      msgForPopUp = `Job Id '${jobId}' is assigned`;
    } else {
      updateObj.jobTimeline = {
        assigned: null
      };

      updateObj.orderStatus = "Accepted";
      msgForPopUp = `Job Id '${jobId}' is unassigned`;
    }

    const updateActivityObj = {
      orderId: jobId,
      type: "assigned",
      updateObj: {
        assignedEmployeeIds: jobItemObj.assignedEmployeeIds,
        contractorId: jobItemObj.contractorId
      }
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/update`,
        {
          ...updateObj
        }
      );

      axios.post(
        `${
          process.env.REACT_APP_API_PREFIX
        }cleaning_order/update_order_activity`,
        { ...updateActivityObj }
      );

      if (show === "acceptedJobs") {
        acceptedJobOrders = acceptedJobOrders.map(job => {
          if (job._id === jobId) {
            return {
              ...job,
              assignedEmployeeIds: jobItemObj.assignedEmployeeIds
            };
          } else {
            return job;
          }
        });

        this.setState({
          acceptedJobOrders
        });
      } else {
        todaysJobOrders = todaysJobOrders.map(job => {
          if (job._id === jobId) {
            return {
              ...job,
              assignedEmployeeIds: jobItemObj.assignedEmployeeIds
            };
          } else {
            return job;
          }
        });

        this.setState({
          todaysJobOrders
        });
      }

      this.handleMenuClose();
      this.props.setMsgAndShow(msgForPopUp);
    } catch (error) {
      this.props.setMsgAndShow(error.response.data.msg);
    }
  };

  setBtn = (nodes, jobIndex, i, itemObj) => async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}employee/get_employee_data_by_job`,
        {
          params: {
            contractorId: this.props.contractorInfo.businessDetails._id,
            time: itemObj.time,
            date: moment(itemObj.date).format("YYYY-MM-DD"),
            jobId: itemObj._id
          }
        }
      );

      const { employeeList } = data;

      this.setState({
        employeeList,
        open: true,
        btnRef: nodes[i],
        jobItemIndex: jobIndex,
        jobItemObj: itemObj
      });
    } catch (error) {
      this.setState({
        open: true,
        btnRef: nodes[i],
        jobItemIndex: jobIndex
      });
      this.props.setMsgAndShow(error.response.data.msg);
    }
  };
 
  getJobDetails = async (jobItemObj) => {
    const { _id:jobId } = jobItemObj;
    const { show } = this.state;
    let doNotShowJobBtns = false;
    if (show === "acceptedJobs" || show === "todaysJobs") {
      doNotShowJobBtns = true;
    }

    let isRejectLoaderOn = true;
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/jobs/${jobId}/get_rejected_by_list`);
      if(res.data.status === "OK"){
        const { totalCont } = res.data;
        jobItemObj = {...jobItemObj, "rejectedBy": totalCont};
        isRejectLoaderOn = false;
      } else {
        isRejectLoaderOn = false;
        setMsgAndShow(res.data.message);
      }
    } catch (error) {
      const { response } = error;
      isRejectLoaderOn = false;
      setMsgAndShow(response ? response.data.message : "Error")
    }

    this.setState({ 
      isJobDetailsModalOpen: true,
      doNotShowJobBtns,
      isRejectLoaderOn, 
      jobItemObj
    });
  };  

  handleJobDetailsModal = () => {
    this.setState({
      isJobDetailsModalOpen: false,
      jobItemObj: {}
    });
  };

  isAssigned = itemObj => itemObj.assignedEmployeeIds.length > 0;

  jobCompleted = jobItem => async () => {

    let { acceptedJobOrders, todaysJobOrders, show, jobItemObj } = this.state;
    const completedTime = moment().format(); 
    const jobUpdateObj = {
      orderId: jobItem._id,
      jobCompleted: true,
      jobTimeline: {
        completed: completedTime
      },
      orderStatus: "Completed"
    };

    const updateActivityObj = {
      orderId: jobItem._id,
      type: "completed",
      updateObj: {
        contractorId: jobItem.contractorId
      }
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/update`,
        { ...jobUpdateObj }
      );
      axios.post(
        `${
          process.env.REACT_APP_API_PREFIX
        }cleaning_order/update_order_activity`,
        { ...updateActivityObj }
      );
      if (show === "acceptedJobs") {
        acceptedJobOrders = acceptedJobOrders.map(job => {
          if (job._id === jobItem._id) {
            return {
              ...job,
              jobCompleted: true,
              jobTimeline: {
                ...jobItem.jobTimeline,
                completed: completedTime
              },
              orderStatus: "Completed"
            };
          }

          return job;
        });

        this.setState({
          acceptedJobOrders
        });
      } else if (show === "todaysJobs") {
        todaysJobOrders = todaysJobOrders.map(job => {
          if (job._id === jobItem._id) {
            return {
              ...job,
              jobCompleted: true,
              jobTimeline: {
                ...jobItem.jobTimeline,
                completed: completedTime
              },
              orderStatus: "Completed"
            };
          }

          return job;
        });

        this.setState({
          todaysJobOrders
        });
      }

      jobItemObj = {
        ...jobItemObj,
        jobCompleted: true,
        jobTimeline: {
          ...jobItemObj.jobTimeline,
          completed: completedTime
        },
        orderStatus: "Completed"
      };

      this.setState({
        jobItemObj
      });

      this.handleJobDetailsModal();
      this.props.setMsgAndShow(`Job Id ${jobItem._id} is completed`);
    } catch (error) {
      this.props.setMsgAndShow(error.response.data.msg);
    }
  };

  reissueJob =  async (jobItem) => {
    try {
      const resData = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}admin/jobs/${jobItem._id}/reissue`
      );

      if(resData.data.status === "OK"){
        this.updateList();
        this.props.setMsgAndShow(`Job Id '${jobItem.displayId}' was reissued`);
      } else {
        this.props.setMsgAndShow(resData.data.message);
      }

    } catch (error) {
      this.props.setMsgAndShow(error.response.data.message);
    }
  };

  openJobActionModal = (whatToDo) => (jobItem, index) => {
     
    this.orderId = jobItem._id;
    let modalText = null;
    this.jobAction = "suspend";
    if(whatToDo && whatToDo === "cancel"){
      modalText = "Why you want to cancel this job?";
      this.jobAction = "cancel";
    } else if(whatToDo && whatToDo === "reissue"){
      modalText = "Why you want to reissue this job?";
      this.jobAction = "reissue";
    }
    this.setState({
      isCancelJobModalOpen: true,
      modalText
    });
  }

  closeCancelJobModal = () => {
    this.setState({
      isCancelJobModalOpen: false,
      reason:"",
      modalText:""
    });

  }

  handleReasonInput = (e) => {
    this.setState({
      reason: e.target.value
    });
  }

  submitRes = async () => {

    try {
      let { reason } = this.state;
      reason = reason.trim();

      if(!reason){
        window.alert("Please enter a valid reason");
        return;
      }


      this.setState({
        openLoader:true
      });


      let jobUrl = `${process.env.REACT_APP_API_PREFIX}admin/jobs/${this.orderId}/`;

      if(this.jobAction  === "reissue"){
        jobUrl += "reissue";
      } else if(this.jobAction === "cancel"){
        jobUrl += "cancel";
      }



      const res = await axios.post(jobUrl,{reason});

      if(res.data.status === "OK"){
        let {  allJobs } = this.props.jobsObj;
        const { job, message } = res.data;
        // allJobs = allJobs.map((j) => j._id === job._id ? job : j );
        this.setState({
          openLoader: false,
          reason:"",
          isCancelJobModalOpen: false
        });
        this.updateList();
        this.orderId = undefined;
        this.props.setMsgAndShow(message);
        // this.props.setJobs(allJobs, "allJobs");
      } else {
        this.setState({
          openLoader: false,
        })
        this.props.setMsgAndShow(res.data.message)
      }

    } catch (error) {
      const { response } = error;
      this.setState({
        openLoader: false
      })
      this.props.setMsgAndShow(response ? response.data.message : "Error");
    }
  }

  completeJob = async (e) => {
    let popupMsg = "Error while completing jobs";
    let isLoading = true;
    try{
      const jobId = e._id;
      this.setState({
        jobCompleted: isLoading,
      });
      const { data } = await axios.post(`${process.env.REACT_APP_API_PREFIX}admin/jobs/${jobId}/complete`); 
      if(data && data.status === "OK"){
        this.updateList();
        popupMsg = "Job Completed Successfully.";
      }else{
        popupMsg =  data.message;
      }
      isLoading = false;
    }catch(error){
      isLoading = false;
      const { response } = error;
      popupMsg = (response && response.data && response.data.message) || popupMsg;
    }
    this.setState({jobCompleted: isLoading});
    this.props.setMsgAndShow(popupMsg);
  }


  openOTPModal = (jobId) => {
    this.setState({
      isOTPModalOpen:true,
      jobId
    })

  }


  checkForOTPAndComplete = (job) => {
    const { isOTPRequired, _id:jobId } = job;
    if(isOTPRequired){
      this.openOTPModal(jobId);
    } else {
      this.completeJob(job);
    }
  }

  // showRejectedByView = async (job) =>{
  //   const { _id } = job;
  //   console.log("Job data ",job);
  //   this.props.history.push({
  //     pathname:"/rejectedBy_jobs",
  //     state:{
  //       type:"jobs",
  //       jobId:_id
  //     }
  //   })
  // }

  openToSelectContractor = (job,optionName) => {
    this.setState({ optionName });
    this.props.setJobId(job._id);
    this.props.showContractorModal(true);
  }

  // handleSelectChange = (event) =>{
  //   console.log("value revoved");
  //   this.setState({ [event.target.name]: event.target.value });
  // };

  checkForDisabled = (obj)=> {
    const { isActive, orderStatus, jobCompleted, cancelledByAdmin} = obj;


    return  (
      !isActive ||  
      (orderStatus === "completed" || jobCompleted ) || 
      ( orderStatus === "cancelled" || cancelledByAdmin ) ||
      (orderStatus === "ongoing")
    )
  }

  checkIfDisabledForComplete = (obj) => {
    const { isActive, orderStatus, jobCompleted, cancelledByAdmin} = obj;


    return  (
      !isActive ||  
      (orderStatus === "completed" || jobCompleted ) || 
      ( orderStatus === "cancelled" || cancelledByAdmin )
    )
  }

  getFilteredList = async () => {
    const { perPage, q, startDate, endDate, showDateRangeInput, status } = this.state;

    if(showDateRangeInput && (!startDate || !endDate)){
      return;
    }

    try {
      this.setState({load: true});
      const data = await getOrdersList({
        q,
        startDate,
        endDate,
        status,
        page:1,
        limit:perPage
      })

      const { orders, total, page, perPage: limit } = data;
      this.setState({
        totalCount: total,
        page: page - 1,
        currPage:page,
        perPage: limit,
        load: false
      })

       this.props.setJobs(orders, "allJobs");
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        load:false
      })
      this.props.setMsgAndShow(errMsg);
    }


  }

  handleFilter = async (event) => {
    const { value } = event.target;
    this.setState({
      status:value
    }, this.getFilteredList)
  };


  handleDateRange = ()=> {
    this.setState({ 
      showDateRangeInput: !this.state.showDateRangeInput,
      endDate: "",
      startDate: "",
      status: "",
      currPage:1
    },this.updateList);

  };

  handleDateChange = (dateType) => (date) => {
    const state = {};
    state[dateType] = date;
    this.setState({
      ...state,
      currPage:1,
    },this.getJobsWithDate);
  }

  getJobsWithDate = async()=> {
    const { showDateRangeInput } = this.state;
    let { q, status, startDate, endDate } = this.state;

    if(showDateRangeInput && (!startDate || !endDate)){
      return;
    }

    try {

      this.setState({
        load:true
      })

      const data = await getOrdersList({
        q,
        status,
        startDate,
        endDate
      })

      const { orders, total, page,  perPage: limit } = data;
      this.setState({
        q: q,
        totalCount: total,
        currPage:page,
        perPage: limit,
        load: false,
        page: page - 1
      });
      this.props.setJobs(orders, "allJobs");
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        load:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  };

  getOrders = async (pageNumber, isLoaderActive) => {

    try {  
      const { perPage, q, startDate, endDate, status } = this.state;  
      
      if(!isLoaderActive){
        this.setState({
          load: true
        })  

      }

      const data = await getOrdersList({
        page: pageNumber,
        limit: perPage,
        q,
        status: this.state.status,
        startDate,
        endDate,
        status
      })

      const { orders, total, page, perPage: limit } = data;
      this.setState({
        totalCount: total,
        currPage:page,
        perPage: limit,
        load: false,
        page: page - 1
      });
      this.props.setJobs(orders, "allJobs");
    } catch (error) {
      const { response } = error;
      this.setState({
        load: false
      })
      this.props.setMsgAndShow(response ? response.data.message : "Error while getting jobs");
    }
  }

  editBooking = (booking) => {
    this.props.history.push(`/jobs/${booking._id}`);
  }

  disableJobEditing = (obj)=> {
    const { jobCompleted, orderStatus, isActive, cancelledByAdmin } = obj;
    return (
      ( !isActive ) ||
      ( orderStatus === "completed" || jobCompleted ) ||
      ( orderStatus === "cancelled" || cancelledByAdmin )
    )
  };

  checkForJobOfferOrAssign = (obj) => {
    const { isActive, orderStatus, jobCompleted, cancelledByAdmin} = obj;
    return  (
      !isActive ||  
      (orderStatus === "completed" || jobCompleted ) || 
      ( orderStatus === "cancelled" || cancelledByAdmin ) ||
      (orderStatus === "ongoing")
    );
  }

  confirmToken = undefined;

  confirmOrder = async( jobObj ) => {
    const { setMsgAndShow } = this.props;
    let popupMsg = "Job confirmed successfully.";
    let showToast = true;
    const { _id: jobId } = jobObj;

    try {
      
      const dataToPass = this.confirmToken ? { token: this.confirmToken } : undefined;
      const jobRes = await axios.put(
        `${process.env.REACT_APP_API_PREFIX}admin/jobs/${jobId}/confirm`,
        dataToPass
      );
      
      if(jobRes && jobRes.data){
        const { data } = jobRes;
        if(data.status === "OK"){
          const { job } = data; 
          popupMsg = `Job Id '${job.displayId}' has been confirmed.`;
          this.updateList();
          this.confirmToken = undefined;
        }
      }

    } catch (error) {
      const { response } = error;
      popupMsg = (response && response.data && response.data.message) || "Error while setting job as confirmed." ;
      showToast = await this.handleConfirmOrderErrorRes(jobObj,response);
    }
    
    if(showToast) setMsgAndShow(popupMsg);
  }

  handleConfirmOrderErrorRes = async( jobObj, response )=> {
    let showToast = true;
    if(response && response.data){
      if(
        response.data.code === ZOK_NO_PAYMENT_METHOD &&
        response.data.customToken
      ) {
        showToast = false;
        const confirmed = await confirmResponse( 
          "Do you want to continue ?", 
          "Customer does not have any payment method yet." 
        );
        if(!confirmed) return;
        this.confirmToken = response.data.customToken;
        this.confirmOrder(jobObj);
      }
    }
    return showToast;
  };

  checkForDisableConfirmBtn = (obj) => {
    const { orderStatus } = obj;
    return (orderStatus !== "processing");
  }


  closeOTPModel = () => {
    this.setState({
      isOTPModalOpen:false,
      jobId:""
    })
  }

  resendCode = async (job) => {
    try {
      this.setState({
        load:true
      })

      const requestRes = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}admin/jobs/${job._id}/resend_otp`
      );

      const { message } = requestRes.data;

      this.setState({
        load:false
      })
      this.props.setMsgAndShow(message);


    } catch (error) {
      const { response } = error;
      this.setState({
        load: false
      })
      this.props.setMsgAndShow(response ? response.data.message : "Error while sending completion code");
    }
  }


  render() {

    const newJobActions = [
      { 
        label: "Get Details",
        onClick: this.getJobDetails,
        isForJobModal: true
      },
      {
        label: "Edit Job",
        onClick: this.editBooking,
        checkForDisabled: true,
        disableBtn: this.disableJobEditing,
        secondParam: "editJob"
      },
      {
        label: "Reissue Order",
        onClick: this.openJobActionModal("reissue"),
        checkForDisabled: true,
        disableBtn: obj=> this.checkForDisabled(obj),//this.checkForDisabled(obj),//obj.cancelledByUser || !obj.contractorId,
        secondParam:"reissue"
      },
      {
        label: "Cancel Order",
        onClick: this.openJobActionModal("cancel"),
        checkForDisabled: true,
        disableBtn: obj=>  this.checkForDisabled(obj),//obj.cancelledByAdmin || obj.cancelledByUser,
        // secondParam:"cancel"
      },
      {
        label: "Confirm Order",
        onClick: this.confirmOrder,
        checkForDisabled: true,
        disableBtn: obj=>  this.checkForDisableConfirmBtn(obj),//obj.cancelledByAdmin || obj.cancelledByUser,
        secondParam:"cancel"
      },
      {
        label: "Complete Job",
        onClick: this.checkForOTPAndComplete, //(obj)=> console.log("Completed \n Contractor-id :",obj.contractorId,"\n Job Completed :",obj.jobCompleted),
        checkForDisabled: true,
        disableBtn: obj=> this.checkIfDisabledForComplete(obj), // Assign Check on Server
        secondParam: "complete"
      },
      {
        label: "Offer To",
        onClick: (job)=> this.openToSelectContractor(job,"offerTo"),
        checkForDisabled: true,
        disableBtn: obj=> this.checkForJobOfferOrAssign(obj),
        secondParam: "offerTo"
      },
      {
        label: "Assign To",
        onClick: (job)=> this.openToSelectContractor(job,"assignTo"),
        checkForDisabled: true,
        disableBtn: obj=> this.checkForJobOfferOrAssign(obj),
        secondParam: "assignTo"
      },
      {
        label:"Resend OTP",
        onClick:this.resendCode,
        checkForDisabled: true,
        disableBtn: obj=> this.checkForDisabled(obj), // Assign Check on Server
        secondParam: "complete"
      }
      // {
      //   label:"Edit",
      //   onClick:this.editBooking,
      //   // checkForDisabled: true,
      //   // disableBtn:this.checkForDisabled,
      // }
      // {
      //   label: "Rejected By",
      //   onClick: this.showRejectedByView,
      //   checkForDisabled: true,
      //   disableBtn: obj=> obj.jobCompleted ? true : false,
      //   secondParam: "rejectedBy"
      // }
      // {
      //   label: "Reject",
      //   onClick: this.rejectJob
      // }
    ];
   
    
    const acceptedJobActions = [
      {
        label: "Get Details",
        onClick: this.getJobDetails,
        isForJobModal: true
      },
      {
        label: "Job Compeleted",
        onClick: this.jobCompleted,
        checkForDisabled: true,
        propToCheck: "jobCompleted"
      },
      {
        label: "Yet To Assign",
        secondaryLabel: "Assigned",
        onClick: this.setBtn,
        isSecondary: this.isAssigned,
        checkForDisabled: true,
        propToCheck: "jobCompleted",
        isRef: true
      }
    ];

    const actions = [
      {
        label: "Confirm",
        onClick: this.confirmAssign
      }
    ];

    const todaysJobActions = [
      {
        label: "Get Details",
        onClick: this.getJobDetails,
        isForJobModal: true
      },
      {
        label: "Job Compeleted",
        onClick: this.jobCompleted,
        checkForDisabled: true,
        propToCheck: "jobCompleted"
      },
      {
        label: "Yet to Assign",
        secondaryLabel: "Assigned",
        onClick: this.setBtn,
        isSecondary: this.isAssigned,
        checkForDisabled: true,
        propToCheck: "jobCompleted",
        isRef: true
      }
    ];

    const { optionName} = this.state;

    const { domesticOpts } = this.props;

    const cleaningTypes = (domesticOpts && domesticOpts.cleaningTypes) || [];

    return (
      <Fragment>
        <JobsDashboard
          getJobDetails={this.getJobDetails}
          newJobActions={newJobActions}
          acceptedJobOrders={this.state.orders}
          acceptedJobActions={acceptedJobActions}
          todaysJobActions={todaysJobActions}
          todaysJobOrders={this.state.orders}
          handleModalClose={this.handleClose}
          changeList={this.changeList}
          openModal={this.openModal}
          handleSave={this.handleSave}
          increaseByOne={this.increaseByOne}
          decreaseByOne={this.decreaseByOne}
          submitData={this.submitData}
          getOrders={this.getOrders}
          getSearch={this.getSearch}
          handleFilter={this.handleFilter}

          searchValue={this.searchValue}
          clearSearch={this.clearSearch}
          searchInput={this.inputRef}
          handleDateChange={this.handleDateChange}
          handleDateRange={this.handleDateRange}

          {...this.state}
          {...this.props}
        />

        <JobDetailsModal
          handleJobCompleted={this.jobCompleted}
          acceptJob={this.acceptJob}
          rejectJob={this.rejectJob}
          handleJobDetailsModal={this.handleJobDetailsModal}
          cleaningTypes={cleaningTypes}
          {...this.state}
        />

        <SelectContractorContainer 
          optionSelected={optionName}
          refreshJobList={this.updateList}
          {...this.props}
        />

        <JobActionModal
          onCancel={this.closeCancelJobModal}
          isModalOpen={this.state.isCancelJobModalOpen}
          onTextInput={this.handleReasonInput}
          onSubmit={this.submitRes}
          modalText={this.state.modalText}
          openLoader={this.state.openLoader}
          requiredText
        />


        <AddJobModal
          showModal={this.state.isModalOpen}
          toggleModal={this.toggleJobModal}
          mode="add"
          onAddSuccess={this.updateList}
          getJobDetails={this.getJobDetails}
        />
        <GetOTPModal 
          closeModal={this.closeOTPModel}
          open={this.state.isOTPModalOpen}
          url={`${process.env.REACT_APP_API_PREFIX}admin/jobs/`}
          action="complete"
          jobId={this.state.jobId}
          onCallSuccess={this.updateList}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  let formValues = {};

  // if (state.form.EditOrderForm) {
  //   const { EditOrderForm } = state.form;
  //   if (EditOrderForm.values) {
  //     formValues = EditOrderForm.values;
  //   }
  // }
  return {
    jobsObj: state.jobs,
    adminObj: state.admin,
    domesticOpts:state.app.cleaningOpts.domestic,
    commercialOpts:state.app.cleaningOpts.commercial,
    // formValues
  };
};

const mapDispatchToProps = dispatch => ({
  setJobs: (jobs, jobsType) => dispatch(setJobs(jobs, jobsType)),
  removeJob: (jobId, jobsType) => dispatch(removeJob(jobId, jobsType)),
  updateJob: (jobType, jobId, updateObj) =>
  dispatch(updateJob(jobType, jobId, updateObj)),
  setJobId: jobId => dispatch(setJobId(jobId)),
  showContractorModal: boolValue => dispatch(showContractorModal(boolValue)),
  resetForm: name => dispatch(reset(name)),
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  reset,
  loadFormData:(payload) => dispatch(loadFormData(payload)),
  resetFormData:() => dispatch(resetFormData())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobsDashboardContainer);