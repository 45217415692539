import { 
  STORE_COMMERCIAL_OPTS,
  STORE_DOMESTIC_PRICE_LIST,
  STORE_DOMESTIC_TYPES,
  SHOW_LOADER,
  HIDE_LOADER,
  TOGGLE_LOADER,
  OPEN_PASS_CHANGE_MODAL,
  CLOSE_PASS_CHANGE_MODAL,
  SET_CARPET_CLEANING_OPTS,
  SET_FREQUENCIES,
  SET_PRICE_LIST
} from "../types";


export const storeDomesticPriceList = (list) => ({
  type: STORE_DOMESTIC_PRICE_LIST,
  payload:list
})

export const storeDomesticTypes = (types) => ({
  type: STORE_DOMESTIC_TYPES,
  payload:types
})

export const storeCommercialOpts = (opts) => ({
  type: STORE_COMMERCIAL_OPTS,
  payload: opts
})


export const showLoader = () => ({
  type:SHOW_LOADER,
})

export const hideLoader = () => ({
  type:HIDE_LOADER
})

export const toggleLoader = () => ({
  type:TOGGLE_LOADER
})

export const openPassChangeModal = () => ({
  type:OPEN_PASS_CHANGE_MODAL
})

export const closePassChangeModal = () => ({
  type:CLOSE_PASS_CHANGE_MODAL
})

export const setCarpetCleaningOpts = (payload) => ({
  type:SET_CARPET_CLEANING_OPTS,
  payload
})

export const setFrequencies = (list) => ({
  type:SET_FREQUENCIES,
  payload:list
})

export const setPriceList = (list) => ({
  type:SET_PRICE_LIST,
  payload:list
})