import { cloneDeep } from "lodash";
import { getValidDate } from "../utils/compareDates";

const sortAdditionalDates = (dates) => {
  let resDates = [];
  if(dates && dates.length){
    const clonedDatesSet = cloneDeep(dates);
    clonedDatesSet.sort((aDate,bDate) => getValidDate(aDate.date) - getValidDate(bDate.date))
    resDates = clonedDatesSet;
  }
  return resDates;
}


export default sortAdditionalDates;