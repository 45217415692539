import React, { Component } from "react";
import Header from "../components/Header";

import { openPassChangeModal } from "../store/actions/app";
import { connect } from "react-redux";
import { setMsgAndShow } from "../store/actions/popup";
import axios from 'utils/http';
import Storage from "utils/Storage";
class HeaderContainer extends Component {

  state = {
    anchorEl: null
  }
  handleClick = event => {

    this.setState({ anchorEl: event.currentTarget });
  };

  handleLogout = async () => {
    try {
      const res =  await axios.get(`${process.env.REACT_APP_API_PREFIX}user/logout`)

      if(res.data) {
        Storage.remove("adminInfo");
        Storage.remove("auth_token");
        window.location.replace('/login');
      }

    } catch (error) {
      this.props.setMsgAndShow("Sorry unable to logout :(")
      Storage.remove("adminInfo");
      Storage.remove("auth_token");
      window.location.replace('/login')
    }
  }
  handleClose = () => {
    this.setState({ anchorEl: null });
  };



  openChangePassModal = () => {
    this.props.openChangePassForm();
    this.handleClose();
  }
  render(){
    return (
      <Header 
        handleClick={this.handleClick}
        handleClose={this.handleClose}
        handleLogout={this.handleLogout}
        openChangePassModal={this.openChangePassModal}
        {...this.state}
        {...this.props} 
      />
    )
    
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg)),
  openChangePassForm:() => dispatch(openPassChangeModal())
})
export default connect(null, mapDispatchToProps)(HeaderContainer);