import React from 'react';
import Grid from "../../core/components/Grid";
import SearchBar from "../SearchBar";
import Table from "../TableWithPagination";
import FabButton from "core/components/FabButton";
import Loader from "../ProgressCircle";

import { withStyles } from "@material-ui/core/styles";


const styles = {
  root: {
    flexGrow: 1,
    // overflow: "hidden",
    flexDirection:"column",
    display: "flex",
  },
  topSpace:{
    marginTop: 18
  },
}

const headers = [
  {
    name: "name",
    label: "Name"
  },
  {
    name:"baseFee",
    label:"Base Fee (%)"
  },
  {
    name:"discountedFee",
    label:"Discounted Fee (%)",
  },
  {
    name: "actions",
    label: "Actions"
  }
];


const columnProps = [
  {
    name:"name",
  },
  {
    name:"baseFee",
  },
  {
    name:"discountedFee",
  }
];



const TierDashboard = ({
  classes,
  onSearchChange,
  clearSearch,
  searchRef,
  gettingTiers,
  processing,
  tierQuery,
  tiersList,
  tierActions,
  handlePageChange,
  tablePage,
  totalCount,
  openAddForm,
}) => {
  return (
    <Grid className={classes.root}>
      {(gettingTiers || processing) && <Loader/>}
      <Grid container alignItems="center">
        <Grid item md={6}>
          <SearchBar 
            placeholder="Search tiers by name"
            onChange={onSearchChange}
            query={tierQuery}
            onClear={clearSearch}
            inputRef={searchRef}
          />
        </Grid>
      </Grid>
      <Table
        list={tiersList}
        totalCount={totalCount}
        actions={tierActions}
        handlePageChange={handlePageChange}
        page={tablePage}
        headers={headers}
        columnProps={columnProps}
        className={classes.topSpace}
      />
      <FabButton onClick={openAddForm} />
    </Grid>
  )
}


export default withStyles(styles)(TierDashboard);