import React from "react";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { muted } from "theme/colors";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  mutedHeading: {
    color: muted,
    fontWeight: 300
  },
  setMarginBottom_8: {
    marginBottom: 8
  }
});

const AdditionalBookingsList = ({ title, list, classes }) => {
  return (
    (list.length > 0) ? (
      <Grid container alignContent="center" alignItems="center" spacing={16}>
        <Grid item xs>
          <Typography variant="subheading" className={classes.mutedHeading}>
            {title}
          </Typography>
          <Grid item container direction="column">
            {list.map((ab, index) => (
              <Grid
                item
                container
                key={index}
                className={classes.setMarginBottom_8}
              >
                <Grid item xs>
                  Job Date :{" "}
                  <span>{moment(ab.date).format("DD MMM YYYY")}</span>
                </Grid>
                <Grid item xs>
                  Job Time :{" "}
                  <span>{moment(ab.time, "hh:mm:ss").format("hh:mm A")}</span>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    ) : null
  );
};

export default withStyles(styles)(AdditionalBookingsList);
