import { isEmpty, isPlainObject } from "lodash";
const showEndDateInput = (formValues)=> {
  let showSelectEndDate = false ;
  if(formValues){
    if(formValues.cleaningType && formValues.type === "domestic"){
      showSelectEndDate = formValues.cleaningType.isOnce ? false : true ;
    }else if(!isEmpty(formValues.frequency) && isPlainObject(formValues.frequency)){
      showSelectEndDate = formValues.frequency.period && formValues.frequency.frequency;
    }
  }
  return showSelectEndDate;
}

export default showEndDateInput;