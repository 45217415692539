import axios from 'axios'
import Storage from "utils/Storage";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    // 'Authorization': 'Bearer ' + Storage.get('auth_token')
    Authorization: {
      toString () {
        return `Bearer ${Storage.get('auth_token')}`
      }
    }
  }
})



export {
  axios as httpRaw,
}

// http.interceptors.request.use (
//   function (config) {
//     const token = Storage.get('auth_token');
//     if (token) config.headers.Authorization = `Bearer ${token}`;
//     return config;
//   },
//   function (error) {
//     return Promise.reject (error);
//   }
// );

export default http;