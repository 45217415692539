import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogActions, TextField , Button} from "@material-ui/core";
import Loader from "../ProgressCircle";




const ContractorActionModal = (props) => { 

  const { 
    onCancel, 
    isModalOpen, 
    modalText, 
    onTextInput,
    requiredText, 
    onSubmit, 
    openLoader 
  } = props;

  return (
    <Dialog
      open={isModalOpen}
      onClose={onCancel}
    >
        <DialogContent>
        {openLoader && <Loader position="fixed" />}
          <DialogContentText>
            { modalText }
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Reason"
            fullWidth
            multiline
            onChange={onTextInput}
            required={requiredText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} variant="outlined">
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Submit
          </Button>
        </DialogActions>
    </Dialog>
  )
}

export default ContractorActionModal;