import { cloneDeep } from "lodash";
import { LOAD_EVENT_DATA } from "../types";


const defaultState = {
  data:null
}

const reducer = (state = defaultState, action) => {
  let finalState = {};

  switch(action.type){
    case LOAD_EVENT_DATA:
      finalState = cloneDeep(state);
      finalState.data = cloneDeep(action.payload);
      break;

    default:
      finalState = state;
  }

  return finalState;
}


export default reducer;