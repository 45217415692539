import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";
import { withStyles } from '@material-ui/core/styles';
import cn from "classnames";
class Event extends Component {
  render(){
    const { event, classes } = this.props
    return (
      <Grid item className={cn(classes.eventInfoCont, event.isAssigned ? classes.greenColor : null)}>
        <Grid item>Order ID:- {event.title}</Grid>
        <Grid item>Time:- {moment(event.start).format("hh:mm A")}</Grid>
        <Grid item className={classes.eventAssigned}>{event.isAssigned ? <p>ASSIGNED</p> : <p>ASSIGN</p>}</Grid>
      </Grid>
    )
  }
}


const styles = theme => ({
  eventAssigned:{
    textAlign:"right"
  },
})

export default withStyles(styles)(Event);