import React, { Component } from "react";
import Reviews from "../components/Reviews";
import { isEmpty } from "lodash";
import { connect  } from "react-redux";
import { setMsgAndShow } from "../store/actions/popup";
import axios from "utils/http";

class ReviewsContainer extends Component {

  state = {
    reviews: [],
    load: false,
    contractorName:"",
    contractorRatings:0
  }
  componentDidMount(){
    const { state } = this.props.location;

    if(!isEmpty(state)){
      const { contractor } = state;
      this.getReviews(contractor);
    }
  }

  getReviews = async (contractor ) => {

    const { setMsgAndShow } = this.props;
    this.setState({
      load: true
    })
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/contractor/reviews`,{
        params:{
          contractorId: contractor.businessId
        }
      })

      if(res.data.status === "OK"){
        const { reviews } = res.data;

        this.setState({
          reviews,
          contractorName: contractor.businessName,
          contractorRatings: contractor.ratings,
          load: false
        })
      } else {
        setMsgAndShow(res.data.message)
      }
    } catch (error) {
      const { response } = error;
      this.setState({
        load: false
      })
      setMsgAndShow(response ? response.data.message : "Error")
    }
  }

  goBack = () => {
    this.props.history.goBack();

  }
  render(){
    return (
      <Reviews 
        goBack={this.goBack}
        {...this.state}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg))
})
export default connect(null, mapDispatchToProps)(ReviewsContainer);