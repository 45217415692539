import axios from "utils/http";


export const listEvents = async (url,params) => {
  try {
    const res = await axios.get(url,{
      params
    });

    return  res.data;
  } catch (error) {
    throw error;
  }
}


export const addEvent = async (url,data) => {
  try {
    const res = await axios.post(url,{...data});
    return  res.data;
  } catch (error) {
    throw error;
  }
}

export const updateEvent = async (url,data) => {
  try {
    const res = await axios.put(url,{...data});
    return  res.data;
  } catch (error) {
    throw error;
  }
}

export const removeEvent = async (url, data) => {
  try {
    const res = await axios.delete(url,{
      data
    })
    return res.data;
  } catch (error) {
    throw error;
  }
}