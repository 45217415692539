import React, { Component, Fragment } from "react";
import CompletedJobs from "../components/CompletedJobs";
import JobDetailsModal from "../components/JobDetailsModal";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { setMsgAndShow } from "../store/actions/popup";
import axios from "utils/http";
import _ from "lodash";
import { capitalizeFirstLetterOnly } from "../utils/capitalize";

class CompletedJobsContainer extends Component {

  state = {
    startDate:"",
    endDate:"",
    businessName:"",
    jobs:[],
    load: false,
    jobItemObj:{},
    isJobDetailsModalOpen: false,
    totalCount: 0,
    page:1,
    tablePage:0
  }
  limit = 10;
  cleaningTypes = [];

  componentDidMount(){
    const { state } = this.props.location;
    if(!isEmpty(state)){
      this.getList();
    }
    this.getCleaningTypes();
  }

  getCleaningTypes = async() => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}cleaning/get_cleaning_types`);

      this.cleaningTypes = data.types;
    } catch (error) {
      const { response } = error;
      this.props.setMsgAndShow(response ? response.data.message : "Error Getting Cleaning types" );
    }
  }


  getContractorJobs = async (contractor, startDate, endDate) => {
    const { setMsgAndShow } = this.props;
    this.setState({
      load: true
    })


  
    try {

      const { page } = this.state;
      const res = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/contractor/get_completed_jobs`,
        {
          params:{
            contractorId: contractor.businessId,
            startDate,
            endDate,
            page,
            limit:this.limit
          }
        }
      )


      this.limit = perPage;
      const { 
        orders,
        perPage,
        total,
        page: receivedPage,
      } = res.data;

      this.setState({
        load: false,
        jobs:orders,
        startDate,
        endDate,
        businessName: contractor.businessName,
        totalCount: total,
        page:receivedPage,
        tablePage:receivedPage - 1
      })
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get orders!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      setMsgAndShow(errMsg);
    }

    
  }


  getList = () => {
    const { state } = this.props.location;
    const { contractor, startDate, endDate } = state;
    this.getContractorJobs(contractor, startDate, endDate);
  }


  viewJob =  (job) => {
    this.setState({
      jobItemObj:job,
      isJobDetailsModalOpen: true
    })
  }

  handleJobDetailsModal = () => {
    this.setState({
      isJobDetailsModalOpen: false,
      jobItemObj: {}
    });
  }

  goBack = () => {
    this.props.history.goBack();
  }

  handlePageChange  = (page) => {
    this.setState({
      page,
    }, this.getList)
  }
  jobActions = [
    {
      label:"View Details",
      onClick: this.viewJob
    }
  ]
  render(){


    return (
      <Fragment>
        <CompletedJobs 
          {...this.state}
          jobActions={this.jobActions}
          goBack={this.goBack}
          handlePageChange={this.handlePageChange}
        />

        <JobDetailsModal
          handleJobDetailsModal={this.handleJobDetailsModal}
          cleaningTypes={this.cleaningTypes}
          {...this.state}
        />
      </Fragment>
    )
  }
}



const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg))
})

export default connect(null,mapDispatchToProps)(CompletedJobsContainer)