import React from "react";
import "./style.css";

const Loader = () => (
  <div className="loader-overlay-conatainer"> 
    <div className="loader">
      <div className="box1"></div>
      <div className="box2"></div>
      <div className="box3"></div>
      <div className="box4"></div>
      <div className="box5"></div>
      <div className="box6"></div> 
      <div className="box7"></div>
      <div className="box8"></div>
      <div className="box9"></div>
      <div className="box10"></div>
    </div> 
  </div>
);


export default Loader;