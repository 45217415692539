import React from "react";
import { MainLayout } from "../layouts";
import UsersContainer from "../containers/UsersContainer";



const UsersPage = (props) => {
  return (
    <MainLayout pageName="Users">
      <UsersContainer {...props}/>
    </MainLayout>
  )
}



export default UsersPage;