import React, { PureComponent } from "react";
import { reduxForm, arrayPush } from "redux-form";
import axios from "utils/http";
import getOptionsValue from "utils/getOptionsValue";
import moment from "moment-timezone";
import { geoCode } from "../utils/google";
import isNumberKey from "../utils/isNumberKey";
import isBackspaceOrDelKey from "../utils/isBackspaceOrDelKey";
import { setMsgAndShow } from "store/actions/popup";
import { calCost, createCarpetAddOns, createCleaningAreasList, createOrderFormData, getAdditionalBookings, getDomesticAdditionalBookings, getPropertyInfo } from "../helpers/orders";
import { isEmpty, cloneDeep, isEqual } from "lodash";
import { loadFormData, resetFormData, updateFormData } from "../store/actions/formData";
import confirmResponse from "../utils/confirmResponse";
import EditJob from "../components/EditJob";
import { commercialFrequencyMap, domesticFrequencyMap } from "../data/formFieldData";
import { connect } from "react-redux";
import removeProp from "../utils/removeProp";
import { validateDateBetweenTwoDates as compareDates, isDateAfter } from "../utils/compareDates";
import getLegacyFrequencyType from "../helpers/getLegacyFrequency";
import { capitalizeAllFirstLetters } from "../utils/capitalize";
import sortAdditionalDates from "../helpers/sortAdditionalDates";
import { storeDomesticPriceList, setPriceList } from "../store/actions/app";
import { getPricingList } from "../sdk/helpers/pricingListAndOrderInfo";
import getNumber from "../utils/getNumber";
import { ZOK_SERVICE_UNAVAILABLE } from "../variables/errorCode";
class EditJobContainer extends PureComponent {
  orderData = null;
  rawAdditionalBookings = null;
  modifiedAdditionalBookings = null;
  doNotUpdateCost = false;
  couponData = {};
  state = {
    customers: [],
    gettingCouponData: false,
    gettingPropertyData: false,
    processingOrder: false,
    gettingPriceList: false
  };
  componentDidMount() {
    geoCode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
    this.props.loadFormData({})
    this.getOrderDetailsAndFillDetails();
  }


  getOrderDetails = async () => {
    try {
      const { jobId } = this.props.match.params;

      const res = await axios.get(`${process.env.REACT_APP_API_WITH_PREFIX}admin/jobs/${jobId}`)
      const { order } = res.data;
      this.orderData = order;
      return true;

    } catch (error) {
      console.log("ERROR", error);
    }
  }


  getAdditionalBookings = async () => {
    try {
      const { jobId } = this.props.match.params;
      const res = await axios.get(`${process.env.REACT_APP_API_WITH_PREFIX}admin/jobs/${jobId}/additional_jobs`);
      const { orders: additionalBookings } = res.data;
      if (additionalBookings && additionalBookings.length) {
        this.rawAdditionalBookings = additionalBookings;
      }
      return true;
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  getCouponData = async () => {
    try {
      const { jobId } = this.props.match.params;
      const res = await axios.get(`${process.env.REACT_APP_API_WITH_PREFIX}admin/jobs/${jobId}/coupon_code`);
      const { couponCode } = res.data;
      if (!isEmpty(couponCode)) {
        this.couponData = couponCode;
      }
      return true;
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  fillOrderDetails = () => {

    const orderDetails = this.orderData;
    let additionalBookings = this.rawAdditionalBookings;
    let couponData = this.couponData;
    const { commercialOpts, domesticOpts, carpetOpts, frequencies } = this.props;
    if (!isEmpty(orderDetails)) {
      let endDate = undefined;
      const bookingDate = orderDetails.date;
      if (additionalBookings && additionalBookings.length) {
        const lastAdditionalBooking = additionalBookings[additionalBookings.length - 1];
        if (!isEmpty(lastAdditionalBooking)) {
          if (isDateAfter(bookingDate, lastAdditionalBooking.date)) {
            endDate = bookingDate;
          } else {
            endDate = lastAdditionalBooking.date;
          }
        }
        additionalBookings = additionalBookings.map((aB) => ({ ...aB, selected: true }))
        this.modifiedAdditionalBookings = additionalBookings;
      }

      const orderFormData = createOrderFormData(orderDetails,
        {
          endDate,
          additionalBookings,
          couponData,
        },
        {
          commercialOpts,
          domesticOpts,
          carpetOpts,
          frequencies,
          mapCostToAdminCost: true,
          fillKeysInfo: true
        });
      this.props.loadFormData(orderFormData);
    }
  }


  getOrderDetailsAndFillDetails = async () => {
    try {
      const orderDetailsRes = this.getOrderDetails();
      const additionalBookingsRes = this.getAdditionalBookings();
      const couponDataRes = this.getCouponData();
      await orderDetailsRes;
      await additionalBookingsRes;
      await couponDataRes;
      this.fillOrderDetails();
      const { jobId } = this.props.match.params;
      this.setPricingListForOrder({ jobId });
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  _updateCost = (prev) => {
    const next = this.props;
    const { formValues, change } = next;

    if (isEmpty(formValues)) return;

    const { serviceAddress } = formValues;

    const propToCompare = [
      "type",
      "bedrooms",
      "bathrooms",
      "carpetAddOns",
      "cleaningAreas",
      // "endDate",
      "adminDiscount",
      "couponData",
      "addOnServices"
      // "addOnWindows"
    ];

    let costUpdateNeeded = null;
    const nextPropertyArea =
      serviceAddress && serviceAddress.propertyArea
        ? parseInt(serviceAddress.propertyArea)
        : 0;

    const nextCleaningType =
      formValues && formValues.cleaningType
        ? formValues.cleaningType.value
        : null;
    const { priceList: pricingList } = this.props;
    if (prev) {
      const { formValues: prevFormValues = {}, priceList: oldPricingList } = prev;

      if (isEmpty(prevFormValues)) return;

      const { serviceAddress: prevServiceAddress } = prevFormValues;
      let prevPropertyArea = 0;

      if (prevServiceAddress && prevServiceAddress.propertyArea) {
        prevPropertyArea = parseInt(prevServiceAddress.propertyArea);
      }

      const prevCleaningType =
        prev.formValues && prev.formValues.cleaningType
          ? prev.formValues.cleaningType.value
          : null;

      costUpdateNeeded =
        nextPropertyArea !== prevPropertyArea ||
        nextCleaningType !== prevCleaningType;

      propToCompare.forEach(prop => {
        if (!costUpdateNeeded) {
          costUpdateNeeded = next.formValues[prop] !== prev.formValues[prop];
        }
      });


      if (!costUpdateNeeded) {
        costUpdateNeeded = !isEqual(oldPricingList, pricingList);
      }
    }

    const changeCost = !this.doNotUpdateCost;

    const priceList = formValues.type !== "commercial" ? pricingList : undefined;

    if (costUpdateNeeded && changeCost) {
      const { discount, discountedPrice, originalCost, estTime, unModifiedCost } = calCost(
        formValues,
        priceList,
      );

      change("orderCost", parseFloat(discountedPrice.toFixed(2)));
      change("discount", discount);
      change("originalCost", parseFloat(originalCost.toFixed(2)));
      change("unModifiedCost", parseFloat(unModifiedCost.toFixed(2)))
      change("adminCost", parseFloat(originalCost.toFixed(2)));
      change("appliedDiscount", discount);
      change("etc", estTime);
    } else if (!changeCost) {
      const { discount, discountedPrice, originalCost, unModifiedCost } = calCost(
        formValues,
        priceList,
        true
      );
      change("orderCost", parseFloat(discountedPrice.toFixed(2)));
      change("discount", discount);
      change("appliedDiscount", discount);
      change("unModifiedCost", parseFloat(unModifiedCost.toFixed(2)))
      change("originalCost", parseFloat(originalCost.toFixed(2)));
    }
    this._recalculateDates(prev, next)
  };

  componentDidUpdate(prevProps) {
    this.updateFormValuesAfterListUpdate(prevProps, this.props);
    this._updateCost(prevProps);
    // this._recalculateDates(prevProps, this.props);
  }

  _recalculateDates = (prevProps, nextProps) => {
    const { formValues: oldFormValues = {} } = prevProps;
    const { formValues: newFormValues = {} } = nextProps;
    let reCalculateDates = false;
    if (!isEqual(newFormValues, oldFormValues)) {
      const orderType = newFormValues.type;
      if (orderType === "domestic") {
        reCalculateDates = !isEqual(newFormValues["cleaningType"], oldFormValues["cleaningType"]);
      } else {
        reCalculateDates = !isEqual(newFormValues["frequency"], oldFormValues["frequency"]);
      }
    }

    if (reCalculateDates) {
      this.fillGeneratedDates();
    }
  }

  checkListsForUpdate = (prevProps, newProps) => {
    const {
      domesticOpts: oldDomesticOpts,
      commercialOpts: oldCommercialOpts,
      frequencies: oldFrequencies,
    } = prevProps;
    const {
      domesticOpts: newDomesticOpts,
      commercialOpts: newCommercialOpts,
      frequencies: newFrequencies,
    } = newProps;


    const areListsUpdated = !isEqual(oldDomesticOpts, newDomesticOpts)
      || !isEqual(newCommercialOpts, oldCommercialOpts) || !isEqual(oldFrequencies, newFrequencies);

    return areListsUpdated;

  }
  updateFormValuesAfterListUpdate = (prevProps, newProps) => {
    const areListsUpdated = this.checkListsForUpdate(prevProps, newProps)
    if (areListsUpdated) {
      this.fillOrderDetails();
    }

  }
  getCustomers = async () => {
    // const initCustomerList =  (this.props && this.props.initialValues && this.props.initialValues.customer) || [];
    try {
      let customersOptions = [];
      const customersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/customers/list`
      );

      if (customersRes && customersRes.data && customersRes.data.customers) {
        const customers = customersRes.data.customers || [];

        customers.forEach(customer => {
          customersOptions.push({
            ...customer,
            label: customer.fullName,
            value: customer._id
          });
        });

        // if(!isEmpty(initCustomerList)) {
        //   customersOptions.forEach( ( customer, index ) => {
        //     if(customer._id === initCustomerList[0]._id){
        //       customersOptions.splice(index,1);
        //     } 
        //   });
        //   customersOptions = [ ...initCustomerList, ...customersOptions ];
        // }

        this.setState({
          customers: customersOptions
        });
      }
    } catch (error) {
      console.log("Error", error);//eslint-disable-line
    }
  };

  verifyCouponCode = async () => {
    const { change } = this.props;
    const { couponCode } = this.props.formValues;

    if (!couponCode) {
      alert("Enter coupon code first");
      return;
    }

    let messageToShow = "Sorry an error occurred";
    this.setState({
      gettingCouponData: true
    });
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}coupon/check`, {
        code: couponCode,
        source: "web",
        order: this.props.formValues
      });

      const { status, coupon, message } = res.data;

      if (status === "OK") {
        change("couponData", coupon);
        change("couponCode", "");
        messageToShow = "Coupon Applied!";
      } else {
        messageToShow = message;
      }

    } catch (error) {
      const { response } = error;
      if (response && response.data && response.data.message) {
        messageToShow = response.data.message;
      }
    }

    this.setState({
      gettingCouponData: false
    });
    this.props.setMsgAndShow(messageToShow);
  }

  // closeEditModal = () => {
  //   this.props.toggleEditModal();
  // };


  handleErrorToastInEditOrder = async (response) => {
    let showToast = true;
    let errorMsg = "Sorry an error occurred!";
    this.props.change("cToken", "");
    if (response && response.data) {
      if (
        response.data.code === ZOK_SERVICE_UNAVAILABLE &&
        response.data.cToken
      ) {
        showToast = false;
        const confirmed = await confirmResponse(
          "Do you want to continue ?",
          response.data.message,
        );
        if (!confirmed) return;
        this.props.change("cToken", response.data.cToken);
        this.props.handleSubmit();
        // this.props.callSubmit();
      } else if (response.data.message) {

        errorMsg = response.data.message;
      }
    }

    if (showToast) {
      this.props.setMsgAndShow(errorMsg);
    }

  }

  processOrder = async obj => {
    const {
      onEditSuccess,
      setMsgAndShow
    } = this.props;
    this.setState({
      processingOrder: true
    });
    let toastMessage = "Sorry an error occurred.";

    try {
      let formValues = cloneDeep(obj);
      let resFrequency;
      let {
        jobId,
        displayId,
        adminCost,
        adminDiscount,
        type: orderType,
        serviceAddress,
        addOnServices,
        addOnWindows,
        cleaningType,
        customer,
        whatAboutKeys,
        bathrooms,
        bedrooms,
        cleaningServices,
        frequency,
        floor,
        requiredEmployees,
        equipments,
        userId: customerId,
        couponData,
        additionalBookings,
        unModifiedCost,
        originalCost,
        cleaningAreas,
        carpetAddOns,
      } = formValues;

      // console.log("formvalues  ", formValues);
      const addOns = {};
      // let userId = (customer && customer._id) || customerId;
      const propsToRemove = [
        "equipments",
        "cleaningServices",
        "customer",
        "frequency",
        "floor",
        "requiredEmployees",
        "serviceAddress",
        "addOnServices",
        "cleaningType",
        "customer",
        "whatAboutKeys",
        "endDate",
        "jobId",
        "additionalBookings",
        "couponData",
        "addOnWindows",
        "adminCost",
        "appliedDiscount",
        "unModifiedCost",
        "cleaningAreas",
        "carpetAddOns"
      ];
      formValues = removeProp(formValues, propsToRemove);

      // formValues.userId = userId;

      if (couponData && couponData.code) formValues.couponCode = couponData.code;


      adminDiscount = getNumber(adminDiscount, 0);
      // if (adminDiscount) {
      //   adminDiscount = parseFloat(adminDiscount);
      // }
      if (!isEmpty(serviceAddress)) {
        let propertyType;

        if (!isEmpty(serviceAddress.propertyType)) {
          propertyType = serviceAddress.propertyType.value;
        }
        serviceAddress = {
          ...serviceAddress,
          state: serviceAddress.state.value,
          phone: serviceAddress.phone.replace(/(\W)+/g, ""),
          propertyType
        };


        if (formValues.type === "carpet") {
          serviceAddress = removeProp(serviceAddress, ["bedrooms", "bathrooms", "propertyType", "propertyArea", "addOnsString", "windowsRange"]);
        }

        if(formValues.type === "domestic"){
          serviceAddress = removeProp(serviceAddress,["propertyType","windowsRange"]);
        }
      }



      if (!isEmpty(frequency)) {
        frequency = frequency.value;
      }


      if (!isEmpty(cleaningType)) {
        cleaningType = cleaningType.value;
      }


      if (formValues.type === "domestic") {

        // formValues = removeProp(formValues,["equipments","cleaningServices"])
        if (bedrooms) {
          serviceAddress.bedrooms = bedrooms;
        }
        if (bathrooms) {
          serviceAddress.bathrooms = bathrooms;
        }
        // if (!isEmpty(cleaningType)) {
        //   cleaningType = cleaningType.value;
        // }

        // if (!isEmpty(whatAboutKeys)) {
        //   whatAboutKeys = whatAboutKeys.value;
        // }

        if (Array.isArray(addOnServices) && addOnServices.length) {
          addOnServices.forEach((addOnService) => {
            addOns[addOnService.name] = addOnService.quantity;
          });
          const addonsArray = Object.keys(addOns);
          let addOnsString = addonsArray.reduce((string, currA) => {
            if (addOns[currA]) {
              string += `, ${currA}`;
            }
            return string;
          });
          addOnsString = capitalizeAllFirstLetters(addOnsString);
          serviceAddress.addOnsString = addOnsString;
        }

      } else if (formValues.type === "commercial") {

        formValues = removeProp(formValues, ["bedrooms", "bathrooms"]);

        if (Array.isArray(cleaningServices) && cleaningServices.length) {
          cleaningServices = getOptionsValue(cleaningServices);
        }

        if (Array.isArray(equipments) && equipments.length) {
          equipments = getOptionsValue(equipments);
        }

        if (!isEmpty(floor)) {
          floor = floor.value;
        }

        if (!isEmpty(requiredEmployees)) {
          requiredEmployees = requiredEmployees.value;
        }

        // if (!isEmpty(frequency)) {
        //   frequency = frequency.value;
        // }
        if (!isEmpty(addOnWindows)) {
          serviceAddress.windowsRange = addOnWindows.label;
          serviceAddress.addOnsString = "Windows";
          addOns.windows = addOnWindows.value;
          addOns.windowsRange = addOnWindows.label;
        }

      } else if (formValues.type === "carpet") {
        formValues = removeProp(formValues, ["bathrooms", "bedrooms", "addOns", "propertyArea"]);

        if (!isEmpty(cleaningType)) {
          formValues.cleaningType = cleaningType.value;
        }

        if (Array.isArray(cleaningAreas) && cleaningAreas.length) {
          formValues.cleaningAreas = createCleaningAreasList(cleaningAreas)
        }

        if (Array.isArray(carpetAddOns) && carpetAddOns.length) {
          formValues.carpetAddOns = createCarpetAddOns(carpetAddOns);
        }

      }

      const address = `${serviceAddress.address} ${serviceAddress.city} ${serviceAddress.state} US ${serviceAddress.zipcode}`;

      const response = await geoCode.fromAddress(address);
      serviceAddress = {
        ...serviceAddress,
        ...response.results[0].geometry.location
      };

      let commercialData = {};


      if (!isEmpty(whatAboutKeys)) {
        whatAboutKeys = whatAboutKeys.value;
      }

      if (orderType === "commercial") {
        commercialData = {
          cleaningServices,
          frequency,
          floor,
          requiredEmployees,
          equipments,
        }
      }





      const orderData = {
        ...formValues,
        adminCost: adminCost ? parseFloat(parseFloat(adminCost).toFixed(2)) : undefined,
        additionalBookings,
        zipcode: serviceAddress.zipcode,
        cleaningType: orderType !== "commercial" ? cleaningType : undefined,
        whatAboutKeys: whatAboutKeys,
        addOns,
        serviceAddress,
        propertyArea: serviceAddress.propertyArea,
        // cleaningServices,
        frequency: orderType !== "domestic" ? frequency : undefined,
        // floor,
        // requiredEmployees,
        // equipments,
        adminDiscount,
        originalCost: unModifiedCost || originalCost,
        ...commercialData
      };

      // console.log("ORDER_DATA", orderData);
      const payload = {
        ...orderData
      };



      // console.log("PA",payload);

      const res = await axios.put(`${process.env.REACT_APP_API_PREFIX}admin/jobs/edit/${jobId}`, { ...payload });

      // // console.log("RES", res);
      if (res && res.data && res.data.status === "OK") {
        const order = res.data.order || {};
        if (!isEmpty(order)) {
          toastMessage = res.data.message || `Job ${displayId} was updated successfully.`;
        }
        this.props.change("cToken", "");
        this.props.loadFormData({});

      }
      this.setState({
        processingOrder: false
      });
      this.props.history.replace("/jobs");
    } catch (error) {
      const { response } = error;
      this.setState({
        processingOrder: false
      })
      this.handleErrorToastInEditOrder(response);
      // toastMessage = (response && response.data && response.data.message) || "Error occured. Please try again later.";
      // this.setState({
      //   processingOrder:false
      // })
    }
    // setMsgAndShow(toastMessage);
  };

  onSelectFinish = (cleaningTypeOrFrequency) => {
    try {
      const { change } = this.props;
      const { type } = this.props.formValues;

      if (type && type === "carpet") {
        change("cleaningType", { value: `carpet_${cleaningTypeOrFrequency.mappedTo}` });
      }
      if (type === "domestic") {
        this.fillGeneratedDates({ cleaningType: cleaningTypeOrFrequency })
      } else {
        this.fillGeneratedDates({ frequency: cleaningTypeOrFrequency });
      }

    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  };

  getDateChange = (date) => {
    try {
      if (date) {
        this.fillGeneratedDates({ date })
      }

    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  };
  submitData = obj => {
    this.processOrder(obj);
  };

  decreaseByOne = type => () => {
    let value = this.props.formValues[type];
    value = value === 1 ? value : value - 1;
    this.props.change(type, value);
  };

  increaseByOne = type => () => {
    let value = this.props.formValues[type];
    value = value + 1;
    this.props.change(type, value);
  };

  selectCustomer = customer => {
    const { change } = this.props;
    if (!isEmpty(customer)) {
      change("serviceAddress.fname", customer.fname);
      change("serviceAddress.lname", customer.lname);
      change("serviceAddress.phone", customer.phone);
      change("serviceAddress.email", customer.email);
    }
  };


  getPropertyDetails = async () => {
    let messageToShow = "Sorry an Error occurred.";
    try {
      const { formValues, change } = this.props;
      if (isEmpty(formValues)) {
        alert("Please add service address!");
        return;
      }

      const { serviceAddress, type } = formValues;

      if (
        isEmpty(serviceAddress) ||
        !serviceAddress.address ||
        !serviceAddress.zipcode ||
        !serviceAddress.city ||
        !serviceAddress.state
      ) {
        throw new Error("Please provide service address!");
      }

      this.setState({
        gettingPropertyData: true
      });


      const propertyRes = await getPropertyInfo(serviceAddress, type, `${process.env.REACT_APP_API_BASE_URL}address/get_info`)
      const { finishedSqFt, bedrooms, bathrooms, imagesData, foundInfo } = propertyRes;
      if (foundInfo) {
        messageToShow = "Found property details!";
        if (type === "domestic") {
          if (bedrooms) {
            change("bedrooms", Math.floor(bedrooms));
          }
          if (bathrooms) {
            change("bathrooms", Math.floor(bathrooms));
          }
        }

        if (finishedSqFt) {
          change("serviceAddress.propertyArea", finishedSqFt);
        }

        if (!isEmpty(imagesData)) {
          change("imagesData", imagesData);
        }
      } else {
        messageToShow = "Details not found"
      }
    } catch (error) {
      const { response } = error;
      if (response && response.status && response.status === 400) {
        messageToShow = "Property details not found!";
      }
      this.props.setMsgAndShow(messageToShow);
      this.setState({
        gettingPropertyData: false
      })
    }

    this.props.setMsgAndShow(messageToShow);
    this.setState({
      gettingPropertyData: false
    });
  };
  generateDates = (extraParams, forceUpdate = false, doNotRemoveFirstDate = true) => {

    try {
      let datesToSet = undefined;
      const { formValues = {} } = this.props;
      const mergedValues = {
        ...formValues,
        ...extraParams,
      }
      const {
        cleaningType,
        date: startDate,
        time,
        frequency,
        type,
        additionalBookings,
        endDate,
      } = mergedValues;
      // const additionalBookings = formValues.additionalBookings;
      if (type === "domestic") {
        datesToSet = getDomesticAdditionalBookings({
          startDate,
          endDate,
          time,
          cleaningType,
          additionalBookings,
        }, forceUpdate);
      } else {
        datesToSet = getAdditionalBookings({
          startDate,
          endDate,
          time,
          frequency,
          additionalBookings
        }, forceUpdate)
        // datesToSet = getAdditionalBookings({
        //   startDate,
        //   endDate,
        //   time,
        //   frequency,
        //   additionalBookings
        // }, forceUpdate);
      }


      return datesToSet;
    } catch (error) {
      console.log("ERROR", error);
    }
  }


  fillGeneratedDates = (params = {}, forceUpdate) => {
    try {
      const { change } = this.props;

      const modifiedAdditionalBookings = this.modifiedAdditionalBookings;
      let datesSet = this.generateDates({ ...params, additionalBookings: modifiedAdditionalBookings }, forceUpdate)
      if (Array.isArray(datesSet) && datesSet.length) {
        change("additionalBookings", datesSet);
      } else {
        let bookings = null;
        if (this.modifiedAdditionalBookings && this.modifiedAdditionalBookings.length) {
          bookings = this.modifiedAdditionalBookings
        }
        change("additionalBookings", bookings);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  }
  getEndDateValue = endDate => {
    try {
      if (endDate) {
        this.fillGeneratedDates({ endDate })
      }
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  };


  getDomesticDates = (params, forceUpdate = false) => {
    try {
      let dataToSet = undefined;
      const { cleaningType, startDate, endDate, time, additionalBookings } = params;
      // change("additionalBookings",undefined);
      if (!isEmpty(cleaningType) && !cleaningType.isOnce) {
        let diffUnit;
        let startOfUnit;
        let recursion = 1;
        if (cleaningType.isEveryWeek) {
          diffUnit = "weeks";
          startOfUnit = "isoWeek";
        } else if (cleaningType.isBiWeekly) {
          diffUnit = "weeks";
          startOfUnit = "isoWeek";
          recursion = 2;
        } else if (cleaningType.isOnceAMonth) {
          startOfUnit = "month";
          diffUnit = "months";
        }

        if (startDate && endDate && time) {

          dataToSet = [];
          const clonedAdditionalBookings = additionalBookings && additionalBookings.length ? cloneDeep(additionalBookings) : [];
          const localStartDate = moment(startDate).startOf(startOfUnit).format("YYYY-MM-DD");
          const datesDiff = (moment(endDate).diff(localStartDate, diffUnit));
          const diff = Math.floor(datesDiff / recursion);
          let sDate = startDate;
          for (let i = 0; i < diff; i++) {

            sDate = moment(sDate).add(recursion, diffUnit).format("YYYY-MM-DD")
            if (diff - 1 === i) {
              sDate = moment(sDate).isBefore(endDate) ? sDate : endDate;
            }
            const startDatePeriod = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
            const endDatePeriod = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");

            if (clonedAdditionalBookings.length && additionalBookings.length) {
              const dateExistsIndex = clonedAdditionalBookings.findIndex((aB) => compareDates(startDatePeriod, endDatePeriod, aB.date));
              if (dateExistsIndex === -1) {
                dataToSet.push({
                  selected: true,
                  time: time,
                  date: sDate
                });
              }
            } else if (!additionalBookings || forceUpdate) {

              dataToSet.push({
                selected: true,
                time: time,
                date: sDate
              });

            }
          }
          dataToSet = [...clonedAdditionalBookings, ...dataToSet];
          dataToSet = sortAdditionalDates(dataToSet);
        }
      }



      return dataToSet;
    } catch (error) {
      console.error(error);// eslint-disable-line
    }
  }


  getCommercialDates = (params, forceUpdate = false, doNotRemoveFirstDate = false) => {
    const { frequency, startDate, endDate, time } = params;
    try {
      let dataToSet = undefined;
      let removeFirstDate = false;
      let additionalBookings = params.additionalBookings && params.additionalBookings.length ? cloneDeep(params.additionalBookings) : [];
      if (!isEmpty(frequency)) {

        let diffUnit = "weeks";
        let unitTypeToAdd = "weeks";
        let startOfUnit = "isoWeek";
        let daysToAdd = 1;
        let recursion = frequency.frequency;

        if (frequency.period === "month") {
          unitTypeToAdd = "months";
          startOfUnit = "month";
          diffUnit = "months";
        }

        if (frequency.period === "daysInWeek") {
          // unitTypeToAdd = "weeks";
          daysToAdd = frequency.frequency;
          recursion = 1;
          removeFirstDate = true;
        }

        // let daysToAdd = 1;
        // let diffUnit = "weeks";
        // let unitTypeToAdd = "weeks";
        // let startOfUnit = "isoWeek";
        // let recursion = 1;
        // switch (frequency.occurence) {
        // case "everyWeek":
        //   daysToAdd = 1;
        //   break;
        // case "biWeekly":
        //   daysToAdd = 2;
        //   removeFirstDate = true;
        //   break;
        // case "everyOtherWeek":
        //   recursion = 2;
        //   break;
        // case "triWeekly":
        //   daysToAdd = 3;
        //   removeFirstDate = true;
        //   break;
        // case "onceAMonth":
        //   unitTypeToAdd = "months";
        //   startOfUnit = "month";
        //   diffUnit = "months";
        //   break;
        // default:
        //   break;
        // }
        if (startDate && endDate && time) {
          dataToSet = [];
          const localStartDate = moment(startDate)
            .startOf(startOfUnit)
            .format("YYYY-MM-DD");
          const datesDiff = moment(endDate).diff(localStartDate, diffUnit);
          const diff = Math.floor(datesDiff / recursion);
          let sDate = startDate;
          for (let i = 0; i < diff; i++) {
            if (unitTypeToAdd === "weeks" && daysToAdd > 1) {
              const startOfWeek = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
              const endOfWeek = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");
              const nextWeek = moment(sDate)
                .add(recursion, unitTypeToAdd)
                .startOf(unitTypeToAdd)
                .add(1, "day")
                .format("YYYY-MM-DD");

              for (let dayToAdd = 1; dayToAdd <= daysToAdd; dayToAdd++) {
                if (dayToAdd > 1) {
                  sDate = moment(sDate)
                    .add(1, "day")
                    .format("YYYY-MM-DD");
                } else {
                  sDate = moment(sDate).format("YYYY-MM-DD");
                }

                const startDatePeriod = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
                const endDatePeriod = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");
                if (additionalBookings && additionalBookings.length) {
                  const dateExistsIndex = additionalBookings.findIndex((aB) => compareDates(startDatePeriod, endDatePeriod, aB.date));
                  if (dateExistsIndex === -1) {
                    dataToSet.push({
                      selected: true,
                      time: time,
                      date: sDate
                    });
                  }
                } else if (compareDates(startOfWeek, endOfWeek, sDate)) {
                  dataToSet.push({
                    selected: true,
                    time: time,
                    date: sDate
                  });
                }
              }
              sDate = nextWeek;
            } else {
              sDate = moment(sDate)
                .add(recursion, unitTypeToAdd)
                .format("YYYY-MM-DD");
              if (diff - 1 === i) {
                sDate = moment(sDate).isBefore(endDate) ? sDate : endDate;
              }

              const startDatePeriod = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
              const endDatePeriod = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");

              if (additionalBookings && additionalBookings.length) {
                const dateExistsIndex = additionalBookings.findIndex((aB) => compareDates(startDatePeriod, endDatePeriod, aB.date));
                if (dateExistsIndex === -1) {
                  dataToSet.push({
                    selected: true,
                    time: time,
                    date: sDate
                  });
                }
              } else {
                dataToSet.push({
                  selected: true,
                  time: time,
                  date: sDate
                });
              }
            }
          }
        }
      }

      if (dataToSet && dataToSet.length) {
        if (removeFirstDate && !doNotRemoveFirstDate) {
          dataToSet = dataToSet.slice(1);
        }

        dataToSet = [...additionalBookings, ...dataToSet];
        dataToSet = sortAdditionalDates(dataToSet);

      }
      return dataToSet;
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  };

  findCustomers = async q => {
    try {
      let customersOptions = [];
      const customersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/customers/search`,
        {
          params: {
            q
          }
        }
      );

      if (customersRes && customersRes.data && customersRes.data.customers) {
        const customers = customersRes.data.customers;

        customers.forEach(customer => {
          customersOptions.push({
            ...customer,
            label: customer.fullName,
            value: customer._id
          });
        });
      }
      return customersOptions;
    } catch (error) {
      console.log("Error", error); //eslint-disable-line
    }
  };


  onOrderTypeChange = async (_, value) => {
    try {

      this.setState({
        processingOrder: true
      })

      const { frequency, cleaningType, serviceAddress: { zipcode } = {}, cleaningAreas, carpetAddOns } = this.props.formValues;
      // commercialOpts,
      const { domesticOpts, change, frequencies } = this.props;
      change("frequency", null);
      change("cleaningType", null);

      if (value === "commercial") {
        let frequencyToSet;
        if (!isEmpty(frequency)) {
          frequencyToSet = frequency
        }

        if (isEmpty(frequencyToSet) && !isEmpty(cleaningType)) {
          const legacyFrequencyType = getLegacyFrequencyType(cleaningType.value);
          frequencyToSet = frequencies.find((fT) => fT.mappedTo === legacyFrequencyType);
        }

        if (!isEmpty(frequencyToSet)) {
          // const legacyFrequencyType = getLegacyFrequencyType(cleaningType.value);
          // const frequencyToSet = frequencies.find((fT) => fT.mappedTo === legacyFrequencyType);
          // if (!isEmpty(frequencyToSet)) {
          change("frequency", frequencyToSet);
          // }
        }
        // this.fillGeneratedDates({frequency:frequencyToSet, type:value})
      } else {
        await this.getPriceListByZipcode(zipcode, value);

        let bedrooms = null;
        let bathrooms = null;
        let frequencyToSet = null;
        let cleaningTypeToSet = null;
        let cleaningAreasToUse = [{}];
        let carpetAddOnsToUse = [{}];
        if (value === "domestic") {
          bedrooms = 1;
          bathrooms = 1;
          const { cleaningTypes } = domesticOpts;
          if (!isEmpty(frequency)) {
            const cleaningTypeValue = `standard_${frequency.mappedTo}`;
            const cleaningTypeObj = cleaningTypes.find((cT) => cT.value === cleaningTypeValue);
            // const cleaningTypeToSet = cleaningTypes.find((cT) => cT.value === cleaningTypeValue);
            if (!isEmpty(cleaningTypeObj)) {
              cleaningTypeToSet = cleaningTypeObj;
              // change("cleaningType", cleaningTypeToSet);
            }
          }
        }

        if (value === "carpet") {
          if (!isEmpty(frequency)) {
            // const cleaningTypeValue = `carpet_${frequency.mappedTo}`;
            cleaningTypeToSet = { value: `carpet_${frequency.mappedTo}` };
            frequencyToSet = frequency;
            // change("cleaningType", { value: cleaningTypeValue });
            // change("frequency", frequency);
          }

          if (isEmpty(frequencyToSet) && !isEmpty(cleaningType)) {
            const legacyFrequencyType = getLegacyFrequencyType(cleaningType.value);
            const frequencyToUse = frequencies.find((fT) => fT.mappedTo === legacyFrequencyType);
            if (!isEmpty(frequencyToUse)) {
              cleaningTypeToSet = { value: `carpet_${frequencyToUse.mappedTo}` };
              frequencyToSet = frequencyToUse;
            }

            // const frequencyToSet = frequencies.find((fT) => fT.mappedTo === legacyFrequencyType);
            // if (!isEmpty(frequencyToSet)) {
            //   const cleaningTypeValue = `carpet_${frequencyToSet.mappedTo}`;
            //   change("cleaningType", { value: cleaningTypeValue });
            //   change("frequency", frequencyToSet);
            // }
          }

          if(!isEmpty(cleaningAreas) && !isEmpty(cleaningAreas[0])){
            cleaningAreasToUse = cleaningAreas;
          }

          if(!isEmpty(carpetAddOns) && !isEmpty(carpetAddOns[0])){
            carpetAddOnsToUse = carpetAddOns;
          }
          change("cleaningAreas", cleaningAreasToUse);
          change("carpetAddOns", carpetAddOnsToUse);
        }


        change("bedrooms", bedrooms);
        change("bathrooms", bathrooms);
        change("frequency", frequencyToSet);
        change("cleaningType", cleaningTypeToSet);


      }

      this.setState({
        processingOrder: false
      })


    } catch (error) {
      console.log("ERROR", error);
      const { response } = error;
      let resMsg = "Sorry an error occurred!";

      if (response && response.data && response.data.message) {
        resMsg = response.data.message;
      }

      this.props.setMsgAndShow(resMsg);
      this.setState({
        processingOrder: false
      })
    }
  }


  cancelOrder = async (booking, index) => {
    try {
      let messageToShow = "Error occurred!";
      const res = await confirmResponse("Are you sure ?", "This booking will be cancelled.");
      if (res) {
        this.setState({
          processingOrder: true
        })
        await axios.post(`${process.env.REACT_APP_API_WITH_PREFIX}admin/jobs/${booking._id}/cancel`);
        this.setState({
          processingOrder: false,
        })
        messageToShow = `Booking dated ${booking.date} cancelled successfully`;
        this.removeBooking(index);
        this.props.setMsgAndShow(messageToShow);
      }

    } catch (error) {
      let errorMsg = "An error occurred!";
      const { response, message } = error;
      if (response && response.data && response.data.message) {
        errorMsg = response.data.message
      } else if (message) {
        errorMsg = message;
      }
      this.setState({
        processingOrder: false,
      })

      this.props.setMsgAndShow(errorMsg)

    }
  }
  removeField = async (fieldIndex) => {
    try {
      const { additionalBookings } = this.props.formValues;
      const booking = additionalBookings[fieldIndex];
      if (!isEmpty(booking)) {
        if (booking._id) {
          this.cancelOrder(booking, fieldIndex)
        } else {
          this.removeBooking(fieldIndex)
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  onAdminCostKeyChange = (e) => {
    const doNotUpdateCost = this.doNotUpdateCost;
    if ((isNumberKey(e) || isBackspaceOrDelKey(e)) && !doNotUpdateCost) {
      this.doNotUpdateCost = true;
    }
  }

  removeBooking = (fieldIndex) => {
    this.props.array.remove("additionalBookings", fieldIndex)
  }


  getPriceListByZipcode = async (zipcode, type) => {
    try {
      const res = await getPricingList({ zipcode, type });
      if (res && res.priceList) {
        this.props.setPriceList(res.priceList);
      }
    } catch (error) {
      throw error;
    }
  }

  setPricingListForOrder = async (paramObj) => {
    let gettingPriceList = true;
    try {

      this.setState({ gettingPriceList });
      const res = await getPricingList(paramObj);
      if (res && res.priceList) {
        this.props.setPriceList(res.priceList);
      }
      gettingPriceList = false;
    } catch (error) {
      gettingPriceList = false;
      this.props.setMsgAndShow("Error occurred while getting pricing list.");
    }

    this.setState({ gettingPriceList });
  }




  onZipcodeBlur = (e) => {
    const zipcode = e.target.value;
    const type = this.props.formValues.type;
    this.setPricingListForOrder({ zipcode, type });
  }


  resetEndDateAndAdditionalBookings = () => {
    try {
      const { change } = this.props;
      change("endDate", null)
      change("additionalBookings", null);
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  }


  onFreqSelect = (freq) => {
    try {
      const { change, formValues: { type } } = this.props;
      this.resetEndDateAndAdditionalBookings();
      if (type && type === "carpet") {
        change("cleaningType", { value: `carpet_${freq.mappedTo}` });
      }
    } catch (error) {
      console.log("ERROR while selecting fre", error);
    }
  }


  onCleaningAreaSelect = (event, newValue, previousValue, name) => {
    const cleaningAreaIndex = parseInt(name.replace(/[^\d]/g, ''));
    this.props.change(`cleaningAreas[${cleaningAreaIndex}].count`,1);
    if (cleaningAreaIndex === this.props.formValues.cleaningAreas.length - 1) {
      this.addCleaningAreaInput()
    }
  }

  addCleaningAreaInput = () => {
    this.props.addInput("cleaningAreas",{});
  }

  clearCleaningAreaInputs = () => {
    this.props.change("cleaningAreas", [{}]);
  }


  addCarpetAddOnInput = () => {
    this.props.addInput("carpetAddOns",{})
  }

  clearCarpetAddOns = () => {
    this.props.change("carpetAddOns",[{}]);
  }


  onCarpetAddOnSelect = (event, newValue, previousValue, name) => {
    const carpetAddOnIndex =  parseInt(name.replace(/[^\d]/g, ''));
    this.props.change(`carpetAddOns[${carpetAddOnIndex}].count`,1);
    if( carpetAddOnIndex === this.props.formValues.carpetAddOns.length -1 ){
      this.addCarpetAddOnInput();
    }
  }

  onAddCounter = (fieldName, currentValue, currentIndex) => {
    const newValue = currentValue && currentValue.count ? parseInt(currentValue.count) + 1 : 1;
    this.props.change(`${fieldName}[${currentIndex}].count`, newValue);
  }

  onDecreaseCounter = (fieldName, currentValue, currentIndex) => {
    const currCount = currentValue && currentValue.count ? parseInt(currentValue.count) : 1;
    this.props.change(`${fieldName}[${currentIndex}].count`, Math.max(1,currCount - 1));
  }

  render() {
    return (
      <EditJob
        {...this.state}
        {...this.props}
        submitData={this.submitData}
        increaseByOne={this.increaseByOne}
        decreaseByOne={this.decreaseByOne}
        customersList={this.state.customers}
        selectCustomer={this.selectCustomer}
        getPropertyDetails={this.getPropertyDetails}
        getEndDateValue={this.getEndDateValue}
        onSelectFinish={this.onSelectFinish}
        getDateChange={this.getDateChange}
        findCustomers={this.findCustomers}
        verifyCouponCode={this.verifyCouponCode}
        onOrderTypeChange={this.onOrderTypeChange}
        removeField={this.removeField}
        onAdminCostKeyChange={this.onAdminCostKeyChange}
        onZipcodeBlur={this.onZipcodeBlur}
        onCleaningAreaSelect={this.onCleaningAreaSelect}
        clearCleaningAreaInputs={this.clearCleaningAreaInputs}
        addCleaningAreaInput={this.addCleaningAreaInput}
        addCarpetAddOnInput={this.addCarpetAddOnInput}
        clearCarpetAddOns={this.clearCarpetAddOns}
        onCarpetAddOnSelect={this.onCarpetAddOnSelect}
        onAddCount={this.onAddCounter}
        onDecreaseCount={this.onDecreaseCounter}
      />
    );
  }
}

const WithFormComponent = reduxForm({
  form: "EditOrderForm",
  destroyOnUnmount: true,
  enableReinitialize: true,
  initialValues: {
    bedrooms: 1,
    bathrooms: 1,
    carpetAddOns:[{}]
  }
})(EditJobContainer);

const mapStateToProps = state => {
  let formValues = {};
  if (state.form.EditOrderForm) {
    formValues = state.form.EditOrderForm.values;
  }
  return {
    domesticOpts: state.app.cleaningOpts.domestic,
    commercialOpts: state.app.cleaningOpts.commercial,
    carpetOpts: state.app.cleaningOpts.carpet,
    formValues,
    frequencies: state.app.cleaningOpts.frequencies,
    priceList: state.app.cleaningOpts.priceList,
    initialValues: state.formData.formData
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadFormData: (payload) => dispatch(loadFormData(payload)),
  updateFormData: (payload) => dispatch(updateFormData(payload)),
  setMsgAndShow: (message) => dispatch(setMsgAndShow(message)),
  resetFormData: () => dispatch(resetFormData()),
  storeDomesticPriceList: (list) => dispatch(storeDomesticPriceList(list)),
  setPriceList: (list) => dispatch(setPriceList(list)),
  addInput:(fieldName,value) => dispatch(arrayPush("EditOrderForm",fieldName,value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WithFormComponent);
