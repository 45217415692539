import React, { Fragment } from "react";
import cn from "classnames";
import { withStyles } from "core/components/Styles";

const styles = theme =>( {
  group: {
    position: "relative",
    margin: "0.3rem 0",
    // fontSize: "1.2rem",
    width: "100%",
  },
  addOn : {
    position: "absolute",
    width: "4rem",
    height: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.left-icon": {
      leflt: 0
    },
    "&.right-icon": {
      right: 0
    },
    "& img": {
      width: "1.5rem",
      height: "1.5rem",
      opacity: 0.7
    }
  },
  field: {
    border: 0,
    borderRadius: 3,
    padding: "0.85rem 1rem",
    backgroundColor:"#F6F7F9",
    width: "100%",
    fontSize:16,
    lineHeight:"normal",
    "&.right-icon" : {
      paddingRight: "4rem"
    },
    "&.left-icon" : {
      paddingLeft: "4rem"
    },
    "&::placeholder": {
      color: "#646464"
    },
    "&:focus": {
      boxShadow: 0,
      outline: "none"
    }
  },
  fieldWrapper: {
    position: "relative",
    textAlign: "left"
  },
  errors:{
    color:"red",
    fontSize:"0.75rem"
  }
});


const InputField = (props) => {
  const {meta, classes, input, ...restProps } = props;
  return (
    <div >
      <input {...restProps}/>
      {meta.touched &&
        ((meta.error && <span className={classes.errors} >{meta.error}</span>) ||
          (meta.warning && <span>{meta.warning}</span>))}
    </div>
  );
};

export default withStyles(styles)(InputField);