import { isEmpty } from "lodash";
export const handleManualEmailFormValues = (formValues) => {
  let emailRelatedTo = undefined;
  let isJobInputEnabled = false; 
  let isUserInputEnabled = false;
  if(!isEmpty(formValues)){
    const { emailType: eType, job, user } = formValues;
    emailRelatedTo = (eType && eType.relatedTo);
    isJobInputEnabled = !isEmpty(job);
    isUserInputEnabled = !isEmpty(user);
  }
  return {
    emailRelatedTo,
    isJobInputEnabled,
    isUserInputEnabled
  };
};
