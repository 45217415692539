import React from "react";
import Grid from "core/components/Grid";
import { Field } from "redux-form";
import { withStyles } from "core/components/Styles";
import cn from "classnames";
const styles =  theme=>({
  selectContStyle :{
    color : "#646464",
    fontWeight : 500,
    fontSize : 14,
    lineHeight : "21px",
    // width: "100%"
  },
  labelStyle : {
    color : "#929292",
    fontSize : 12,
    lineHeight : "18px",
    marginBottom: 8,
  },
  topMargin_4:{
    marginTop: 4,
  },
  outlined:{
    border:"1px solid #d8d8d8",
    marginTop:0,
    borderRadius:4,
    padding:"6px 8px"
  }
});
const FormInputField = ({ 
  classes, 
  label="", 
  name, 
  component,  
  trackField,
  ...rest 
})=>{
  
  const onBlurHandler = (e)=>  trackField && trackField(label);

  return(
    <Grid className={classes.selectContStyle}>
      <span className={cn({ [classes.labelStyle]: label } )}>
        {label}
      </span>
      <div className={cn({  
        [classes.topMargin_4]: label
      },)}>
        <Field 
          name={name} 
          component={component} 
          className={classes.outlined}
          onBlur={onBlurHandler}
          {...rest} 
        /> 
      </div>
    </Grid>
  );
} 
export default withStyles(styles)(FormInputField);