import React, { Component } from "react"
import { connect } from "react-redux";
import axios from "utils/http";
import { setMsgAndShow } from "../store/actions/popup";
import moment from "moment-timezone";
// import removeProp from "../utils/removeProp";
import ContractorEarnings from "../components/ContractorEarnings";


class ContractorEarningsContainer extends Component {
  state = {
    contractorsEarningsList:[],
    startDate:moment().startOf("isoWeek").format("YYYY-MM-DD"),
    endDate: moment().endOf("isoWeek").format("YYYY-MM-DD"),
    totalCount: 0,
    perPage: 10,
    currPage:1,
    load: false,
    page:0,
    gettingList:false
  }

  getList = async () => {
    const { currPage, perPage, startDate, endDate } = this.state;
    try {
      this.setState({
        gettingList:true
      })
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/get_contractors_earnings`,{
          params:{
            startDate,
            endDate,
            page: currPage,
            limit: perPage
          }
        }
      );

      
      const { contractorsList, total, page, perPage: limit } = data;

      this.setState({
        contractorsEarningsList: contractorsList,
        startDate,
        endDate,
        totalCount: total,
        currPage:page,
        perPage: limit,
        load: false,
        page:page - 1,
        gettingList:false
      })
    } catch (error) {
      const { response } = error;
      let errorMsg = "Unable to get list";

      if(response && response.data && response.data.message){
        errorMsg = response.data.message;
      }
      this.setState({
        gettingList:false
      })
      this.props.setMsgAndShow(errorMsg)
    }
  }
  componentDidMount() {
    this.getList();
  }

  handleDateChange = (dateType) => (date) => {
    const state = {};
    state[dateType] = date;
    this.setState({
      ...state
    })
  }

  payContractor = (contractorItem) => async () => {
    const contractorId = contractorItem.businessId;
    const contractorEarnings = contractorItem.contractorEarnings;

    const { startDate, endDate} = this.state;

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_PREFIX}admin/pay_contractor`,{
        contractorId,
        startDate,
        endDate,
        amount: contractorEarnings
      })

        this.props.setMsgAndShow(data.message);
    } catch (error) {
      const {  response } = error;
      this.props.setMsgAndShow(response ? response.data.message : "Error");
    }
  }


  viewCompletedJobs =  (contractor) => {
    const { startDate, endDate } = this.state;
    this.props.history.push({
      pathname:"/completed_jobs",
      state:{
        type:"contractor",
        contractor,
        startDate,
        endDate
      }
    })
  }


  getContractorsEarningsByPage = async (pageNumber) => {
    this.setState({
      currPage:pageNumber
    }, this.getList)
  }

  contractorActions = [
    // {
    //   label: "Pay Contractor",
    //   onClick: this.payContractor
    // },
    {
      label:"View Jobs",
      onClick:this.viewCompletedJobs
    }
  ];


  getUpdatedList = () => {
    this.setState({
      currPage:1
    }, this.getList)
  }
  render(){

    return (
      <ContractorEarnings 
        contractorActions={this.contractorActions}
        handleDateChange={this.handleDateChange}
        getUpdatedList={this.getUpdatedList}
        getContractorsEarningsByPage={this.getContractorsEarningsByPage}
        {...this.state}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg))
})


export default connect(null, mapDispatchToProps)(ContractorEarningsContainer);