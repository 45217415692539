
export const bookingSourceOptions = [
  { title: "Google", value: "google" },
  { title: "Thumbtack", value: "thumbtack" },
  { title:"Yelp", value:"yelp"},
  { title: "Others", value: "others" },
]


export const orderTypeOptions = [
  { title: "Domestic", value: "domestic" },
  { title: "Commercial", value: "commercial" },
  { title:"Carpet",value:"carpet"}
]


export const DEFAULT_EVENT_VALUES = {
  type:{value:"unavailability"},
  name:"Unavailable",
  isAllDay:true
}

export const ORDER_PRICE_LIST_TYPES = [
  { title: "Domestic", value: "domestic" },
  { title: "Carpet", value: "carpet" },
]


export const ORDER_DISCOUNT_ALLOWED_OTPS = 
  [
    { title: "Yes", value: "1" },
    { title: "No", value: "0" }
  ]