export function capitalizeFirstLetter(str) {
  if (str == null && str == undefined) {
    return "";
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
export function capitalizeAllFirstLetters(str){
  if(str == null || str == undefined){
    return "";
  }else{
    return str.split(" ").map((item) => { return item.charAt(0).toUpperCase() + item.slice(1); }).join(" ");
  }
}
export function capitalizeFirstLetterOnly(str) {
  if (str == null && str == undefined) {
    return "";
  } else {
      return str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1);
  }
}