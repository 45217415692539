import axios from "utils/http";

export const getList = async (page, q, limit) => {
  try {

    if(q){
      q = encodeURIComponent(q);
    }
  
    const res = await axios.get(`${process.env.REACT_APP_API_WITH_PREFIX}admin/tiers/list`,{params:{
      limit,
      page,
      q
    }});

    return res.data;
  } catch (error) {
    throw error;
  }
}