import React from "react";
import Toolbar from "react-big-calendar/lib/Toolbar";
import Grid from "core/components/Grid";
import Button from "core/components/Button";
import IconButton from "core/components/IconButton";
import Typography from "core/components/Typography";
import { withStyles } from "core/components/Styles";
import cn from "classnames"
import { ChevronLeft, ChevronRight } from "mdi-material-ui";
// import { withMixpanel } from "../../utils/react-mixpanel";
import { capitalizeFirstLetterOnly } from "../../utils/capitalize";

const styles = theme => ({
  calenderToolbar: {
    marginBottom: 20,
    padding:10
    // display: 'flex',

    // background:"red",
    // padding: 20
  },
  monthYearAndDayDateLabelContainer : {
    width : 789
  },
  monthAndYearLabel:{
    fontSize : 28,
    // fontFamily : "Poppins",
    marginRight : 30        
  },
  dayDateLabelAndBtnsContainer:{
    display : "flex"
  },
  monthAndYearNavBtn: {
    color: "#252426",
    height: 20,
    width: 20,
    border: "0.7px solid",
    "&:hover":{
      boxShadow:"0 8px 16px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor : "#663DBD",
      color: "#FFFFFF"
    }
  }, 
  monthAndYearNavBtnIcon:{
    height: 12
  },
  btnNav: {
    fontSize: 15,
    maxHeight: 45,
    // fontFamily : "Poppins",
    fontWeight: "normal",
    borderRadius: 5,
    textTransform: "capitalize",
    minHeight: 45,
    padding: 0,
    minWidth: 102,
    [theme.breakpoints.down('sm')]: {
      padding: "0 16px",
      minWidth: 0,
    }
  },
  btnNavigateBar: {
    "& button":{
      borderRadius : 34,
      backgroundColor:"#FFFFFF",
      marginRight : 10, 
      boxShadow:"0 8px 16px 0 rgba(0, 0, 0, 0.1)",
      "&:hover":{
        boxShadow:"0 8px 16px 0 rgba(0, 0, 0, 0.2)",
      }
    }
    // "& button:first-child": {
    //   borderRadius : 34,
    //   backgroundColor:"#FFFFFF",
    //   marginRight : 10, 
    //   boxShadow:"0 8px 16px 0 rgba(0, 0, 0, 0.1)",
    //   "&:hover":{
    //     boxShadow:"0 8px 16px 0 rgba(0, 0, 0, 0.2)",
    //   }
    // },
    // "& button:second-child":{
    //   borderRadius : 34,
    //   backgroundColor:"#FFFFFF",
    //   marginRight : 10, 
    //   boxShadow:"0 8px 16px 0 rgba(0, 0, 0, 0.1)",
    //   "&:hover":{
    //     boxShadow:"0 8px 16px 0 rgba(0, 0, 0, 0.2)",
    //   }
    // },
    // "& button:last-child": {
    //   borderRadius  : 34,
    //   backgroundColor:"#FFFFFF",
    //   boxShadow:"0 8px 16px 0 rgba(0, 0, 0, 0.1)",
    //   "&:hover":{
    //     boxShadow:"0 8px 16px 0 rgba(0, 0, 0, 0.2)",
    //   }
    // }
  },
  // monthBtnGradient:{
  //   background: "linear-gradient(98.2deg, #462EB4 2.88%, #B062D2 83.6%)",
  //   color : "#fff"
  // },
  dateLabel: {
    textAlign: "center",
  },  

  marginLeft_20: {
    marginLeft: 20
  },
  marginRight_20: {
    marginRight: 20
  },
  
	activeView:{
    background: "linear-gradient(98.2deg, #462EB4 2.88%, #B062D2 83.6%)",
    color : "#fff",
    boxShadow:"0 8px 16px 0 rgba(0, 0, 0, 0.1)",
    height : 36,
    width : 101
  },
  inActiveView :{
    background : "#fff",
    color : "black",
    boxShadow:"0 8px 16px 0 rgba(0, 0, 0, 0.1)",
  },
  labelText:{
    // fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: -0.1,
    textAlign:"center",
    marginLeft: 9,
    marginRight: 9,
    color: "#292529",
  },
  searchBoxCont:{
    width: 250,
    // height: 40,
    // borderRadius: 25,
    // boxShadow: "0 3px 11px 0 rgba(189, 159, 189, 0.11)",
    // backgroundColor: "#ffffff",
    // paddingLeft:25,
    // paddingRight: 15,
    // fontFamily: "Montserrat",
    // display:"flex",
    // justifyContent:"space-between",
    // alignItems:"center",
    // alignContent:"center"
  },
  searchBox:{
    border:"none",
    fontSize: 12,
    outline:"none",
    color: "#a8b2b9",
    flex:1,
    "&::placeholder":{
      color: "#a8b2b9",
    }
  },
  searchIcon:{
    height: 16,
    width:16,
    color:"#a8b2b9"
  }
});

class CalendarToolbar extends Toolbar {

  navigateMonth = (to)=> ()=> {
    this.navigate(to);
    to = capitalizeFirstLetterOnly(to);
    const { trackButtonEvent } = this.props;

    if(trackButtonEvent){
      trackButtonEvent(`${to}_Month`,window.location.pathname);
    }

  }

  calenderView = (jobView)=> ()=> {
    // this.view.bind(null, jobView);
    this.view(jobView);
    jobView = capitalizeFirstLetterOnly(jobView);  
    const { trackButtonEvent } = this.props;
    if(trackButtonEvent){
      trackButtonEvent(`${jobView}_View`,window.location.pathname);
    }

  }

  render() {
		const { classes, view } = this.props; 
    return (
      <Grid container  
        justify="space-between" 
        alignContent="center" 
        alignItems="center"
        className={classes.calenderToolbar}  
        id="calendarToolbar"
      > 
          <Grid container 
            alignItems = "center" 
            className={classes.monthYearAndDayDateLabelContainer} 
          >

            <Grid item>
              <Grid container justify="center"  >
                <Typography variant="h3" className={classes.monthAndYearLabel} id="currentMonth" >
                  {this.props.label}
                </Typography>
              </Grid>
            </Grid>

            <Grid item className = {classes.dayDateLabelAndBtnsContainer} >
              <Grid container justify="space-between" alignItems="center" >
                <IconButton 
                  variant = "fab"
                  className={classes.monthAndYearNavBtn} 
                  onClick={this.navigateMonth("PREV")}
                  size="small" 
                  id="prevMonth"
                > 
                  <ChevronLeft className={classes.monthAndYearNavBtnIcon}/>
                </IconButton>
                
                <Typography variant="h3" className={classes.labelText} id="thisMonth" >
                  {this.props.label}
                </Typography>

                <IconButton 
                  variant = "fab"
                  onClick={this.navigateMonth("NEXT")}
                  className={classes.monthAndYearNavBtn} 
                  size="small" 
                  id="nextMonth"
                >
                  <ChevronRight className={classes.monthAndYearNavBtnIcon}/>
                </IconButton>
              </Grid>
            </Grid>

          </Grid>
 

          <Grid
            className={classes.btnNavigateBar}
          >

            <Button
              onClick={this.calenderView("day")}
              className={cn(
                classes.btnNav, 
                view === "day" ? classes.activeView : classes.inActiveView
              )}
              id="dayView"
            >
              Day
            </Button>

            <Button
              onClick={this.calenderView("week")}
              className={cn(
                classes.btnNav, 
                view === "week" ? classes.activeView : classes.inActiveView
              )}
              id="weekView"
            >
              Week
            </Button>
            <Button
              onClick={this.calenderView("month")}
              className={cn(
                classes.btnNav, 
                view === "month" ? classes.activeView : classes.inActiveView
              )}
              id="monthView"
            >
              Month
            </Button>
           
          </Grid>
        
      </Grid>
    );
  }
}


export default withStyles(styles)(CalendarToolbar); 