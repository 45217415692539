import React from "react";
import Dialog from "../../core/components/Dialog";
import DialogContent from "../../core/components/DialogContent";
import DialogTitle from "../../core/components/DialogTitle";

const DropZoneDialog = ({ isOpen, toggle, children, title }) => (
  <Dialog
    open={isOpen}
    onClose={toggle}
    fullWidth
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {children}
    </DialogContent>
  </Dialog>
);

export default DropZoneDialog;