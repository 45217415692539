import React from 'react';
import Grid from "../../core/components/Grid";
import Typography from "../../core/components/Typography";
import { withStyles } from "../../core/components/Styles";
import AssignedCleaner from "./AssignedCleaner";
import { muted } from "theme/colors";


const styles = {
  mutedHeading: {
      color: muted,
      fontWeight: 300,
      marginBottom:5,
    },
}
const AssignedCleanersInfo = ({
  cleaners,
  classes
}) => {
  return (
    cleaners && cleaners ?
      <Grid >
        <Typography
          variant="subheading"
          className={classes.mutedHeading}
        >
          Assigned Cleaners
        </Typography>

        <Grid container spacing={8}>
          {cleaners.map((cleaner, index) => (
            <Grid item key={`assigned-cleaner-item-${index}`}>
              <AssignedCleaner
                cleaner={cleaner}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      : null
  )
}


export default withStyles(styles)(AssignedCleanersInfo);