import React, { Component} from "react";
import Dropzone from "react-dropzone";
import axios from "../../utils/http";
import Button from "../../core/components/Button";
import cn from "classnames";
import Loader from "../Loader";
import { CloudUpload } from "mdi-material-ui";

import "./style.css";

class DropZone extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
      dropzoneActive: false,
      loading: false,
      openSnackbar: false
    };
  }

  onDragEnter = () => {
    this.setState({
      dropzoneActive: true
    });
  }

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false
    });
  }

  onDrop = (files) => {
    this.setState({
      files,
      dropzoneActive: false
    }); 
  }

  handleFileUpload = () => {
    const { files } = this.state;
    const { onUploaded, url, noFileSelected, validate, formData } = this.props;
    
    if(validate && !validate(files)) return;

    if(!files || !files[0]) {
      if(noFileSelected) noFileSelected();
      return;
    }

    
    const data = new FormData();
    data.append("file", files[0]);
    
    if(formData) {
      for(let key in formData) {
        data.append(key, formData[key]);
      }
    }
    
    this.setState({loading: true});

    axios.post(url, data)
      .then((res) => {
        this.setState({loading: false, files: []});
        onUploaded(res);
      }).catch(e => {
        console.error(e); // eslint-disable-line
        this.setState({ files: []});
        onUploaded();
      });
  }


  render() {
    const { files, dropzoneActive, loading } = this.state;
    const { accept, onCancel } = this.props;

    return (
      <div>
        <Dropzone
          style={{position: "relative"}}
          accept={accept}
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          disabled={loading}
        >
          { loading && <Loader /> }
          <div className={cn("drop-zone-area", dropzoneActive ? "drop-zone-success" : "")}>
            <h4>Click or Drag a File Here</h4>
            {<CloudUpload />}
          </div>
          <ul>
            { files.map((f, key) => <li key={key}>{f.name} - {f.size} bytes</li>) }
          </ul>
        </Dropzone>
        <div className="drop-zone-footer">
          <Button  variant="contained" color="secondary" onClick={onCancel} disabled={loading} className="footer-action-button btn-danger">Cancel</Button>
          <Button variant="contained" color="primary" onClick={this.handleFileUpload} disabled={loading} className="footer-action-button btn-primary">Upload</Button>
        </div>
      </div>
    );
  }
}

export default DropZone;