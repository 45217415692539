import React from "react";
import Dialog from "core/components/Dialog";
import DialogTitle from "core/components/DialogTitle";
import DialogContent from "core/components/DialogContent";
import { Form, FieldArray, Field } from "redux-form";
import Loader from "../ProgressCircle";
import ZButton from "core/components/Button";
import ZTypography from "core/components/Typography";
import Grid from "core/components/Grid";
import DialogActions from "core/components/DialogActions";
import {
  MaterialTextField,
  FormInputField
} from "../FormField";

import {
  required,
  number,
  notNegativeOrZero
} from "../FormField/Validations";




const TierFormModal = ({
  open,
  closeForm,
  submitData,
  handleSubmit,
  processing,
  title,
  mode = "add"
}) => {
  return (
    <Dialog
      open={open}
      onClose={closeForm}
      fullWidth
      // maxWidth="md"
    >
      {(processing) && <Loader position="fixed" zIndex={999} />}
      <Form onSubmit={handleSubmit(submitData)}>
        <DialogTitle id="form-dialog-title">
          <ZTypography color="secondary" gradient bold fontSize={25}>
            {title}
          </ZTypography>
        </DialogTitle>
        <DialogContent>

          <Grid container justify="space-between" direction="column" wrap="nowrap" spacing={16}>
            <Grid item>
              <FormInputField
                label="Name"
                name="name"
                placeholder="Enter tier name"
                component={MaterialTextField}
                validate={[required]}
                disableUnderline
                disabled={mode === "edit"}
              />
            </Grid>
            <Grid item>
              <FormInputField
                label="Base Fee (%)"
                type="number"
                name="baseFee"
                placeholder="Enter base fee"
                component={MaterialTextField}
                validate={[required, number, notNegativeOrZero]}
                disableUnderline
              />
            </Grid>
            <Grid item>
              <FormInputField
                label="Discounted Fee (%)"
                type="number"
                name="discountedFee"
                placeholder="Enter discounted fee"
                component={MaterialTextField}
                validate={[required, number, notNegativeOrZero]}
                disableUnderline
              />
            </Grid>
          </Grid>


        </DialogContent>
        <DialogActions>
          <ZButton color="primary" onClick={closeForm} >
            Cancel
          </ZButton>
          <ZButton type="submit" gradient color="primary">
            Submit
          </ZButton>

        </DialogActions>
      </Form> 
    </Dialog>
  )
}


export default TierFormModal;