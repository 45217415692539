import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { reset, reduxForm } from "redux-form";
import { setMsgAndShow } from "../store/actions/popup";
import socket from "socket";
import axios from "utils/http";
import _ from "lodash";
import moment from "moment-timezone";
import removeProp from "../utils/removeProp";
import FlaggedContractors from "../components/FlaggedContractors";
import ContractorActionModal from "../components/ContractorActionModal";
// import AssignJobMenu from "../components/AssignJobMenu";
import ContractorDetailsModal from "../components/ContractorDetailsModal"
import getTotalPagesCount from "../utils/getTotalPagesCount";
// import { setJobs, removeJob, updateJob } from "../store/actions/jobs";

class ContractorsDashboardContainer extends Component {

  constructor(props){
    super(props);
  
    this.dropDownAnchor = null;
    this.assignedEmployees = [];
    this.contractorAction = null;
    this.debounceFun = _.debounce(this.search, 250)  
    this.state = {
      show: "requests",
      contractors: [],
      contractorObj:{},
      isContractorDetailsModalOpen: false,
      doNotShowJobBtns: false,
      isModalOpen: false,
      employeeList: [],
      jobItemIndex: "",
      jobItemObj: {},
      open: false,
      btnRef: null,
      isContractorActionModalOpen: false,
      openLoader: false,
      entityId:"",
      modalText:"",
      reason:"",
      totalCount: 0,
      perPage: 10,
      currPage:1,
      load: false,
      q:"",
      page:0,
    };


    this.inputRef = React.createRef();

  }

  entityId = null;
  entityName = "";
  searchValue = "";
  silentSuspend = undefined;
  componentDidMount() {
    this.addData();
  }

  getContractorsList = async () => {
    try {
      const { currPage, perPage } = this.state;
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/contractors/flagged`,{
          params: {
            page: currPage,
            limit: perPage
          }
        }
      );

      const { contractors,total, page,  perPage: limit } = data;

      return {
        contractors,
        totalCount: total,
        currPage:page,
        perPage: limit,
      }
    } catch (error) {
      throw error;
    }
  }

  updateList = async (showLoader = true) => {
    try {
      if(showLoader){
        this.setState({
          load:true,
        })
      }
      const { perPage, currPage } = this.state;
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/contractors/flagged`,{
          params: {
            page: currPage,
            limit: perPage
          }
        }
      );

      const { contractors,total, page, perPage: limit,  } = data;

      const totalPages = getTotalPagesCount(total, limit);

      if(currPage > totalPages){
        this.getContractors(totalPages, true);
      } else {
        this.setState({
          contractors,
          totalCount: total,
          currPage:page,
          perPage: limit,
          load: false,
          page: page - 1
        })

      }

    } catch (error) {
      const { response } = error;
      this.setState({
        load:false
      })
      this.props.setMsgAndShow(response ? response.data.message : "Error while getting contractors list")
    }
  }
   
  addData = async () => {
    try {
      this.setState({
        load:true,
      })
      const { currPage, perPage } = this.state;
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/contractors/flagged`,{
          params: {
            page: currPage,
            limit: perPage
          }
        }
      );

      const { contractors,total, page, perPage: limit } = data;

      this.setState({
        contractors,
        totalCount: total,
        currPage:page,
        perPage: limit,
        load: false,
        page: page - 1
      })
    } catch (error) {
      const { response } = error;
      this.setState({
        load:false
      })
      this.props.setMsgAndShow(response ? response.data.message : "Error while getting contractors list")
    }
  };
  


  openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false
    });
    this.props.resetForm("OrderForm");
  };

  handleMenuClose = () => {
    this.setState({
      open: false,
      jobItemIndex: ""
    });
  };

  
    
  getContractorDetails = (contractorObj) => {
    // console.log("Cont in Container ",contractorObj); For checking contr details
    this.setState({
      contractorObj,
      isContractorDetailsModalOpen: true
    })
  }
     
  handleContractorModal= () => {
    this.setState({
      isContractorDetailsModalOpen: false,
      contractorObj: null
    });
  };


  getSearch = (e) => {   
    this.debounceFun(e.target.value);
  }

  clearSearch = ()=> {
    this.inputRef.current.value = "";
    this.search();
  }

  search = async (q) => {  

    this.searchValue = this.inputRef.current.value; 

    const { perPage, currPage } = this.state;
    this.setState({load: true});
   
    
    if(q){
      q = q.trim().toLowerCase();
    }

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/get_all_contractors`,{
          params:{
            q: q,
            limit: perPage,
            page: currPage
          }
        }
      );

      const { contractors, total, page, perPage: limit } = data;
      // console.log("response recieved ",data); // Checking search result
      this.setState({
        contractors,
        q: q,
        totalCount: total,
        currPage:page,
        perPage: limit,
        load: false
      })
    } catch (error) {
      console.log("error ",error);
      const { response } = error;
      this.props.setMsgAndShow(response ? response.data.message : "Error while getting contractors list")
    }
  }

  openContractorActionModal = (cO,index,additionalParams = []) => {

    const [ whatToDo ] = additionalParams;
    let modalText = "Why you want to suspend this contractor?";
    this.contractorAction = "suspend";
    this.entityId = cO.businessId;
    this.entityName = cO.businessName;
    this.silentSuspend = whatToDo === "suspendSilently";

    this.setState({
      isContractorActionModalOpen: true,
      modalText
    })
  }

  closeContractorActionModal = () => {
    this.contractorAction = null;
    this.entityId = null;
    this.entityName  = "";
    this.silentSuspend = undefined;
    this.setState({
      isContractorActionModalOpen: false,
      modalText:"",
      reason:""
    })
  }
  handleReasonInput = (e) => {
    this.setState({
      reason:e.target.value
    })
  }

  submitRes = () => {

    const { reason } = this.state;
    let res = null;

    if(!reason || !reason.trim()){
      window.alert("Please provide reason")
      return;
    };


    if(this.contractorAction === "suspend"){
      res = window.confirm("Any pending jobs of this contractor will be released into the system. Do you want to continue?")
    } else if(this.contractorAction === "revoke"){
      res = true;
    }


    if(res){
      this.processRequest();
    }
  }

  processRequest = async () => {
    const { setMsgAndShow } = this.props;

    this.setState({
      load: true
    })
    try {
      let apiUrl = `${process.env.REACT_APP_API_PREFIX}admin/contractor/`;

      if(this.contractorAction === "suspend"){
        apiUrl +="suspend";
      }
      const entityId = this.entityId;
      const entityName = this.entityName;
      const { reason } = this.state;
      await axios.post(apiUrl, {
        contractorId: entityId,
        reason,
        silent: this.silentSuspend
      })

      this.setState({
        isContractorActionModalOpen: false,
        modalText:"",
        reason:"",
      })
      this.entityId = null;
      this.entityName = null;
      this.silentSuspend = undefined;
      this.updateList(false);
      setMsgAndShow(`${entityName} has been suspended`);
    } catch (error) {
      const { response } = error;
      this.setState({
        load: false
      })
      setMsgAndShow(response ? response.data.message : "Error");
    }
  }



  getContractors = async (pageNumber, isLoaderActive) => {


    try {
      let { perPage, q } = this.state;

      if(!isLoaderActive){
        this.setState({
          load: true
        })

      }
      // if(q){
      //   q = q.trim().toLowerCase();
      // }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/contractors/flagged`,{
          params: {
            page: pageNumber,
            limit: perPage,
            q
          }
        }
      );

      const { contractors,total, page, perPage: limit } = data;

      this.setState({
        contractors,
        totalCount: total,
        currPage:page,
        perPage: limit,
        load: false,
        page: page - 1,
      })
    } catch (error) {
      const { response } = error;
      this.props.setMsgAndShow(response ? response.data.message : "Error while getting contractors list")
    }
  }

  contractorActions = [
    {
      label:"Suspend",
      onClick: this.openContractorActionModal,
      checkForDisabled: true,
      propToCheck:"isSuspended"
    },
    {
      label:"Suspend Silently",
      onClick: this.openContractorActionModal,
      checkForDisabled: true,
      propToCheck:"isSuspended",
      secondParam:"suspendSilently",
    },
  ]

  render() {

    return (
      <Fragment>
        <FlaggedContractors
          getContractorDetails={this.getContractorDetails}
          contractorActions={this.contractorActions}
          // todaysJobActions={todaysJobActions}
          // todaysJobOrders={this.state.orders}
          handleModalClose={this.handleClose}
          // changeList={this.changeList}
          openModal={this.openModal}
          handleSave={this.handleSave}
          increaseByOne={this.increaseByOne}
          decreaseByOne={this.decreaseByOne}
          submitData={this.submitData}
          getSearch={this.getSearch}
          getContractors={this.getContractors}
          searchValue={this.searchValue}
          clearSearch={this.clearSearch}
          searchInput={this.inputRef}
          {...this.state}
          {...this.props}
        />

        {this.state.contractorObj && <ContractorDetailsModal
          // handleJobCompleted={this.jobCompleted}
          // acceptJob={this.acceptJob}
          // rejectJob={this.rejectJob}
          handleContractorModal={this.handleContractorModal}
          {...this.state}
        />}

        <ContractorActionModal 
          onCancel={this.closeContractorActionModal}
          isModalOpen={this.state.isContractorActionModalOpen}
          modalText={this.state.modalText}
          onTextInput={this.handleReasonInput}
          onSubmit={this.submitRes}
          openLoader={this.state.openLoader}
          requiredText
        />
        
        
      </Fragment>
    );
  }
}



const mapDispatchToProps = dispatch => ({
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  reset
});




export default connect(undefined,mapDispatchToProps)(ContractorsDashboardContainer);
