import { 
  // SET_DETAILS, 
  SET_ADMIN_INFO, 
  // SET_ORDER_HISTORY 
} from "../types";

// export const setDetails = (userInfo, businessDetails, orderHistory) => ({
//   type: SET_DETAILS,
//   payload: [
//     userInfo,
//     businessDetails,
//     orderHistory
//   ]
// })

export const setAdminInfo = (adminInfo) => ({
  type:SET_ADMIN_INFO,
  payload: adminInfo
})


// export const setOrderHistory = (orderHistory) => ({
//   type: SET_ORDER_HISTORY,
//   payload: orderHistory
// })