import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setMsgAndShow } from "../store/actions/popup";
import axios from "utils/http";
import { debounce, isEqual } from "lodash";
import PriceListsDashboard from "../components/PriceLists";
import AddPriceListFormModal from "./AddPriceListModalContainer";
import EditPriceListFormModal from "./EditPriceListModalContainer";
import confirmResponse from "../utils/confirmResponse";
import { loadFormData } from "../store/actions/formData";
import { getList as getPriceLists } from "../helpers/pricelists";
import getTotalPagesCount from "../utils/getTotalPagesCount";
import removeProp from "../utils/removeProp";
import { capitalizeFirstLetterOnly } from "../utils/capitalize";

class PriceListsContainer extends Component {

  limit = 10;
  page = 1;
  cToken = undefined;
  searchZipCode = undefined;
  searchRef = React.createRef();
  searchList = (searchValue) => {
    this.setState({
      zipcodeSearch:searchValue,
      page:1
    }, this.getPriceLists)
  }
  lazySearch = debounce(this.searchList,250);
  state = {
    processing:false,
    gettingPriceLists:false,
    openAddForm:false,
    openEditModal:false,
    priceLists:[],
    totalCount:0,
    frequencyType:"",
    cleaningType:"",
    zipcodeSearch:"",
    listType:"",
    page:1,
    tablePage:0,
    freqList:{}
  }

  getPriceLists = async (pageNumber,isLoaderActive) => {
    // console.log("REEEEE", receivedPage, )
    try {

      if(!isLoaderActive){
        this.setState({
          gettingPriceLists:true,
        })
      }

      const pageToSend = pageNumber ? pageNumber : this.state.page;

      const data = await getPriceLists({
        limit:this.limit,
        page:pageToSend,
        frequency:this.state.frequencyType,
        cleaningType:this.state.cleaningType,
        zipcode:this.state.zipcodeSearch,
        listType:this.state.listType,
      })

      const { 
        lists,
        perPage,
        total,
        page:receivedPage,
      } = data;

      this.limit = perPage;

      this.setState({
        gettingPriceLists:false,
        priceLists:lists,
        totalCount: total,
        page:receivedPage,
        tablePage:receivedPage - 1
      })

    } catch (error) {
      const { response } = error;
      let errorMessage = "Unable to get price lists!";
      if(response.data && response.data.message){
        errorMessage = response.data.message;
      }
      this.props.setMsgAndShow(errorMessage);
    }
  }

  createFreqList = (frequencies) => {
    try {
      let frequenciesList = {};

      if(frequencies && frequencies.length){
        frequencies.forEach((f) => {
          frequenciesList[f.mappedTo] = f.label
        })
      }

      this.setState({
        freqList:frequenciesList
      })
    } catch (error) {
      console.log("ERR", error);
    }
  }

  componentDidMount(){
    this.createFreqList(this.props.frequencies);
    this.getPriceLists();
  }


  componentDidUpdate(prevProps) {
    const { frequencies:oldFrequencies } = prevProps;
    const { frequencies:newFrequencies } = this.props;
    if(!isEqual(newFrequencies, oldFrequencies)){
      this.createFreqList(newFrequencies);
    }
  }

  openAddPriceModal = () => {
    this.setState({
      openAddForm:true,
    })
  }
  closeAddModal = () => {
    this.setState({
      openAddForm:false
    })
  }

  updateList = async () => {

    try {

      this.setState({
        gettingPriceLists:true
      })
      this.limit = 10;
    
      const data = await getPriceLists({
        limit:this.limit,
        page:this.state.page,
        frequency:this.state.frequencyType,
        cleaningType:this.state.cleaningType,
        zipcode:this.state.zipcodeSearch,
        listType:this.state.listType
      })


      const { 
        lists,
        perPage,
        total,
        page:receivedPage,
      } = data;

      const totalPages = getTotalPagesCount(total, perPage);

      if(receivedPage > totalPages){
        this.getPriceLists(totalPages, true);
      } else {
        this.setState({
          gettingPriceLists:false,
          priceLists:lists,
          totalCount: total,
          page:receivedPage,
          tablePage:receivedPage - 1
        })
      }
  
    } catch (error) {
      const { response } = error;
      let errorMessage = "Unable to get price lists!";
      if(response.data && response.data.message){
        errorMessage = response.data.message;
      }
      this.props.setMsgAndShow(errorMessage);
    }

  }

  editPriceList = async (priceList) => {
    try {


      let priceListData = {
        hiddenZipcodes:priceList.zipcodes.join(","),
        zipcodes:priceList.zipcodes,
        type:{value:priceList.type},
        frequency:{value:priceList.frequency},
        baseCost:priceList.baseCost.toString(),
        "discount":priceList.discount.toString(),
        "bathCost":priceList.bathCost.toString(),
        "bedCost":priceList.bedCost.toString(),
        "addOnPrice":priceList.additionalServicesPricing.cost.toString(),
        "propertyPricing":{
            "cost": priceList.propertyPricing.cost,
            "skip":priceList.propertyPricing.skip
        },
        priceListId:priceList._id,
        couponsAllowed: Number(priceList.couponsAllowed).toString(),
        listType:priceList.listType
      }


      if(priceList.listType === "carpet"){
        priceListData = removeProp(priceListData,["baseCost","bedCost","bathCost","propertyPricing.skip"])
      }

      this.props.loadFormData(priceListData);
      this.setState({
        openEditModal:true,
      })
    } catch (error) {
      console.log("ERROR", error);
    }
  }
  
  removePriceList = async (priceList) => {
    try {
      const {  zipcodes } = priceList;
      const confirmed = await confirmResponse( 
        "Do you want to continue ?", 
        `Price list for ${zipcodes.join(",")} will be deleted`
      );
      if(!confirmed) return;
      
      this.setState({
        processing:true,
      })

      await axios.delete(`${process.env.REACT_APP_API_WITH_PREFIX}admin/price_lists/remove/${priceList._id}`);
      this.setState({
        processing:false,
      })
      this.updateList();
    } catch (error) {
      const { response } = error;
      let errorMessage = "Unable to delete price list!";
      if(response.data && response.data.message){
        errorMessage = response.data.message;
      }
      this.setState({
        processing:false,
      })
      this.props.setMsgAndShow(errorMessage);
    }
  }

  closeEditModal = () => {
    this.setState({
      openEditModal:false
    })
  }

  handleFrequencyChange = (e) => {
    this.setState({
      frequencyType:e.target.value,
      page:1
    }, this.getPriceLists);


  }

  handleCleaningTypeChange = (e) => {
    this.setState({
      cleaningType:e.target.value,
      page:1
    }, this.getPriceLists)
  }

  onSearchChange = (e) => {
    this.lazySearch(e.target.value);
  }

  clearSearch = () => {

    this.setState({
      page:1,
    }, () => {    
      this.searchRef.current.value = "";
      this.searchList("");
    })

  }

  handelPageChange  = (page) => {
    this.setState({
      page,
    }, this.getPriceLists)
  }


  getTableColumns = () => {
    return [
      {
        name:"listType",
        capitalize: capitalizeFirstLetterOnly
      },
      {
        name:"type",
        capitalize: capitalizeFirstLetterOnly
      },
      {
        name:"frequency",
        isFrequency:true,
        frequencies:this.state.freqList
      },
      {
        name:"zipcodes",
        isStringList:true,
      }
    ];
  }

  handleListTypeChange = (e) => {
      this.setState({
        listType:e.target.value,
        page:1
      }, this.getPriceLists)
  }
   priceListActions = [
    {
      label: "Edit",
      onClick: this.editPriceList, 
    },
    {
      label:"Remove",
      onClick: this.removePriceList,
    },
  ]
  render() {
  
    return (
      <Fragment>
        <PriceListsDashboard 
          {...this.state}
          openAddForm={this.openAddPriceModal}
          priceListActions={this.priceListActions}
          handleCleaningTypeChange={this.handleCleaningTypeChange}
          handleFrequencyChange={this.handleFrequencyChange}
          searchRef={this.searchRef}
          onSearchChange={this.onSearchChange}
          clearSearch={this.clearSearch}
          // searchRef={this.searchRef}
          handelPageChange={this.handelPageChange}
          frequencies={this.props.frequencies}
          getTableColumnsData={this.getTableColumns}
          handleListTypeChange={this.handleListTypeChange}
        />
        <AddPriceListFormModal 
          open={this.state.openAddForm} 
          closeModal={this.closeAddModal}
          onSuccess={this.updateList}
        />

        <EditPriceListFormModal 
          open={this.state.openEditModal}
          closeModal={this.closeEditModal}
          onSuccess={this.updateList}
        />
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  frequencies:state.app.cleaningOpts.frequencies
})

const mapDispatchToProps = dispatch => ({
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  loadFormData: (formData) => dispatch(loadFormData(formData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceListsContainer);