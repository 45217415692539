import React from "react";
// import momentTz from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import UserAvatar from "core/components/UserAvatar";
import { withStyles } from "@material-ui/core/styles"
import { FaStar, FaRegStar } from "react-icons/fa";
import Rating from "react-rating";
import renderDate from "../../utils/renderDate"

const styles = () => ({
  root:{
    paddingTop:20
  },
  content: {
  },
  userMessage: {
    padding: "20px 30px 20px",
  },
  messageHeading: {
    paddingBottom: 10,
    marginBottom: 20,
    borderBottom: "1px solid #f1f1f1"
  },
  messageFooter: {
    marginTop: 20,
    borderTop: "1px solid #f1f1f1"
  },
  reveiwerName:{
    marginLeft: 10
  }
})



const ReviewItem = (props) => {

  const {  classes, reviewItem = {} } = props;
  const { 
    reviewerFirstName, 
    reviewerLastName, 
    reviewerProfileImage,
    review, 
    ratings,
    // _id,
    createdat,
    reviewerEmail
  } = reviewItem;
  const time = renderDate(createdat);

  

  const reviewerName = `${reviewerFirstName} ${reviewerLastName}`;
  const reviewerInitials = `${reviewerFirstName.charAt(0)}${reviewerLastName.charAt(0)}`
  let imageURL = undefined;
  if(reviewerProfileImage){
    imageURL = process.env.REACT_APP_USER_FILES + reviewerProfileImage
  }
  // console.log("Env  ", process.env.REACT_APP_USER_FILES  );
  // console.log("Img ",reviewerProfileImage );
  return (
    <Grid className={classes.root}>
      <Grid container spacing={16} className={classes.content}>
        <Grid item xs>
          <Paper elevation={2} className={classes.userMessage}>
            <Grid container justify="space-between" alignItems='center' className={classes.messageHeading}>
              <Grid item container xs alignItems="center" spacing={16}>
                <Grid item>
                  <UserAvatar
                    user={{
                      initials: reviewerInitials,
                      email: reviewerEmail,
                      img: imageURL
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2">{reviewerName}</Typography>
                </Grid>

                <Grid item>
                  <Rating
                    stop={5}
                    initialRating={ratings}
                    fractions={10}
                    emptySymbol={<FaRegStar size={16}/>}
                    fullSymbol={<FaStar color="#6610f2" size={16} />}
                    readonly
                  />
                </Grid>
              </Grid>

              <Grid item>
                <Typography variant="caption">{time}</Typography>

              </Grid>
            </Grid>
            <Typography variant="body1">{review}</Typography>
          </Paper>
        </Grid>
      </Grid>
      
    </Grid>
  )
}


export default withStyles(styles)(ReviewItem);