import React from "react";
import { MainLayout } from "../layouts";
import EventsContainer from "../containers/EventsContainer";




const EventsPage = (props) => {
  return (
    <MainLayout pageName="Unavailabilities">
      <EventsContainer {...props}/>
    </MainLayout>
  )
}



export default EventsPage;