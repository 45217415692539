import {SET_JOB_ID,REMOVE_JOB_ID,SHOW_OFFER_CONTRACTOR_MODAL} from "../types" ;

export const setJobId = (jobId)=> ({
	type: SET_JOB_ID,
	payload: jobId
})

export const removeJobId = ()=> ({
	type: REMOVE_JOB_ID,
	payload: ""
}) 

export const showContractorModal = (boolean)=> ({
	type: SHOW_OFFER_CONTRACTOR_MODAL,
	payload: boolean
})