
const parsers = {
  "float":parseFloat,
  "int":parseInt,
}

const getNumber = (value, defaultValue, type = "float") => {
  const parser = parsers[type];
  let num = parser(value, 10);

  if(type === "float"){
    num = parseFloat(num.toFixed(2));
  }
  return isNaN(num) ? defaultValue : num;
}


export default getNumber;