import React from "react";
import { IconButton } from "@material-ui/core";





const ExtendedIconButton =  ({children, ...restProps}) => {
  return (
    <IconButton {...restProps}>
      {children}
    </IconButton>
  )
}


export default ExtendedIconButton;