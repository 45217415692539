import { isEmpty } from "lodash";
export const getOptions = (values,labelList) => {
  if(!isEmpty(values) && !isEmpty(labelList)){
   return values.map((v) => labelList.find((vl) => v.toLowerCase() === vl.label.toLowerCase()));
  }

  return [];
} 

export const getOption = (value,options) => {
  if(!isEmpty(value) && !isEmpty(options)){
    return options.find((o) => o.label.toLowerCase() === value.toLowerCase())
  }


  return {};
}  