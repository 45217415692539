import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const OptionItem = props => {
  const { option } = props;
  return (
    <Grid item xs>
      {option}
    </Grid>
  );
};

const OptionList = props => {
  const { classes, title, list, OptionComponent = OptionItem } = props;
  return ( list && list.length > 0 ) ? (
    <Grid item xs>
      <Typography variant="subheading" className={classes.mutedHeading}>
        {title}
      </Typography>
      <Grid item container direction="column">
        {list.map((option, i) => (
          <OptionComponent option={option} key={i} />
        ))}
      </Grid>
    </Grid>
  ) : null;
};


export default OptionList;
