import React, { Component } from 'react'
import { withStyles, Typography} from "core/components";
import cn from "classnames";

const styles = theme => ({
  primaryGradient: {
    boxShadow : "0px 9px 27px rgba(80, 80, 80, 0.31)",
    borderRadius : 5,
    background : "linear-gradient(180deg, #50E3C2 0%, #008DA7 100%)",
    textTransform : "uppercase",
    color : "#fff"
  },
  secondaryGradient: {
    display: "inline-block",
    color : "transparent",
    "-webkit-background-clip" : "text",
    backgroundImage : "linear-gradient(93.31deg, #462EB4 2.88%, #B062D2 83.6%)"
  },
  bold: {
    fontWeight : 600
  },
  inlineBlock: {
    display : "inline-block"
  },
  root:{
    zIndex:0
  }
})

class ZTypography extends Component {
  render() {
    const { classes, color="default", gradient, className, bold, inlineBlock, root, fontSize, style, ...rest } = this.props;
    const classNames = cn({
      [classes.primaryGradient]: color === "primary" && gradient,
      [classes.secondaryGradient]: color === "secondary" && gradient,
      [classes.bold]: bold,
      [classes.inlineBlock]: inlineBlock,
      [classes.root]: root,
      [className]: className
    })

    const styles = {...style};
    if(fontSize) styles.fontSize = fontSize;
    return (
      <Typography
        {...rest}
        style={styles}
        color={color}
        className={classNames}
      />
    )
  }
}
export default withStyles(styles)(ZTypography);