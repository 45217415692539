import React from "react";
import Dialog from "core/components/Dialog";
import DialogTitle from "core/components/DialogTitle";
import DialogContent from "core/components/DialogContent";
import Grid from "core/components/Grid";
// import Button from "core/components/Button";
import { withStyles } from "core/components/Styles";
import { Field, Form, FieldArray } from "redux-form";
import { connect } from "react-redux";
import cn from "classnames";
import {
  ExtendedAdvanceSelect,
  FormInputField,
  MaterialTextField,
  RadioSelectBtn,
  MaterialCheckBox,
} from "../FormField";
import states from "data/statesTitleCase.json";

import { required, number, minLength, maxLength, email, notNegativeOrZero } from "../FormField/Validations";
import { formatPhoneNumber, normalizePhone } from "../FormField/formatters";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker2";
import Typography from "core/components/Typography";
import {
  // domesticCleaningOptions,
  // commercialCleaningOptions,
  // domesticPropertyOptions,
  // commercialPropertyOptions,
  commercialPropertyTypes,
  commercialWindowsOpts,
  addOnServices,
  domesticOnlyKeyOptions
} from "./FormFieldData";
import Loader from "../ProgressCircle";
import ZButton from "core/components/Button";
import ZTypography from "core/components/Typography";
import ServiceField from "./SeviceField";
import MultiBookings from "./MutliBookings";
import CleaningAreasInput from "./CleaningAreasInput";
import CarpetAddOnsInput from "./CarpetAddOnsInput";
import { ReactComponent as BedroomIcon } from "assets/images/icons/bedroom.svg";
import { ReactComponent as BathroomIcon } from "assets/images/icons/bathroom.svg";
import showEndDateInput from "../../helpers/showEndDateInput";
import { bookingSourceOptions, orderTypeOptions } from "../../variables/formData";
const stateObjOptions = states.map(state => {
  return {
    label: state.name,
    value: state.abbreviation
  };
});

const minLength_5 = minLength(5);
const maxLength_5 = maxLength(5);

const styles = theme => ({
  modalCont: {},
  inputMarginTop_10: {
    marginTop: 10
  },
  cleaningTypeHeading: {
    marginRight: 0,
    marginTop: 0,
    marginBottom: 4,
    fontSize: 12,
    color: "#929292",
    lineHeight: "18px"
  },
  title: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  selectorCont: {
    border: "1px solid #4a4a4a",
    maxWidth: 150,
    fontSize: "20px",
    borderRadius: ".3rem",
    overflow: "hidden",
    margin: 0,
    marginTop: 5,
    padding: 0,
    "& .dec, .inc": {
      background: "#f8f8f8",
      width: "30%",
      cursor: "pointer",
      textAlign: "center",
      padding: "0.3rem 0.5rem",
      display: "inline-block"
    },
    "& span:first-child": {
      borderRight: "1px solid #4a4a4a"
    },
    "& span:last-child": {
      borderLeft: "1px solid #4a4a4a"
    }
  },
  inputSelect: {
    paddingTop: 0,
    "& .field-wrapper": {
      height: 46,
      "& > span": {
        right: 0,
        color: "#c4c4c4",
        "& img": {
          width: "1.3rem",
          height: "1.3rem",
          opacity: 0.7
        }
      },
      "& > input": {
        color: "#646464",
        fontWeight: 500,
        lineHeight: "21px",
        fontSize: 14,
        height: 46,
        border: "solid 1px #d8d8d8",
        background: "#ffffff",
        padding: "5px 18px", //"20px 56px 20px 20px",
        "&::placeholder ": {
          color: "#b7b7b7"
        }
      }
    },
  },
  disp: {
    width: "40%",
    textAlign: "center",
    display: "inline-block"
  },
  propertyDetailsHeading: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  textCenter: {
    textAlign: "center"
  },
  addOnStyle: {
    backgroundColor: "blue"
  },
  setInCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputMarginTop_4: {
    marginTop: 4
  },
  noTopPadding: {
    paddingTop: "2px !important"
  },
  actualCostMsg: {
    fontSize: 12,
    color: "#929292"
  },
  finalCostCont: {
    marginBottom: 40
  }
  // costStyle:{
  //   display: "flex",
  //   alignItems: "center",
  //   height: 33
  // }
});

const JobFormModal = (props) => {
  const {
    handleClose,
    handleSubmit,
    classes,
    handleSave,
    showModal,
    closeModal,
    formValues = {},
    increaseByOne,
    decreaseByOne,
    submitData,
    customerOptions,
    openNewUserform,
    addNewCustomer,
    showDialogLoader,
    domesticOpts = {},
    commercialOpts = {},
    customersList,
    selectCustomer,
    getPropertyDetails,
    getEndDateValue,
    onSelectFinish,
    getDateChange,
    findCustomers,
    mode,
    verifyCouponCode,
    gettingCouponData,
    gettingPropertyData,
    gettingPriceList,
    processingOrder,
    onOrderTypeChange,
    onAdminCostKeyChange,
    onZipcodeBlur,
    searchContractors,
    contractorsServedOptions,
    showServedContractors,
    frequencies,
    onFreqSelect,
    carpetOpts,
    onCleaningAreaSelect,
    addCleaningAreaInput,
    clearCleaningAreaInputs,
    addCarpetAddOnInput,
    clearCarpetAddOns,
    onCarpetAddOnSelect,
    onAddCount,
    onDecreaseCount,
  } = props;

  const { additionalBookings, date } = formValues;
  const {
    cleaningTypes,
  } = domesticOpts;

  const {
    floorTypes,
    // frequencyTypes,
    cleaningServices,
    equipmentTypes,
    employeeOpts
  } = commercialOpts;

  const { cleaningAreas, addOns: carpetAddOns } = carpetOpts;
  const showSelectEndDate = showEndDateInput(formValues);
  const {
    originalCost = 0,
    unModifiedCost,
    appliedDiscount,
    orderCost = 0,
    isRehireBooking
  } = formValues;

  return (
    <Dialog
      open={showModal}
      onClose={closeModal}
      fullWidth
      className={classes.modalCont}
      maxWidth="md"
    >
      {(gettingCouponData || gettingPropertyData || processingOrder || gettingPriceList) && <Loader position="fixed" zIndex={999} />}
      <Form onSubmit={handleSubmit(submitData)}>
        <DialogTitle id="form-dialog-title" className={classes.textCenter}>
          <ZTypography color="secondary" gradient bold fontSize={25}>
            {mode === "edit" ? "Edit Job" : "Add New Job"}
          </ZTypography>
        </DialogTitle>
        <DialogContent>

          <Grid className={classes.inputsCont}>
            <Grid>
              <Field
                component={RadioSelectBtn}
                name="type"
                validate={[required]}
                inRow
                options={orderTypeOptions}
                onOptionChange={onOrderTypeChange}
                disabled={mode === "edit"}
              />
            </Grid>

            <Grid container justify="space-between" alignItems="flex-end" className={cn([classes.inputsCont, classes.inputMarginTop_10])} spacing={8}>
              <Grid item md={8} xs={12}>
                {
                  !addNewCustomer
                    ? <FormInputField
                      async
                      label="Search Customer"
                      type="text"
                      id="customer"
                      name="customer"
                      component={ExtendedAdvanceSelect}
                      onChange={selectCustomer}
                      options={customersList}
                      loadOptions={findCustomers}
                      defaultOptions={customersList}
                      // validate={[required]}
                      placeholderValue="Search customer"
                      backgroundColor="transparent"
                      material
                      disableUnderline
                    />
                    : null
                }
              </Grid>
              {/* <Grid item md={!addNewCustomer ? 4 : 12} xs={12} > 
                <Grid container justify="center" alignItems="flex-start">
                  <ZButton color="primary" gradient onClick={openNewUserform}>
                    {!addNewCustomer ? "Add New Customer" : "Select customer"}
                  </ZButton> 
                </Grid>
              </Grid> */}

            </Grid>



            <Grid container justify="space-between" spacing={8}>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}>
                <FormInputField label="First Name"
                  type="text"
                  id="firstName"
                  name="serviceAddress.fname"
                  placeholder="Enter First Name"
                  component={MaterialTextField}
                  validate={[required]}
                  disableUnderline
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}>
                <FormInputField label="Last Name"
                  type="text"
                  id="lastName"
                  name="serviceAddress.lname"
                  placeholder="Enter Last Name"
                  component={MaterialTextField}
                  validate={[required]}
                  disableUnderline
                />
              </Grid>
            </Grid>

            <Grid container justify="space-between" spacing={8}>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}>
                <FormInputField label="Phone"
                  id="phone"
                  name="serviceAddress.phone"
                  placeholder="Enter Phone Number"
                  component={MaterialTextField}
                  validate={[required]}
                  disableUnderline
                  format={formatPhoneNumber}
                  parse={normalizePhone}

                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}>
                <FormInputField label="Email"
                  type="text"
                  id="email"
                  name="serviceAddress.email"
                  placeholder="Enter Email Address"
                  component={MaterialTextField}
                  validate={[required, email]}
                  disableUnderline
                />
              </Grid>
            </Grid>


            <Grid container justify="space-between" spacing={8}>
              <Grid item xs={12} md={5} className={classes.inputMarginTop_10}>
                <FormInputField label="Address"
                  type="text"
                  id="address"
                  name="serviceAddress.address"
                  placeholder="Enter Address"
                  component={MaterialTextField}
                  validate={[required]}
                  disableUnderline
                />
              </Grid>
              <Grid item xs={12} md={2} className={classes.inputMarginTop_10}>
                <FormInputField
                  label="Apt/Suite"
                  type="text"
                  id="unit"
                  name="serviceAddress.unit"
                  placeholder="Enter Apt/Suite"
                  component={MaterialTextField}
                  disableUnderline
                />
              </Grid>

              <Grid item xs={12} md={5} className={classes.inputMarginTop_10}>
                <FormInputField label="Zipcode"
                  type="text"
                  id="zipcode"
                  name="serviceAddress.zipcode"
                  placeholder="Enter Zipcode"
                  component={MaterialTextField}
                  validate={[required, number, minLength_5, maxLength_5]}
                  disableUnderline
                  onBlur={onZipcodeBlur}
                />
              </Grid>
            </Grid>


            <Grid container justify="space-between" spacing={8}>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}>
                <FormInputField label="City"
                  type="text"
                  id="city"
                  name="serviceAddress.city"
                  placeholder="City"
                  component={MaterialTextField}
                  validate={[required]}
                  disableUnderline
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}>
                <FormInputField
                  label="State"
                  type="text"
                  id="state"
                  name="serviceAddress.state"
                  component={ExtendedAdvanceSelect}
                  options={stateObjOptions}
                  validate={[required]}
                  placeholderAdd="Select State..."
                  backgroundColor="transparent"
                  material
                  disableUnderline
                // menuPlacement="top" // To open the list to top direction

                />
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.inputMarginTop_10}>
              <ZButton type="button" gradient color="primary" onClick={getPropertyDetails}>
                Get Property Details
              </ZButton>
            </Grid>






            <Grid item xs={12} md={8} className={classes.inputMarginTop_10}>
              <FormInputField
                label="Property Details"
                id="addressAdditionalInfo"
                name="serviceAddress.additionalNotes"
                placeholder="Additional Notes for address"
                component={MaterialTextField}
                multiline
                rows={6}
                disableUnderline
              />
            </Grid>





            {
              formValues.type !== "carpet" ?
                <Grid container spacing={8} className={classes.inputMarginTop_10}>
                  <Grid item xs={12} md={6}>
                    <FormInputField label="Property Area"
                      type="number"
                      id="propertyArea"
                      name="serviceAddress.propertyArea"
                      placeholder="Enter Property Area"
                      component={MaterialTextField}
                      validate={[required, number]}
                      disableUnderline
                    />
                  </Grid>

                  {formValues.type === "commercial" ? (
                    <Grid item xs={12} md={6}>
                      <FormInputField
                        label="Select Property Type"
                        type="text"
                        id="propertyType"
                        name="serviceAddress.propertyType"
                        component={ExtendedAdvanceSelect}
                        options={commercialPropertyTypes}
                        backgroundColor="transparent"
                        validate={[required]}
                        material
                        disableUnderline
                        menuPlacement="top" // To open the list to top direction

                      />
                    </Grid>
                  ) : null}
                </Grid>
                : null

            }


            {formValues.type === "domestic" ? (
              <Grid container className={classes.inputMarginTop_10}>
                <Grid item xs={12} md={6} className={classes.setInCenter}>
                  <ServiceField
                    title="Bedrooms"
                    icon={BedroomIcon}
                    count={formValues.bedrooms}
                    onAdd={increaseByOne("bedrooms")}
                    onDelete={decreaseByOne("bedrooms")}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.setInCenter}>
                  <ServiceField
                    title="Bathrooms"
                    icon={BathroomIcon}
                    count={formValues.bathrooms}
                    onAdd={increaseByOne("bathrooms")}
                    onDelete={decreaseByOne("bathrooms")}
                  />
                </Grid>
              </Grid>
            ) : null}


            {
              formValues.type !== "carpet" ?
                <Grid
                  container
                  className={classes.inputMarginTop_4}
                  justify="space-between"
                  spacing={8}
                >
                  <Grid item xs={12} md={6} className={classes.noTopPadding}>
                    {
                      formValues.type === "domestic" ?
                        <FormInputField
                          label="Cleaning Type"
                          type="text"
                          id="cleaningType"
                          name="cleaningType"
                          component={ExtendedAdvanceSelect}
                          options={cleaningTypes}
                          backgroundColor="transparent"
                          validate={[required]}
                          onChange={onSelectFinish}
                          material
                          disableUnderline
                        // menuPlacement="top" // To open the list to top direction

                        />
                        : null
                    }

                    {
                      formValues.type === "commercial" ?
                        <FormInputField
                          validate={[required]}
                          isMulti={true}
                          close={false}
                          label="Cleaning Services"
                          id="cleaningServices"
                          name="cleaningServices"
                          component={ExtendedAdvanceSelect}
                          options={cleaningServices}
                          backgroundColor="transparent"
                          material
                          disableUnderline
                          closeMenuOnSelect={false}
                          menuPlacement="top" // To open the list to top direction

                        />
                        : null
                    }
                  </Grid>

                  {
                    formValues.type !== "domestic" ?
                      <Grid item xs={12} md={6} className={classes.noTopPadding}>
                        <FormInputField
                          label="Frequency"
                          id="frequency"
                          name="frequency"
                          component={ExtendedAdvanceSelect}
                          options={frequencies}
                          backgroundColor="transparent"
                          material
                          onChange={onFreqSelect}
                          disableUnderline
                          validate={[required]}
                          // closeMenuOnSelect={false}
                          menuPlacement="top" // To open the list to top direction
                        />

                      </Grid>
                      : null
                  }

                </Grid>
                :
                null

            }



            {
              formValues.type === "carpet" ?
              <Grid container>
                <Grid item xs={12} md={6} className={classes.noTopPadding}>
                  <FormInputField
                  label="Frequency"
                  id="frequency"
                  name="frequency"
                  component={ExtendedAdvanceSelect}
                  options={frequencies}
                  backgroundColor="transparent"
                  material
                  onChange={onFreqSelect}
                  disableUnderline
                  validate={[required]}
                  // closeMenuOnSelect={false}
                  menuPlacement="top" // To open the list to top direction
                  />
                </Grid>
              </Grid>
              : null
            }
            <Grid
              container
              className={classes.inputMarginTop_4}
              justify="space-between"

              spacing={8}
            >
              <Grid item xs={12} md={6} className={classes.inputSelect}>
                <p className={classes.cleaningTypeHeading}>Date</p>
                <Field
                  name="date"
                  component={DatePicker}
                  validate={[required]}
                  placeholder="Select date"
                  getDate={getDateChange}
                  addOnStyle={classes.addOnStyle}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputSelect}>
                <p className={classes.cleaningTypeHeading}>Time</p>
                <Field
                  name="time"
                  component={TimePicker}
                  validate={[required]}
                  placeholder="Select time"
                  addOnStyle={classes.addOnStyle}
                />
              </Grid>
            </Grid>



            {
              showSelectEndDate && mode === "add" ? (
                <Grid item xs={12} md={6} className={classes.inputSelect}>
                  <p className={classes.cleaningTypeHeading}>Choose Till What Date you want Cleaning</p>
                  <Field
                    name="endDate"
                    component={DatePicker}
                    // material
                    validate={[required]}
                    placeholder="Select date"
                    addOnStyle={classes.addOnStyle}
                    getDate={getEndDateValue}
                    minDate={date}
                  />
                </Grid>
              ) : null
            }


            {
              additionalBookings && additionalBookings.length ?
                <FieldArray
                  name="additionalBookings"
                  component={MultiBookings}
                  validate={[required]}
                  showTick={false}
                /> : null

            }
            {
              formValues.type === "carpet" ?

                <Grid>

                  <Grid className={classes.inputMarginTop_10}>
                    <FieldArray
                      name="cleaningAreas"
                      component={CleaningAreasInput}
                      validate={[required]}
                      list={cleaningAreas}
                      addInput={addCleaningAreaInput}
                      clearInputs={clearCleaningAreaInputs}
                      onSelect={onCleaningAreaSelect}
                      onAddCount={onAddCount}
                      onDecreaseCount={onDecreaseCount}
                    />

                  </Grid>
                  <Grid className={classes.inputMarginTop_10}>
                    <FieldArray
                      name="carpetAddOns"
                      component={CarpetAddOnsInput}
                      // validate={[required]}
                      list={carpetAddOns}
                      addInput={addCarpetAddOnInput}
                      clearInputs={clearCarpetAddOns}
                      onSelect={onCarpetAddOnSelect}
                      onAddCount={onAddCount}
                      onDecreaseCount={onDecreaseCount}
                    />

                  </Grid>


                  {/* <Grid
                    container
                    className={classes.inputMarginTop_4}
                    justify="space-between"
                    spacing={8}
                  >
                    <Grid item xs={12} md={6} className={classes.noTopPadding}>
                      <FormInputField
                        isMulti={true}
                        close={false}
                        label="Add Ons"
                        id="carpetAddOns"
                        name="carpetAddOns"
                        component={ExtendedAdvanceSelect}
                        options={carpetAddOns}
                        backgroundColor="transparent"
                        material
                        // validate={[required]}
                        disableUnderline
                        closeMenuOnSelect={false}
                        menuPlacement="top" // To open the list to top direction

                      />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.noTopPadding}>
                      <FormInputField
                        label="Frequency"
                        id="frequency"
                        name="frequency"
                        component={ExtendedAdvanceSelect}
                        options={frequencies}
                        backgroundColor="transparent"
                        material
                        onChange={onFreqSelect}
                        disableUnderline
                        validate={[required]}
                        // closeMenuOnSelect={false}
                        menuPlacement="top" // To open the list to top direction
                      />
                    </Grid>
                  </Grid> */}
                </Grid>
                :
                null
            }



            {
              formValues.type === "commercial" ?
                <Grid
                  container
                  className={classes.inputMarginTop_4}
                  justify="space-between"

                  spacing={8}
                >
                  <Grid item xs={12} md={6} className={classes.noTopPadding}>
                    <FormInputField
                      label="Floor Type"
                      id="floorType"
                      name="floor"
                      component={ExtendedAdvanceSelect}
                      options={floorTypes}
                      backgroundColor="transparent"
                      material
                      disableUnderline
                      validate={[required]}
                      // closeMenuOnSelect={false}
                      menuPlacement="top" // To open the list to top direction
                    />

                  </Grid>
                  <Grid item xs={12} md={6} className={classes.noTopPadding}>
                    <FormInputField
                      isMulti={true}
                      close={false}
                      label="Equipment Type"
                      id="equipmentType"
                      name="equipments"
                      component={ExtendedAdvanceSelect}
                      options={equipmentTypes}
                      backgroundColor="transparent"
                      material
                      validate={[required]}
                      disableUnderline
                      closeMenuOnSelect={false}
                      menuPlacement="top" // To open the list to top direction

                    />

                  </Grid>
                </Grid>
                : null
            }

            {
              formValues.type !== "commercial" ? (
                <Grid item xs={12} md={8} className={classes.inputMarginTop_10}>
                  <FormInputField
                    label="From where we get the key?"
                    id="whatAboutKeys"
                    name="whatAboutKeys"
                    component={ExtendedAdvanceSelect}
                    options={domesticOnlyKeyOptions}
                    backgroundColor="transparent"
                    material
                    validate={formValues.type !== "carpet" ? [required] : null}
                    disableUnderline
                    // closeMenuOnSelect={false}
                    menuPlacement="top" // To open the list to top direction
                  />
                </Grid>
              ) : null
            }

            <Grid container justify="space-between" spacing={8}>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}>
                {
                  formValues.type === "domestic" ?
                    <FormInputField
                      isMulti={true}
                      close={false}
                      label="Add on services"
                      id="addOnServices"
                      name="addOnServices"
                      component={ExtendedAdvanceSelect}
                      options={addOnServices}
                      backgroundColor="transparent"
                      material
                      disableUnderline
                      closeMenuOnSelect={false}
                      menuPlacement="top" // To open the list to top direction

                    />
                    : null
                }

                {
                  formValues.type === "commercial" ?
                    <FormInputField
                      label="Number of Employees"
                      id="numberOfEmployees"
                      name="requiredEmployees"
                      component={ExtendedAdvanceSelect}
                      options={employeeOpts}
                      backgroundColor="transparent"
                      material
                      disableUnderline
                      validate={[required]}
                      // closeMenuOnSelect={false}
                      menuPlacement="top" // To open the list to top direction
                    />
                    : null

                }

              </Grid>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}>

                {
                  formValues.type === "commercial" ?

                    <FormInputField
                      label="Number of Windows"
                      type="text"
                      id="addOnServices"
                      name="addOnServices"
                      component={ExtendedAdvanceSelect}
                      options={commercialWindowsOpts}
                      backgroundColor="transparent"
                      material
                      disableUnderline
                      menuPlacement="top" // To open the list to top direction

                    />
                    : null
                }

                {
                  formValues.type === "domestic" ?
                    <FormInputField label="Estimate (in Minutes)"
                      type="number"
                      id="etc"
                      name="etc"
                      placeholder="Calculated completion time"
                      component={MaterialTextField}
                      validate={[required, number]}
                      disableUnderline
                      disabled
                    />
                    : null
                }

              </Grid>
            </Grid>

            <Grid item xs={12} md={8} className={classes.inputMarginTop_10}>
              <FormInputField
                label="Additional Booking Notes"
                id="additionalBookingNotes"
                name="additionalBookingNotes"
                placeholder="Additional Notes for booking"
                component={MaterialTextField}
                multiline
                rows={6}
                disableUnderline
              />
            </Grid>

            <Grid item xs={12} md={8} className={classes.inputMarginTop_10}>
              <Grid>
                <ZTypography color="default" variant="caption">
                  Source
                </ZTypography>
              </Grid>
              <Grid className={classes.inputMarginTop_10}>
                <Field
                  component={RadioSelectBtn}
                  name="bookingSource"
                  validate={[required]}
                  inRow
                  options={bookingSourceOptions}
                />
              </Grid>
            </Grid>

            {
              showServedContractors ?
                <Grid item xs={12} md={8} className={classes.inputMarginTop_10}>
                  <Grid className={classes.inputMarginTop_10}>
                    <Field
                      id="isRehireBooking"
                      name="isRehireBooking"
                      component={MaterialCheckBox}
                      color="primary"
                      label={<p>Rehire</p>}
                      useInputValue
                    />
                  </Grid>
                  {
                    isRehireBooking ?
                      <Grid >
                        <FormInputField
                          async
                          label="Select Contractor to rehire"
                          id="rehiredContractorId"
                          name="rehiredContractorId"
                          component={ExtendedAdvanceSelect}
                          options={contractorsServedOptions}
                          loadOptions={searchContractors}
                          defaultOptions={contractorsServedOptions}
                          validate={[required]}
                          // validate={[required]}
                          placeholderValue="Search or Select contractor"
                          backgroundColor="transparent"
                          material
                          disableUnderline
                        />

                      </Grid>
                      :
                      null

                  }


                </Grid>

                :
                null

            }

            <Grid item xs={12} md={8} className={classes.inputMarginTop_10}>
              <Grid
                container
                className={classes.inputMarginTop_4}
                alignItems="center"
                spacing={8}
              >

                <Grid item xs={12} md={6} >
                  <FormInputField label="Coupon Code"
                    type="text"
                    id="couponCode"
                    name="couponCode"
                    placeholder="Enter Coupon Code"
                    component={MaterialTextField}
                    disableUnderline
                  />
                </Grid>



                <Grid item className={classes.inputMarginTop_10}>
                  <ZButton type="button" gradient color="primary" onClick={verifyCouponCode}>
                    Apply Coupon
                  </ZButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid>
              <Field
                id="DoNotCheckForContractors"
                name="doNotCheckForContractors"
                component={MaterialCheckBox}
                color="primary"
                label={<p>Do not check for contractors in the area </p>}
              />
            </Grid>


            <Grid className={cn(classes.inputMarginTop_10, classes.finalCostCont)}>
              <Grid container justify="center">
                <Grid item md={6}>
                  <Grid item xs >
                    <Grid container alignItems="center" justify="space-between">
                      <Grid item>
                        <Typography>Cost</Typography>
                      </Grid>
                      <Grid item>
                        <FormInputField
                          type="number"
                          name="adminCost"
                          placeholder="Provided /Calcu. Cost"
                          component={MaterialTextField}
                          // onKeyPress={onAdminCostKeyChange
                          onKeyUp={onAdminCostKeyChange}
                          validate={[required, number, notNegativeOrZero]}
                          disableUnderline
                        />

                        <span className={classes.actualCostMsg}> Actual cost is ${unModifiedCost || originalCost}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs className={classes.inputMarginTop_10}>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography>
                          Applied Discount:
                        </Typography>
                      </Grid>
                      <Grid item>
                        {appliedDiscount ? appliedDiscount : "0%"}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs className={classes.inputMarginTop_10}>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography>
                          Final Cost:
                        </Typography>
                      </Grid>
                      <Grid item>
                        {`$${orderCost}`}
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>

            <Grid item xs={12} className={classes.inputMarginTop_10}>
              <ZButton fullWidth type="submit" gradient color="primary">
                {mode === "edit" ? "Update Job" : "Add Job"}
              </ZButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Form>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  showDialogLoader: false
});
const WithStylesAddJobModal = withStyles(styles)(JobFormModal);

export default connect(mapStateToProps)(WithStylesAddJobModal);
