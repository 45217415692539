import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
// import Highlighter from "react-highlight-words";
// import TeamMember from "../../containers/TeamMemberImageContainer";
// import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
// import AddRecord from "../AddRecord/AddRecord";
// import Cancel from "@material-ui/icons/Cancel";
// import Clear from "@material-ui/icons/Clear";
import { CloseCircle } from "mdi-material-ui";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => ({
  chip: {
    margin: "4px 8px 4px 0px",
    boxShadow: "0 2px 4px 0 rgba(178, 188, 196, 0.15) !important",
    border: "solid 1px #cbcbcb !important",
    background: "#fff !important"
  },
  chipMultiSelect: {
    "&.has-value": {
      "& .Select-control": {
        padding: 5
      }
    },
    "&.Select--multi": {
      "& .Select-value": {
        background: "none",
        border: 0,
        margin: 0,
        "& .Select-value-icon": {
          display: "none"
        },
      },
      ".Select-value-label": {
        padding: 0
      }
    }
  },
  advanceSelect: {
    marginTop: 10,
    marginBottom: 8,

    "& .Select-control": {
      border: 0,
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      boxShadow: "none",
      borderRadius: 0,
      position: "relative",
      transition: "borderBottom 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
      },
      "&:hover::before": {
        opacity: 1
      },
      "&::before": {
        content: '" "',
        display: "block",
        width: "100%",
        position: "absolute",
        bottom: 0,
        opacity: 0,
        border: "1px solid #000"
      },
      "& .Select-value": {
        paddingLeft: 0
      },
      "& .Select-placeholder": {
        color: theme.palette.default,
        paddingLeft: 0
      }
    },
    "&.is-focused .Select-control": {
      opacity: 1,
      borderBottom: "1px solid #f79029 !important"
    },
    "&.chip-multi-select": {
      "&.has-value": {
        "& .Select-control": {
          padding: 5
        }
      },
      "& .Select-value": {
        background: "none",
        border: 0,
        margin: 0,
        "& .Select-value-icon": {
          display: "none"
        },
      },
      "&.Select--multi .Select-value-label": {
        padding: 0
      }
    }
  },
  disableSelectUnderline: {
    "& .Select-control": {
      borderBottom: "none !important",
    },
    "&.is-focused .Select-control": {
      opacity: 1,
      borderBottom: "none !important"
    },
  },
  advanceSelectControl: {
    marginTop: 16,
    "& label": {
      pointerEvents: "none",
      zIndex: 0,
      transform: "translate(0, 1.5px) scale(0.75)",
      transformOrigin: "top left"
    }
  },
  error: {
    color: "red",
    fontSize: 12,
  },
  warning: {
    color: "orange",
    fontSize: 12
  }
});

const materialStyles = {
  control: styles => ({ 
    ...styles,
    backgroundColor: 'white',
    border: 0,
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    boxShadow: "none",
    borderRadius: 0,
    position: "relative",
    transition: "borderBottom 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
    },
    "&:hover::before": {
      opacity: 1
    },
    "&::before": {
      content: '" "',
      display: "block",
      width: "100%",
      position: "absolute",
      bottom: 0,
      opacity: 0,
      border: "1px solid #000"
    },
  })
}

class SelectComponent extends Component {
  static propTypes = {
    classes: PropTypes.object,
    config: PropTypes.object,
    options: PropTypes.array,
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    id: PropTypes.string,
    chip: PropTypes.bool,
    placeholderAdd: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    allowAdd: PropTypes.bool,
    createObject: PropTypes.func,
    multiple: PropTypes.bool,
    rightArrow: PropTypes.func,
    objectCreate: PropTypes.object,
    notClear: PropTypes.bool,
    renderSelectedFunc: PropTypes.func,
    renderOptionFunc: PropTypes.func,
    clearValueFunc: PropTypes.func,
    disableUnderline: PropTypes.bool
  };

  state = {
    updateComponent: false,
    openNew: false,
    selectedItem: null
  };
  componentWillReceiveProps(newProps) {
    if (
      this.props.objectCreate &&
      newProps.objectCreate &&
      this.props.objectCreate.success != newProps.objectCreate.success &&
      newProps.objectCreate.success == true
    ) {
      this.setState({ openNew: false });
    }
  }
  renderSelected = option => {
    const { chip, renderSelectedFunc } = this.props;
    let render = option.label;
    if (chip) {
      render = ( ""
        // <Chip
        //   avatar={
        //     option.value.photo || option.value.initials ? (
        //       <TeamMember teamMember={option.value} size={32} />
        //     ) : (
        //         null
        //       )
        //   }
        //   label={option.label}
        //   className={`ta-chip ta-chip-default ${this.props.classes.chip}`}
        //   onDelete={this.emptyFunc}
        //   deleteIcon={<Cancel onMouseDown={this.removeSelected(option)} />}
        // />
      );
    } else if (renderSelectedFunc) {
      render = renderSelectedFunc(option);
    }
    return render;
  };

  emptyFunc = () => { };

  removeSelected = option => e => {
    let selected = this.props.input.value;
    selected &&
      selected.forEach((v, i) => {
        if (v.label === option.label) {
          selected.splice(i, 1);
        }
      });

    this.setState({ updateComponent: !this.state.updateComponent });
  };

  // renderOption = option => {
  //   const { renderOptionFunc } = this.props;
  //   const renderOption = (
  //     <Highlighter
  //       searchWords={[this._inputValue]}
  //       textToHighlight={option.label ? option.label : ""}
  //     />
  //   );
  //   return !renderOptionFunc ? renderOption : renderOptionFunc(option, renderOption);
  // };

  openNew = () => {
    this.setState({ openNew: true });
  };

  closeNew = () => {
    this.setState({ openNew: false });
  };

  onChange = item => {
    if (item && item.value === "-1") {
      this.openNew();
    } else {
      const { input } = this.props;
      input.onChange(item);
    }
  };

  onInputChange = inputValue => (this._inputValue = inputValue);

  clearValue = e => {
    e.stopPropagation();
    e.preventDefault();
    const { input, clearValueFunc } = this.props;
    if (input && input.onChange) {
      if (clearValueFunc) {
        clearValueFunc();
      } else {
        input.onChange(null);
      }
    }
  }

  clearIcon = () => {
    return <div><CloseCircle onTouchEnd={this.clearValue} onMouseDown={this.clearValue} /></div>;
  }

  render() {
    const {
      input,
      label,
      meta,
      classes,
      id,
      multiple,
      options,
      chip,
      rightArrow,
      allowAdd,
      placeholderAdd,
      createObject,
      config,
      notClear,
      disableUnderline,
      material,
      ...custom
    } = this.props;

    let classNames = `advance-select ${classes.advanceSelect} ${disableUnderline ? classes.disableSelectUnderline : ""}`;
    classNames += chip ? ` chip-multiple-select ${classes.chipMultiSelect}` : "";

    const onBlur = () => {
      multiple ? input.onBlur([...input.value]) : input.onBlur(input.value);
      // input.onBlur([...input.value])
    };

    const { 
      touched = false, 
      error,
      // warning, 
      // invalid 
    } = meta ? meta : {};

    return (
      <div className={`${meta && touched && error ? "has-error" : ""}`}>
        <FormControl fullWidth className={`advance-select-control ${classes.advanceSelectControl}`}>
          {input ? (
            <Select
              {...input}
              {...custom}
              multi={multiple}
              options={
                allowAdd && options
                  ? [...options, { label: placeholderAdd, value: "-1" }]
                  : options
              }
              arrowRenderer={input && input.value ? null : rightArrow}
              valueRenderer={this.renderSelected}
              onInputChange={this.onInputChange}
              // optionRenderer={this.renderOption}
              onChange={this.onChange}
              onBlur={onBlur}
              className={classNames}
              placeholder={label}
              id={id}
              searchable
              clearRenderer={this.clearIcon}
              clearable={notClear ? false : !multiple}
              styles={material ? materialStyles: {}}
            />
          ) : (
              <Select
                {...custom}
                multi={multiple}
                options={
                  allowAdd && options
                    ? [...options, { label: placeholderAdd, value: "-1" }]
                    : options
                }
                arrowRenderer={rightArrow}
                valueRenderer={this.renderSelected}
                optionRenderer={this.renderOption}
                className={classNames}
                placeholder={label}
                id={id}
                searchable
                clearRenderer={this.clearIcon}
                clearable={notClear ? false : !multiple}
                styles={material ? materialStyles: {}}
              />
            )}
          {input && input.value && input.value != "" && <InputLabel htmlFor={id}>{label} </InputLabel>}
        </FormControl>
        <div className="help-block">
        {meta.touched &&
          ((meta.error && <span className={classes.error}>{meta.error}</span>) ||
            (meta.warning && <span className={classes.warning}>{meta.warning}</span>))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SelectComponent);
