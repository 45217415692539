import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
// import Snackbar from '@material-ui/core/Snackbar';
// import IconButton from '@material-ui/core/IconButton';
// import { Close } from "mdi-material-ui";
import logo from "../../files/images/logo.jpg";

const Login = ({
  classes,
  handleLogin,
  handleChange,
  isSnackBarOpen,
  vertical,
  horizontal,
  msg,
  handleSnackbarClose
}) => {
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Card raised={true}>
        <CardContent className={classes.mainCard}>
          <Grid
            container
            className={classes.mainData}
            justify="center"
            alignContent="center"
            alignItems="center"
            direction="column"
          >
            <img src={logo} alt="logo" />
            <span className={classes.title}>Zoklean Dashboard</span>
          </Grid>
          <Grid className={classes.secondItem}>
            <form className={classes.formCont}>
              <TextField
                id="username"
                label="Username"
                margin="normal"
                type="email"
                onChange={handleChange("username")}
              />
              <TextField
                id="password"
                label="Password"
                type="password"
                margin="normal"
                onChange={handleChange("password")}
              />
             <Button onClick={handleLogin} className={classes.loginBtn}>Login</Button>
            </form>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: "#f9f9f9",
    height: "100vh",
    width: "100%"
  },
  mainData: {
    padding: 20
  },
  mainDiv: {
    width: "100%"
  },
  title: {
    marginTop: 20
  },
  mainCard: {
    width: 400
  },
  textCenter: {
    textAlign: "center"
  },
  secondItem: {
    marginTop: 30
  },
  formCont:{
    display: 'flex',
    flexDirection: 'column',
  },
  loginBtn: {
    backgroundColor:"#4a4a4a",
    marginTop: 20
  }
});

export default withStyles(styles)(Login);