import React, { PureComponent } from "react";
import EventsDashboard from "../components/EventsDashboard";
import AddEventFormContainer from "./AddEventFormContainer";
import UpdateEventFormContainer from "./UpdateEventFormContainer";
import { connect, } from "react-redux";
import { setMsgAndShow } from "../store/actions/popup";
import { listEvents } from '../helpers/events';
import { loadEventData } from "../store/actions/eventForm";
import { loadFormData } from "../store/actions/formData";
import momentTz from "moment-timezone";
import { cloneDeep, isEmpty, isObject } from "lodash";
import { DEFAULT_EVENT_VALUES } from "../variables/formData";


class EventsDashboardContainer extends PureComponent {
  state = {
    gettingList:false,
    openAddForm:false,
    openUpdateForm:false,
    events:[]
  }
  rawEvents = [];
  currView = "month";
  currDate = null;
  currDateRange = []


  setCurrWeek = () => {
    const startDate = momentTz().startOf("week").format("YYYY-MM-DD");
    const endDate = momentTz().endOf("week").format("YYYY-MM-DD");
    this.currDateRange = [startDate, endDate];
  }
  componentDidMount(){
    this.setCurrWeek();
    this.getListOfEvents();
  }


  createEvent = (rawEvent) => {
    const startTime = momentTz(`${rawEvent.date} ${rawEvent.startTime}`, "YYYY-MM-DD HH:mm:ss").toDate();
    const endTime = momentTz(`${rawEvent.date} ${rawEvent.endTime}`,"YYYY-MM-DD HH:mm:ss").toDate();
    return {
      _id:rawEvent._id,
      name:rawEvent.name,
      startTime,
      endTime,
      allDay:rawEvent.isAllDay,
    }
  }


  createEvents = (events) => {
    let createdEvents = [];

    if(events && Array.isArray(events) &&  events.length){
      createdEvents = events.map(this.createEvent)
    }
    return createdEvents;
  }

  getListOfEvents = async (date,mode = "month") => {
    try {


      if(momentTz(date,true).isValid()){
        
        let events = cloneDeep(this.state.events);
        this.setState({
          gettingList:true
        })
        const now = momentTz(date,true);
  
  
  
        let urlToSendRequest = `${process.env.REACT_APP_API_WITH_PREFIX}admin/events/list`;
        let paramsToSend = {};
        if(mode === "day"){
          urlToSendRequest += `/${now.format("YYYY-MM-DD")}`;
        } else {
          paramsToSend = {
            month:now.format("MMM"),
            year:now.format("YYYY")
          }
        }
        this.currDate = now;
        const res = await listEvents(urlToSendRequest,paramsToSend)
  
        if(res.events && res.events.length){
          events = res.events;
          this.rawEvents = events;
          events = this.createEvents(events);
        }
  
        this.setState({
          gettingList:false,
          events
        })
      }
      
    } catch (error) {

      const { response } = error;
      let errorMessage = "Unable to get events list!";
      if(response && response.data && response.data.message){
        errorMessage = response.data.message;
      }
      this.setState({
        gettingList:false
      })
      this.props.setMsgAndShow(errorMessage);
    }

  }

  getListOfEventsByRange = async (dateRange) => {
    try {

      if(dateRange && dateRange.length){

        this.setState({
          gettingList:true
        })
        let startDate = dateRange[0];
        let endDate = dateRange[dateRange.length - 1];
        let events = [];
        if(startDate && endDate){
          startDate = momentTz(startDate).format("YYYY-MM-DD");
          endDate = momentTz(endDate).format("YYYY-MM-DD");
          this.currDateRange = [startDate,endDate];
          const res = await listEvents(`${process.env.REACT_APP_API_WITH_PREFIX}admin/events/list`,{
            startDate,
            endDate,
            mode:"range"
          })
          events = res.events;
          this.rawEvents = events;
  
        }
  
        events = this.createEvents(events);
        this.setState({
          gettingList:false,
          events
        })
      }

    } catch (error) {
      const { response } = error;
      let errorMessage = "Unable to get events list!";
      if(response && response.data && response.data.message){
        errorMessage = response.data.message;
      }
      this.setState({
        gettingList:false
      })
      this.props.setMsgAndShow(errorMessage);
    }
  }



  handleDateChange = (date) => {
    if(this.currView === "day"){
      this.getListOfEvents(date,this.currView);
    }
  }

  handleDateRangeChange = (dateRange) => {
    let date = undefined;
    if(!isEmpty(dateRange)){
      if(this.currView === "week"){
        date = dateRange;
        // this.getListOfEventsByRange(date);
      } else if(this.currView === "month"){
        date = [dateRange.start, dateRange.end];
        // date = this.currDate;
        // this.getListOfEvents(date,this.currView);
      }
    }

    if(!isEmpty(date)){
      this.getListOfEventsByRange(date);
    }


  }

  handleViewChange = (view) => {
    this.currView = view;
  }


  openAddModal = () => {
    this.props.loadEventData(DEFAULT_EVENT_VALUES)
    this.setState({
      openAddForm:true
    })
  }

  openDetailsModal = (eventData) => {
    const rawEventData = this.rawEvents.find((event) => event._id === eventData._id);
    if(!isEmpty(rawEventData)){
      const formValues = {
        eventId:rawEventData._id,
        name:rawEventData.name,
        type:{value:rawEventData.type},
        startTime:rawEventData.startTime,
        endTime:rawEventData.endTime,
        date:rawEventData.date,
        isAllDay: rawEventData.isAllDay,
      }

      this.props.loadFormData(formValues)
      this.setState({
        openUpdateForm:true
      })
    }
  }
  closeAddModal = () => {
    this.setState({
      openAddForm:false
    })
  }

  closeDetailsModal = () => {
    this.setState({
      openUpdateForm:false
    })
  }


  addEvent = (eventData) => {
    let events = cloneDeep(this.state.events);
    const createdEvents = this.createEvents([eventData]);
    events = events.concat(createdEvents);
    this.rawEvents.push(eventData);
    this.setState({
      events
    })
  }

  updateEvent = (updatedEventData) => {
    let rawEvents = this.rawEvents;
    let events = cloneDeep(this.state.events);
    rawEvents = rawEvents.map((event) => event._id === updatedEventData._id ? updatedEventData : event);
    const createdEvent = this.createEvent(updatedEventData);
    events = events.map((event) => event._id === createdEvent._id ? createdEvent : event)
    this.rawEvents = rawEvents;
    this.setState({
      events
    })
  }


  onSuccess = (actionType) => (eventData) => {
    if(actionType === "add"){
      this.addEvent(eventData);
    } else if(actionType === "update"){
      this.updateEvent(eventData);
    }
  }

  onRemove = (removedEventData) => {
    let events = cloneDeep(this.state.events);

    
    this.rawEvents = this.rawEvents.filter((event) => event._id !== removedEventData._id);
    events = events.filter((event) => event._id !== removedEventData._id);
    this.setState({
      events
    })
  }

  selectSlot = (slotData) => {

    let startTime = "00:00:00", endTime = "23:59:00", isAllDay = true, date;
    if(slotData.slots && slotData.slots.length){
      const slots = slotData.slots;
      date = momentTz(slots[0]).format("YYYY-MM-DD");
      if(slots.length > 1){
        startTime = momentTz(slots[0]).format("HH:mm:ss");
        endTime = momentTz(slots[slots.length - 1]).format("HH:mm:ss");
        isAllDay = false;
      }
    }


    const formValues = {
      ...DEFAULT_EVENT_VALUES,
      startTime,
      endTime,
      isAllDay,
      date,
      // type:{value:"unavailability"},
      // name:"Unavailable"
    }

    this.props.loadEventData(formValues)
    this.setState({
        openAddForm:true
    })


  }


  render(){
    return (
      <>
        <EventsDashboard 
          events={this.state.events}
          handleDateChange={this.handleDateChange}
          handleDateRangeChange={this.handleDateRangeChange}
          gettingList={this.state.gettingList}
          openAddForm={this.openAddModal}
          handleViewChange={this.handleViewChange}
          openDetailsModal={this.openDetailsModal}
          selectSlot={this.selectSlot}
        />

        <AddEventFormContainer 
          open={this.state.openAddForm} 
          closeModal={this.closeAddModal}
          onSuccess={this.onSuccess("add")}
        />

        <UpdateEventFormContainer
          open={this.state.openUpdateForm} 
          closeModal={this.closeDetailsModal}
          onSuccess={this.onSuccess("update")}
          onRemove={this.onRemove}
        />
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  loadEventData: (eventData) => dispatch(loadEventData(eventData)),
  loadFormData:(formData) => dispatch(loadFormData(formData))
});

export default connect(
  undefined,
  mapDispatchToProps
)(EventsDashboardContainer);