import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

// Custom Components
import Loader from "../ProgressCircle";
import Header from "../../containers/HeaderContainer";
import SideDrawer from "../SideDrawer";
import DatePicker from "../../components/DatePicker";
import MainContent from "../MainContent";
// import DataList from "../DataList";
import Table from "../TableWithPagination";
// import contractorEarningObjProps from "./contractorEarningObjProps";
import { MaterialTextField } from "../FormField";

const styles = theme => ({
  root: {

  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  }
});



const headers = [
  {
    name:"businessName",
    label:"Business Name"
  },
  {
    name: "totalEarnings",
    label: "Total Earnings ($)"
  },
  {
    name: "totalZokFee",
    label: "Zoklean Fee ($)"
  },
  {
    name: "totalShareAmt",
    label: "Contractor Share ($)"
  },
  {
    name:"totalTips",
    label:"Total Tips ($)"
  },
  {
    name:"actions",
    label:"Actions"
  }
];


const columnProps = [

  {
    name: "businessName",
  },

  {
    name: "totalEarnings",
    label: "Total Earnings ($)"
  },
  {
    name: "totalZokFee",
    label: "Zoklean Fee ($)"
  },
  {
    name: "totalShareAmt",
    label: "Contractor Share ($)"
  },
  {
    label:"Total Tips ($)",
    name:"totalTips",
    isTip: true
  }

]











const ContractorEarnings = ({
  contractorActions,
  contractorsEarningsList,
  startDate,
  endDate,
  classes,
  handleDateChange,
  totalCount,
  getContractorsEarningsByPage,
  page,
  getUpdatedList,
  gettingList,
}) => {
  return (
    <div className={classes.root}>
      {(gettingList) && <Loader/>}
        <Grid container alignItems="baseline" spacing={24} >
            <Grid item>
                <DatePicker 
                  name="startDate"
                  getDate={handleDateChange("startDate")}
                  label="From"
                  shrink
                  value={startDate}
                  material
                  placeholder="YYYY-MM-DD"
                  rightIcon="date"
                  isNotMinDate
                />
            </Grid>

            <Grid item>

                <DatePicker 
                  name="endDate"
                  getDate={handleDateChange("endDate")}
                  label="To"
                  shrink
                  value={endDate}
                  material
                  placeholder="YYYY-MM-DD"
                  rightIcon="date"
                  isNotMinDate
                />
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={getUpdatedList}> GET DATA</Button>
            </Grid>
        </Grid>


        <Grid>


          <Table
            list={contractorsEarningsList}
            totalCount={totalCount}
            headers={headers}
            columnProps={columnProps}
            actions={contractorActions}
            handlePageChange={getContractorsEarningsByPage}
            page={page}
          />
        </Grid>
    </div>
  );
};

export default withStyles(styles)(ContractorEarnings);
