import React from "react";
import { MainLayout } from "../layouts";
import FlaggedContractorsDashboard from "../containers/FlaggedContractorsDashboard";




const EditJobPage = (props) => {
  return (
    <MainLayout pageName="Flagged Contractors">
      <FlaggedContractorsDashboard {...props}/>
    </MainLayout>
  )
}



export default EditJobPage;