import { SET_MSG_AND_SHOW, RESET_MSG } from "../types";
import _ from "lodash";


const defaultState = {
  msg:"",
  isPopupOpen: false,
  vertical: 'bottom',
  horizontal: 'right',
}


const reducer = (state = defaultState, action) => {
  let finalState = {}
  switch(action.type){
    case SET_MSG_AND_SHOW:
      finalState = _.cloneDeep(state);
      finalState.msg = action.payload;
      finalState.isPopupOpen = true
      break;
    case RESET_MSG:
      finalState = _.cloneDeep(state);
      finalState.msg = "";
      finalState.isPopupOpen = false;
      break;
    default:
      finalState = state
  }

  return finalState
}

export default reducer;