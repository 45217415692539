import React from "react";
import { MainLayout } from "../layouts";
import PriceListsContainer from "../containers/PriceListsContainer";




const PriceListsPage = (props) => {
  return (
    <MainLayout pageName="Price Lists">
      <PriceListsContainer {...props}/>
    </MainLayout>
  )
}



export default PriceListsPage;