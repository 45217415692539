export const weekDaysOptions = [
  {
    label: "Monday",
    value: "monday"
  },
  {
    label: "Tuesday",
    value: "tuesday"
  },
  {
    label: "Wednesday",
    value: "wednesday"
  },
  {
    label: "Thursday",
    value: "thursday"
  },
  {
    label: "Friday",
    value: "friday"
  },
  {
    label: "Saturday",
    value: "saturday"
  },
  {
    label: "Sunday",
    value: "sunday"
  }
];
