import React, { PureComponent } from 'react'
import Grid from "core/components/Grid";
import IconButton from "core/components/IconButton";
import Icon from "core/components/Icon";
import { Close, Plus, Minus } from 'mdi-material-ui';
import { withStyles } from "core/components/Styles";
import Button from "core/components/Button";
import Typography from "core/components/Typography";
import cn from "classnames";
// import { Field } from "redux-form";
import {
  ExtendedAdvanceSelect,
  FormInputField,
  MaterialTextField
} from "../FormField";
// import DatePicker from "../DatePicker";
// import TimePicker from "../TimePicker";
// import { MaterialCheckBox } from '../FormField';
import {   notNegativeOrZero } from "../FormField/Validations";


const styles = theme => ({
  modalCont: {},
  inputMarginTop_10: {
    marginTop: 10
  },
  cleaningTypeHeading: {
    marginRight: 0,
    marginTop: 0,
    marginBottom: 4,
    fontSize: 12,
    color: "#929292",
    lineHeight: "18px"
  },
  title: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  selectorCont: {
    border: "1px solid #4a4a4a",
    maxWidth: 150,
    fontSize: "20px",
    borderRadius: ".3rem",
    overflow: "hidden",
    margin: 0,
    marginTop: 5,
    padding: 0,
    "& .dec, .inc": {
      background: "#f8f8f8",
      width: "30%",
      cursor: "pointer",
      textAlign: "center",
      padding: "0.3rem 0.5rem",
      display: "inline-block"
    },
    "& span:first-child": {
      borderRight: "1px solid #4a4a4a"
    },
    "& span:last-child": {
      borderLeft: "1px solid #4a4a4a"
    }
  },
  inputSelect:{
    paddingTop: 0,
		"& .field-wrapper":{
      height: 46,
			"& > span":{
				right: 0,
				color: "#c4c4c4",
        "& img":{
          width: "1.3rem",
          height: "1.3rem",
          opacity: 0.7
        }
			},
			"& > input":{
        color: "#646464",
        fontWeight: 500,
        lineHeight: "21px",
				fontSize: 14,
				height: 46,
				border: "solid 1px #d8d8d8",
				background: "#ffffff",
				padding: "5px 18px", //"20px 56px 20px 20px",
				"&::placeholder ":{
					color: "#b7b7b7"
				}
			}
		},
	},
  disp: {
    width: "40%",
    textAlign: "center",
    display: "inline-block"
  },
  propertyDetailsHeading: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  textCenter: {
    textAlign: "center"
  },
  addOnStyle:{
    backgroundColor:"blue"
  },
  setInCenter:{
    display:"flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputMarginTop_4:{
    marginTop: 4
  },
  noTopPadding:{
    paddingTop: "2px !important"
  },
  controlWrapper:{
    marginBottom:8,
  },
  helperBtn:{
		borderWidth: "1px",
		borderRadius: "30px",
    "& p":{
      marginLeft:5
    }
  },
  addInputBtn:{
    color:"#50e3c2",
    borderColor: "#50e3c2",
    "& p":{
      color:"#50e3c2",
    }
  },
  removeInputBtn:{
    borderColor: "#ea7255",
    color:"#ea7255",
    "& p":{
      color:"#ea7255",
    }

  },
  counterIconBtn:{
    height:24,
    width:24,
    color:"#50e3c2",
    borderColor: "#50e3c2",
    border:"1px solid",
  },
  counterDecreaseBtn:{
    borderColor: "#ea7255",
    color:"#ea7255",
  }
  // costStyle:{
  //   display: "flex",
  //   alignItems: "center",
  //   height: 33
  // }
});

class CarpetAddOnsInput extends PureComponent {

  removeField = (fieldIndex) => () => {
    const { onRemoveField, fields } = this.props;
    if(onRemoveField){
      onRemoveField(fieldIndex);
    } else {
      fields.remove(fieldIndex);
    }

  }

  addCount = (fieldIndex) => () => {
    const { get, name } = this.props.fields;
    const { onAddCount } = this.props;
    const storedValue = get(fieldIndex);
    if(onAddCount){
      onAddCount(name,storedValue, fieldIndex);
    }

    
    // console.log("SINS", storedValue);
    // insert(fieldIndex, storedValue);
  }

  decreaseCount = (fieldIndex) => () => {
    const { get, name } = this.props.fields;
    const { onDecreaseCount } = this.props;
    const storedValue = get(fieldIndex);
    if(onDecreaseCount){
      onDecreaseCount(name,storedValue, fieldIndex);
    }
  }

  render(){
    const { classes, fields, list, onSelect, addInput, clearInputs } = this.props;
    return (

      <Grid > 
        <p className={classes.cleaningTypeHeading}>Add Ons</p>
        <Grid>
          {
            fields && fields.map((f, index) => (
              <Grid
                container
                // className={classes.inputMarginTop_4}
                // justify="space-between"
                alignItems="center"
                spacing={16}
                key={`add-carpet-add-on-${index}`}
              >
                <Grid item xs={12} md={4} className={classes.inputSelect}>
                  {/* <p className={classes.cleaningTypeHeading}>Name</p> */}
                  <FormInputField 
                    // id="cleaning"
                    placeholderValue="Select Add On"
                    // label="Area"
                    name={`${f}._id`}
                    component={ExtendedAdvanceSelect}
                    options={list}
                    backgroundColor="transparent"
                    material
                    // validate={[required]}
                    onChange={onSelect}
                    disableUnderline
                    styles={{
                      controlWrapper:classes.controlWrapper
                    }}
                    // closeMenuOnSelect={false}
                    menuPlacement="top" // To open the list to top direction
                    
                  />
                </Grid>
                <Grid item container xs={12} md={3} className={classes.inputSelect} spacing={8} justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <IconButton  className={classes.counterIconBtn} onClick={this.addCount(index)}>
                      <Plus/>
                    </IconButton>

                  </Grid>
                  <Grid item md={6} xs={6}>
                    <FormInputField
                      // label="Size"
                      name={`${f}.count`}
                      component={MaterialTextField}
                      placeholder="Enter total count" 
                      disableUnderline  
                      type="number"
                      validate={[notNegativeOrZero]}
                      // addOnStyle={classes.addOnStyle}
                    />
                  </Grid>

                  <Grid item>
                      <IconButton className={cn(classes.counterIconBtn, classes.counterDecreaseBtn)} onClick={this.decreaseCount(index)}>
                        <Minus />
                      </IconButton>

                    </Grid>


                  {/* <Grid item container md={6} spacing={8} xs={4} >
                    <Grid item>
                      <IconButton  className={classes.counterIconBtn} onClick={this.addCount(index)}>
                        <Plus/>
                      </IconButton>

                    </Grid>
                    <Grid item>
                      <IconButton className={cn(classes.counterIconBtn, classes.counterDecreaseBtn)} onClick={this.decreaseCount(index)}>
                        <Minus />
                      </IconButton>

                    </Grid>

                  </Grid> */}
                </Grid>
                <Grid item >
                  {
                    index > 0 ?
                    <IconButton onClick={this.removeField(index)}>
                      <Close />
                    </IconButton>
                    : null
                  }
                </Grid>
              </Grid>
            ))

          }


        </Grid>

        <Grid 
          container
          spacing={16}
        >
          <Grid item>
            <Button 
              variant="outlined" 
              onClick={addInput}
              className={cn(classes.helperBtn, classes.addInputBtn)}
              // className={cn(classes.tableOptionsMargin,classes.tableOptionOne)}
            >
              <Icon>add</Icon>
              <Typography>
                Add Add On
              </Typography> 
            </Button>

          </Grid>

          <Grid item>
            <Button 
              variant="outlined" 
              onClick={clearInputs}
              className={cn(classes.helperBtn, classes.removeInputBtn)}
              // className={cn(classes.tableOptionsMargin,classes.tableOptionOne)}
            >
              <Icon>trash</Icon>
              <Typography>
                Clear
              </Typography> 
            </Button>

          </Grid>


        </Grid>
      </Grid>
    )
  }
}


export default withStyles(styles)(CarpetAddOnsInput);
