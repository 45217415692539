import axios from "../../utils/http";
import { isEmpty } from "lodash";

export const getPricingList = async (paramObj) => {
  try {
    const query = {
      params: {}
    };
    const res = {};
    const jobId = (paramObj.jobId && paramObj.jobId.trim()) || undefined;
    const zipcode = (paramObj.zipcode && paramObj.zipcode.trim()) || undefined;
    const type = (paramObj.type && paramObj.type.trim()) || undefined;
    if (jobId) {
      query.params.jobId = jobId;
    } else if (zipcode) {
      query.params.zipcode = zipcode;
    }

    if(type){
      query.params.type = type;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}cleaning/get_pricing_list`,
      query
    );

    if (response && response.data && response.data.status === "OK") {
      if ( !isEmpty(response.data.pricingList) ) {
        res.priceList = response.data.pricingList;
      }
    }

    return res;
  } catch (error) {
    // console.log("Error getting pricing list : ", error);
    throw error;
  }
};
