import React from "react";
import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom";
// import PropsRoute from "./PropsRoute";



const Routes = ({routes, type}) => {

  return (
    <>
      {
        routes.map((route,key) => {
          if(route.authorizedUser && route.authorizedUser.indexOf(type) === -1 ) {
            return null;
          }
          if(route.children) {
            return <Routes type={type} routes={route.children} key={key}/>;
          }
          return <Route path={route.path} component={route.component} key={key} exact={route.exact} strict={route.strict}/>;
        })
      }
    </>
  )
}



const SwitchRoutes = ({
  routes,
  userType,
  redirectTo,
}) => {
  return (
    <Router>
      <Switch>
        <Routes routes={routes} type={userType}/>
        <Redirect to={redirectTo} />
      </Switch>
    </Router>
  )
}


export default SwitchRoutes;