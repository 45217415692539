import React from "react";
import { MainLayout } from "../layouts";
import ManagersDashboardContainer from "../containers/ManagersDashboardContainer";




const ManagersDashboardPage = (props) => {
  return (
    <MainLayout pageName="Managers">
      <ManagersDashboardContainer {...props}/>
    </MainLayout>
  )
}



export default ManagersDashboardPage;