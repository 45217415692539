import React, { Component } from 'react';
import {Map, GoogleApiWrapper} from 'google-maps-react';
 
export class GoogleMap extends Component {
  googleMap = null

  defaults = {
    center: {lat: 34.052, lng: -118.243},
    zoom: 8 
  }

  containerStyle = {
    position:"relative"
  }
  circleDefaults = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    center: this.defaults.center,
    radius: 10000
  }
  circle = []

  createCircle = opts => {
    if(this.googleMap && this.googleMap.map){

      const circle = new this.props.google.maps.Circle({
        ...this.circleDefaults,
        ...opts,
        map: this.googleMap.map
      })
      
      this.circle.push(circle);
    }
  }

  onReady = () => {
    const {circle, center, zoom} = this.props
    if(center) this.updateMapCenter(center);
    if(zoom) this.updateZoom(zoom)
    if(circle) this.createCircle(circle);
  }

  updateCircleRadius = (c, r) => {
    if(c) c.setRadius(r);
  }
  updateCircleCenter = (c, center) => {
    if(c) c.setCenter(center);
  }

  updateMapCenter = (center) => {
    this.googleMap && this.googleMap.map && this.googleMap.map.setCenter(center);
  }
  updateZoom = z => {
    this.googleMap && this.googleMap.map && this.googleMap.map.setZoom(z);
  }

  componentDidUpdate (prevProps) {
    const {circle, center, zoom} = this.props
    if(center) this.updateMapCenter(center);
    if(zoom) this.updateZoom(zoom)
    if(circle) {
      const {radius, center} = circle
      if(radius !== prevProps.circle.radius) this.updateCircleRadius(this.circle[0], radius)
      if(center !== prevProps.circle.center) this.updateCircleCenter(this.circle[0], center)
    }

  }

  render() {
    const {center, zoom, width, height, position = "relative"} = this.props
    return (
        <Map
          containerStyle={this.containerStyle}
          ref={ref => this.googleMap = ref}
          google={this.props.google}
          initialCenter={center ? center : this.defaults.center}
          zoom={zoom ? zoom : this.defaults.zoom}
          onReady={this.onReady}
          style={{
            width: width,
            height: height,
            position
          }}
        />

    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_GOOGLE_KEY)
})(GoogleMap)