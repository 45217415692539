import React from "react";
import { MainLayout } from "../layouts";
import TiersContainer from "../containers/TiersDashboardContainer";




const TiersPage = (props) => {
  return (
    <MainLayout pageName="Tiers">
      <TiersContainer {...props}/>
    </MainLayout>
  )
}



export default TiersPage;