import React from "react";
import Header from "../containers/HeaderContainer";
import SideDrawer from "../components/SideDrawer";
import MainContent from "../components/MainContent";
import { withStyles } from "@material-ui/core/styles";


const styles = {
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex"
  },
}



const MainLayout = ({children, classes, pageName}) => {
  return (
    <div className={classes.root}>
      <Header name={pageName} />
      <SideDrawer />
      <MainContent>
        {children}
      </MainContent>
    </div>
  )
}



export default withStyles(styles)(MainLayout);