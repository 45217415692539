import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "../../core/components/IconButton";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Button from "@material-ui/core/Button";
import {
  // Magnify,
  // Calendar,
  // Bell,
  SettingsOutline,
  // ChevronDown,
  Logout
} from "mdi-material-ui";
import logo from "../../files/images/logo.jpg";
import {drawerWidth, appBar} from "theme/vars"

const styles = theme => ({
  logoWrapper:{ 
    width: drawerWidth,
    backgroundColor: "#fff",
    textAlign: "center",
    height: appBar
  },
  header: {
    // backgroundColor: "rgb(74, 74, 74)",,
    backgroundColor:"#49d3b9",
    zIndex: 1300,
  },
  headerBar: {
    paddingLeft: 16,
    paddingRight: 10
  },
  searchIcon: {
    color: "white",
    fontSize: "2.5rem",
    marginLeft: 16
  },
  title: {
    color: "white",
    fontWeight: '300'
  },
  logo: {
    height: 64,
    width: 64
  },
  profilePic: {
    height: 30,
    width: 30,
    borderRadius: "50%"
  },
  colorWhite: {
    color: "#fff"
  },

  logoutBtn:{
    borderRadius: "50%",
    fontSize: 14,
    color:"#fff",
    padding: 10,
    minWidth: 36
  }

});

class Header extends Component {
  /*
    Commented out (Improvements) : 
      1, Profile pic : MUST be included dynamically if needed.
      2, DropDown Menu & MenuItem : Can be added later to provide more options.
      3, handleClick, handleClose, anchorEl are needed to perform the actions above so dont remove them.
  */


 
  render() {

    const { classes, name, handleClick, handleClose, handleLogout, anchorEl, openChangePassModal } = this.props;

    return (
      <AppBar position="absolute" color="default" className={classes.header}>
        <Grid container className={classes.root} alignItems="center">
          <Grid
            container
            item
            className={classes.logoWrapper}
            alignItems="center"
            justify="center"
          >
            <img className={classes.logo} src={logo} alt="logo" />
          </Grid>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.headerBar}
            item
            xs
          > 
            <Grid
              item
              xs
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Typography
                variant="display1"
                className={classes.title}
              >
                {name}
              </Typography>
              {/* <Magnify className={classes.searchIcon} /> */}
            </Grid>
            <Grid
              item
              xs
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={16}
            >
              {/* <Grid item>
                <Calendar className={classes.colorWhite} />
              </Grid>
              <Grid item>
                <Bell className={classes.colorWhite} />
              </Grid>
              */}
              <Grid item>
                <IconButton onClick={handleClick}>
                  <SettingsOutline className={classes.colorWhite} />
                </IconButton>
              </Grid> 
              <Grid item style={{display: "flex", alignItems: "center"}}>
                {/* <img
                  src={logo}
                  className={classes.profilePic}
                  alt="profile-pic"
                /> */}

                <Button 
                  onClick={handleLogout}
                  className = {classes.logoutBtn}
                >
                  <Logout/>
                </Button>

                {/* <IconButton
                  aria-owns={anchorEl ? 'simple-menu' : null}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <ChevronDown className={classes.colorWhite} />
                </IconButton> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>


        <Popper 
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          transition 
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    <MenuItem onClick={openChangePassModal}>Change Password</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Header);
