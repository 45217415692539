import React, { Component } from "react";
import TimePickerModal from "./TimePickerModal";
import {InputField} from "../FormField";
const _img_clock = require("../../images/utils/clock-circular-outline.png");

class TimePicker extends Component  {

  state = {
    isOpen: false,
    hrs: 0,
    minutes: 0,
    format: 24,
    defaultValue: null
  }

  toggleModal = () => {
    this.setState({isOpen: !this.state.isOpen});
  }
  openModal = () => {
    this.setState({isOpen: true});
  }
  closeModal = () => {
    this.setState({isOpen: false});
    // this.onChange(null);
    // const {input} = this.props;
    this.onChange(this.state.defaultValue);
  }

  onTimeChange = type => (value) => {
    const state = {...this.state};
    
    state[type] = value;
    this.setState({...state});
    
    const hrs = state.hrs < 10 ? "0"+ state.hrs : state.hrs;
    const minutes = state.minutes < 10 ? "0"+ state.minutes : state.minutes;
    this.onChange(`${hrs}:${minutes}`);
  }
  
  onChange = item => {
    const {input} = this.props;
    if(input) {
      input.onChange(item); 
    }
  }

  componentDidMount(){
    const {input} = this.props;
    if(input) {
      this.setState({defaultValue: input.value})
      this.onChange(input.value);
    } 
    else this.onChange(null);
  }

  getInputValues = (value) => {
    if(!value) return {
      inputHrs: 0,
      inputMinutes: 0
    };
    
    return {
      inputHrs: Number(value.split(":")[0]),
      inputMinutes: Number(value.split(":")[1])
    };
  }

  componentDidUpdate() {
    const {input} = this.props;
    if(input) {
   
      const {inputHrs, inputMinutes} = this.getInputValues(input.value);
      
      this.setState(prevState => {
        if(prevState.hrs !== inputHrs || prevState.minutes !== inputMinutes){ 
          return {
            hrs: inputHrs,
            minutes: inputMinutes
          };
        }
      });
    }
  }
  
  render() {
    
    const {input, type, id, meta} = this.props;
    let inputHrs = 0, inputMinutes = 0;
    
    if(input && input.value) {
      const val = this.getInputValues(input.value);
      inputHrs = val.inputHrs;
      inputMinutes = val.inputMinutes;
    } 


    return (
      <div>
        <InputField 
          leftIcon={<img src={_img_clock} alt="clock"/>}
          input={input} 
          meta={meta}
          type={type}
          id={id}
          readOnly
          onClick={this.openModal}
        />
        <TimePickerModal
          {...this.state}
          hrs={input ? inputHrs : this.state.hrs}
          minutes={input ? inputMinutes  : this.state.minutes}
          toggle={this.toggleModal}
          handleOpen={this.openModal}
          handleClose={this.closeModal}
          handleTime={this.onTimeChange}
        />
      </div>
    );
  }
}
export default TimePicker;
