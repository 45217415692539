import React from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";
import { Account } from "mdi-material-ui";
import { withStyles } from "@material-ui/core/styles";


const EmployeeList = ({
  employeeList,
  jobItemObj,
  classes,
  assignJob
}) => {
  return (
    <Grid className={classes.freeEmployeeList}>
    {employeeList && employeeList.map((employee, key) => {
      return (
        <Grid
          key={`employpee-list-${key}`}
          container
          alignContent="center"
          alignItems="center"
          justify="space-between"
          >
          <Avatar>
            <Account />
          </Avatar>
          <p>{employee.fname + " " + employee.lname}</p>
          <Checkbox value={employee.userId} color="primary" onChange={assignJob} checked={jobItemObj.assignedEmployeeIds.includes(employee.userId)} />
        </Grid> 
      );
    })}
  </Grid>
  )
}


const styles = theme => ({
  freeEmployeeList:{
    marginTop: 10,
  }
})

export default withStyles(styles)(EmployeeList);