import React from "react"; 
import Table from "../TableWithPagination";
import Loader from "../ProgressCircle";
import { withStyles } from "@material-ui/core/styles";
import SearchBar from "../SearchBar";
import Grid from "../../core/components/Grid";
import Select from "core/components/Select";
import MenuItem from 'core/components/MenuItem';
import FormControl from "core/components/FormControl";
import ExportListBtn from "../ExportListBtn";
import InputLabel from "core/components/InputLabel";
// Custom Components

import Header from "../../containers/HeaderContainer";
import SideDrawer from "../SideDrawer";
import MainContent from "../MainContent";
// import DataList from "../DataList";
// import AddJobModal from "../AddJobModal";

// import contractorItemProps from "./contractorItemProps";




const headers = [
  {
    name:"businessName",
    label:"Business Name"
  },
  {
    name: "createdat",
    label: "Joining Date"
  },
  {
    name: "businessType",
    label: "Business Types"
  },
  {
    name: "zipcode",
    label: "Zipcode"
  },
  {
    name:"businessPhone",
    label:"Business Phone"
  },
  {
    // name:"",
    label:"Owner Phone"
  },
  {
    name:"ratings",
    label:"Ratings"
  },
  {
    name:"status",
    label:"Status"
  },
  {
    name:"tier",
    label:"Tier"
  },
  {
    name: "actions",
    label: "Actions"
  }
];
 





// const columnProps = [
//   {
//     name:'displayId'
//   },
//   {
//     name: "createdat",
//     isTimeStamp: true,
//     formatRequired:"DD MMM YYYY"
//   },
//   {
//     name: "date",
//     isDate: true,
//     inFormat:"YYYY-MM-DD",
//     formatRequired:"DD MMM YYYY"
//   },
//   {
//     name: "time",
//     isTime:true,
//     inFormat:"hh:mm:ss",
//     formatRequired:"hh:mm A"
//   },
//   {
//     name: "orderCost",
//   },
//   {
//     name: "orderStatus",
//     isStatus: true,
//     capitalize: (string) => capitalizeFirstLetterOnly(string)
//   },
// ];
const columnProps = [
  // {
  //   name: "businessId"
  // },
  {
    name: "businessName",
  },
  {
    name:"createdat",
    isTimeStamp: true,
    formatRequired:"MM/DD/YYYY"
  },
  {
    name:"businessType",
    isList: true,
    joinList: (list) => list.join(",")
  },
  {
    name:"zipcode"
  },
  {
    name:"businessPhone"
  },
  {
    name:"ownerPhone",
  },
  {
    name:"ratings",
    isRatings:true,
    outOf:5
  },
  {
    name:'status'
  },
  {
    name:"tier",
    isSelectBtn:true
  }
]

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex"
  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  },
  formControl:{
    // margin: theme.spacing.unit,
    minWidth:"100%",
    width:"100%"
  }
});

const JobsDashboard = ({
  classes,
  contractorActions,
  contractors,
  getSearch, 
  load,
  totalCount,
  handlePageChange,
  searchValue,
  clearSearch,
  searchInput,
  q,
  page,
  tierTypes,
  handleTierChange,
  tierType,
  handleContractorTierChange,
  selectTierOptions,
  isSelectedOption,
}) => {
  return (
    <div className={classes.root}>
      <Header name="Contractors" />
      <SideDrawer />
      <MainContent>



        <Grid container spacing={16} alignItems="baseline" justify="space-between" wrap="nowrap">
          <Grid item md={6}  container spacing={16} alignItems="flex-end">
            <Grid item md={6} sm={12}>
              <SearchBar 
                placeholder="Search business by zipcode, business name. owner's email"
                onChange={getSearch}

                query={searchValue}
                onClear={clearSearch}
                inputRef={searchInput}
              />
            </Grid>

            
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="cleaning-type">
                  Tier
                </InputLabel>
                <Select
                  value={tierType}
                  onChange={handleTierChange}
                  displayEmpty
                  inputProps={{
                    id: 'tier-type',
                  }}
                >
                  <MenuItem value="">All</MenuItem>
                  {
                    tierTypes && tierTypes.map((tier, index) => {
                      return (
                        <MenuItem key={`tier-type-${index}`} value={tier}>{tier}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item>
            <ExportListBtn 
              requestUrl={`${process.env.REACT_APP_API_WITH_PREFIX}admin/contractors/list/export`}
              params={{
                q:searchValue,
                tierType: tierType ? encodeURIComponent(tierType) : ""
              }}
              title="Download List"
            />
          </Grid>
        </Grid>

        { load && <Loader />}

        <Table
          list={contractors}
          totalCount={totalCount}
          actions={contractorActions}
          handlePageChange={handlePageChange}
          load={load}
          page={page}
          headers={headers}
          columnProps={columnProps}
          selectBtnOptions={selectTierOptions}
          onOptionSelect={handleContractorTierChange}
          isSelectedOption={isSelectedOption}
          disableSelectTextCapitalize={true}
          selectedOptionProp="tier"
        />
      </MainContent>
    </div>
  );
};



export default withStyles(styles)(JobsDashboard);
