import React from "react";
import { MainLayout } from "../layouts";
import CompletedJobs from "../containers/CompletedJobsContainer";




const ContractorCompletedJobsPage = (props) => {
  return (
    <MainLayout pageName="Completed Jobs">
      <CompletedJobs {...props}/>
    </MainLayout>
  )
}



export default ContractorCompletedJobsPage;