export const cleaningTypes = [
  {
    label:"Standard",
    value:"standard"
  },
  {
    label:"Deep",
    value:"deep"
  },
  {
    label:"Move In/Out",
    value:"move_in_out"
  }
]


export const cleaningFrequencyTypes = [
  {
    label:"Just this once",
    value:1,
  },
  {
    label:"Weekly",
    value:2,
  },
  {
    label:"Every Other Week",
    value:3,
  },
  {
    label:"Every Month",
    value:4,
  }
]