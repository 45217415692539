import { SET_JOB_ID,REMOVE_JOB_ID,SHOW_OFFER_CONTRACTOR_MODAL } from "../types";
//import _ from "lodash";

const defaultState = {
	jobId: "",
	isContModalOpen: false
}

const reducer = (state = defaultState, action)=>{
	let finalState = {};
	switch(action.type){
		case SET_JOB_ID:
			finalState = {...state, jobId: action.payload};
			break;
		case REMOVE_JOB_ID:
			finalState = {...state, jobId: action.payload};
			break;
		case SHOW_OFFER_CONTRACTOR_MODAL: 
			finalState = {...state, isContModalOpen: action.payload};
			break;
		default :
			finalState = state;
	}
	return finalState;
}
 
export default reducer;