import React, { PureComponent } from 'react';
import axios from "utils/http";
import getOptionsValue from "utils/getOptionsValue";
// import moment from "moment-timezone";
import { geoCode } from "../../utils/google";
import { setMsgAndShow } from "store/actions/popup";
import { isEmpty, cloneDeep, isEqual } from "lodash";
import AddJobModal from "./JobFormModal";
import { reduxForm, arrayPush } from "redux-form";
import { connect } from "react-redux";
import removeProp from "../../utils/removeProp";
import { capitalizeAllFirstLetters } from "../../utils/capitalize";
import { normalizePhone } from "../FormField/formatters";
import isNumberKey from "../../utils/isNumberKey";
import confirmResponse from "../../utils/confirmResponse";
import { calCost, createCarpetAddOns, createCleaningAreasList, getAdditionalBookings, getDomesticAdditionalBookings, getPropertyInfo } from "../../helpers/orders";
import isBackspaceOrDelKey from "../../utils/isBackspaceOrDelKey";
import { storeDomesticPriceList, setPriceList } from "../../store/actions/app";
import { getPricingList } from "../../sdk/helpers/pricingListAndOrderInfo";
// import { validateDateBetweenTwoDates as compareDates } from "../../utils/compareDates";
import PreviousBookingsModal from "../BookingsModal";
import { ZOK_SERVICE_UNAVAILABLE } from '../../variables/errorCode';


class AddJobModalContainer extends PureComponent {
  doNotUpdateCost = false;
  createdOrderBody = undefined;
  state = {
    customers: [],
    addNewCustomer: false,
    gettingCouponData: false,
    gettingPropertyData: false,
    processingOrder: false,
    gettingPriceList: false,
    showBookingSelectModal: false,
    selectedCustomer: {},
    contractorsServedOptions: []
  }
  componentDidMount() {
    geoCode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
  }

  _updateCost = (prev) => {
    const next = this.props;

    const { formValues, change } = next;
    if (isEmpty(formValues)) return;

    const { serviceAddress } = formValues;

    const propToCompare = [
      'type',
      "bedrooms",
      "bathrooms",
      "cleaningAreas",
      "carpetAddOns",
      // "blinds", 
      // "windows", 
      // "fridge", 
      // "oven", 
      // "walls", 
      // "ecoFriendly", 
      "couponData",
      "addOnServices",
      "addOnWindows",
      "adminDiscount"
    ];

    let costUpdateNeeded = false;

    const nextPropertyArea = serviceAddress && serviceAddress.propertyArea
      ? parseInt(serviceAddress.propertyArea)
      : 0;

    const nextCleaningType = formValues && formValues.cleaningType
      ? formValues.cleaningType.value
      : null;
    const pricingList = this.props.priceList;

    if (prev) {
      const { formValues: prevFormValues = {}, priceList: oldPricingList } = prev;

      if (isEmpty(prevFormValues)) return;

      const { serviceAddress: prevServiceAddress } = prevFormValues;
      let prevPropertyArea = 0;

      if (prevServiceAddress && prevServiceAddress.propertyArea) {
        prevPropertyArea = parseInt(prevServiceAddress.propertyArea)
      }

      const prevCleaningType = prev.formValues && prev.formValues.cleaningType
        ? prev.formValues.cleaningType.value
        : null;

      costUpdateNeeded = nextPropertyArea !== prevPropertyArea || nextCleaningType !== prevCleaningType;


      propToCompare.forEach(prop => {
        if (!costUpdateNeeded) {
          costUpdateNeeded = next.formValues[prop] !== prev.formValues[prop];
        }
      });


      if (!costUpdateNeeded) {
        costUpdateNeeded = !isEqual(oldPricingList, pricingList);
      }

    }


    const changeCost = !this.doNotUpdateCost;

    const priceList = formValues.type !== "commercial" ? pricingList : undefined;


    if (costUpdateNeeded && changeCost) {
      const { discount, discountedPrice, originalCost, estTime, unModifiedCost } = calCost(
        formValues,
        priceList,
      );

      change("orderCost", parseFloat(discountedPrice.toFixed(2)));
      change("discount", discount);
      change("originalCost", parseFloat(originalCost.toFixed(2)));
      change("unModifiedCost", parseFloat(unModifiedCost.toFixed(2)))
      change("adminCost", parseFloat(originalCost.toFixed(2)));
      change("appliedDiscount", discount);
      change("etc", estTime);
    } else if (!changeCost) {
      const { discount, discountedPrice, originalCost, unModifiedCost, estTime } = calCost(
        formValues,
        priceList,
        true
      );
      change("orderCost", parseFloat(discountedPrice.toFixed(2)));
      change("discount", discount);
      change("appliedDiscount", discount);
      change("unModifiedCost", parseFloat(unModifiedCost.toFixed(2)))
      change("originalCost", parseFloat(originalCost.toFixed(2)));
      change("etc", estTime);
    }

  }

  componentDidUpdate(prevProps) {
    const { showModal: prevShowModal } = prevProps;
    const { showModal: newShowModal } = this.props;

    if (prevShowModal !== newShowModal && newShowModal) {
      this.getCustomers();
    }
    this._updateCost(prevProps)
  }

  getCustomers = async () => {
    try {
      let customersOptions = [];
      const customersRes = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/customers/list`);

      if (customersRes && customersRes.data && customersRes.data.customers) {
        const customers = customersRes.data.customers || [];

        customers.forEach((customer) => {
          customersOptions.push({
            ...customer,
            label: customer.fullName,
            value: customer._id,
          })
        })
      }
      this.setState({
        customers: customersOptions,
      })
    } catch (error) {
      console.log("Error", error);
    }
  }

  clearRehireList = () => {
    this.setState({
      contractorsServedOptions: [],
      showServedContractors: false
    })
  }
  closeModal = () => {
    this.props.reset();
    this.clearRehireList();
    this.props.toggleModal();
    this.doNotUpdateCost = false;
  }


  handleErrorToastInAddOrder = async (response) => {
    let showToast = true;
    let errorMsg = "Sorry an error occurred!";
    this.props.change("cToken", "");
    if (response && response.data) {
      if (
        response.data.code === ZOK_SERVICE_UNAVAILABLE &&
        response.data.cToken
      ) {
        showToast = false;
        const confirmed = await confirmResponse(
          "Do you want to continue ?",
          response.data.message,
        );
        if (!confirmed) return;
        this.props.change("cToken", response.data.cToken);
        this.props.handleSubmit();
        // this.props.callSubmit();
      } else if (response.data.message) {
        errorMsg = response.data.message;
      }
    }

    if (showToast) {
      this.props.setMsgAndShow(errorMsg);
    }

  }

  processOrder = async (obj) => {
    try {

      const { onAddSuccess } = this.props;
      this.setState({
        processingOrder: true
      });
      let formValues = cloneDeep(obj);
      let {
        serviceAddress,
        addOnServices,
        cleaningType,
        customer,
        whatAboutKeys,
        bathrooms,
        bedrooms,
        cleaningServices,
        frequency,
        floor,
        requiredEmployees,
        equipments,
        unModifiedCost,
        originalCost,
        adminCost,
        rehiredContractorId,
        isRehireBooking,
        cToken,
        cleaningAreas,
        carpetAddOns,

      } = formValues;
      const addOns = {};
      let customerId;
      let rehireContId;
      const propsToRemove = [
        "equipments",
        "cleaningServices",
        "frequency",
        "floor",
        "requiredEmployees",
        "serviceAddress",
        "addOnServices",
        "cleaningType",
        "customer",
        "whatAboutKeys",
        "endDate",
        "couponCode",
        "adminCost",
        "appliedDiscount",
        "unModifiedCost",
        "isRehireBooking",
        "cleaningAreas",
        "carpetAddOns",
        "cToken"
      ]
      formValues = removeProp(formValues, propsToRemove);

      if (!isEmpty(serviceAddress)) {
        let propertyType;

        if (!isEmpty(serviceAddress.propertyType)) {
          propertyType = serviceAddress.propertyType.value;
        }

        serviceAddress = {
          ...serviceAddress,
          state: serviceAddress.state.value,
          phone: normalizePhone(serviceAddress.phone),
          propertyType
        }

        // if (formValues.type === "carpet") {
        //   serviceAddress = removeProp(serviceAddress, ["bedrooms", "bathrooms", "propertyType", "propertyArea", "addOnsString", "windowsRange"]);
        // }
      }

      if (isRehireBooking && !isEmpty(rehiredContractorId)) {
        rehireContId = rehiredContractorId.value;
      }

      if (formValues.type === "domestic") {
        if (bedrooms) {
          serviceAddress.bedrooms = bedrooms
        }
        if (bathrooms) {
          serviceAddress.bathrooms = bathrooms;
        }
        if (!isEmpty(cleaningType)) {
          cleaningType = cleaningType.value
        }


        if (Array.isArray(addOnServices) && addOnServices.length) {
          addOnServices.forEach((addOnService) => {
            addOns[addOnService.name] = addOnService.quantity
          })
          const addonsArray = Object.keys(addOns);
          let addOnsString = addonsArray.reduce((string, currA) => {
            if (addOns[currA]) {
              string += `, ${currA}`
            }
            return string;
          });
          addOnsString = capitalizeAllFirstLetters(addOnsString);
          serviceAddress.addOnsString = addOnsString;
        }

      } else if (formValues.type === "commercial") {

        formValues = removeProp(formValues, ["bathrooms", "bedrooms"]);

        if (Array.isArray(cleaningServices) && cleaningServices.length) {
          cleaningServices = getOptionsValue(cleaningServices);
        }

        if (Array.isArray(equipments) && equipments.length) {
          equipments = getOptionsValue(equipments);
        }

        if (!isEmpty(floor)) {
          floor = floor.value;
        }

        if (!isEmpty(requiredEmployees)) {
          requiredEmployees = requiredEmployees.value;
        }


        if (!isEmpty(addOnServices)) {
          serviceAddress.windowsRange = addOnServices.label;
          serviceAddress.addOnsString = "Windows";
          addOns.windows = addOnServices.value;
          addOns.windowsRange = addOnServices.label;
        }

      } else if(formValues.type === "carpet"){
        formValues = removeProp(formValues, ["bathrooms", "bedrooms","addOns","propertyArea"]);

        if(!isEmpty(cleaningType)){
          cleaningType = cleaningType.value;
        }

        if (Array.isArray(cleaningAreas) && cleaningAreas.length) {
          formValues.cleaningAreas = createCleaningAreasList(cleaningAreas)
        }

        if (Array.isArray(carpetAddOns) && carpetAddOns.length) {
          formValues.carpetAddOns = createCarpetAddOns(carpetAddOns);
        }



      }



      if (!isEmpty(frequency)) {
        frequency = frequency.value;
      }

      if (!isEmpty(whatAboutKeys) ) {
        whatAboutKeys = whatAboutKeys.value;
      }

      const address = `${serviceAddress.address} ${serviceAddress.city} ${serviceAddress.state} US ${serviceAddress.zipcode}`;


      const geoCodeAddressResponse = geoCode.fromAddress(address);
      const response = await geoCodeAddressResponse;
      serviceAddress = {
        ...serviceAddress,
        ...response.results[0].geometry.location
      }

      const orderData = {
        ...formValues,
        adminCost: adminCost ? parseFloat(parseFloat(adminCost).toFixed(2)) : undefined,
        zipcode: serviceAddress.zipcode,
        cleaningType,
        whatAboutKeys,
        addOns,
        serviceAddress,
        propertyArea: serviceAddress.propertyArea,
        cleaningServices,
        frequency,
        floor,
        requiredEmployees,
        equipments,
        // cleaningAreas,
        // carpetAddOns,
        originalCost: unModifiedCost || originalCost,
        rehiredContractorId: rehireContId
      }

      if (!isEmpty(customer)) {
        customerId = customer._id;
      }


      const payload = {
        orderData,
        customerId,
        cToken
      };




      await axios.post(`${process.env.REACT_APP_API_PREFIX}admin/jobs/add`, { ...payload });

      this.props.change("cToken", "");

      this.setState({
        processingOrder: false
      })
      this.closeModal();

      this.props.setMsgAndShow("Order Added!");
      if (onAddSuccess) {
        onAddSuccess();
      }





    } catch (error) {
      // let errorMessageToShow = "Sorry an error occurred!";
      console.log("ERROR", error);
      const { response } = error;
      // if(response && response.data && response.data.message){
      //   errorMessageToShow = response.data.message;
      // }
      this.setState({
        processingOrder: false
      })
      this.handleErrorToastInAddOrder(response);
      // this.props.setMsgAndShow(errorMessageToShow);
    }


  }


  resetEndDateAndAdditionalBookings = () => {
    try {
      const { change } = this.props;
      change("endDate", null)
      change("additionalBookings", null);
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  }


  onSelectFinish = () => {
    try {
      // const { change } = this.props;

      // change("endDate", null)
      // change("additionalBookings", null);
      this.resetEndDateAndAdditionalBookings();
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  }

  onFreqSelect = (freq) => {
    try {
      const { change, formValues: { type } } = this.props;
      this.resetEndDateAndAdditionalBookings();
      if (type && type === "carpet") {
        change("cleaningType", { value: `carpet_${freq.mappedTo}` });
      }
    } catch (error) {
      console.log("ERROR while selecting fre", error);
    }
  }

  getDateChange = () => {
    try {
      const { change } = this.props;
      change("endDate", null)
      change("additionalBookings", null);
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  }
  submitData = (obj) => {
    this.processOrder(obj);
  }

  decreaseByOne = type => () => {
    let value = this.props.formValues[type];
    value = value === 1 ? value : value - 1;
    this.props.change(type, value);
  };

  increaseByOne = type => () => {
    let value = this.props.formValues[type];
    value = value + 1;
    this.props.change(type, value);
  };


  getServedContractors = async (customerId, q) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/customers/list_served_contractors`, {
        params: {
          customerId,
          q
        }
      });

      const { contractors } = res.data;

      return contractors;
    } catch (error) {
      throw error;
    }
  }

  createOptionsFromContractorsList = (contractorsList) => {
    try {
      let contractorsOptions = [];
      if (contractorsList && contractorsList.length) {
        contractorsOptions = contractorsList.map((c) => ({ label: c.businessName, value: c._id }));
      }
      return contractorsOptions;
    } catch (error) {
      throw error;
    }
  }

  getServedContractorsOptions = async (customerId) => {
    try {
      const contractors = await this.getServedContractors(customerId);
      const contractorsServedOptions = this.createOptionsFromContractorsList(contractors);
      let showServedContractors = false;
      if (contractorsServedOptions && contractorsServedOptions.length) {
        showServedContractors = true;
      }

      this.setState({
        contractorsServedOptions,
        showServedContractors
      })
    } catch (error) {

      let errMsg = "Unable to get contractors list!";
      const { response } = error;

      if (response && response.data && response.data.message) {
        errMsg = response.data.message;
      }
      this.props.setMsgAndShow(errMsg);

    }
  }


  selectCustomer = (customer) => {
    const { change } = this.props;
    if (!isEmpty(customer)) {
      change("serviceAddress.fname", customer.fname);
      change("serviceAddress.lname", customer.lname);
      change("serviceAddress.phone", customer.phone);
      change("serviceAddress.email", customer.email);
      this.getServedContractorsOptions(customer._id);
      this.showBookingsModal(customer);
    }
  }

  // processPropertyDetails = async (serviceAddress, orderType) => {
  //   // let resBody = {}
  //   let address = "";
  //   // const response = {};
  //   let imagesData = {}, finishedSqFt = null, bedrooms = 0, bathrooms = 0, foundInfo = false;
  //   try {

  //     address = serviceAddress.address;
  //     if (serviceAddress.unit) {
  //       let { unit } = serviceAddress
  //       unit = unit.replace(/[^a-zA-Z0-9]/g, "");
  //       address += ` UNIT ${unit}`;
  //     }
  //     address += `, ${serviceAddress.city}, ${serviceAddress.state.value}`;
  //     // response.address = address;
  //     const { data: { result } } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}address/get_info`, {
  //       address,
  //       zipcode: serviceAddress.zipcode,
  //       type: orderType === "carpet" ? "image" : "detailed"
  //     })



  //     imagesData = result.imagesData;
  //     finishedSqFt = result.finishedSqFt;
  //     bedrooms = result.bedrooms;
  //     bathrooms = result.bathrooms;
  //     if ((result && result.finishedSqFt) || (result && !isEmpty(result.imagesData))) {
  //       foundInfo = true;
  //     }

  //     // if((result && result.finishedSqFt) || (result && !isEmpty(result.imagesData)) ) { 
  //     // }
  //   } catch (error) {
  //     throw error;
  //   }

  //   return {
  //     foundInfo,
  //     imagesData,
  //     finishedSqFt,
  //     bedrooms,
  //     bathrooms
  //   }


  // }

  getPropertyDetails = async () => {

    try {
      const { formValues, change } = this.props;
      if (isEmpty(formValues)) {
        alert("Please add service address!");
        return;
      }
      let successMsgToShow = "Property details not found!";

      const { serviceAddress, type } = formValues;

      if (isEmpty(serviceAddress) || !serviceAddress.address || !serviceAddress.zipcode || !serviceAddress.city || !serviceAddress.state) {
        alert("Please provide service address!");
        return
      }

      this.setState({
        gettingPropertyData: true
      })

      const propertyRes = await getPropertyInfo(serviceAddress, type, `${process.env.REACT_APP_API_BASE_URL}address/get_info`);
      const { finishedSqFt, bedrooms, bathrooms, imagesData, foundInfo } = propertyRes;

      if (foundInfo) {
        successMsgToShow = "Found property details!";
        if (type === "domestic") {
          if (bedrooms) {
            change("bedrooms", Math.floor(bedrooms))
          }
          if (bathrooms) {
            change("bathrooms", Math.floor(bathrooms))
          }
        }

        if (finishedSqFt) {
          change("serviceAddress.propertyArea", finishedSqFt);
        }

        if (!isEmpty(imagesData)) {
          change("imagesData", imagesData);
        }
      }


      this.setState({
        gettingPropertyData: false
      })

      this.props.setMsgAndShow(successMsgToShow);


    } catch (error) {
      let messageToShow = "Sorry an error occurred!";
      const { response } = error;

      if (response && response.status && (response.status === 400 || response.status === 404)) {
        messageToShow = "Property details not found!";
      }
      this.props.setMsgAndShow(messageToShow);
      this.setState({
        gettingPropertyData: false
      })
    }
  }

  getEndDateValue = (endDate) => {
    // const {  trackPickerEvent } = this.props;
    try {
      const { formValues, change } = this.props;
      const { cleaningType, date: startDate, time, frequency, type, } = formValues;
      let datesToSet = undefined;
      if (type === "domestic") {
        datesToSet = getDomesticAdditionalBookings({
          startDate,
          endDate,
          time,
          cleaningType
        });
      } else {
        datesToSet = getAdditionalBookings({
          startDate,
          endDate,
          time,
          frequency
        });
      }

      if (Array.isArray(datesToSet) && datesToSet.length) {
        change("additionalBookings", datesToSet);
      } else {
        // change("endDate", undefined);
        change("additionalBookings", null);
      }
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  }

  // getDomesticDates = (params) => {
  //   try {
  //     let dataToSet = undefined;
  //     const { cleaningType, startDate, endDate, time } = params;

  //     // change("additionalBookings",undefined);
  //     if (!isEmpty(cleaningType)) {
  //       let diffUnit;
  //       let startOfUnit;
  //       let recursion = 1;
  //       if (cleaningType.isEveryWeek) {
  //         diffUnit = "weeks";
  //         startOfUnit = "isoWeek";
  //       } else if (cleaningType.isBiWeekly) {
  //         diffUnit = "weeks";
  //         startOfUnit = "isoWeek";
  //         recursion = 2;
  //       } else if (cleaningType.isOnceAMonth) {
  //         startOfUnit = "month";
  //         diffUnit = "months";
  //       }
  //       if (startDate && endDate && time) {
  //         dataToSet = [];
  //         const localStartDate = moment(startDate).startOf(startOfUnit).format("YYYY-MM-DD");
  //         const datesDiff = (moment(endDate).diff(localStartDate, diffUnit));
  //         const diff = Math.floor(datesDiff / recursion);

  //         let sDate = startDate;
  //         for (let i = 0; i < diff; i++) {
  //           sDate = moment(sDate).add(recursion, diffUnit).format("YYYY-MM-DD")
  //           if (diff - 1 === i) {
  //             sDate = moment(sDate).isBefore(endDate) ? sDate : endDate;
  //           }
  //           dataToSet.push({
  //             time: time,
  //             date: sDate
  //           });
  //         }
  //       }
  //     }
  //     return dataToSet;
  //   } catch (error) {
  //     console.error(error);// eslint-disable-line
  //   }
  // }
  // getCommercialDates = (params) => {

  //   try {
  //     const { frequency, startDate, endDate, time } = params;
  //     let dataToSet = undefined;
  //     let removeFirstDate = false;
  //     if (!isEmpty(frequency)) {
  //       // let daysToAdd = 1;
  //       let diffUnit = "weeks";
  //       let unitTypeToAdd = "weeks";
  //       let startOfUnit = "isoWeek";
  //       let daysToAdd = 1;
  //       // let recursion = 1;
  //       let recursion = frequency.frequency;

  //       if (frequency.period === "month") {
  //         unitTypeToAdd = "months";
  //         startOfUnit = "month";
  //         diffUnit = "months";
  //       }

  //       if (frequency.period === "daysInWeek") {
  //         // unitTypeToAdd = "weeks";
  //         daysToAdd = frequency.frequency;
  //         recursion = 1;
  //         removeFirstDate = true;
  //       }


  //       // switch (frequency.occurence) {
  //       //   case "everyWeek":
  //       //     daysToAdd = 1;
  //       //     break;
  //       //   case "biWeekly":
  //       //     daysToAdd = 2;
  //       //     removeFirstDate = true;
  //       //     break;
  //       //   case "everyOtherWeek":
  //       //     recursion = 2;
  //       //     break;
  //       //   case "triWeekly":
  //       //     daysToAdd = 3;
  //       //     removeFirstDate = true;
  //       //     break;
  //       //   case "onceAMonth":
  //       //     unitTypeToAdd = "months";
  //       //     startOfUnit = "month";
  //       //     diffUnit = "months";
  //       //     break;
  //       //   default:
  //       //     break;
  //       // }


  //       if (startDate && endDate && time) {
  //         dataToSet = [];
  //         const localStartDate = moment(startDate).startOf(startOfUnit).format("YYYY-MM-DD");
  //         const datesDiff = moment(endDate).diff(localStartDate, diffUnit);
  //         const diff = Math.floor(datesDiff / recursion);
  //         let sDate = startDate;


  //         for (let i = 0; i < diff; i++) {
  //           // if (unitTypeToAdd === "weeks" && daysToAdd > 1) {
  //           //   const startOfWeek = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
  //           //   const endOfWeek = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");
  //           //   const nextWeek = moment(sDate).add(recursion, unitTypeToAdd).startOf(unitTypeToAdd).add(1, 'day').format("YYYY-MM-DD");
  //           //   for (let dayToAdd = 1; dayToAdd <= daysToAdd; dayToAdd++) {
  //           //     if (dayToAdd > 1) {
  //           //       sDate = moment(sDate).add(1, 'day').format("YYYY-MM-DD");
  //           //     } else {
  //           //       sDate = moment(sDate).format("YYYY-MM-DD");
  //           //     }
  //           //     if(compareDates(startOfWeek,endOfWeek,sDate)){
  //           //       dataToSet.push({
  //           //         time: time,
  //           //         date: sDate
  //           //       });
  //           //     }
  //           //   }
  //           //   sDate = nextWeek;
  //           // } 

  //           if (unitTypeToAdd === "weeks" && daysToAdd > 1) {
  //             const startOfWeek = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
  //             const endOfWeek = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");

  //             const nextWeek = moment(sDate).add(recursion, unitTypeToAdd).startOf(unitTypeToAdd).add(1, 'day').format("YYYY-MM-DD");

  //             for (let dayToAdd = 1; dayToAdd <= daysToAdd; dayToAdd++) {
  //               if (dayToAdd > 1) {
  //                 sDate = moment(sDate).add(1, 'day').format("YYYY-MM-DD");
  //               } else {
  //                 sDate = moment(sDate).format("YYYY-MM-DD");
  //               }
  //               if (compareDates(startOfWeek, endOfWeek, sDate)) {
  //                 dataToSet.push({
  //                   time: time,
  //                   date: sDate
  //                 });
  //               }
  //             }
  //             sDate = nextWeek;
  //           } else {
  //             sDate = moment(sDate).add(recursion, unitTypeToAdd).format("YYYY-MM-DD");
  //             if (diff - 1 === i) {
  //               sDate = moment(sDate).isBefore(endDate) ? sDate : endDate;
  //             }
  //             dataToSet.push({
  //               time: time,
  //               date: sDate
  //             });
  //           }

  //           // if (unitTypeToAdd === "days" && daysToAdd > 1) {
  //           //   const startOfWeek = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
  //           //   const endOfWeek = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");
  //           //   const nextWeek = moment(sDate).add(recursion, unitTypeToAdd).startOf(unitTypeToAdd).add(1, 'day').format("YYYY-MM-DD");
  //           //   for (let dayToAdd = 1; dayToAdd <= daysToAdd; dayToAdd++) {
  //           //     if (dayToAdd > 1) {
  //           //       sDate = moment(sDate).add(1, 'day').format("YYYY-MM-DD");
  //           //     } else {
  //           //       sDate = moment(sDate).format("YYYY-MM-DD");
  //           //     }
  //           //     if(compareDates(startOfWeek,endOfWeek,sDate)){
  //           //       dataToSet.push({
  //           //         time: time,
  //           //         date: sDate
  //           //       });
  //           //     }
  //           //   }
  //           //   sDate = nextWeek;
  //           // } 


  //         }
  //       }
  //     }
  //     if (removeFirstDate) {
  //       dataToSet = dataToSet.slice(1);

  //     }
  //     return dataToSet;

  //   } catch (error) {
  //     console.error(error);// eslint-disable-line
  //   }
  // }

  findCustomers = async (q) => {
    try {
      let customersOptions = [];
      const customersRes = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/customers/search`, {
        params: {
          q
        }
      });

      if (customersRes && customersRes.data && customersRes.data.customers) {
        const customers = customersRes.data.customers;

        customers.forEach((customer) => {
          customersOptions.push({
            ...customer,
            label: customer.fullName,
            value: customer._id,
          })
        })
      }
      return customersOptions;
    } catch (error) {
      console.log("Error", error);
    }
  }

  verifyCouponCode = async () => {
    const { change } = this.props;
    const { couponCode, customer } = this.props.formValues;

    if (!couponCode) {
      alert("Enter coupon code first");
      return
    }

    const checkCouponData = {
      code: couponCode,
      source: "web",
      order: this.props.formValues
    };

    if (!isEmpty(customer)) {
      checkCouponData.order = {
        ...checkCouponData.order,
        customerId: customer._id
      };
    }

    let messageToShow = "Sorry an error occurred";
    try {
      this.setState({
        gettingCouponData: true
      });

      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}coupon/check`, checkCouponData);

      const { status, coupon, message } = res.data;

      if (status === "OK") {
        change("couponData", coupon);
        change("couponCode", "");
        messageToShow = "Coupon Applied!";
      } else {
        messageToShow = message;
      }

      this.setState({
        gettingCouponData: false
      })

      this.props.setMsgAndShow(messageToShow)
    } catch (error) {
      const { response } = error;

      if (response && response.data && response.data.message) {
        messageToShow = response.data.message;
      }

      this.setState({
        gettingCouponData: false
      })

      this.props.setMsgAndShow(messageToShow)

      // const  errorMsg = handleErrorResponse(error);
    }
  }

  handleRadioOptions = (e) => ({
    name: e.target.name,
    value: e.target.value
  });

  onOrderTypeChange = (e) => {
    const { name, value } = this.handleRadioOptions(e);
    this.props.reset();
    this.doNotUpdateCost = false;
    if (value === "carpet") {
      this.props.change("bedrooms", null);
      this.props.change("bathrooms", null);
    }
    this.props.change(name, value);
  };

  onOrderSourceChange = (e) => {
    const { name, value } = this.handleRadioOptions(e);
    this.props.change(name, value);
  };


  onAdminCostKeyChange = (e) => {
    const doNotUpdateCost = this.doNotUpdateCost;
    if ((isNumberKey(e) || isBackspaceOrDelKey(e)) && !doNotUpdateCost) {
      this.doNotUpdateCost = true;
    }
  }

  showBookingsModal = (selectedCustomer) => this.setState({
    selectedCustomer,
    showBookingSelectModal: true
  });

  closeBookingSelectModal = () => this.setState({
    selectedCustomer: {},
    showBookingSelectModal: false
  });


  onZipcodeBlur = async (e) => {

    let gettingPriceList = true;
    try {
      const zipcode = e.target.value;
      const type = this.props.formValues.type;
      this.setState({ gettingPriceList });
      const res = await getPricingList({ zipcode, type });
      gettingPriceList = false;
      if (res && res.priceList) {
        this.props.setPriceList(res.priceList);
      }
    } catch (error) {
      gettingPriceList = false;
      this.props.setMsgAndShow("Error occurred while getting pricing list.");
    }
    this.setState({ gettingPriceList });
  }
  setSelectedCustomerInCustomersList = (selectedCustomer) => {
    selectedCustomer = {
      ...selectedCustomer,
      label: selectedCustomer.fullName,
      value: selectedCustomer._id,
    };
    this.props.change("customer", selectedCustomer);
  };

  onRehireOrderChange = (e) => {
    const { name, value } = this.handleRadioOptions(e);
    this.props.change(name, value);
  }

  searchContractors = async (q) => {
    try {
      const customerId = this.props.formValues.customer._id;
      const contractors = await this.getServedContractors(customerId, q)
      const contractorsServedOptions = this.createOptionsFromContractorsList(contractors);
      this.setState({
        contractorsServedOptions
      })
    } catch (error) {
      let errMsg = "Unable to get contractors list!";
      const { response } = error;

      if (response && response.data && response.data.message) {
        errMsg = response.data.message;
      }
      this.props.setMsgAndShow(errMsg);
    }
  }


  onCleaningAreaSelect = (event, newValue, previousValue, name) => {
    const cleaningAreaIndex =  parseInt(name.replace(/[^\d]/g, ''));
    this.props.change(`cleaningAreas[${cleaningAreaIndex}].count`,1);
    if( cleaningAreaIndex === this.props.formValues.cleaningAreas.length -1 ){
      this.addCleaningAreaInput()
    }
    // const selecetdproduct = name.split(".");
    // this.props.change(`${selecetdproduct[0]}.description`, newValue.description)
    // this.props.change(`${selecetdproduct[0]}.rate`, newValue.rate)
    // this.props.change(`${selecetdproduct[0]}.quantity`, newValue.quantity)
  }

  addCleaningAreaInput = () => {
    this.props.addInput("cleaningAreas",{});
  }

  clearCleaningAreaInputs = () => {
    this.props.change("cleaningAreas",[{}]);
  }

  addCarpetAddOnInput = () => {
    this.props.addInput("carpetAddOns",{})
  }

  clearCarpetAddOns = () => {
    this.props.change("carpetAddOns",[{}]);
  }


  onCarpetAddOnSelect = (event, newValue, previousValue, name) => {
    const carpetAddOnIndex =  parseInt(name.replace(/[^\d]/g, ''));
    this.props.change(`carpetAddOns[${carpetAddOnIndex}].count`,1);
    if( carpetAddOnIndex === this.props.formValues.carpetAddOns.length -1 ){
      this.addCarpetAddOnInput();
    }
  }

  onAddCounter = (fieldName, currentValue, currentIndex) => {
    const newValue = currentValue && currentValue.count ? parseInt(currentValue.count) + 1 : 1;
    this.props.change(`${fieldName}[${currentIndex}].count`, newValue);
  }

  onDecreaseCounter = (fieldName, currentValue, currentIndex) => {
    const currCount = currentValue && currentValue.count ? parseInt(currentValue.count) : 1;
    this.props.change(`${fieldName}[${currentIndex}].count`, Math.max(1,currCount - 1));
  }

  render() {

    return (
      <>
        <AddJobModal
          {...this.state}
          {...this.props}
          closeModal={this.closeModal}
          submitData={this.submitData}
          increaseByOne={this.increaseByOne}
          decreaseByOne={this.decreaseByOne}
          customersList={this.state.customers}
          selectCustomer={this.selectCustomer}
          getPropertyDetails={this.getPropertyDetails}
          getEndDateValue={this.getEndDateValue}
          onSelectFinish={this.onSelectFinish}
          getDateChange={this.getDateChange}
          findCustomers={this.findCustomers}
          verifyCouponCode={this.verifyCouponCode}
          onOrderTypeChange={this.onOrderTypeChange}
          onOrderSourceChange={this.onOrderSourceChange}
          onAdminCostKeyChange={this.onAdminCostKeyChange}
          onRehireOptionChange={this.onRehireOrderChange}
          searchContractors={this.searchContractors}
          onZipcodeBlur={this.onZipcodeBlur}
          onFreqSelect={this.onFreqSelect}
          onCleaningAreaSelect={this.onCleaningAreaSelect}
          clearCleaningAreaInputs={this.clearCleaningAreaInputs}
          addCleaningAreaInput={this.addCleaningAreaInput}
          addCarpetAddOnInput={this.addCarpetAddOnInput}
          clearCarpetAddOns={this.clearCarpetAddOns}
          onCarpetAddOnSelect={this.onCarpetAddOnSelect}
          onAddCount={this.onAddCounter}
          onDecreaseCount={this.onDecreaseCounter}
        />
        <PreviousBookingsModal
          {...this.state}
          {...this.props}
          closeBookingSelectModal={this.closeBookingSelectModal}
          setSelectedCustomerInCustomersList={this.setSelectedCustomerInCustomersList}
        />
      </>
    );
  }
}


const WithFormComponent = reduxForm({
  form: "OrderForm",
  destroyOnUnmount: true,
  enableReinitialize: true,
  initialValues: {
    bedrooms: 1,
    bathrooms: 1,
    type: "domestic",
    cleaningAreas:[{}],
    carpetAddOns:[{}]
  }
})(AddJobModalContainer);

const mapStateToProps = (state) => {
  let formValues = {};
  if (state.form.OrderForm) {
    formValues = state.form.OrderForm.values;
  }
  return {
    domesticOpts: state.app.cleaningOpts.domestic,
    commercialOpts: state.app.cleaningOpts.commercial,
    priceList: state.app.cleaningOpts.priceList,
    frequencies: state.app.cleaningOpts.frequencies,
    carpetOpts: state.app.cleaningOpts.carpet,
    formValues,
    // initialValues:state.formData.formData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (message) => dispatch(setMsgAndShow(message)),
  storeDomesticPriceList: (list) => dispatch(storeDomesticPriceList(list)),
  setPriceList: (list) => dispatch(setPriceList(list)),
  addInput:(fieldName,value) => dispatch(arrayPush("OrderForm",fieldName,value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WithFormComponent);