import React from "react";
import { MainLayout } from "../layouts";
import JobsDashboardContainer from "../containers/JobsDashboardContainer";




const JobsDashboardPage = (props) => {
  return (
    <MainLayout pageName="Jobs">
      <JobsDashboardContainer {...props}/>
    </MainLayout>
  )
}



export default JobsDashboardPage;