import React from "react";
import { withStyles } from "@material-ui/core/styles";
const TextAreaField = ({ rows, name, placeholder, onClick, input, classes, ...rem }) => {
  return (
    <textarea
      className={classes.textAreaInput}
      onClick={onClick}
      rows={rows}
      name={name}
      placeholder={placeholder}
      {...input}
      {...rem}
    />
  );
};


const styles = theme => ({
  textAreaInput:{
    backgroundColor:"#fafafa",
    border: "1px solid #d5d5d5",
    width:"100%",
    padding: "1.2rem 1rem",
    fontSize:16
  }
})


export default withStyles(styles)(TextAreaField)