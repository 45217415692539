import axios from "utils/http";



export const getList = async ({
  page,
  limit,
  q
}) => {
  try {

    q = q && q.trim();
    if(q){
      q = q.toLowerCase();
    }
    const res = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/managers/list`,{
      params:{
        page,
        limit,
        q
      }
    })

    return res.data;
  } catch (error) {
    throw error;
  }
}