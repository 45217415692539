import React from "react"; 
import Table from "../TableWithPagination";
import Loader from "../ProgressCircle";
import { withStyles } from "@material-ui/core/styles";
import SearchBar from "../SearchBar";
// Custom Components

// import DataList from "../DataList";
// import AddJobModal from "../AddJobModal";

// import contractorItemProps from "./contractorItemProps";




const headers = [
  {
    name: "businessId",
    label: "Business ID"
  },
  {
    name:"businessName",
    label:"Business Name"
  },
  {
    name: "flagsInfo",
    label: "Flags"
  },
  {
    name: "lastFlagDate",
    label: "Last Flagged On"
  },
  {
    name: "actions",
    label: "Actions"
  }

  // {
  //   name: "zipcode",
  //   label: "Zipcode"
  // },
  // {
  //   name:"businessPhone",
  //   label:"Phone"
  // },
  // {
  //   name:"ratings",
  //   label:"Ratings"
  // },
  // {
  //   name: "actions",
  //   label: "Actions"
  // }
];
 





// const columnProps = [
//   {
//     name:'displayId'
//   },
//   {
//     name: "createdat",
//     isTimeStamp: true,
//     formatRequired:"DD MMM YYYY"
//   },
//   {
//     name: "date",
//     isDate: true,
//     inFormat:"YYYY-MM-DD",
//     formatRequired:"DD MMM YYYY"
//   },
//   {
//     name: "time",
//     isTime:true,
//     inFormat:"hh:mm:ss",
//     formatRequired:"hh:mm A"
//   },
//   {
//     name: "orderCost",
//   },
//   {
//     name: "orderStatus",
//     isStatus: true,
//     capitalize: (string) => capitalizeFirstLetterOnly(string)
//   },
// ];
const columnProps = [
  {
    name: "businessId"
  },
  {
    name: "businessName",
  },
  {
    name:"flagsInfo",
    isFlagsRow:true,
    firstFlagKey:"redFlagCount",
    secondFlagKey:"orangeFlagCount",
    firstFlagColor:"red",
    secondFlagColor:"orange",
  },
  {
    name:"lastFlagDate",
  },

  // {
  //   name:"businessType",
  //   isList: true,
  //   joinList: (list) => list.join(",")
  // },
  // {
  //   name:"zipcode"
  // },
  // {
  //   name:"businessPhone"
  // },
  // {
  //   name:"ratings",
  //   isRatings:true,
  //   outOf:5
  // }
]

const styles = theme => ({
  root: {
    flexGrow: 1,
    // overflow: "hidden",
    flexDirection:"column",
    display: "flex",
  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  }
});

const JobsDashboard = ({
  jobsObj,
  jobItemIndex,
  open,
  btnRef,
  handleMenuClose,
  show,
  isModalOpen,
  classes,
  handleModalClose,
  changeList,
  openModal,
  newJobOrders,
  contractorActions,
  acceptedJobOrders,
  acceptedJobActions,
  todaysJobActions,
  todaysJobOrders,
  handleSubmit,
  handleSave,
  formValues,
  increaseByOne,
  decreaseByOne,
  submitData,
  getContractorDetails,
  contractors,
  getSearch, 
  load,
  totalCount,
  getContractors,
  page,

  searchValue,
  clearSearch,
  searchInput,


  q
}) => {
  return (
    <div className={classes.root}>
        {/* <SearchBar 
          placeholder="Search business by zipcode, business name. owner's email"
          onChange={getSearch}

          query={searchValue}
          onClear={clearSearch}
          inputRef={searchInput}
        /> */}

        { load && <Loader />}

        <Table
          list={contractors}
          totalCount={totalCount}
          actions={contractorActions}
          handlePageChange={getContractors}
          load={load}
          page={page}
          headers={headers}
          columnProps={columnProps}
        />
    </div>
  );
};



export default withStyles(styles)(JobsDashboard);
