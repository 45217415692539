import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {appBar, drawerWidth} from "theme/vars"

const styles = theme => ({
  mainContent:{
    marginTop: appBar,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: "15px 15px 0px",
    width: window.innerWidth - drawerWidth,
    position: "relative"
  },
  footer: {
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footerText:{
    color:"#9b9b9b"
  }
})

class MainContent extends Component {
  render(){
    const { classes } = this.props;
    return (
      <div className={classes.mainContent}>
        {this.props.children}
        <footer className={classes.footer}>
         <p className={classes.footerText}>  &copy; 2018 Zoklean. All rights reserved </p>
        </footer>
      </div>
    )
  }
}

export default withStyles(styles)(MainContent);