import { SET_DETAILS, SET_ADMIN_INFO, SET_ORDER_HISTORY } from "../types";
import _ from "lodash";

const defaultState = {
  adminInfo:{},
}



const reducer = (state = defaultState, action) => {
    let finalState = {}

    switch(action.type){
      case SET_DETAILS:
      finalState = _.cloneDeep(state)
      finalState.userInfo = _.cloneDeep(action.payload[0])
      finalState.businessDetails = _.cloneDeep(action.payload[1])
      finalState.orderHistory = _.cloneDeep(action.payload[2])
      break;
      case SET_ADMIN_INFO:
      finalState = _.cloneDeep(state)
      finalState.adminInfo = _.cloneDeep(action.payload);
      break;
      case SET_ORDER_HISTORY:
      finalState = _.cloneDeep(state);
      finalState.orderHistory = _.cloneDeep(action.payload);
      break;
      default:
      finalState = state
    }

    return finalState
}



export default reducer;