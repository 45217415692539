import React from "react";
// import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
import Table from "../TableWithPagination";
import Loader from "../ProgressCircle";
import { withStyles } from "@material-ui/core/styles";
import { capitalizeFirstLetterOnly } from "../../utils/capitalize";
import GreenCheckBox from "../FormField/GreenCheckBox";
import ExportListBtn from "../ExportListBtn";
// Custom Components

import SearchBar from "../SearchBar";
import DatePicker from "../../components/DatePicker";

import Header from "../../containers/HeaderContainer";
import SideDrawer from "../SideDrawer";
import MainContent from "../MainContent";
import FabButton from "core/components/FabButton";
import Calender from "mdi-material-ui/Calendar";
import {
  FormControl,
  Grid, 
  FormControlLabel
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { MaterialTextField } from "../FormField";


// import DataList from "../DataList";
// import AddJobModal from "../AddJobModal";

// import jobItemProps from "./jobItemProps";

const headers = [
  {
    name: "displayId",
    label: "Order ID"
  },
  {
    name:"createdat",
    label:"Date Of Booking"
  },
  {
    name: "date",
    label: "Date Of Cleaning"
  },
  {
    name: "time",
    label: "Time"
  },
  {
    name: "orderCost",
    label: "Cost ($)"
  },
  {
    name:"orderStatus",
    label:"Status"
  },
  {
    name:"customerHasPaymentMethod",
    label:"Payment Method",
  },
  {
    name: "actions",
    label: "Actions"
  }
];

const columnProps = [
  {
    name:"displayId"
  },
  {
    name: "createdat",
    isTimeStamp: true,
    formatRequired:"DD MMM YYYY"
  },
  {
    name: "date",
    isDate: true,
    inFormat:"YYYY-MM-DD",
    formatRequired:"DD MMM YYYY"
  },
  {
    name: "time",
    isTime:true,
    inFormat:"hh:mm:ss",
    formatRequired:"hh:mm A"
  },
  {
    name: "orderCost",
  },
  {
    name: "orderStatus",
    isStatus: true,
    capitalize: (string) => capitalizeFirstLetterOnly(string)
  },
  {
    name:"customerHasPaymentMethod",
    isBoolean:true,
    isTrue:"Yes",
    isFalse:"No"
  }
];

const styles = theme => ({
  root: {},
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  padTop: {
    paddingTop: 10
  },
  setMargin_10:{
    marginLeft: 20
  },
  topSpace:{
    marginTop: 18
  },
  disableMargin: {
    margin: 0
  }
});


const JobsDashboard = ({
  jobsObj,
  jobItemIndex,
  open,
  btnRef,
  handleMenuClose,
  show,
  isModalOpen,
  classes,
  handleModalClose,
  handleSelectChange,
  changeList,
  openModal,
  newJobOrders,
  newJobActions,
  acceptedJobOrders,
  acceptedJobActions,
  todaysJobActions,
  todaysJobOrders,
  handleSubmit,
  handleFilter,
  handleSave,
  formValues,
  increaseByOne,
  decreaseByOne,
  submitData,
  getJobDetails,
  getSearch,
  actions,
  totalCount,
  getOrders,
  load,
  jobCompleted,
  jobId,
  status,
  startDate,
  endDate,
  showDateRangeInput,

  
  searchValue,
  clearSearch,
  searchInput,
  handleDateChange,
  handleDateRange,
  page,
  ...props
}) => {

  return (
    <div className={classes.root}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item container md={9} sm={12} alignItems="baseline" spacing={16}>
            <Grid item md={3} >
              <SearchBar 
                placeholder="Search Job by ID, address, customer's name or email."
                onChange={getSearch}
                query={searchValue}
                onClear={clearSearch}
                inputRef={searchInput}
              />
            </Grid>
            <Grid item >
              <FormControl className={classes.formControl}>
                <Select
                  value={status}
                  onChange={handleFilter}
                  name="filter"
                  displayEmpty
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value={"assigned"}>In Progress</MenuItem>
                  <MenuItem value={"completed"}>Completed</MenuItem>
                  <MenuItem value={"accepted"}>Accepted</MenuItem>
                  <MenuItem value={"confirmed"}>Pending</MenuItem>
                  <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                </Select>
              </FormControl>

            </Grid>
            <Grid item  >
              <DatePicker 
                name="startDate"
                getDate={handleDateChange("startDate")}
                label={ showDateRangeInput ? "From" : "Select Date"}
                shrink
                value={startDate}
                material
                placeholder="YYYY-MM-DD"
                rightIcon="date"
                leftIcon={false}
                isNotMinDate
              />
            </Grid>
            {
                showDateRangeInput &&
                  <Grid item >
                    <DatePicker 
                      name="endDate"
                      getDate={handleDateChange("endDate")}
                      value={endDate}
                      shrink
                      material
                      placeholder="YYYY-MM-DD"
                      label="To"
                      rightIcon="date"
                      shrink
                      isNotMinDate
                    />
                  </Grid>
              }

              <Grid item >
                <FormControlLabel
                  control={<GreenCheckBox checked={showDateRangeInput} onChange={handleDateRange} name="dateRange"/>}
                  label="Date range"
                />
              </Grid>
          </Grid>

          <Grid item >
            <ExportListBtn 
              requestUrl={`${process.env.REACT_APP_API_WITH_PREFIX}admin/jobs/list/export`}
              params={{
                q:searchValue,
                status,
                startDate,
                endDate
              }}
              title="Download List"
            />
          </Grid>

        
        </Grid>

        {(load || jobCompleted) && <Loader/>}
        
        <Table
          list={jobsObj.allJobs}
          totalCount={totalCount}
          actions={newJobActions}
          handlePageChange={getOrders}
          page={page}
          headers={headers}
          columnProps={columnProps}
          className={classes.topSpace}
        />

      <FabButton onClick={openModal} />
    </div>
  );
};


export default withStyles(styles)(JobsDashboard);