import React from "react";
import moment from "moment-timezone";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { muted } from "theme/colors";
import { withStyles } from "@material-ui/core/styles";
import { capitalizeFirstLetterOnly } from "../../utils/capitalize";
import Rating from "react-rating";
import { FaStar, FaRegStar } from "react-icons/fa";
import _, { isEmpty } from "lodash";
import Loader from "../ProgressCircle";
import { getJobTrackingDetails } from "../../utils/JobDetailsHelper";
import { getDomesticCleaningType } from "./cleaningTypes";
import OptionList from "./OptionList";
import AdditionalBookingsList from "./AdditionalBookingsList";
import AssignedCleanerInfo from "../AssignedCleanersInfo";
import CleaningAreaOption from "../CleaningAreaOption";
import CarpetAddOns from "../CarpetAddOns";

const styles = theme => ({
  mutedHeading: {
    color: muted,
    fontWeight: 300
  },

  setMarginBottom_12: {
    marginBottom: 12
  },
  zokFeeInfoCont:{
    marginBottom:15
  }
});

// const renderEstimate = (jobObj) => {
//   if(jobObj.orderData){
//     return `${jobObj.orderData.etc} Mins`
//   }
//   return `${jobObj.etc} Mins`
// }

const JobDetailsModal = ({
  classes,
  isJobDetailsModalOpen,
  isRejectLoaderOn,
  jobItemObj,
  // handleJobCompleted,
  // acceptJob,
  // rejectJob,
  handleJobDetailsModal,
  // doNotShowJobBtns,
  cleaningTypes
}) => {
  const {
    serviceAddress,
    addOns,
    // contractorId,
    completionCode,
    contractorDetails,
    rejectedBy,
    reviewObj,
    type,
    assignedEmployeesDetails,
    zokFeeInfo
  } = jobItemObj;

  const { jobOrigin, deviceUsedForBooking, referrer } = getJobTrackingDetails(
    jobItemObj
  );

  let { additionalBookings } = jobItemObj;
  additionalBookings = (Array.isArray(additionalBookings) && additionalBookings.length) ? additionalBookings : [];

  const isDomestic = jobItemObj.type === "domestic";

  const cleaningTypeObj = getDomesticCleaningType(cleaningTypes);

  return (
    <Dialog
      fullWidth
      open={isJobDetailsModalOpen}
      onClose={handleJobDetailsModal}
    >
      <DialogTitle id="job-modal-title">Job Details</DialogTitle>
      <DialogContent>
        <Grid container alignContent="center" spacing={16}>
          <Grid item xs>
            <Typography variant="subheading" className={classes.mutedHeading}>
              Contractor Information
            </Typography>
            {
              !_.isEmpty(contractorDetails) ? (
                <Grid item container direction="column">
                  {/* <Grid item>Business Id: {contractorDetails ? contractorDetails.businessId : null}</Grid> */}
                  <Grid item>
                    Business Name:{" "}
                    {contractorDetails ? contractorDetails.businessName : null}
                  </Grid>
                  <Grid item>
                    Owner Name:{" "}
                    {contractorDetails ? contractorDetails.ownerName : null}
                  </Grid>
                  {/* <Grid item>Office Phone: {contractorDetails ? contractorDetails.businessPhone : null}</Grid> */}
                  {/* <Grid item>Owner Cell: {contractorDetails ? contractorDetails.ownerPhone : null}</Grid> */}
                  <Grid item>
                    Email:{" "}
                    {contractorDetails ? contractorDetails.ownerEmail : null}
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <span>Not Available</span>
                </Grid>
              )
            }
          </Grid>
        </Grid>

        <AssignedCleanerInfo cleaners={assignedEmployeesDetails} />
        <Grid container alignItems="center" spacing={16}>
          <Grid item xs>
            <Typography variant="subheading" className={classes.mutedHeading}>
              Order Status
            </Typography>
            <span>{capitalizeFirstLetterOnly(jobItemObj.orderStatus)}</span>
          </Grid>
        </Grid>
        {
          completionCode ?
          <Grid container alignItems="center" spacing={16}>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Order OTP
              </Typography>
              <span>{completionCode}</span>
            </Grid>
          </Grid>
          : null

        }

        {!_.isEmpty(reviewObj) && (
          <Grid
            container
            alignContent="center"
            alignItems="center"
            spacing={16}
          >
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Customer Ratings :-
              </Typography>
              {
                reviewObj.customerRatings ? (
                  <Rating
                    stop={5}
                    initialRating={reviewObj.customerRatings}
                    fractions={10}
                    emptySymbol={<FaRegStar size={16} />}
                    fullSymbol={<FaStar color="#6610f2" size={16} />}
                    readonly
                  />
                ) : (
                  <span>No Ratings.</span>
                )
              }
            </Grid>
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Contractor Ratings :-
              </Typography>
              {
                reviewObj.contractorRatings ? (
                  <Rating
                    stop={5}
                    initialRating={reviewObj.contractorRatings}
                    fractions={10}
                    emptySymbol={<FaRegStar size={16} />}
                    fullSymbol={<FaStar color="#6610f2" size={16} />}
                    readonly
                  />
                ) : (
                  <span>No Ratings.</span>
                )
              }
            </Grid>
          </Grid>
        )}
        <Grid container alignContent="center" alignItems="center" spacing={16}>
          <Grid item xs>
            <Typography variant="subheading" className={classes.mutedHeading}>
              Job Date
            </Typography>
            <span>{moment(jobItemObj.date).format("DD MMM YYYY")}</span>
          </Grid>
          <Grid item xs>
            <Typography variant="subheading" className={classes.mutedHeading}>
              Job Time
            </Typography>
            <span>{moment(jobItemObj.time, "hh:mm:ss").format("hh:mm A")}</span>
          </Grid>
        </Grid>

        <Grid container alignContent="center" alignItems="center" spacing={16}>
          <Grid item xs>
            <Typography variant="subheading" className={classes.mutedHeading}>
              Job Type
            </Typography>
            <span>{capitalizeFirstLetterOnly(jobItemObj.type)}</span>
          </Grid>
          { 
            (isDomestic) 
              ? <Grid item xs>
                <Typography variant="subheading" className={classes.mutedHeading}>
                  Cleaning Type
                </Typography>
                <span>{cleaningTypeObj[jobItemObj.cleaningType]}</span>
              </Grid>
              : <Grid item xs>
                <Typography variant="subheading" className={classes.mutedHeading}>
                  Frequency
                </Typography>
                <span>{jobItemObj.frequency}</span>
              </Grid>
          }
        </Grid>

        {
          type === "carpet" ?
        <Grid container alignContent="center"  spacing={16}>
            <OptionList
              title = "Cleaning Areas"
              list = {jobItemObj.cleaningAreas}
              classes = {classes}
              OptionComponent={CleaningAreaOption}
            />
            <OptionList
              title = "Additional cleanings"
              list = {jobItemObj.carpetAddOns}
              classes = {classes}
              OptionComponent={CarpetAddOns}
            />
        </Grid>
          : null
        }


        {/* {
          <
        } */}

        <Grid container alignContent="center" spacing={16}>
          <Grid item xs>
            <Typography variant="subheading" className={classes.mutedHeading}>
              Customer Info
            </Typography>
            <Grid item container alignContent="center" direction="column">
              <Grid item>
                Name:{" "}
                {serviceAddress
                  ? `${serviceAddress.fname} ${serviceAddress.lname}`
                  : null}
              </Grid>
              <Grid item>
                Email: {serviceAddress ? serviceAddress.email : null}
              </Grid>
              <Grid item>
                Phone: {serviceAddress ? serviceAddress.phone : null}
              </Grid>
              <Grid item>User ID: {jobItemObj.userId}</Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Typography variant="subheading" className={classes.mutedHeading}>
              Service Address
            </Typography>
            <Grid item container direction="column">
              <Grid item xs>
                Address: {serviceAddress ? serviceAddress.address : null}
              </Grid>
              <Grid item xs>
                Unit: {serviceAddress && serviceAddress.unit ? serviceAddress.unit: 'N/A'}
              </Grid>
              <Grid item xs>
                City: {serviceAddress ? serviceAddress.city : null}
              </Grid>
              <Grid item xs>
                State: {serviceAddress ? serviceAddress.state : null}
              </Grid>
              <Grid item xs>
                Property Area: {serviceAddress && serviceAddress.propertyArea
                  ? serviceAddress.propertyArea
                  : "Not Given"}
              </Grid>
              <Grid item xs>
                Property Type:{" "}
                {serviceAddress && serviceAddress.propertyType
                  ? serviceAddress.propertyType
                  : "Not Given"}
              </Grid>
              <Grid item xs>
                Zipcode: {jobItemObj.zipcode}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {
          (jobItemObj.bathrooms || jobItemObj.bedrooms) &&
          <Grid container alignContent="center" alignItems="center" spacing={16}>
            { 
              jobItemObj.bedrooms &&
              <Grid item xs>
                <Typography variant="subheading" className={classes.mutedHeading}>
                  Bedrooms
                </Typography>
                <span>{jobItemObj.bedrooms}</span>
              </Grid>
            }
            { 
              jobItemObj.bathrooms &&
              <Grid item xs>
                <Typography variant="subheading" className={classes.mutedHeading}>
                  Bathrooms
                </Typography>
                <span>{jobItemObj.bathrooms}</span>
              </Grid>
            }
          </Grid>
        }

        <Grid container alignContent="center" spacing={16}>
          {
            type !== "carpet" ?
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Add Ons
              </Typography>

              {
                type === "commercial" ? (
                  <Grid item container direction="column">
                    {addOns && addOns.windowsRange ? 
                      <Grid item xs>
                        Windows:{" "}
                        {addOns.windowsRange}
                      </Grid>
                      : "N/A"
                      }
                  </Grid>
                ) : (
                  <Grid item container direction="column">
                    <Grid item xs>
                      Oven: {addOns && addOns.oven ? addOns.oven : "Not Selected"}
                    </Grid>
                    <Grid item xs>
                      Fridge: {addOns && addOns.fridge ? addOns.fridge : "Not Selected"}
                    </Grid>
                    <Grid item xs>
                      Blinds: {addOns && addOns.blinds ? addOns.blinds : "Not Selected"}
                    </Grid>
                    <Grid item xs>
                      Walls: {addOns && addOns.blinds ? addOns.blinds : "Not Selected"}
                    </Grid>
                    <Grid item xs>
                      Windows: {addOns && addOns.windows ? addOns.windows : "Not Selected"}
                    </Grid>
                    <Grid item xs>
                      Eco Friendly:{addOns &&  addOns.ecoFriendly ? addOns.ecoFriendly : "Not Selected"}
                    </Grid>
                  </Grid>
                )
              }
            </Grid>
            : 
            null

          }
          <Grid item xs>
            <Typography variant="subheading" className={classes.mutedHeading}>
              Condition
            </Typography>
            <span>
              {jobItemObj.condition ? jobItemObj.condition : "Not Given"}
            </span>
          </Grid>
        </Grid>

        {
          (!isDomestic) && (jobItemObj.requiredEmployees || jobItemObj.floor) &&
          <Grid container alignContent="center" alignItems="center" spacing={16}>
            { 
              jobItemObj.requiredEmployees &&
              <Grid item xs>
                <Typography variant="subheading" className={classes.mutedHeading}>
                  Required Employees
                </Typography>
                <span>{jobItemObj.requiredEmployees}</span>
              </Grid>
            }
            { 
              jobItemObj.floor &&
              <Grid item xs>
                <Typography variant="subheading" className={classes.mutedHeading}>
                  Floor Type
                </Typography>
                <span>{jobItemObj.floor}</span>
              </Grid>
            }
          </Grid>
        }

        { 
          (!isDomestic) &&
          <Grid container alignContent="center"  spacing={16}>
            <OptionList
              title = "Cleaning Services"
              list = {jobItemObj.cleaningServices}
              classes = {classes}
            />
            <OptionList
              title = "Equipments"
              list = {jobItemObj.equipments}
              classes = {classes}
            />
          </Grid>
        }

        <Grid container alignContent="center" spacing={16}>
          { 
            (jobItemObj.orderCost) &&
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Job Cost{" "}
              </Typography>
              <span>
                {"$" + jobItemObj.orderCost}
              </span>
            </Grid>
          }
          {
            (jobItemObj.etc) 
              ? <Grid item xs>
                <Typography variant="subheading" className={classes.mutedHeading}>
                  Job Estimated Completion Time (in Mins)
                </Typography>
                <span>{`${jobItemObj.etc} min`}</span>
              </Grid>
              : null
          }
        </Grid>


        {
          !isEmpty(zokFeeInfo) ?
          <Grid className={classes.zokFeeInfoCont} >
            <Typography variant="subheading" className={classes.mutedHeading}>
              Zolean Fee Info{" "}
            </Typography>
            <Grid>
              <Grid>
                Amount: {"$" + zokFeeInfo.amount}
              </Grid>
              <Grid>
                Desc: {zokFeeInfo.desc}
              </Grid>
            </Grid>
          </Grid>
        : null
        }


        <Grid container alignContent="center"  spacing={16}>
          {!_.isEmpty(deviceUsedForBooking) && (
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Device Used For Booking
              </Typography>
              <Grid item container direction="column">
                {deviceUsedForBooking.deviceName && (
                  <Grid item xs>
                    Device Name: {deviceUsedForBooking.deviceName}
                  </Grid>
                )}
                {deviceUsedForBooking.deviceOs && (
                  <Grid item xs>
                    Operating System: {deviceUsedForBooking.deviceOs}
                  </Grid>
                )}
                {deviceUsedForBooking.deviceType && (
                  <Grid item xs>
                    Device Type: {deviceUsedForBooking.deviceType}
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {jobOrigin && (
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Origin
              </Typography>
              <span>{jobOrigin}</span>
            </Grid>
          )}
        </Grid>
        {referrer && (
          <Grid
            container
            alignContent="center"
            alignItems="center"
            spacing={16}
          >
            <Grid item xs>
              <Typography variant="subheading" className={classes.mutedHeading}>
                Referrer
              </Typography>
              <span>{referrer}</span>
            </Grid>
          </Grid>
        )}

        {isRejectLoaderOn ? (
          <Loader />
        ) : (
          !_.isEmpty(rejectedBy) && (
            <Grid
              container
              alignContent="center"
              alignItems="center"
              spacing={16}
            >
              <Grid item xs>
                <Typography
                  variant="subheading"
                  className={classes.mutedHeading}
                >
                  Rejected By
                </Typography>
                <Grid item container direction="column">
                  {rejectedBy.map((contractor, index) => (
                    <Grid key={index} className={classes.setMarginBottom_12}>
                      { (contractor.data && contractor.data.fullName) &&
                      <Grid item>Owner Name: {contractor.data.fullName}</Grid>
                      }
                      <Grid item>
                        Business Name: {contractor.business_name}
                      </Grid>
                      <Grid item>Email: {contractor.email}</Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {!_.isEmpty(reviewObj) && reviewObj.customerReview && (
                <Grid item xs>
                  <Typography
                    variant="subheading"
                    className={classes.mutedHeading}
                  >
                    Customer Review
                  </Typography>
                  <span>{reviewObj.customerReview}</span>
                </Grid>
              )}
            </Grid>
          )
        )}
        { (additionalBookings.length > 0) && <AdditionalBookingsList title="Additional Bookings" list={additionalBookings}/> }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleJobDetailsModal} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(JobDetailsModal);