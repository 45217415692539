import React, { Component } from "react";
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { NavLink } from "react-router-dom";
import {drawerWidth, appBar} from "theme/vars"

const styles = theme => ({
  drawerPaper: {
    height:"100vh",
    paddingTop: appBar,
    position: 'relative',
    width: drawerWidth,
    backgroundColor:"#f7f7f7"
  },
  sideDrawer:{
  },
  navLink: {
    color: "#9b9b9b",
    textDecoration: "none",
    "&.active": {
      color: "#4a4a4a"
    }
  }
})

const routes = [
//  {
//    name:"Dashboard",
//    link:"/dashboard",
//  },
 {
   name:"Jobs",
   link:"/jobs",
   authorizedUser:["admin","manager"]
 },
 {
   name:"Contractors",
   link:"/contractors",
   authorizedUser:["admin","manager"]
 },
 {
   name:"Flagged Contractors",
   link:"/flagged_contractors",
   authorizedUser:["admin","manager"]
 },
 {
   name:"Contractors Earnings",
   link:"/contractors_earnings",
   authorizedUser:["admin","manager"]
 },
 {
   name:"Price Lists",
   link:"/price_lists",
   authorizedUser:["admin","manager"]
 },
 {
  name:"Employees",
  link:"/employees",
  authorizedUser:["admin","manager"]
 },
 {
   name:"Users",
   link:"/users",
   authorizedUser:["admin","manager"]
 },
 {
   name:"Payouts",
   link:"/payouts",
   authorizedUser:["admin","manager"]
 },
 {
  name:"Emails",
  link:"/emails",
  authorizedUser:["admin","manager"]
 },
 {
   name:"Managers",
   link:"/managers",
   authorizedUser:["admin"]
 },
 {
  name:"Tiers",
  link:"/tiers",
  authorizedUser:["admin","manager"]
},
{
  name:"Unavailabilities",
  link:"/unavailabilities",
  authorizedUser:["admin","manager"]
}
]


const SideBarLinks = ({classes, routes, userType}) => (
  routes.map((route, key)=> {
    if(route.authorizedUser && route.authorizedUser.indexOf(userType) === -1 ) {
      return null;
    }
    return (
      <NavLink
        to={route.link}
        key={`side-drawer-item-${key}`}
        className={classes.navLink}
      >
        <ListItem button component="div">
           {route.name}
        </ListItem>
      </NavLink>
    )
  } 
  )
)


class SideDrawer extends Component {
  render() {
    const { classes, adminInfo:{userType} } = this.props;
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
        className={classes.sideDrawer}
      >
      <List component="div">
        <SideBarLinks routes={routes} classes={classes} userType={userType}/>
      </List>
      </Drawer>
    );
  }
}



const mapStateToProps = (state) => ({
  adminInfo: state.admin.adminInfo,
})


const WithStyles = withStyles(styles)(SideDrawer);

export default connect(mapStateToProps)(WithStyles);