import React from "react";
import { MainLayout } from "../layouts";
import EditJobContainer from "../containers/EditJobContainer";




const EditJobPage = (props) => {
  return (
    <MainLayout pageName="Edit Job">
      <EditJobContainer {...props}/>
    </MainLayout>
  )
}



export default EditJobPage;