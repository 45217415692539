import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from "@material-ui/core/styles";
import {SliderField} from "../FormField";
import {primaryColor, primaryHoverColor, primaryGradient} from "../../utils/colors";

const styles = theme => ({
  modal: {
    maxWidth: 400,
    margin: ".5rem auto"
  },
  modalBody: {
    padding: "50px 1rem 40px"
  },
  modalFooter: {
    justifyContent: "stretch",
    border: "none"
  },
  showTime: {
    textAlign: "center"
  },
  time: {
    fontSize: "2.5em",
    background: primaryGradient,
    color: "#fff",
    display: "inline-block",
    padding: "0px .25em",
    margin: "0 .15em",
    borderRadius: 3
  },
  separator: {
    fontSize: "2.5em",
    color: primaryColor,
  },
  sliders: {
    paddingTop: 20
  },
  timeText : {
    position: "relative",
    left: "-10",
    fontSize: "15",
    color: primaryColor,
    marginTop: "7",
    marginBottom: "10"
  },
  slider: {
    padding: 7
  },
  primaryButton: {
    background: primaryColor,
    border: "none",
    flex: "1 1",
    "&:hover": {
      background: primaryHoverColor
    }
  },
  btns: {
    flex: "1 1"
  }
});

const getValue = function (value, type) {
  if(type === "hrs" && value > 12) value -= 12;
  if(value < 10) value = "0"+ value;
  return value;
};

const TimePickerModal = ({isOpen, toggle, handleClose, classes, handleTime, hrs, minutes}) => {
  return (
    <Dialog open={isOpen} onClose={toggle} className={classes.modal}>
      <DialogContent className={classes.modalBody}>
        <div className={classes.showTime}>
          <span className={classes.time}>{getValue(hrs, "hrs")}</span>
          <span className={classes.separator}>:</span>
          <span className={classes.time}>{getValue(minutes)}</span>
          <span className={classes.separator}>{ hrs > 11 ?  "PM" : "AM" }</span>
        </div>
        <div className={classes.sliders}>
          <div className={classes.timeText}>Hours:</div>
          <div className={classes.slider}>
            <SliderField onChange={handleTime("hrs")} max={23} defaultValue={hrs} value={hrs}/>
          </div>
          <div className={classes.timeText}>Minutes:</div>
          <div className={classes.slider}>
            <SliderField onChange={handleTime("minutes")} max={59} defaultValue={minutes} value={minutes}/>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button className={classes.primaryButton} onClick={toggle}>Save</Button>{" "}
        <Button className={classes.btns} color="secondary" onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(TimePickerModal);
