import React, { PureComponent } from "react";
import ZButton from "../../core/components/Button";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import axios from "../../utils/http";
import { toggleLoader } from "../../store/actions/app";
import { setMsgAndShow } from "../../store/actions/popup";

class ExportListBtn extends PureComponent {

  handleCSVRequest = async () => {
    try {
      const { link, fileName:receivedFileName, requestUrl, params, toggleLoader } = this.props;
      toggleLoader();
      let fileUrl;
      let fileName;
      if(!isEmpty(requestUrl)){
        // loadingFromWeb = true;
        const res = await axios({
          method:"get",
          url:requestUrl,
          params
        })

        const { fileInfo } = res.data;
        ({ fileName, url:fileUrl } = fileInfo)
      } else if(link && receivedFileName){
        fileUrl = link;
        fileName = receivedFileName;
      }


      // console.log("FF", fileUrl,fileName);
      if(fileUrl){
        const link = document.createElement('a');
        link.href = fileUrl;
        // link.target = "_blank";
        link.setAttribute(
          'download',
          fileName,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
      toggleLoader();
    } catch (error) {
      let errorMsg = "Unable to request export please try again later!";
      const { response } = error;
      if(response && response.data && response.data.message){
        errorMsg = response.data.message;
      }

      this.props.setMsgAndShow(errorMsg);
      toggleLoader()
    }
  }
  render(){
    const { title = "Download List" } = this.props;
    return (
      <ZButton gradient color="primary" onClick={this.handleCSVRequest}>
        {title}
      </ZButton>

    )
  }
}





const mapDispatchToProps = (dispatch) => ({
  toggleLoader: () => dispatch(toggleLoader()),
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
})

export default connect(undefined,mapDispatchToProps)(ExportListBtn);