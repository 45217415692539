import React from "react";
import { Field, Form } from "redux-form";
import Grid from "core/components/Grid";
import {
  ExtendedAdvanceSelect,
  FormInputField,
  MaterialTextField
} from "../FormField";
import { withStyles } from "core/components/Styles";
import {
  required,
  email,
  matchPasswords
} from "../FormField/Validations";
import { formatPhoneNumber } from "../FormField/formatters";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import TimePicker from "../TimePicker2";
import Loader from "../ProgressCircle";
import ZButton from "core/components/Button";
import ZTypography from "core/components/Typography";
import { weekDaysOptions } from "./options";
import IconButton from "@material-ui/core/IconButton";
import { Pencil } from "mdi-material-ui";
import { isEmpty } from "lodash";
import cn from "classnames";
import { capitalizeAllFirstLetters } from "../../utils/capitalize";


const styles = theme => ({
  textCenter: {
    textAlign: "center"
  },
  inputSelect: {
    paddingTop: 0,
    "& [class*=fieldWrapper]": {
      height: 46,
      "& > span": {
        right: 0,
        color: "#c4c4c4",
        "& img": {
          width: "1.3rem",
          height: "1.3rem",
          opacity: 0.7
        }
      },
      "& > input": {
        color: "#646464",
        fontWeight: 500,
        lineHeight: "21px",
        fontSize: 14,
        height: 46,
        border: "solid 1px #d8d8d8",
        background: "#ffffff",
        padding: "5px 18px", //"20px 56px 20px 20px",
        "&::placeholder ": {
          color: "#b7b7b7"
        }
      }
    }
  },
  cleaningTypeHeading: {
    marginRight: 0,
    marginTop: 0,
    marginBottom: 4,
    fontSize: 12,
    color: "#929292",
    lineHeight: "18px"
  },
  inputMarginTop_10: {
    marginTop: 10
  },
  imageFrame:{
    border: "3px dotted #686868",
    height: "14.28rem",
    width: "14.28rem",
    margin: ".9rem 0 1rem 0",
    borderRadius: "8%"   
  },
  profileHolder:{
    position: "relative",
    margin: "0 0 .91rem 0"
  },
	addImageText:{
    display: "inline",
    color: "#686868",
    fontSize: "1.28rem",
    textTransform: "uppercase",
    border: "1px dashed #aaa",
    padding: ".35rem 1.07rem",
    borderRadius: ".35rem",
  },
  editImageBtn:{
    position: "absolute",
    right: "-1.2rem",
    bottom: ".3rem",
    height: 30,
    width: 30,
    boxShadow: "4px 7px 14px 0 rgba(59, 39, 95, 0.21)",
    background:"#fff",
    borderRadius: "50%",
    "&:hover":{
      background:"#fff"
		}
	},
	pencilIcon:{
    color: "#fd9b5b",
    fontSize: 14
  },
  profileStyle: {
    height: "14.28rem",
    width: "14.28rem"
  }
});

const EditEmployeeModal = ({
  submitData,
  isEditModalOpen,
  handleEditModal,
  updatingEmployee,
  handleSubmit,
  employeeObj,
  onPhotoUpload,
  classes,
  initialValues
}) => {
  let fullName = "";
  let profileImage = "";
  if(!isEmpty(initialValues)){
    fullName = capitalizeAllFirstLetters(`${initialValues.firstName} ${initialValues.lastName}`);
    profileImage =  (!isEmpty(employeeObj) ? employeeObj.profileImage : "") || (initialValues.profileImage);  
  } 
  return (
    <Dialog
      open={isEditModalOpen}
      onClose={handleEditModal}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Form onSubmit={handleSubmit(submitData)}>
        <DialogTitle id="form-dialog-title" className={classes.textCenter}>
          <ZTypography color="secondary" gradient bold fontSize={25}>
            Update Employee
          </ZTypography>
        </DialogTitle>
        <DialogContent>
          {updatingEmployee && <Loader zIndex={999} position="fixed" />}

          <Grid container justify="center" spacing={24}>
            <Grid item onClick={onPhotoUpload} className={cn({ 
              [classes.imageFrame]: !profileImage, 
              [classes.profileHolder]: profileImage, 
            })} xs={3}>
              { 
                profileImage 	
                  ? <img src={`${process.env.REACT_APP_USER_FILES}${profileImage}`} className={classes.profileStyle} alt={ fullName } />
                  : <ZTypography classes={{ root: classes.addImageText }}>
                    + Click to Add Picture
                  </ZTypography>
              }    
              { profileImage &&
                <IconButton size="small" className={classes.editImageBtn}>
                  <Pencil className={classes.pencilIcon}/>
                </IconButton>
              }
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={16}>
              <Grid item xs={12} md={4}>
                <FormInputField
                  label="First Name"
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter First Name"
                  component={MaterialTextField}
                  validate={[required]}
                  disableUnderline
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInputField
                  label="Last Name"
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter Last Name"
                  component={MaterialTextField}
                  validate={[required]}
                  disableUnderline
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInputField
                  label="Phone"
                  id="phone"
                  name="phone"
                  placeholder="Enter Phone Number"
                  component={MaterialTextField}
                  validate={[required]}
                  format={formatPhoneNumber}
                  disableUnderline
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInputField
                  label="Email"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter Email Address"
                  component={MaterialTextField}
                  validate={[required, email]}
                  disableUnderline
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInputField
                  label="Password"
                  name="password"
                  type="password"
                  id="password"
                  placeholder="Enter Password"
                  component={MaterialTextField}
                  disableUnderline
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInputField
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm Your Password"
                  component={MaterialTextField}
                  validate={[matchPasswords]}
                  disableUnderline
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormInputField
                  isMulti={true}
                  close={false}
                  label="Week Days"
                  id="workDays"
                  name="workDays"
                  component={ExtendedAdvanceSelect}
                  options={weekDaysOptions}
                  backgroundColor="transparent"
                  validate={[required]}
                  material
                  disableUnderline
                  closeMenuOnSelect={false} // To keep the select menu open
                  menuPlacement="top" // To open the list to top direction
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.inputMarginTop_10}
              justify="space-between"
              spacing={16}
            >
              <Grid item xs={12} md={6} className={classes.inputSelect}>
                <p className={classes.cleaningTypeHeading}>
                  Job Shift Start Time
                </p>
                <Field
                  name="jobShiftStartTime"
                  component={TimePicker}
                  validate={[required]}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputSelect}>
                <p className={classes.cleaningTypeHeading}>
                  Job Shift End Time
                </p>
                <Field
                  name="jobShiftEndTime"
                  component={TimePicker}
                  validate={[required]}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ZButton type="submit" color="primary" gradient>
            Update Employee
          </ZButton>
          <ZButton onClick={handleEditModal}>Cancel</ZButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default withStyles(styles)(EditEmployeeModal);
