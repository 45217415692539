import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import cn from "classnames";

const styles = theme => ({
  overlayStyles: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: "rgba(255,255,255,0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9
  }
});
const Loader = ({ classes, position, size, zIndex, className }) => {

  const defaultStyles = {
    position: position || 'absolute'
  }

  if(zIndex){
    defaultStyles.zIndex = zIndex;
  }
  return (
    <div
      className={cn(classes.overlayStyles, className ? className : "")}
      style={defaultStyles}
    >
      {" "}
      <CircularProgress color="secondary" size={size}/>
    </div>
  );
}



export default withStyles(styles)(Loader);
