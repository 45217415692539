import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { reset, reduxForm } from "redux-form";
import { setMsgAndShow } from "../store/actions/popup";
import socket from "socket";
import axios from "utils/http";
import _ from "lodash";
import moment from "moment-timezone";
import removeProp from "../utils/removeProp";
import { parseQuery } from "../utils/query-string";
import confirmResponse from "../utils/confirmResponse";
import ContractorsDashboard from "../components/ContractorsDashboard";
import ContractorActionModal from "../components/ContractorActionModal";
import CommentInputModal from "../components/CommentInputModal";
// import AssignJobMenu from "../components/AssignJobMenu";
import ContractorDetailsModal from "../components/ContractorDetailsModal"
import getTotalPagesCount from "../utils/getTotalPagesCount";
import { getList as getContractorsList } from "../helpers/contractors";
// import { setJobs, removeJob, updateJob } from "../store/actions/jobs";

class ContractorsDashboardContainer extends Component {

  constructor(props){
    super(props);
    this.contractorId = undefined;
    this.dropDownAnchor = null;
    this.assignedEmployees = [];
    this.contractorAction = null;
    this.debounceFun = _.debounce(this.search, 250)  
    this.rawTierTypes = [];
    this.state = {
      show: "requests",
      contractors: [],
      contractorObj:{},
      isContractorDetailsModalOpen: false,
      isCommentInputOpen:false,
      doNotShowJobBtns: false,
      isModalOpen: false,
      employeeList: [],
      jobItemIndex: "",
      jobItemObj: {},
      open: false,
      btnRef: null,
      isContractorActionModalOpen: false,
      commentInputLoader:false,
      openLoader: false,
      entityId:"",
      modalText:"",
      reason:"",
      totalCount: 0,
      perPage: 10,
      currPage:1,
      load: false,
      q:"",
      page:0,
      contractorComment:"",
      tierTypes:[],
      tierType:"",
      selectTierOptions:[],
    };

    this.inputRef = React.createRef();

  }

  searchValue = "";



  getTierTypes = async () => {
    try {
      let tiers = [];
      const res = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/tiers/list`);
      const tiersData = res.data;
      
      tiers = tiersData.list;
      if(tiers && tiers.length ){
        this.rawTierTypes = tiers;
        const tierTypes = tiers.map((tierData) => tierData.name);
        const selectTierOptions = tiers.map((tierData) => ({label:tierData.name,value:tierData.name}))
        this.setState({
          tierTypes,
          selectTierOptions
        })
      }
    } catch (error) {
      const { response } = error;
      this.props.setMsgAndShow(response ? response.data.message : "Error while getting tier types")
    }
  }
  componentDidMount() {
    this.getTierTypes();
    this.addData();
  }

  // componentDidUpdate = async (prevProps, nextProps) => {
  //   const { formValues, contractorInfo } = prevProps;

  //   if (formValues.type !== this.props.formValues.type) {
  //     this.props.change("addOnServices", null);
  //   }
  //   if (
  //     JSON.stringify(contractorInfo) !==
  //     JSON.stringify(this.props.contractorInfo)
  //   ) {
  //     this.addData();
  //   }
  // };



  addData = async () => {
    try {

      this.setState({
        load:true
      })
      const {filter, v} = parseQuery(window.location.search);
      let tierType = "";
      let clearUrl = false;
      if(filter && filter === "tier" && v){
        tierType = v;
        clearUrl = true;
      }
      const { currPage, perPage, q } = this.state;

      const { contractors,total, page, perPage: limit } = await getContractorsList(q,tierType,perPage,currPage);
      this.setState({
        contractors,
        totalCount: total,
        currPage:page,
        perPage: limit,
        load: false,
        tierType,
        page: page - 1
      })
      if(clearUrl){
        this.props.history.push("/contractors")
      }
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }

      this.setState({
        load:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  };
  

  openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false
    });
    this.props.resetForm("OrderForm");
  };

  handleMenuClose = () => {
    this.setState({
      open: false,
      jobItemIndex: ""
    });
  };

  getContractorDetails = (contractorObj) => {
    // console.log("Cont in Container ",contractorObj); For checking contr details
    this.setState({
      contractorObj,
      isContractorDetailsModalOpen: true
    })
  }
     
  handleContractorModal= () => {
    this.setState({
      isContractorDetailsModalOpen: false,
      contractorObj: {}
    });
  };


  payout = (contractorObj) => async () => {
    console.log(contractorObj)
  } 

  getSearch = (e) => {   
    this.debounceFun(e.target.value);
  }

  clearSearch = ()=> {
    this.inputRef.current.value = "";
    this.search();
  }

  search = async (q) => {  

    this.searchValue = this.inputRef.current.value; 

    let { perPage, tierType } = this.state;
    this.setState({load: true});
   
    try {
  
      const { contractors, total, page, perPage: limit } = await getContractorsList(q,tierType,perPage,1);

      this.setState({
        contractors,
        q: q,
        totalCount: total,
        currPage:page,
        perPage: limit,
        load: false,
        page:page - 1
      })
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }

      this.setState({
        load:false,
      })
      this.props.setMsgAndShow(errMsg);
    }
  }

  approveContractor = async (contractorObj) => {
    const { setMsgAndShow } = this.props;
    let { contractors } = this.state;
    try {
      this.setState({
        load: true,
      })
      const { businessId: contractorId } = contractorObj;

      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}admin/contractor/approve`,{
        contractorId
      })

      this.refreshList(true)
      setMsgAndShow(res.data.message);
    } catch (error) {

      this.setState({
        load: false
      })
      const { response } = error;
      setMsgAndShow(response ? response.data.message : "Error");
    }
  }


  openContractorActionModal = (cO,index,additionalParams = []) => {

    const [ whatToDo ] = additionalParams;
    let modalText = "Why you want to suspend this contractor?";
    this.contractorAction = "suspend";
    if(whatToDo && whatToDo === "revoke"){
      modalText = "Why you want to revoke this contractor's suspension?";
      this.contractorAction = "revoke";
    }
    if(whatToDo && whatToDo === "suspendSilently"){
      this.contractorAction = "suspendSilently";
    }

    if(whatToDo && whatToDo === "disqualify"){
      modalText = "Why you want to disqualify this contractor?";
      this.contractorAction = "disqualify";
    }
    this.setState({
      isContractorActionModalOpen: true,
      entityId: cO.businessId,
      modalText
    });
  }

  closeContractorActionModal = () => {
    this.contractorAction = null;
    this.setState({
      isContractorActionModalOpen: false,
      entityId:"",
      modalText:"",
      reason:""
    })
  }
  handleReasonInput = (e) => {
    this.setState({
      reason:e.target.value
    })
  }

  submitRes = () => {

    const { reason } = this.state;
    let res = null;

    if(!reason || !reason.trim()){
      window.alert("Please provide reason")
      return;
    };


    if(
      this.contractorAction === "suspend" || 
      this.contractorAction === "suspendSilently"
    ){
      res = window.confirm("Any pending jobs of this contractor will be released into the system. Do you want to continue?")
    } else if(this.contractorAction === "revoke"){
      res = true;
    } else if(this.contractorAction === "disqualify"){
      res = true;
    }


    if(res){
      this.processRequest();
    }
  }

  processRequest = async () => {
    const { setMsgAndShow } = this.props;

    this.setState({
      load: true
    })
    
    try {
      let apiUrl = `${process.env.REACT_APP_API_PREFIX}admin/contractor/`;
      let suspendSilently = undefined;
      if(
        this.contractorAction === "suspend" ||
        this.contractorAction === "suspendSilently"
      ){
        apiUrl +="suspend";
        suspendSilently = this.contractorAction === "suspendSilently";
      } else if(this.contractorAction === "revoke"){
        apiUrl += "revoke_suspension";
      } else if(this.contractorAction === "disqualify"){
        apiUrl += "disqualify"
      }
      const { entityId, reason } = this.state;


      const res = await axios.post(apiUrl, {
        contractorId: entityId,
        reason,
        silent: suspendSilently
      });
      this.setState({
        isContractorActionModalOpen:false
      })

      this.refreshList(true)
      setMsgAndShow(res.data.message)
  
    } catch (error) {
      const { response } = error;
      this.setState({
        load: false
      })
      setMsgAndShow(response ? response.data.message : "Error");
    }
  }


  openReviews =  (contractor) => {

    this.props.history.push({
      pathname:"/reviews",
      state:{
        contractor
      }
    })
  }


  getContractors = async (pageNumber,loaderIsActive) => {

    try {

      if(!loaderIsActive){
        this.setState({
          load: true
        })
      }
      let { perPage, q, tierType } = this.state;
      const { contractors,total, page, perPage: limit } = await getContractorsList(q,tierType,perPage,pageNumber);
      this.setState({
        contractors,
        totalCount: total,
        currPage:page,
        perPage: limit,
        load: false,
        page: page - 1
      })
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        load:false
      })
      this.props.setMsgAndShow(errMsg)
    }
  }


  checksForDisqualified = (obj) => {
    try {
      let btnDisabled = true;

      if(obj && (obj.status.toLowerCase() === "pending")){
        btnDisabled = false;
      }

      return btnDisabled;
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  openCommentInput = (contractor) => {
      this.contractorId = contractor.businessId;
      this.setState({
        isCommentInputOpen: true,
      })
  }

  closeCommentInput = () => {
    this.contractorId = undefined;
    this.setState({
      isCommentInputOpen: false,
      commentInputLoader:false,
    })
  }


  handleCommentText = (e) => {
    this.setState({
      contractorComment:e.target.value
    })
  }

  submitContractorComment = async () => {
    const { setMsgAndShow } = this.props;
    
    try {
      const apiUrl = `${process.env.REACT_APP_API_PREFIX}admin/contractor/comments/add`;
      let comment = this.state.contractorComment;
      comment = comment && comment.trim();

      if(!comment){
        alert("Comment is required");
        return;
      }

      this.setState({
        commentInputLoader:true
      })

      const res = await axios.post(apiUrl, {
        contractorId: this.contractorId,
        comment,
      });

      this.closeCommentInput();

      setMsgAndShow(res.data.message)
  
    } catch (error) {
      const { response } = error;
      this.setState({
        commentInputLoader: false
      })
      setMsgAndShow(response ? response.data.message : "Error");
    }
  }

  handleTierChange = (e) => {
    this.setState({
      tierType:e.target.value,
    }, () => {
      this.getContractors(1);
    });

  }


  changeContractorTier = async (contractorId, tierId) => {
    try {
      this.setState({
        load:true
      })

      await axios.put(`${process.env.REACT_APP_API_PREFIX}admin/contractor/change_tier`, {
        contractorId,
        tierId,
      })


      this.refreshList(true);

      this.props.setMsgAndShow("Tier updated successfully");
    } catch (error) {
      const { response } = error;
      let errorMsg = "Unable to change tier";
      if(response && response.data && response.data.message){
        errorMsg = response.data.message;
      }
      this.setState({
        load: false
      })
      this.props.setMsgAndShow(errorMsg);
    }
  }
  handleContractorTierChange = async (contractorData, tierOption) => {
    try {
      const contractorId = contractorData.businessId;
      const tierData = this.rawTierTypes.find((t) => t.name === tierOption.value);
      const tierId = tierData._id;
      const res = await confirmResponse( 
        "Do you want to continue ?", 
        `${contractorData.businessName}'s tier will be changed`
      );

      if(res){
        this.changeContractorTier(contractorId, tierId)
      }
      
    } catch (error) {
      this.props.setMsgAndShow("Unable to process request!")
    }
  }


  isSelectedOption = (itemValue, optionValue) => {
    let isSelected = false;

    if(itemValue.toLowerCase() === optionValue.toLowerCase()){
      isSelected = true;
    }

    return isSelected;
  }


  refreshList = async (loaderIsActive) => {
  
    try {

      if(!loaderIsActive){
        this.setState({
          load: true
        })
      }
      let { perPage, q, tierType, currPage } = this.state;

      const { contractors,total, page, perPage: limit } = await getContractorsList(q,tierType,perPage,currPage);
      
      const totalPages = getTotalPagesCount(total,limit);
      
      if(page > totalPages){
        this.getContractors(totalPages,true);
      } else {
        this.setState({
          contractors,
          totalCount: total,
          currPage:page,
          perPage: limit,
          load: false,
          page: page - 1,
        })

      }
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        load:false
      })

      this.props.setMsgAndShow(errMsg);
    }
  }


  handlePageChange = (pageNumber) => {
    this.getContractors(pageNumber);
  }
  contractorActions = [
    {
      label: "Get Details",
      onClick: this.getContractorDetails, 
      isForJobModal: true
    },
    {
      label:"Approve",
      onClick: this.approveContractor,
      checkForDisabled: true,
      disableBtn: obj => !!obj.isApproved,
      propToCheck: "isApproved"
    },
    {
      label:"Suspend",
      onClick: this.openContractorActionModal,
      checkForDisabled: true,
      propToCheck:"isSuspended"
    },
    {
      label:"Suspend Silently",
      onClick: this.openContractorActionModal,
      checkForDisabled: true,
      disableBtn: (obj) => obj.isSuspended,
      secondParam: "suspendSilently"
    },
    {
      label:"Revoke Suspension",
      onClick: this.openContractorActionModal,
      checkForDisabled: true,
      disableBtn: (obj) => !obj.isSuspended,
      secondParam:"revoke"
    },
    {
      label:"Disqualify",
      onClick: this.openContractorActionModal,
      checkForDisabled: true,
      disableBtn: this.checksForDisqualified,
      secondParam:"disqualify",
      // propToCheck:""
    },
    {
      label:"Add a comment",
      onClick: this.openCommentInput,
    },
    {
      label:"View Reviews",
      onClick: this.openReviews,
    }
    // {
    //   label: "Payout",
    //   onClick: this.payout,
    //   checkForDisabled: true,
    //   propToCheck: "cancelledByAdmin"
    // },
    // {
    //   label: "Reject",
    //   onClick: this.rejectJob
    // }
  ];


  render() {

    return (
      <Fragment>
        <ContractorsDashboard
          getContractorDetails={this.getContractorDetails}
          contractorActions={this.contractorActions}
          // todaysJobActions={todaysJobActions}
          // todaysJobOrders={this.state.orders}
          handleModalClose={this.handleClose}
          // changeList={this.changeList}
          openModal={this.openModal}

          submitData={this.submitData}
          getSearch={this.getSearch}
          handlePageChange={this.handlePageChange}
          searchValue={this.searchValue}
          clearSearch={this.clearSearch}
          searchInput={this.inputRef}
          handleTierChange={this.handleTierChange}
          handleContractorTierChange={this.handleContractorTierChange}
          isSelectedOption={this.isSelectedOption}
          {...this.state}
          {...this.props}
        />

        <ContractorDetailsModal
          handleContractorModal={this.handleContractorModal}
          {...this.state}
        />

        <ContractorActionModal 
          onCancel={this.closeContractorActionModal}
          isModalOpen={this.state.isContractorActionModalOpen}
          modalText={this.state.modalText}
          onTextInput={this.handleReasonInput}
          onSubmit={this.submitRes}
          openLoader={this.state.openLoader}
          requiredText
        />
        

        <CommentInputModal
          onCancel={this.closeCommentInput}
          isModalOpen={this.state.isCommentInputOpen}
          modalText="Add a comment for the contractor"
          onTextInput={this.handleCommentText}
          onSubmit={this.submitContractorComment}
          openLoader={this.state.commentInputLoader}
          requiredText
        />
        
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    jobsObj: state.jobs,
    adminObj: state.admin,
  };
};

const mapDispatchToProps = dispatch => ({
  // setJobs: (jobs, jobsType) => dispatch(setJobs(jobs, jobsType)),
  // removeJob: (jobId, jobsType) => dispatch(removeJob(jobId, jobsType)),
  // updateJob: (jobType, jobId, updateObj) => dispatch(updateJob(jobType, jobId, updateObj)),
  resetForm: name => dispatch(reset(name)),
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  reset
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractorsDashboardContainer);
