import React, { PureComponent } from "react";
import ChangePasswordModal from "../components/ChangePasswordModal";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import axios from "utils/http";
import { setMsgAndShow } from "../store/actions/popup";
import { closePassChangeModal } from "../store/actions/app";


class ChangePasswordModalContainer extends PureComponent {

  state = {
    load:false,
  }
  submitData = async (data) => {
    try {

      this.setState({
        load:true,
      })

      const { username } = this.props.adminInfo;
      const dataToSend = {
        oldPass:data.currPass,
        pass:data.password,
        email:username,
      }

      const res  = await axios.post(`${process.env.REACT_APP_API_BASE_URL}user/password/reset`,{...dataToSend});
      this.setState({
        load:false,
      })

      this.closeModal();
      this.props.setMsgAndShow(res.data.message);
    } catch (error) {
      let errorMessageToShow = "Sorry an error occurred!";
      const { response }= error;
      if(response && response.data && response.data.message){
        errorMessageToShow = response.data.message;
      }
      this.setState({
        load:false
      })
      this.props.setMsgAndShow(errorMessageToShow);
    }
  }

  closeModal = () => {
    this.props.reset();
    this.props.closePassChangeModal();
  }
  render(){
    const { openModal } = this.props;
    return (
      <ChangePasswordModal 
        loadLoader={this.state.load}
        submitData={this.submitData}
        {...this.props}
        isModalOpen={openModal}
        closeModal={this.closeModal}
      />
    )
  }
}




const WithReduxForm = reduxForm({
  form: "ChangePasswordForm",
  destroyOnUnmount: true,
})(ChangePasswordModalContainer);


const mapStateToProps = (state) => ({
  openModal:state.app.openPassChangeModal,
  adminInfo: state.admin.adminInfo,
})

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow:(message) => dispatch(setMsgAndShow(message)),
  closePassChangeModal:() => dispatch(closePassChangeModal())
})


export default connect(mapStateToProps, mapDispatchToProps)(WithReduxForm);