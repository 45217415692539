import React, { Component } from 'react'
import { withStyles, Button} from "core/components";
import cn from "classnames";

const styles = theme => ({
  primaryGradient: {
    boxShadow : "0px 9px 27px rgba(80, 80, 80, 0.31)",
    borderRadius : 5,
    background : "linear-gradient(180deg, #50E3C2 0%, #008DA7 100%)",
    textTransform : "uppercase",
    color : "#fff"
  },
  secondaryGradient: {
    boxShadow : "0px 9px 27px rgba(80, 80, 80, 0.31)",
    borderRadius : 5,
    background : "linear-gradient(180deg, #2201AB 0%, #7E10C7 100%)",
    textTransform : "uppercase",
    color : "#fff"
  },
  disableTextCapitalize:{
    textTransform:"none!important"
  }
})

class ZButton extends Component {
  render() {
    const { classes, color="default", gradient, className, disableTextCapitalize, ...rest } = this.props;
    const classNames = cn({
      [classes.primaryGradient]: color === "primary" && gradient,
      [classes.secondaryGradient]: color === "secondary" && gradient,
      [classes.disableTextCapitalize]:disableTextCapitalize,
      [className]: className
    })
    return (
      <Button
        {...rest}
        color={color}
        className={classNames}
      />
    )
  }
}
export default withStyles(styles)(ZButton);