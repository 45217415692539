import React, { Component } from "react";
import axios from "../utils/http";
import _ from "lodash";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { setMsgAndShow } from "../store/actions/popup";
import { loadFormData, resetFormData } from "../store/actions/formData";
import EmployeeDetailsModal from "../components/EmployeeDetailsModal";
import EditEmployeeModal from "../components/EditEmployeeModal";
import Employees from "../components/Employees";
import { ImageDropZone, DropZoneDialog  } from "../components/DropZone";

class EmployeeDashboardContainer extends Component {
  state = {
    employees: [],
    isEditModalOpen: false,
    totalCount: 0,
    perPage: 10,
    currPage: 1,
    load: false,
    searchLoad: false,
    updatingEmployee: false,
    showDetailsModal: false,
    showDropZone: false,
    employeeObj: { profileImage: "" },
    q: "",
    employeeId: "",
    page:0,
  };

  inputRef = React.createRef();

  searchValue = "";

  componentDidMount() {
    this.getEmployees(this.state.currPage);
  }

  getSearch = (e) => this.debounceFun(e.target.value);

  clearSearch = () => {
    this.inputRef.current.value = "";
    this.searchValue = "";
    this.getEmployees(1);
  };

  search = async (q) => {
     
    this.searchValue = this.inputRef.current.value;
    const { perPage, currPage } = this.state;
    let updateObj = { searchLoad: false };
    if ( !(q && q.trim()) ) {
      this.getEmployees(1);
    }
    this.setState({ searchLoad: true });
    q = q.trim().toLowerCase();
    
    try {
      const usersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/employees/list`,
        {
          params: {
            q: q,
            limit: perPage,
            page: 1
          }
        }
      );
      if (usersRes.data.status === "OK") {
        const { employees, total, page,  perPage: limit } = usersRes.data;
        updateObj = { 
          employees, 
          q, 
          totalCount: total, 
          currPage: page, 
          perPage: limit, 
          page: page - 1,
          ...updateObj };
      }
    } catch (error) {
      const { response } = error;
      this.props.setMsgAndShow(
        response ? response.data.message : "Error while getting Jobs list"
      );
    }
    this.setState(updateObj);
  };

  debounceFun = _.debounce(this.search, 250);

  openEditEmployeeForm = (employeeObj) => {
    this.setState({
      isEditModalOpen: true,
      employeeId: employeeObj._id
    });

    const workDays = employeeObj.workDays.map((day)=> {
      return {
        label: day,
        value: day
      };
    });

    const obj = {
      firstName: employeeObj.fname,
      lastName: employeeObj.lname,
      phone: employeeObj.phone,
      email: employeeObj.email,
      jobShiftStartTime: employeeObj.jobShiftStartTime,
      jobShiftEndTime: employeeObj.jobShiftEndTime,
      workDays,
      profileImage: employeeObj.profileImage
    };
    
    this.props.loadFormData(obj);    
  };

  closeEditEmployeeForm = () => {
    this.props.reset();
    this.props.resetFormData();
    this.setState({
      isEditModalOpen: false,
      employeeObj:{},
      employeeId: ""
    });
  };

  showEmployeeDetails = (employeeObj)=> this.setState({ 
    showDetailsModal: true,
    employeeObj  
  });

  closeEmployeeModal = ()=> this.setState({
    showDetailsModal: false,
    employeeObj: {}
  });

  getEmployees = async (pageNumber) => {
    const { setMsgAndShow } = this.props;
    let updateObj = { load: false };
    try {
      const { perPage } = this.state;
      this.setState({ load: true });
      const usersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/employees/list`,
        {
          params: {
            page: pageNumber,
            limit: perPage,
            q:this.searchValue,
          }
        }
      );

      if (usersRes.data.status === "OK") {
        const { employees, total, page, perPage: limit } = usersRes.data;
        updateObj = { 
          employees, 
          totalCount: total, 
          currPage: page, 
          perPage: limit, 
          page: page - 1,
           ...updateObj };
      }
    } catch (error) {
      const { response } = error;
      setMsgAndShow(response ? response.data.message : "Error");
    }
    this.setState(updateObj);
  };

  updateEmployee = async(obj)=> {
    let formValues = _.cloneDeep(obj);
    const workDays = [];
    let msg = "Error Occurred!";
    let stateObj = { updatingEmployee: false };
    const { employeeId } = this.state;
    this.setState({ updatingEmployee: true });
    
    formValues.workDays.forEach(day => {
      workDays.push(day.value);
    });

    delete formValues.confirmPassword;

    formValues = {
      ...formValues,
      workDays
    };

    const { profileImage } = this.state.employeeObj;
    if(profileImage){
      formValues.profileImage = profileImage;
    }

    try {
      const employeeData = await axios.put(
        `${process.env.REACT_APP_API_PREFIX}admin/employees/update/${employeeId}`,
        { ...formValues }
      );


        msg = "Employee details were updated";
        this.closeEditEmployeeForm();
        this.getEmployees(this.state.currPage);
  
      
    } catch (error) {
      const { response } = error;
      if(response && response.data){
        msg = response.data.message ? response.data.message : msg;
      }
    }

    this.setState(stateObj);
    this.props.setMsgAndShow(msg);
  };

  submitData = obj => {
    this.updateEmployee(obj);
  };

  handleDropZone = ()=> this.setState({ showDropZone: !this.state.showDropZone });

  fileUploaded = async(response)=> {
    let toastMsg = "Error occured while uploading photo.";
    let updateObj = { showDropZone: false };
    try {
      if(response && response.data){
        if(response.data.status === "OK"){
          updateObj = {
            ...updateObj,
            employeeObj:{ profileImage: response.data.profileImage }
          };
          toastMsg = "Photo uploaded successfully.";
          this.getEmployees(this.state.currPage);
        }
      }      
    } catch (error) {
      const { response } = error;
      if(response && response.data){
        toastMsg = response.data.message || toastMsg;
      }
    }
    this.setState(updateObj);
    this.props.setMsgAndShow(toastMsg);
  }

  onPhotoUpload = ()=> this.setState({ showDropZone: true });

  render() {
    const actions = [
      {
        label: "View Details",
        onClick: this.showEmployeeDetails
      },
      {
        label: "Update",
        onClick: this.openEditEmployeeForm
      }
    ];

    const { employeeId, employeeObj, showDetailsModal } = this.state;
    
    return (
      <>

        <Employees
          actions={actions}
          submitSuccess={this.submitSuccess}
          getEmployees={this.getEmployees}
          getSearch={this.getSearch}
          searchValue={this.searchValue}
          clearSearch={this.clearSearch}
          searchInput={this.inputRef}
          {...this.state}
        />

        <EmployeeDetailsModal
          isDetailsModalOpen = {showDetailsModal}
          employeeItemObj = {employeeObj}
          handleDetailsModal = {this.closeEmployeeModal}
        /> 
        
        <EditEmployeeModal
          submitData={this.submitData}
          handleEditModal={this.closeEditEmployeeForm}
          onPhotoUpload={this.onPhotoUpload}
          {...this.props}
          {...this.state}
        />

        <DropZoneDialog
          isOpen={this.state.showDropZone}
          toggle={this.handleDropZone}
          title="Upload Employee Profile Picture"
        >
          <ImageDropZone
            onUploaded={this.fileUploaded}
            onCancel={this.handleDropZone}
            url={`${process.env.REACT_APP_API_PREFIX}admin/upload_employee_photo`}
            accept=".jpg,.png,.gif"
            formData={{ employeeId }}
          />
        </DropZoneDialog>

      </>
    );
  }
}

const EmployeeDashboardFormComponent = reduxForm({
  form: "EditEmployeeForm",
  enableReinitialize: true
})(EmployeeDashboardContainer);


const mapStateToProps = (state) => ({
  initialValues: state.formData.formData
});

const mapDispatchToProps = dispatch => ({
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  loadFormData:(payload) => dispatch(loadFormData(payload)),
  resetFormData:() => dispatch(resetFormData())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeDashboardFormComponent);