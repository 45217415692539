import React from "react";
import SearchBar from "../SearchBar";
import Loader from "../ProgressCircle";
import Table from "../TableWithPagination";
import { MainLayout } from "../../layouts";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex"
  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  }
});

const headers = [
  {
    name: "fullName",
    label: "Employee Name"
  },
  {
    name: "businessName",
    label: "Company Name"
  },
  {
    name: "email",
    label: "Email"
  },
  {
    name: "phone",
    label: "Phone"
  },
  {
    name: "isActive",
    label: "Active"
  },
  // {
  //   name: "isSuspended",
  //   label: "Suspended"
  // },
  {
    name: "isVerified",
    label: "Verified"
  },
  {
    name: "actions",
    label: "Actions"
  }
];

const columnProps = [
  {
    fnameProp: "fname",
    lnameProp: "lname",
    isName: true
  },
  {
    name: "businessName"
  },
  {
    name: "email"
  },
  {
    name: "phone"
  },
  {
    name: "isActive",
    isBoolean: true,
    isTrue: "Yes",
    isFalse: "No"
  },
  // {
  //   name: "isSuspended",
  //   isBoolean: true,
  //   isTrue: "Yes",
  //   isFalse: "No"
  // },
  {
    name: "isVerified",
    isBoolean: true,
    isTrue: "Yes",
    isFalse: "No"
  }
];

const EmployeeDashboard = ({
  classes,
  employees,
  actions,
  totalCount,
  getSearch,
  getEmployees,
  
  load,
  searchLoad,
  page,
  searchValue,
  clearSearch,
  searchInput
}) => {
  return (
    <div className={classes.root}>
      <MainLayout pageName="Employees">
        <SearchBar 
          placeholder="Search Employee by name, phone or email."
          onChange={getSearch}
          query={searchValue}
          onClear={clearSearch}
          inputRef={searchInput}
        /> 
        {searchLoad && <Loader/>}
        <Table
          list={employees}
          headers={headers}
          columnProps={columnProps}
          totalCount={totalCount}
          actions={actions}
          handlePageChange={getEmployees}
          page={page}
        />
        {load && <Loader />}
      </MainLayout>
    </div>
  );
};

export default withStyles(styles)(EmployeeDashboard);
