import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from '@material-ui/core/Checkbox';
import { PageFirst, PageLast, ArrowLeft, ArrowRight } from "mdi-material-ui";
import PopUpMenu from "../PopUpMenu";
import SelectMenuBtn from "../SelectMenuButton";
import { DotsVertical } from "mdi-material-ui";
import { FaStar, FaRegStar } from "react-icons/fa";
import Rating from "react-rating";
import momentTz from "moment-timezone";
import Loader from "../ProgressCircle";
import Button from "@material-ui/core/Button";
import Icon from "core/components/Icon";
import { Grid, Typography } from "@material-ui/core";
// import ArrowLeft from '@material-ui/icons/ArrowLeft';
// import ArrowRight from '@material-ui/icons/ArrowRight';
// import LastPageIcon from '@material-ui/icons/LastPage';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5
  }
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;
    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <PageLast /> : <PageFirst />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? <ArrowRight /> : <ArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? <ArrowLeft /> : <ArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <PageFirst /> : <PageLast />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(TablePaginationActions);

// let counter = 0;
// function createData(name, calories, fat) {
//   counter += 1;
//   return { id: counter, name, calories, fat };
// }

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  tdCell:{
    padding: "4px 0px 4px 12px"
  },
  tdHead:{
    padding:"4px 0px 4px 12px"
  },
  flag:{
    marginLeft:10,
  }
});

class CustomPaginationActionsTable extends React.Component {
  state = {
    page: 0,
    rowsPerPage: 10,
  };

  handleChangePage = (event, page) => {
    // this.setState({ page });
    if(this.props.handlePageChange){
      this.props.handlePageChange(page + 1)
    }
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes, list, headers, columnProps, totalCount, actions, paginate = true, load, page, selectBtnOptions, onOptionSelect, selectedOptionProp, isSelectedOption, disableSelectTextCapitalize } = this.props;
    const { rowsPerPage, } = this.state;
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);
    const emptyRows = rowsPerPage - list.length;
    const listArray =  paginate ? list : list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) 
    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
        { load && <Loader />}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headers &&
                  headers.map((header, index) => {
                    if (header.isNumeric) {
                      return (
                        <TableCell variant="head" className={classes.tdHead} key={`table-header-${index}`} numeric>
                          {header.label}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell variant="head" className={classes.tdHead} key={`table-header-${index}`}>
                        {header.label}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {listArray && listArray
                .map((item, index) => {
                  return (
                    <TableRow key={`table-row-${item._id}-${index}`}>
                      { columnProps &&
                        columnProps.map((column, index) => {
                          if(column.isSubmitButton){
                            return(
                              <TableCell  className={classes.tdCell} key={`table-item-${index}`}>
                                <Button 
                                  variant="contained" 
                                  color="primary" 
                                  onClick={this.props.handlePassButton(item)}
                                >
                                  {column.buttonText}
                                </Button>
                              </TableCell>
                            )
                          } else if(column.isCheckBox){
                            return(
                              <TableCell  className={classes.tdCell} key={`table-item-${index}`}>
                                <Checkbox
                                  onClick={this.props.handleCheckChange(item[column.businessId])}
                                  color="primary"
                                />
                              </TableCell>
                            )
                          } else if (column.isName) {
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                                {item[column.fnameProp] +
                                  " " +
                                  item[column.lnameProp]}
                              </TableCell>
                            );
                          } else if (column.isNumeric) {
                            return (
                              <TableCell className={classes.tdCell} numeric key={`table-item-${index}`}>
                                {item[column.name]}
                              </TableCell>
                            );
                          } else if (column.isAddress) {
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                                {item[column.name] ?
                                  `
                                  ${item[column.name][column.addressProp]}, 
                                  ${item[column.name][column.cityProp]}, 
                                  ${item[column.name][column.stateProp]}, 
                                  ${item[column.name][column.zipcodeProp]}
                                  `
                                  :
                                  "N/A"
                                }
                              </TableCell>
                            );
                          } else if (column.isBoolean) {
                            return item[column.name] ? (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                                {column.isTrue}
                              </TableCell>
                            ) : (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                                {column.isFalse}
                              </TableCell>
                            );
                          } else if(column.isStatus){
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                              {column.capitalize(item[column.name])}
                              </TableCell>
                            ) 
                          } else if(column.isTimeStamp){

                            const textToDisplay = momentTz(item[column.name]).format(column.formatRequired)
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                              {textToDisplay}
                              </TableCell>
                            )
                          } else if(column.isDate){
                            const textToDisplay = momentTz(item[column.name], column.inFormat).format(column.formatRequired)
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                              {textToDisplay}
                              </TableCell>
                            )
                          } else if(column.isTime){
                             const textToDisplay = momentTz(item[column.name], column.inFormat).format(column.formatRequired)
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                              {textToDisplay}
                              </TableCell>
                            )
                          } else if(column.isList){
                            const textToDisplay = Array.isArray(item[column.name]) ? column.joinList(item[column.name]): "Not Provided";

                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                              {textToDisplay}
                              </TableCell>
                            )
                          } else if(column.isRatings){
                            const textToDisplay = item && item[column.name] ? 
                              <Rating
                                stop={column.outOf}
                                initialRating={item[column.name]}
                                fractions={10}
                                emptySymbol={<FaRegStar size={16}/>}
                                fullSymbol={<FaStar color="#6610f2" size={16} />}
                                readonly
                              />
                              : "No Ratings";
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                              {textToDisplay}
                              </TableCell>
                            )
                          } else if(column.isTip){
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                              {item[column.name] ? item[column.name] : 0}
                              </TableCell>
                            )
                          } else if(column.isFrequency){
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                              {column.frequencies[item[column.name]]}
                              </TableCell>
                            )
                          } else if(column.isStringList){
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                              {item[column.name].join(',')}
                              </TableCell>
                            )
                          } else if(column.isFlagsRow){
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                                <Grid container spacing={8}>
                                  <Grid item xs={4} container spacing={8}>
                                    <Grid item >
                                      <Icon color={column.firstFlagColor}>flag</Icon>
                                    </Grid>
                                    <Grid item  >
                                      {item[column.name][column.firstFlagKey]}
                                    </Grid>
                                  </Grid> 
                                  <Grid item xs={4} container spacing={8}>
                                    <Grid item >
                                      <Icon color={column.secondFlagColor}>flag</Icon>
                                    </Grid>
                                    <Grid item  >
                                      {item[column.name][column.secondFlagKey]}
                                    </Grid>
                                  </Grid> 
                                

                                </Grid>
                              </TableCell>
                            )
                          } else if(column.isFrequency){
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                              {column.frequencies[item[column.name]]}
                              </TableCell>
                            )
                          } else if(column.isStringList){
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                              {item[column.name].join(',')}
                              </TableCell>
                            )
                          } else if(column.isPhone){
                            let phoneNumber = item[column.name];
                            
                            if(column.formatter && phoneNumber){
                              phoneNumber = column.formatter(phoneNumber);
                            }
                            return (
                              <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                              {phoneNumber || "N/A"}
                              </TableCell>
                            )
                          } else if(column.isSelectBtn){
                            return (
                              <TableCell key={`select-btn-col-${index}`} >
                                <SelectMenuBtn
                                  buttonProps={{
                                    fontSize: "xs",
                                    disableTextCapitalize:disableSelectTextCapitalize
                                  }}
                                  options={selectBtnOptions} 
                                  itemIndex={index}
                                  text={item[column.name]}
                                  item={item}
                                  onSelect={onOptionSelect}
                                  selectedOptionProp={selectedOptionProp}
                                  isSelectedOption={isSelectedOption}
                                />
                              </TableCell>
                            )
                          }


                          return (
                            <TableCell className={classes.tdCell} key={`table-item-${index}`}>
                            {column.capitalize ? column.capitalize(item[column.name]) : item[column.name]}

                              {/* {item[column.name]} */}
                            </TableCell>
                          );
                        })}

                      {
                        actions && actions.length ?
                    
                        <TableCell key={`action-col-${index}`}>
                          <PopUpMenu
                            icon={<DotsVertical />}
                            buttonProps={{
                              fontSize: "xs"
                            }}
                            options={actions}
                            item={item}
                          />
                        </TableCell>
                        : null
                        
                        }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 57 * emptyRows }}>
                  <TableCell colSpan={headers.length} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  colSpan={headers.length}
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomPaginationActionsTable);