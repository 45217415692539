import React, { PureComponent } from "react";
// import withStyles from "react-jss";
import cn from "classnames";
import { withStyles } from "core/components/Styles";
import Grid from "core/components/Grid";
// import selected from "../../assets/images/svg/selected.svg"
import tick from "../../assets/images/tick.png";
const styles = () => ({
  optionsCont:{
    display:"flex",
    paddingTop :26,
    maxWidth: 340,
    width: "100%"
  },
  inputStyle:{
    // padding: "22px 8px 16px 8px", 
    margin: " 0 30px 0 0 "
    //height : "100%",
    // width : 161,
    // height : 96,
    // display : "flex",
    // flexDirection :"column",
    // justifyContent : "center",
    // alignItems : 'center',
    // background: "#ffffff",
    // boxShadow : "0px 9px 33px rgba(0, 0, 0, 0.09)",

  },
  titleOfBtn: {
    fontWeight : 500,
    lineHeight : "22px",
    fontSize : 15,
    color: "#000"
  },

  titleNotSelected: {
    fontWeight: 300,
    fontSize: 14,
    lineHeight:"21px",
    color: "rgba(36, 42, 55, 0.5)"
  },

  radioBtn:{
    marginRight:8,
    height : 18,
    width : 18,
    //background : "#4fe2c2"
    border :"1px solid #D8D8D8",
    outline : "none",
    borderRadius: "50%",
    "-webkit-appearance":"none",
    "&::before":{
      content : `url(${tick})`,
      display:"flex",
      justifyContent:"center",
      alignItems :"center",
      height:"100%",
      opacity :0
    },
    "&:checked":{
      background: "#4FE2C2",
      "&::before":{
        opacity:1
      }
    }
  },
  defaultRadioBtn:{
    height : 28,
    width : 34,
    border :"1px solid #D8D8D8",
    background : "#fff"
  },
  error: {
    color: "red",
    fontSize: 12,
  },
  warning: {
    color: "orange",
    fontSize: 12
  },
  widthAuto: {
    width: "auto"
  }
})



class RadioSelectBtn extends PureComponent {

  changeOption = (option) => {
    const { input, onOptionChange} = this.props;
    if(input && input.onChange){
      input.onChange(option);
    }

    if(onOptionChange){
      onOptionChange(option, option.target.value);
    }
  }
  render () {
    const { input = {}, meta = {}, options, classes, width, inRow, disabled } = this.props;
    const hasError = meta && meta.touched && meta.error;
    return (
      <Grid
        container 
        alignItems="center"
        alignContent="space-between" 
        // justify="center" 
        className={cn({
          // [classes.optionsCont]: inRow, 
          [classes.widthAuto]: width === "auto"
        })}
      >
        {options.map((o,i) => (
          <label 
            className={cn(classes.inputStyle)}
            key={`radio-options-item-${i}`}
          >
            <input
              type="radio"
              {...input} 
              value={o.value}
              checked={o.value === input.value}
              onChange={this.changeOption}
              className={cn("form-control", classes.radioBtn)}
              disabled={disabled}
            />{" "}
            <span className={cn({
              [classes.titleOfBtn]: o.value === input.value,
              [classes.titleNotSelected]: o.value !== input.value 
            })}>{o.title}</span>
          </label>
        ))}
        {hasError && (
          <span
           className={classes.error}
          >
            {meta.error}
          </span>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(RadioSelectBtn)