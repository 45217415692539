import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Loader from "../ProgressCircle";
import { KeyboardBackspace } from "mdi-material-ui"
import { withStyles } from "@material-ui/core/styles";

import Header from "../../containers/HeaderContainer";
import SideDrawer from "../SideDrawer";
import MainContent from "../MainContent";
// import Loader from "../ProgressCircle";
// import DataList from "../DataList";
// import jobProps from "./jobProps";
import Table from "../TableWithPagination";

const styles = () => ({
})

const headers = [
  {
    name: "displayId",
    label: "Order ID"
  },
  {
    name: "date",
    label: "Date Of Cleaning"
  },
  {
    name: "orderCost",
    label: "Order Cost ($)"
  },
  {
    name: "zokFeeAmt",
    label: "Zoklean Share ($)"
  },
  {
    name:"contractorShareAmt",
    label:"Contractor Share ($)"
  },
  {
    name:"tipAmt",
    label:"Tip ($)"
  },
  {
    name: "actions",
    label: "Actions"
  }
];

const columnProps = [
  {
    name:'displayId'
  },
  {
    name: "date",
    isDate: true,
    inFormat:"YYYY-MM-DD",
    formatRequired:"DD MMM YYYY"
  },
  {
    name: "orderCost",
  },
  {
    name: "zokFeeAmt",
  },
  {
    name: "contractorShareAmt",
  },
  {
    name:"tipAmt"
  }
];

const CompletedJobs = (props) => {
  const { 
    classes,
    jobs,
    startDate,
    endDate,
    businessName,
    jobActions,
    goBack,
    totalCount,
    tablePage,
    handlePageChange,
    load
  } = props;

  return (

        <Grid>
            {load && <Loader/>}
          <Grid container alignItems="center" spacing={16}>
            <Grid item>
              <Tooltip title="Go Back">
                  <IconButton onClick={goBack}>
                    <KeyboardBackspace/>
                  </IconButton>

              </Tooltip>
            </Grid>

            {
              businessName ?
                <Grid item>
                  <Typography>
                    Jobs Completed By {businessName}
                  </Typography>
                </Grid> : null
            }
            {
              startDate && endDate ?
              <Grid item>
                  <Typography>
                    Payout Period: {startDate} --- {endDate}
                  </Typography>
              </Grid> : null
            }

          </Grid>

          <Grid>
            
              <Table
                list={jobs}
                totalCount={totalCount}
                headers={headers}
                columnProps={columnProps}
                actions={jobActions}
                page={tablePage}
                handlePageChange={handlePageChange}
              />
          </Grid>
        </Grid>
  )
}



export default withStyles(styles)(CompletedJobs);
