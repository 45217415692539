import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Table from "../TableWithPagination";
import SearchBar from "../SearchBar";

const headers = [
  {
    name: "isChecked",
    label: ""
  },
  {
    name: "businessId",
    label: "Business ID"
  },
  {
    name: "businessName",
    label: "Business Name"
  },
  {
    name: "createdat",
    label: "Joining Date"
  },
  {
    name: "businessType",
    label: "Business Types"
  },
  {
    name: "zipcode",
    label: "Zipcode"
  },
  {
    name: "businessPhone",
    label: "Phone"
  },
  {
    name: "ratings",
    label: "Ratings"
  },
  {
    name: "actions",
    label: "Actions"
  }
];

let columnProps = [
  {
    name: "businessId"
  },
  {
    name: "businessName"
  },
  {
    name: "createdat",
    isTimeStamp: true,
    formatRequired: "MM/DD/YYYY"
  },
  {
    name: "businessType",
    isList: true,
    joinList: list => list.join(",")
  },
  {
    name: "zipcode"
  },
  {
    name: "businessPhone"
  },
  {
    name: "ratings",
    isRatings: true,
    outOf: 5
  }
];

const getColumnProps = (optionSelected)=>  optionSelected === "offerTo" 
  ? [ { businessId: "businessId", isCheckBox: true }, ...columnProps ]
  : [ { buttonText: "Assign" ,isSubmitButton: true }, ...columnProps ];


const PassedJobDetailsModal = ({
  onClose,
  actions,
  contractorActions,
  getContractors,
  getSearch,
  onSubmit,
  contractors,
  contLoad,
  contTotalCount,
  isContModalOpen,
  isSubmitted,
  modalTitle,
  optionSelected,
  ...props
}) => {



  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isContModalOpen}
      onClose={onClose}
    >
      <DialogTitle id="job-modal-title">
        {modalTitle}
      </DialogTitle>
      <DialogContent>
        
        <SearchBar 
          placeholder="Search business by zipcode, business name. owner's email"
          onChange={getSearch}
        />
        <Table
          list={contractors}
          totalCount={contTotalCount}
          actions={contractorActions}
          headers={headers}
          columnProps={getColumnProps(optionSelected)}
          handlePageChange={getContractors}
          load={contLoad}
          {...props}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        {
          optionSelected === "offerTo" &&
            <Button variant="contained" color="primary" onClick={onSubmit}>
              Submit
            </Button>
        }
      </DialogActions>
    </Dialog>
  );
};
export default PassedJobDetailsModal;