import React, { PureComponent } from "react";
import AddEventForm from "../components/EventForm";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { setMsgAndShow } from "../store/actions/popup";
import { addEvent } from "../helpers/events";
import { cloneDeep } from "lodash";

class AddEventFormContainer extends PureComponent {

  state = {
    processing:false
  }
  closeForm = () => {
    this.props.closeModal();
    this.props.reset();
  }

  submitData = async (formValues) => {
    try {

      this.setState({
        processing:true
      })
      const eventData = cloneDeep(formValues);
      eventData.type = eventData.type.value;
      const res = await addEvent(`${process.env.REACT_APP_API_WITH_PREFIX}admin/events/add`,{...eventData});
      this.setState({
        processing:false
      })
      this.closeForm();
      if(this.props.onSuccess){
        this.props.onSuccess(res.event);
      }

      this.props.setMsgAndShow("Unavailability added");
    } catch (error) {
      let errMsg = "Unable to add unavailability";
      const { response } = error;

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        processing:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  }

  eventTypesOptions = [
    {
      label:"Unavailability",
      value:"unavailability"
    }
  ]
  render(){
    return (
      <AddEventForm 
        {...this.props}
        processing={this.state.processing}
        submitData={this.submitData}
        closeForm={this.closeForm}
        title="Add Unavailability"
        eventTypesOptions={this.eventTypesOptions}
      />
    )
  }
}

const WithForm = reduxForm({
  form: "AddEventForm",
  destroyOnUnmount: true,
  initialValues:{
    type:{value:"unavailability"},
    name:"Unavailable",
    isAllDay:true,
  },
  enableReinitialize:true,
  keepDirtyOnReinitialize:true,
})(AddEventFormContainer);

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow:(message) => dispatch(setMsgAndShow(message)),
  // resetFormData:() => dispatch(resetFormData())
})


const mapStateToProps = (state) => {
    let formValues = {};
    if(state.form.AddEventForm && state.form.AddEventForm.values){
      formValues = state.form.AddEventForm.values;
    }
    return {
      formValues,
      initialValues:state.eventForm.data
    };

}


export default connect(mapStateToProps,mapDispatchToProps)(WithForm)