import { 
  STORE_DOMESTIC_PRICE_LIST,
  // CLEAR_DOMESTIC_PRICE_LIST,
  STORE_COMMERCIAL_OPTS,
  STORE_DOMESTIC_TYPES,
  SHOW_LOADER,
  HIDE_LOADER,
  TOGGLE_LOADER,
  OPEN_PASS_CHANGE_MODAL,
  CLOSE_PASS_CHANGE_MODAL,
  SET_CARPET_CLEANING_OPTS,
  SET_FREQUENCIES,
  SET_PRICE_LIST
} from "../types";
import { cloneDeep } from "lodash";

const defaultState = {
  showLoader:false,
  cleaningOpts:{
    domestic:{
      cleaningTypesIndex:{},
      cleaningTypes:[],
      pricingList:{},
    },
    commercial:{
      floorTypes:[],
      frequencyTypes:[],
      cleaningServices:[],
      equipmentTypes:[],
      employeeOpts:[]
    },
    carpet:{
      addOns:[],
      cleaningAreas:[]
    },
    frequencies:[],
    freqOpts:[],
    priceList:{}
  },
  openPassChangeModal:false,
}



const reducer = (state = defaultState, action) => {
    let finalState = {}

    switch(action.type){
      case STORE_DOMESTIC_PRICE_LIST:
        finalState = cloneDeep(state)
        finalState.cleaningOpts.domestic.pricingList = cloneDeep(action.payload)
        break;
      case STORE_DOMESTIC_TYPES:
        finalState = cloneDeep(state);
        finalState.cleaningOpts.domestic.cleaningTypes = cloneDeep(action.payload)
        break;
      case STORE_COMMERCIAL_OPTS:
        finalState = cloneDeep(state);
        finalState.cleaningOpts.commercial = cloneDeep(action.payload);
        break;
      case SHOW_LOADER:
        finalState = cloneDeep(state);
        finalState.showLoader = true;
        break;
      case HIDE_LOADER:
        finalState = cloneDeep(state);
        finalState.showLoader = false;
        break;
      case TOGGLE_LOADER:
        finalState = cloneDeep(state);
        finalState.showLoader = !finalState.showLoader;
        break;
      case OPEN_PASS_CHANGE_MODAL:
        finalState = cloneDeep(state);
        finalState.openPassChangeModal = true;
        break;
      case CLOSE_PASS_CHANGE_MODAL:
        finalState = cloneDeep(state);
        finalState.openPassChangeModal = false;
        break;

      case SET_CARPET_CLEANING_OPTS:
        finalState = cloneDeep(state);
        finalState.cleaningOpts.carpet = cloneDeep(action.payload);
        break;
      case SET_FREQUENCIES:
        finalState = cloneDeep(state);
        finalState.cleaningOpts.frequencies = cloneDeep(action.payload.frequencies);
        finalState.cleaningOpts.freqOpts = cloneDeep(action.payload.freqOpts)
        break;
      case SET_PRICE_LIST:
        finalState = cloneDeep(state);
        finalState.cleaningOpts.priceList = cloneDeep(action.payload);
        break;
      default:
      finalState = state
    }

    return finalState
}



export default reducer;