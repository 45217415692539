import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setMsgAndShow } from "../store/actions/popup";
import axios from "utils/http";
import _ from "lodash";
// import moment from "moment-timezone";
// import removeProp from "../utils/removeProp";
import PassedJobModal from "../components/PassedJobModal";
import { 
  setJobId, 
  // removeJobId 
} from "../store/actions/contractorModal";
import Loader from "../components/ProgressCircle";
import ContractorDetailsModal from "../components/ContractorDetailsModal";
class SelectContractorsContiner extends Component {
	constructor(props){
		super(props);
		this.debounceFun = _.debounce(this.search, 250) 
		this.state = {
			isContractorDetailsModalOpen: false,
			contractorObj: {},
      contractors:[],
      contTotalCount:0,
      contCurrPage:1,
      contPerPage:10,
      contTotalPages:0,
      contLoad: false,
      isSubmitted: false,
      q:"",
      page:0,
		}
	}

	contractList=[];

	componentDidMount(){
    this.addContData();
	}

	addContData = async () => {
    this.setState({
      contLoad: true
    })
    try {
      const { contCurrPage, contPerPage } = this.state;
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/get_approved_contractors`,{
          params: {
            page: contCurrPage,
            limit: contPerPage
          }
        }
      );
      const { contractors,total, page, perPage: limit } = data;
      this.setState({
        contractors,
        contTotalCount: total,
        conCurrPage:page,
        contPerPage: limit,
        contLoad: false,
        page: page - 1,
      })
    } catch (error) {
      const { response } = error;
      this.setState({
        contLoad:false
      })
      // this.props.setMsgAndShow(response ? response.data.message : "Error while getting contractors list")
      setMsgAndShow(response ? response.data.message : "Error while getting contractors list")
    }
  };
  
  getContractors = async (pageNumber) => {
    this.setState({
      contLoad: true
    })
    try {
      let { contPerPage, q } = this.state;
      q = q.trim().toLowerCase();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/get_approved_contractors`,{
          params: {
            page: pageNumber,
            limit: contPerPage,
            q
          }
        }
      );
      
      const { contractors,total, page, perPage: limit } = data;
      this.setState({
        contractors,
        contTotalCount:total,
        contCurrPage:page,
        contPerPage:limit,
        contLoad: false,
        page: page - 1,
      })
      
    } catch (error) {

      const { response } = error;
      // this.props.setMsgAndShow(response ? response.data.message : "Error while getting contractors list")
      setMsgAndShow(response ? response.data.message : "Error while getting contractors list")
    }
  }

	getSearch = (e) => { 
    this.debounceFun(e.target.value);
  }

  search = async (q) => { 
    const { contPerPage, contCurrPage } = this.state;
    this.setState({contLoad: true});
   
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}admin/get_approved_contractors`,{
          params:{
            q: q.toLowerCase(),
            limit: contPerPage,
            page: 1
          }
        }
      );
      const { contractors, total, page, perPage: limit } = data;
      this.setState({
        contractors,
        q: q.toLowerCase(),
        contTotalCount: total,
        contCurrPage:page,
        contPerPage: limit,
        contLoad: false,
        page: page - 1,
      })
    } catch (error) {
      const { response } = error;
      // this.props.setMsgAndShow(response ? response.data.message : "Error while getting contractors list")
      setMsgAndShow(response ? response.data.message : "Error while getting contractors list")
    }
  }

  handleCheckChange = (businessId) => event =>{
    const { target } = event;
    const { checked } = target;
    const contractList = this.contractList //_.cloneDeep(this.contractList);
    if(checked){
      contractList.push(businessId)
      this.contractList = contractList
    }else{
      const idIndex = contractList.indexOf(businessId); 
      if(idIndex > -1){
        contractList.splice(idIndex,1);
        this.contractList = contractList;
      }
    } 
  };
 
  handlePassButton = ( contObj )=> async()=>{
    let popUp = "Error occured, Please try again later.";
    const { businessId:contractorId, businessName } = contObj;
    const { refreshJobList, showContractorModal, setMsgAndShow, contModal } = this.props;
    const jobId = contModal.jobId;
    this.setState( {contLoad: true} );
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}admin/jobs/${jobId}/assign`,{ contractorId });
      if(res && res.data){
        if(res.data.status && res.data.status === "OK"){
          popUp = `Job Assigned to ${_.startCase(businessName)}.`;
        } 
      }
      showContractorModal(false);
    } catch (error) {
      const { message } = error && error.response && error.response.data ? error.response.data : "";
      popUp = message || popUp;
    }
    this.setState({ contLoad: false });
    refreshJobList();
    setMsgAndShow(popUp); 
  };

  submitPassedJob = async ()=>{
    if(this.contractList.length > 0){
      const jobId = this.props.contModal.jobId;
      const contractorList = this.contractList;
      try {
        this.setState( {contLoad: true} );

        const { data } = await axios.post(`${process.env.REACT_APP_API_PREFIX}admin/jobs/${jobId}/offer`,{ contractorList });
        const { status, message } = data;
        if(status === "OK"){
          this.contractList = []
          
          this.setState({
            contLoad: false,
            isSubmitted: true
          });
          this.props.setMsgAndShow(message); //this.state.isSubmitted ? "Submitted Successfully." : "Bad Request.");
          this.props.showContractorModal(false);
        
        }else{
          this.contractList = [];
          this.props.setMsgAndShow(message);  
        }
      } catch (error) {
        this.contractList = [];
        this.setState({contLoad: false});
        const { message } = error.response.data;
        //this.props.showContractorModal(false);
        this.props.setMsgAndShow(message);
      }
    }else{
      this.props.setMsgAndShow("Please Select atleast One Contractor");
    }
  }

  closePassedJob = ()=> {
    this.props.showContractorModal(false);
  }

  getContractorDetails = (contractorObj) => {
    this.setState({
      isContractorDetailsModalOpen: true,
      contractorObj
    })
  }

  handleContractorDetailsModal= () => {
    this.setState({
      isContractorDetailsModalOpen: false,
      contractorObj: null
    });
  };

	render() { 
		const contractorActions = [
      {
        label : "Get Details",
        onClick : this.getContractorDetails
      }
    ];
    const { isContModalOpen } = this.props.contModal;
    const { optionSelected } = this.props;
		return(
			isContModalOpen &&
				(<Fragment> 
					{this.state.contLoad && <Loader/>}
					<PassedJobModal
            isContModalOpen={isContModalOpen}
						getContractors={this.getContractors}
						getSearch={this.getSearch}
            onSubmit={this.submitPassedJob}
            onClose={this.closePassedJob}
            handlePassButton={this.handlePassButton}
						handleCheckChange={this.handleCheckChange}
            contractorActions={contractorActions}
            modalTitle={optionSelected === "offerTo" ? "Offer To" : "Assign To" }
						{...this.state}
						{...this.props}
					/>
					{
						this.state.contractorObj && 
							<ContractorDetailsModal
								handleContractorModal={this.handleContractorDetailsModal}
								{...this.state}
							/>
					}
				</Fragment>)
		)
	}
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
  setJobId: jobId => dispatch(setJobId(jobId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectContractorsContiner);
