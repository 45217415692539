import swal from "./sweetalert";


const confirmResponse= async (question,alertText)=>{
  let confirmed = false;
  try {
    const response = await swal({
      title: question,
      text: alertText,
      type: "question"
    })
    if (response.value === true) {
      confirmed = true;
    }
  } catch (error) {
    throw error;
  }
  return confirmed;
}

export default confirmResponse;