import React, { PureComponent } from "react";
import FormControlLabel from 'core/components/FormControlLabel';
import Checkbox from 'core/components/Checkbox';
import withStyles from "core/components/Styles/withStyles";


const styles = () => ({
  error: {
    color: "red",
    fontSize: 12,
  },
  warning: {
    color: "orange",
    fontSize: 12
  }
})



class MaterialCheckBox  extends PureComponent {

  handleChange = (e) => {
    const { input, onChange } = this.props;
    if(input) input.onChange(e);
    if(onChange) onChange(e);
  }
  render(){
    const {  input, label, value, meta, classes, useInputValue, ...rest  } = this.props;
    let checkedValue = input.checked;
    if(useInputValue){
      checkedValue = input.value;
    }
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name={input.name}
              checked={checkedValue}
              // {...input}
              {...rest}
              onChange={this.handleChange}
            />
          }
          label={label}
        />
        <div className="help-block">
          {meta.touched &&
            ((meta.error && <span className={classes.error}>{meta.error}</span>) ||
              (meta.warning && <span className={classes.warning}>{meta.warning}</span>))}
          </div>
      </div>
      
    )
  }
}



export default withStyles(styles)(MaterialCheckBox);