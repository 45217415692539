import axios from "utils/http";

export const getList = async (q, tierType,limit,page) => {
  try {

    if(q){
      q = q.trim().toLowerCase();
    }
    if(tierType){
      tierType = encodeURIComponent(tierType);
    }

    const res = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}admin/get_all_contractors`,{
        params: {
          page,
          limit,
          tierType,
          q
        }
      }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
}