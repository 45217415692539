import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";


import Header from "../../containers/HeaderContainer";
import SideDrawer from "../SideDrawer";
import MainContent from "../MainContent";
import Loader from "../ProgressCircle";
// import DataList from "../DataList";
// import payoutProps from "./payoutProps";
import Table from "../TableWithPagination";

const styles = () => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex"
  },
  loader:{
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: "rgba(255,255,255,0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9
  }
})






// const payoutProps = [
//   {
//     label:"businessName",
//     name:"Business Name"
//   },

//   {
//     name: "Created",
//     label: "createdat",
//     isTimeStamp: true,
//     formatRequired:"DD MMM YYYY HH:mm A"
//   },
//   {
//     name: "From",
//     label: "startDate",
//     isTimeStamp: true,
//     formatRequired:"DD MMM YYYY"
//   },
//   {
//     name: "To",
//     label: "endDate",
//     isTimeStamp: true,
//     formatRequired:"DD MMM YYYY"
//   },
//   {
//     name:"Amount ($)",
//     label:"amount"
//   },
//   {
//     name:"Status",
//     label:"status"
//   }

// ]



const headers = [
  {
    name: "businessName",
    label: "Business Name"
  },
  {
    name:"createdat",
    label:"Created"
  },
  {
    name: "startDate",
    label: "From"
  },
  {
    name: "endDate",
    label: "To"
  },
  {
    name: "amount",
    label: "Amount ($)"
  },
  {
    name:"status",
    label:"Status"
  },
  {
    name:"actions",
    label:"Actions"
  }
];

const columnProps = [
  {
    name:'businessName',
  },
  {
    name: "createdat",
    isTimeStamp: true,
    formatRequired:"DD MMM YYYY HH:mm A"
  },
  {
    name: "startDate",
    isDate: true,
    inFormat:"YYYY-MM-DD",
    formatRequired:"DD MMM YYYY"
  },
  {
    name: "endDate",
    isDate:true,
    inFormat:"YYYY-MM-DD",
    formatRequired:"DD MMM YYYY"
  },
  {
    name:"amount"
  },
  {
    name:"status",
  }
  
];










const Payouts = (props) => {
  const { classes, payouts, payoutsActions, load, totalCount, getPayoutsByPage, page } = props;
  return (
    <Grid className={classes.root}>
      <Header name="Payouts" />
      <SideDrawer />
      <MainContent>
          <Grid>
            {load && <Loader />}
            <Typography>Payouts:</Typography>
            <Grid>



              <Table
                list={payouts}
                totalCount={totalCount}
                headers={headers}
                columnProps={columnProps}
                actions={payoutsActions}
                handlePageChange={getPayoutsByPage}
                page={page}
              />
              {/* <DataList
                // getDetails={getContractorDetails}
                dataList={payouts}
                dataItemObjProps={payoutProps}
                actions={payoutsActions}
                marginTop={20}
                errMsg="No Data Found"
              /> */}
            </Grid>
          </Grid>
      </MainContent>
    </Grid>
  )
}




export default  withStyles(styles)(Payouts)