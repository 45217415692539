const getLegacyFrequencyType = (cleaningType) => {
    let frequency;
    if(cleaningType){
      frequency = cleaningType.split(/_(\d+$)/,2)[1];
      frequency = parseInt(frequency);
    }
  

    return frequency;
}


export default getLegacyFrequencyType;