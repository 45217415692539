import React, { Component } from "react";
import Payouts from "../components/Payouts";


import { setMsgAndShow } from "../store/actions/popup";
import { connect } from "react-redux";
import axios from "../utils/http";


class PayoutsContainer extends Component {

  state = {
    payouts:[],
    load: false,
    totalCount: 0,
    perPage: 10,
    currPage:1,
    page:0,
  }
  componentDidMount(){
    this.getPayouts();
  }

  getPayouts = async () => {
    this.setState({
      load: true
    })
    try {
      const { currPage, perPage } = this.state;
      const res = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/get_payouts_list`, {
        params:{
          page: currPage,
          limit: perPage
        }
      });


      if(res.data.status === "OK"){
        const { payouts, total, page, perPage: limit  } = res.data;
        this.setState({
          payouts,
          load: false,
          totalCount: total,
          currPage:page,
          perPage: limit,
          page: page - 1,
        })
      } else {
        this.setState({
          load: false
        })
        this.props.setMsgAndShow(res.data.message)
      }
    } catch (error) {
      const { response } = error;
      this.setState({
        load: false
      })
      this.props.setMsgAndShow(response ? response.data.message : "Error");
    }
  }

  viewJobs = (transaction) => {

    this.props.history.push({
      pathname:"/completed_jobs",
      state:{
        contractor: transaction,
        startDate: transaction.startDate,
        endDate: transaction.endDate
      }
    })
  }


  getPayoutsByPage = async (pageNumber) => {
    this.setState({
      load: true
    })
    try {
      const { perPage } = this.state;
      const res = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/get_payouts_list`, {
        params:{
          page: pageNumber,
          limit: perPage
        }
      });


      if(res.data.status === "OK"){
        const { payouts, total, page, perPage: limit  } = res.data;
        this.setState({
          payouts,
          load: false,
          totalCount: total,
          currPage:page,
          perPage: limit,
          page: page - 1,
        })
      } else {
        this.setState({
          load: false
        })
        this.props.setMsgAndShow(res.data.message)
      }
    } catch (error) {
      const { response } = error;
      this.setState({
        load: false
      })
      this.props.setMsgAndShow(response ? response.data.message : "Error");
    }
  }
  render(){
    const payoutsActions = [
      {
        label:"View Jobs",
        onClick: this.viewJobs
      }
    ]
    return (
      <Payouts 
          payoutsActions={payoutsActions}
          getPayoutsByPage={this.getPayoutsByPage}
         {...this.state}  
      />
    )
  }
}




const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg))
})



export default connect(null, mapDispatchToProps)(PayoutsContainer)