export const getValidDate = function(d){ return new Date(d) }

export function validateDateBetweenTwoDates(fromDate,toDate,givenDate){
  return getValidDate(givenDate) <= getValidDate(toDate) && getValidDate(givenDate) >= getValidDate(fromDate);
}

export function isDateAfter(givenDate,afterDate){
  return getValidDate(givenDate) > getValidDate(afterDate);
}


export default validateDateBetweenTwoDates;