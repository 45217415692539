import { cloneDeep, isEmpty, isPlainObject } from "lodash";
import { getOption, getOptions } from "./getOptions";
import axios from "utils/http"
import moment from "moment-timezone";
import isValidFloat from "../utils/isValidFloat";
import { validateDateBetweenTwoDates } from "../utils/compareDates";
import { addOnServices as addOnServicesDomestic, commercialWindowsOpts, domesticOnlyKeyOptions } from "../data/formFieldData";
import sortAdditionalDates from "./sortAdditionalDates";
import parseNumber from "../utils/getNumber";
import getNumber from "../utils/getNumber";
import removeProp from "../utils/removeProp";


export const calCost = (formValues, pL, manualOverride) => {
  let responseBody = {};
  let estTime = 0;
  // const addOns = {};
  try {

    const orderType = formValues.type;
    let bedrooms = formValues.bedrooms;
    let bathrooms = formValues.bathrooms;
    const addOnServices = formValues.addOnServices;
    const cleaningAreas = formValues.cleaningAreas;
    const carpetAddOns = formValues.carpetAddOns;
    const adminCost = formValues.adminCost ? parseFloat(formValues.adminCost) : 0;
    const originalCost = formValues.originalCost ? parseFloat(parseFloat(formValues.originalCost).toFixed(2)) : 0;
    // const blinds = formValues.blinds;
    // const windows = formValues.windows;
    // const fridge = formValues.fridge;
    // const oven = formValues.oven;
    // const walls = formValues.walls;
    // const ecoFriendly = formValues.ecoFriendly;
    const couponData = formValues.couponData;
    const adminDiscount = formValues.adminDiscount ? parseFloat(parseFloat(formValues.adminDiscount).toFixed(2)) : 0;
    // const addOnWindows = formValues.addOnWindows;
    const cleaningType = formValues && formValues.cleaningType ? formValues.cleaningType.value : null;
    let propertyArea = formValues && formValues.serviceAddress && formValues.serviceAddress.propertyArea ? parseInt(formValues.serviceAddress.propertyArea) : 0;

    // const pL = orderType === "commercial" ? commercialPriceList : domesticPriceList;
    let cPCost = 0;
    let totalCost = 0;
    let bathPrice = 0;
    let bedPrice = 0;
    let propertyPrice = 0;
    let additionalServicesTotalCost = 0;
    let discount = 0;
    let totalCleaningAddonsService = 0;
    let servicesTotalTime = 0;
    let addOnServicesSum = 0;
    let cleaningAddonsSum = 0;
    let unModifiedCost = 0;
    let propertySum = 0;

    // console.log("ADMIN", adminDiscount);

    if (orderType !== "commercial" && pL) {
      const cP = pL[cleaningType] ? pL[cleaningType] : undefined;
      if (cP) {
        bedrooms = bedrooms && parseInt(bedrooms);
        bathrooms = bathrooms && parseInt(bathrooms);
        propertyArea = propertyArea && parseInt(propertyArea);
        discount += cP.discount || 0;
        cPCost = cP.cost;
        if (orderType === "domestic") {

          // addOnServices.forEach(
          //   service => (addOns[service.name] = service.quantity)
          // );

          if (bathrooms) {
            cleaningAddonsSum += bathrooms;
            bathPrice = bathrooms * cP.bathCost
          }

          if (bedrooms) {
            cleaningAddonsSum += bedrooms;
            bedPrice = bedrooms * cP.bedCost;
          }


        }
        // if (cP) {



        // if (bathrooms) {
        //   bathPrice = bathrooms * cP.bathCost
        // }

        // if (bedrooms) {
        //   bedPrice = bedrooms * cP.bedCost;
        // }


        const additionalServicesPricingData = cP.additionalServicesPricing;

        if (additionalServicesPricingData) {

          ({ additionalServicesTotalCost, addOnServicesSum } = calAddOnsCost(orderType === "carpet" ? carpetAddOns : addOnServices, additionalServicesPricingData, orderType));
          // if (Array.isArray(addOnServices) && addOnServices.length && orderType === "domestic") {
          //   addOnServices.forEach(service => {
          //     if (additionalServicesPricingData.items.indexOf(service.name) > - 1) {
          //       additionalServicesTotalCost += additionalServicesPricingData.cost;
          //       addOnServicesSum += 1;
          //     }
          //   });
          // }

        }
        servicesTotalTime = (cleaningAddonsSum * cP.perItemTime) + (addOnServicesSum * cP.perItemTime);
        // cleaningAddonsSum = bedrooms + bathrooms;

        // if (propertyArea) {

        if (cP.propertyPricing) {
          const propertyPriceData = cP.propertyPricing;


          // propertyPrice = Math.ceil(Math.max(0, (propertyArea - parseInt(propertyPriceData.skip))) / propertyPriceData.multiplyBy) * propertyPriceData.cost

          if (propertyArea) {
            propertyPrice = calPropertyCost(propertyArea, propertyPriceData);
          }
          if (orderType === "carpet" && Array.isArray(cleaningAreas) && cleaningAreas.length) {
            ({propertyPrice, propertySum} = calPropertyCostFromList(cleaningAreas, propertyPriceData));
            servicesTotalTime += (propertySum  * cP.perItemTime);
          }

        }
        // }


        totalCleaningAddonsService = (bedPrice + bathPrice);
        estTime = cP.minDuration + servicesTotalTime
      }

      // }





    } else if (orderType === "commercial" && originalCost) {
      cPCost = originalCost
    }


    if (couponData) {
      if (couponData.discountPercent) {
        discount += couponData.discountPercent;
      }
    }

    if (adminDiscount) {
      const parsedDiscount = parseFloat(parseFloat(adminDiscount).toFixed(2))
      discount += parsedDiscount;
    }

    totalCost = cPCost + totalCleaningAddonsService + propertyPrice + additionalServicesTotalCost
    if (manualOverride && adminCost) {
      unModifiedCost = totalCost;
      totalCost = adminCost !== totalCost ? adminCost : totalCost
    }

    responseBody = {
      originalCost: totalCost,
      discount: `${discount.toFixed(2)}%`,
      discountedPrice: (totalCost - ((totalCost / 100) * discount)),
      estTime,
      unModifiedCost
    }

    return responseBody;
  } catch (error) {
    throw error;
  }


}


export const createOrderFormData = (orderData, extraData = {}, config) => {
  try {

    const {
      domesticOpts,
      commercialOpts,
      frequencies,
      carpetOpts,
      mapCostToAdminCost,
      fillKeysInfo = false,
      addAdminDiscount = true,
    } = config;
    const order = cloneDeep(orderData);

    let cleaningTypeOption;
    let appliedDiscount = "0%";
    let adminDiscount = 0;
    const {
      cleaningTypes
    } = domesticOpts;

    let addOnServices;
    const {
      floorTypes,
      // frequencyTypes,
      cleaningServices: commercialCleaningOpts,
      equipmentTypes,
      employeeOpts
    } = commercialOpts;

    const {
      addOns: carpetAddOnsOpts,
      // cleaningAreas:carpetCleaningOpts,
    } = carpetOpts;

    const {
      addOns,
      carpetAddOns,
      cleaningAreas,

      // adminDiscount,
      // additionalBookingsCount = 0,
      date,
      time,
      cleaningType,
      whatAboutKeys,
      type,
      discount: storedDiscount,
      adminDiscount: storedAdminDiscount,
      bookingSource,
      etc,
      orderCost = 0,
      additionalBookingNotes = "",
      displayId,
      imagesData,
      serviceAddress,
      bedrooms,
      bathrooms,
      cleaningServices,
      frequency,
      floor,
      requiredEmployees,
      equipments,
      originalCost,
      userId,
      _id: jobId
      // commercialServiceTypes,
    } = order;

    // console.log("OrderInfo", order);

    // let { customerDetails:customer = {} } = order;
    // customer = [{
    //   ...customer, 
    //   label: customer.fullName,
    //   value: customer._id
    // }];

    let orderObj = {
      orderCost: orderCost ? parseFloat(parseFloat(orderCost).toFixed(2)) : 0,
      jobId,
      displayId,
      additionalBookingNotes,
      etc,
      // customer,
      // userId,
      imagesData,
      type,
      serviceAddress
    };

    let typeOptions = cleaningTypes;
    if (type === "domestic") {

      for (const cT of typeOptions) {
        if (cT.value === cleaningType) {
          cleaningTypeOption = cT;
          break;
        }
      }

      orderObj.cleaningType = cleaningTypeOption;
      orderObj.bathrooms = bathrooms;
      orderObj.bedrooms = bedrooms;

      if (!isEmpty(addOns)) {
        addOnServices = [];
        Object.keys(addOns).forEach((addOnName) => {
          if (addOns[addOnName]) {
            const addOnService = addOnServicesDomestic.find((service) =>
              service.name.toLowerCase() === addOnName.toLowerCase()
            );
            if (addOnService) addOnServices.push(addOnService);
          }
        });
      }


    } else if (type === "commercial") {
      orderObj.bedrooms = null;
      orderObj.bathrooms = null;
      orderObj.cleaningServices = getOptions(cleaningServices, commercialCleaningOpts);
      // orderObj.frequency = getOption(frequency, frequencyTypes);
      orderObj.floor = getOption(floor, floorTypes);
      orderObj.equipments = getOptions(equipments, equipmentTypes);
      orderObj.requiredEmployees = getOption(requiredEmployees, employeeOpts)
      // orderObj.serviceAddress = {
      //   ...orderObj.serviceAddress,
      //   propertyArea: serviceAddress.propertyArea,
      //   propertyType: { value: serviceAddress.propertyType },
      //   state: { value: serviceAddress.state }
      // };
      if (addOns && addOns.windows) {
        const addOnServices = getOption(addOns.windowsRange, commercialWindowsOpts);
        orderObj.addOnWindows = addOnServices
        delete addOns.windows;
        delete addOns.windowsRange;
      }
    } else if (type === "carpet") {
      orderObj.bedrooms = null;
      orderObj.bathrooms = null;

      if (!isEmpty(carpetAddOns)) {
        orderObj.carpetAddOns = carpetAddOns.map((cA) => ({
          _id:{value:cA._id},
          count:cA.count.toString()
        }));
      }

      if (!isEmpty(cleaningAreas)) {
        orderObj.cleaningAreas = cleaningAreas.map((cL) => ({ 
          _id: { value: cL._id }, 
          size: cL.size.toString(),
          count:cL.count.toString()
        }))
      }
    }


    if (whatAboutKeys && fillKeysInfo) {
      orderObj.whatAboutKeys = domesticOnlyKeyOptions.find((keyO) => {
        return keyO.value.toLowerCase() === whatAboutKeys.toLowerCase() || keyO.secondValue.toLowerCase() === whatAboutKeys.toLowerCase()
      });
    }



    if (!isEmpty(orderObj.serviceAddress)) {
      orderObj.serviceAddress = {
        ...orderObj.serviceAddress,
        state: { value: serviceAddress.state },
      };

      if(type === "carpet"){
        orderObj.serviceAddress = removeProp(orderObj.serviceAddress,["propertyArea", "propertyType"]);
      }

      if (serviceAddress.propertyArea && type !== "carpet") {
        orderObj.serviceAddress.propertyArea = serviceAddress.propertyArea.toString();
      }


      if (type === "commercial" && serviceAddress.propertyType) {
        orderObj.serviceAddress.propertyType = { value: serviceAddress.propertyType };
      }

    }


    if (!isEmpty(frequency)) {
      const storedFrequency = getOption(frequency, frequencies);
      if (type === "carpet") {
        orderObj.cleaningType = { value: `carpet_${storedFrequency.mappedTo}` };
      }

      orderObj.frequency = storedFrequency
    }

    if (originalCost && mapCostToAdminCost) {
      orderObj.adminCost = originalCost
      orderObj.originalCost = originalCost;
    }


      // if (storedAdminDiscount) {
      //   const parsedAdminDiscount = parseFloat(storedAdminDiscount);
      //   if (isValidFloat(parsedAdminDiscount)) {
      //     adminDiscount = parsedAdminDiscount;
      //   }
      //   orderObj.adminDiscount = adminDiscount;
      // }


    if (storedDiscount) {
      const discountPercent = parseFloat(storedDiscount);
      if (discountPercent) {
        orderObj.appliedDiscount = `${discountPercent.toFixed(2)}%`;
      }
  
      if (storedAdminDiscount && addAdminDiscount) {
        const parsedAdminDiscount = parseFloat(storedAdminDiscount);
        if (isValidFloat(parsedAdminDiscount)) {
          adminDiscount = parsedAdminDiscount;
        }
        
        orderObj.adminDiscount = adminDiscount;
      }
    } else {
      orderObj.appliedDiscount = '0%'
    }







    // if(storedOriginalCost && storedOriginalCost !== orderCost){
    //   orderObj.orderCost = originalCost;
    // }


    orderObj = {
      ...orderObj,
      date,
      time,
      addOnServices,
      ...extraData,
      bookingSource,
    };




    return orderObj;

  } catch (error) {
    throw error;
  }
}


export const getList = async ({ q, startDate, endDate, page, limit, status }) => {
  try {

    q = q && q.trim();

    if (q) {
      q = q.toLowerCase();
    }
    const res = await axios.get(`${process.env.REACT_APP_API_PREFIX}admin/get_all_orders`, {
      params: {
        page,
        limit,
        startDate,
        endDate,
        q,
        status
      }
    })

    return res.data;
  } catch (error) {
    throw error;
  }
}



const calPropertyCost = (propertyArea, propertyPriceData) => {
  try {
    let propertyPrice = 0;
    if (propertyArea) {
      propertyPrice = Math.ceil(
        Math.max(0, propertyArea - parseInt(propertyPriceData.skip)) /
        propertyPriceData.multiplyBy
      ) * propertyPriceData.cost;

    }
    return propertyPrice;
  } catch (error) {
    throw error;
  }
};


const calPropertyCostFromList = (propertyList, propertyPricing) => {
  try {
    let propertyPrice = 0;
    let propertySum = 0;
    if(Array.isArray(propertyList) && propertyList.length){
      ({propertyPrice, propertySum } = propertyList.reduce((propertyPriceAcc,property) => {
        const parsedSize = parseNumber(property.size, 0);
        const parsedCount = parseNumber(property.count,0);
        propertyPriceAcc.propertySum += parsedCount;
        propertyPriceAcc.propertyPrice += calPropertyCost(parsedSize, propertyPricing) * parsedCount;
        return propertyPriceAcc;
      },{
        propertyPrice:0,
        propertySum:0
      }));
      // propertyList.forEach((property) => {
      //   propertySum += property.count;
      //   propertyPrice += calPropertyCost(property.size, propertyPricing) * property.count;
      // });
    }



    // console.log("DDD", propertySum, propertyPrice);

    return {
      propertyPrice,
      propertySum
    };
  } catch (error) {
    throw error;
  }
};


const calAddOnsCost = (addOns, additionalServicesPricingData, orderType) => {
  try {
    let additionalServicesTotalCost = 0;
    let addOnServicesSum = 0;
    if (!isEmpty(addOns)) {
      if (isPlainObject(addOns)) {
        const addOnKeys = Object.keys(addOns);
        additionalServicesTotalCost = addOnKeys.reduce((accumulator, currentValue) => {
          if (!isEmpty(additionalServicesPricingData.items)) {
            if (addOns[currentValue] && additionalServicesPricingData.items.indexOf(currentValue) > - 1) {
              addOnServicesSum += 1;
              return accumulator + additionalServicesPricingData.cost;
            }
          } else {
            addOnServicesSum += 1;
            return accumulator + additionalServicesPricingData.cost;
          }

          return accumulator;
        }, 0);
      } else if (Array.isArray(addOns)) {
        additionalServicesTotalCost = addOns.reduce((accumulator, currentValue) => {
          if (!isEmpty(additionalServicesPricingData.items)) {
            if (additionalServicesPricingData.items.indexOf(currentValue.name) > - 1) {
              addOnServicesSum += 1;
              return accumulator + additionalServicesPricingData.cost;
            }
          } else {
            if(orderType === "carpet"){
              const parsedCount = getNumber(currentValue.count,0);
              const cost = parsedCount * additionalServicesPricingData.cost;
              addOnServicesSum += parsedCount;
              return accumulator + cost;
            } else {
              addOnServicesSum += 1;
              return accumulator + additionalServicesPricingData.cost;
            }
          }




          return accumulator;
        }, 0);
      }
    }

    return {
      additionalServicesTotalCost,
      addOnServicesSum
    };

  } catch (error) {
    throw error;
  }
};



export const getDomesticAdditionalBookings = (params, forceUpdate, markAsSelected) => {
  try {
    let dataToSet = undefined;
    const { cleaningType, startDate, endDate, time } = params;
    let additionalBookings = params.additionalBookings && params.additionalBookings.length ? cloneDeep(params.additionalBookings) : [];
    // change("additionalBookings",undefined);
    if (!isEmpty(cleaningType)) {
      let diffUnit;
      let startOfUnit;
      let recursion = 1;
      if (cleaningType.isEveryWeek) {
        diffUnit = "weeks";
        startOfUnit = "isoWeek";
      } else if (cleaningType.isBiWeekly) {
        diffUnit = "weeks";
        startOfUnit = "isoWeek";
        recursion = 2;
      } else if (cleaningType.isOnceAMonth) {
        startOfUnit = "month";
        diffUnit = "months";
      }
      if (startDate && endDate && time) {
        dataToSet = [];
        const localStartDate = moment(startDate).startOf(startOfUnit).format("YYYY-MM-DD");
        const datesDiff = (moment(endDate).diff(localStartDate, diffUnit));
        const diff = Math.floor(datesDiff / recursion);

        let sDate = startDate;
        for (let i = 0; i < diff; i++) {
          sDate = moment(sDate).add(recursion, diffUnit).format("YYYY-MM-DD")
          if (diff - 1 === i) {
            sDate = moment(sDate).isBefore(endDate) ? sDate : endDate;
          }


          if (additionalBookings.length && additionalBookings.length) {
            const startDatePeriod = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
            const endDatePeriod = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");
            const dateExistsIndex = additionalBookings.findIndex((aB) => validateDateBetweenTwoDates(startDatePeriod, endDatePeriod, aB.date));
            if (dateExistsIndex === -1) {
              dataToSet.push({
                selected: true,
                time: time,
                date: sDate
              });
            }
          } else {
            dataToSet.push({
              selected:true,
              time: time,
              date: sDate
            });

          }
        }



        if (dataToSet && dataToSet.length) {

          dataToSet = [...additionalBookings, ...dataToSet];
          dataToSet = sortAdditionalDates(dataToSet);
        }
      }
    }
    return dataToSet;
  } catch (error) {
    throw error;
  }
}
export const getAdditionalBookings = (params, forceUpdate, doNotRemoveFirstDate, markAsSelected = false) => {
  try {
    const { frequency, startDate, endDate, time } = params;
    let dataToSet = undefined;
    let removeFirstDate = false;
    let additionalBookings = params.additionalBookings && params.additionalBookings.length ? cloneDeep(params.additionalBookings) : [];
    if (!isEmpty(frequency)) {
      let diffUnit = "weeks";
      let unitTypeToAdd = "weeks";
      let startOfUnit = "isoWeek";
      let daysToAdd = 1;
      let recursion = frequency.frequency;

      if (frequency.period === "month") {
        unitTypeToAdd = "months";
        startOfUnit = "month";
        diffUnit = "months";
      }

      if (frequency.period === "daysInWeek") {
        // unitTypeToAdd = "weeks";
        daysToAdd = frequency.frequency;
        recursion = 1;
        removeFirstDate = true;
      }



      if (startDate && endDate && time) {
        dataToSet = [];
        const localStartDate = moment(startDate).startOf(startOfUnit).format("YYYY-MM-DD");
        const datesDiff = moment(endDate).diff(localStartDate, diffUnit);
        let diff = Math.floor(datesDiff / recursion);
        let sDate = startDate;

        if (unitTypeToAdd === "weeks" && daysToAdd > 1) {
          diff += 1;
        }
        // let i = 0;
        // do {
        //   if (unitTypeToAdd === "weeks" && daysToAdd > 1) {
        //     const startOfWeek = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
        //     const endOfWeek = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");

        //     const nextWeek = moment(sDate).add(recursion, unitTypeToAdd).startOf(unitTypeToAdd).add(1, 'day').format("YYYY-MM-DD");

        //     for (let dayToAdd = 1; dayToAdd <= daysToAdd; dayToAdd++) {
        //       if (dayToAdd > 1) {
        //         sDate = moment(sDate).add(1, 'day').format("YYYY-MM-DD");
        //       } else {
        //         sDate = moment(sDate).format("YYYY-MM-DD");
        //       }
        //       if(additionalBookings && additionalBookings.length){
        //         const startDatePeriod = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
        //         const endDatePeriod = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");
        //         const dateExistsIndex = additionalBookings.findIndex((aB) => validateDateBetweenTwoDates(startDatePeriod,endDatePeriod,aB.date));
        //         if(dateExistsIndex === -1){
        //           dataToSet.push({
        //             selected:true,
        //             time: time,
        //             date: sDate
        //           });
        //         }
        //       } else if (validateDateBetweenTwoDates(startOfWeek, endOfWeek, sDate)) {
        //         dataToSet.push({
        //           time: time,
        //           date: sDate
        //         });
        //       }
        //     }
        //     sDate = nextWeek;
        //   } else {
        //     sDate = moment(sDate).add(recursion, unitTypeToAdd).format("YYYY-MM-DD");
        //     if (diff - 1 === i) {
        //       sDate = moment(sDate).isBefore(endDate) ? sDate : endDate;
        //     }

        //     if(additionalBookings && additionalBookings.length){
        //       const startDatePeriod = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
        //       const endDatePeriod = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");
        //       const dateExistsIndex = additionalBookings.findIndex((aB) => validateDateBetweenTwoDates(startDatePeriod,endDatePeriod,aB.date));
        //       if(dateExistsIndex === -1){
        //         dataToSet.push({
        //           selected:true,
        //           time: time,
        //           date: sDate
        //         });
        //       }
        //     } else {
        //       dataToSet.push({
        //         time: time,
        //         date: sDate
        //       });

        //     }
        //   }

        //   i++;
        // } while (i < diff)


        for (let i = 0; i < diff; i++) {

          if (unitTypeToAdd === "weeks" && daysToAdd > 1) {
            const startOfWeek = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
            const endOfWeek = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");

            const nextWeek = moment(sDate).add(recursion, unitTypeToAdd).startOf(unitTypeToAdd).add(1, 'day').format("YYYY-MM-DD");

            for (let dayToAdd = 1; dayToAdd <= daysToAdd; dayToAdd++) {
              if (dayToAdd > 1) {
                sDate = moment(sDate).add(1, 'day').format("YYYY-MM-DD");
              } else {
                sDate = moment(sDate).format("YYYY-MM-DD");
              }


              // console.log("STRA", sDate);
              if (additionalBookings && additionalBookings.length) {
                const startDatePeriod = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
                const endDatePeriod = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");
                const dateExistsIndex = additionalBookings.findIndex((aB) => validateDateBetweenTwoDates(startDatePeriod, endDatePeriod, aB.date));

                // console.log("DATE", startDatePeriod, endDatePeriod, sDate);
                if (dateExistsIndex === -1) {
                  dataToSet.push({
                    selected: true,
                    time: time,
                    date: sDate
                  });
                }
              } else if (validateDateBetweenTwoDates(startOfWeek, endOfWeek, sDate)) {
                dataToSet.push({
                  selected:true,
                  time: time,
                  date: sDate
                });
              }
            }
            sDate = nextWeek;
          } else {
            sDate = moment(sDate).add(recursion, unitTypeToAdd).format("YYYY-MM-DD");
            if (diff - 1 === i) {
              sDate = moment(sDate).isBefore(endDate) ? sDate : endDate;
            }


            // console.log("S_DT", sDate);

            if (additionalBookings && additionalBookings.length) {
              const startDatePeriod = moment(sDate).startOf(startOfUnit).format("YYYY-MM-DD");
              const endDatePeriod = moment(sDate).endOf(startOfUnit).format("YYYY-MM-DD");

              // console.log("D", startDatePeriod, endDatePeriod, sDate)
              const dateExistsIndex = additionalBookings.findIndex((aB) => validateDateBetweenTwoDates(startDatePeriod, endDatePeriod, aB.date));
              if (dateExistsIndex === -1) {
                dataToSet.push({
                  selected: true,
                  time: time,
                  date: sDate
                });
              }
            } else {
              dataToSet.push({
                selected:true,
                time: time,
                date: sDate
              });

            }
          }

        }
      }
    }


    if (dataToSet && dataToSet.length) {
      if (removeFirstDate && !doNotRemoveFirstDate) {
        dataToSet = dataToSet.slice(1);
      }

      dataToSet = [...additionalBookings, ...dataToSet];
      dataToSet = sortAdditionalDates(dataToSet);

    }
    // if (removeFirstDate) {
    //   dataToSet = dataToSet.slice(1);
    // }
    return dataToSet;
  } catch (error) {
    throw error;
  }
}



export const getPropertyInfo = async (serviceAddress, orderType, infoUrl) => {
  // let resBody = {}
  let address = "";
  // const response = {};
  let imagesData = {}, finishedSqFt = null, bedrooms = 0, bathrooms = 0, foundInfo = false;
  try {

    address = serviceAddress.address;
    if (serviceAddress.unit) {
      let { unit } = serviceAddress
      unit = unit.replace(/[^a-zA-Z0-9]/g, "");
      address += ` UNIT ${unit}`;
    }
    address += `, ${serviceAddress.city}, ${serviceAddress.state.value}`;
    // response.address = address;
    const { data: { result } } = await axios.post(infoUrl, {
      address,
      zipcode: serviceAddress.zipcode,
      type: orderType === "carpet" ? "image" : "detailed"
    })



    imagesData = result.imagesData;
    finishedSqFt = result.finishedSqFt;
    bedrooms = result.bedrooms;
    bathrooms = result.bathrooms;
    if ((result && result.finishedSqFt) || (result && !isEmpty(result.imagesData))) {
      foundInfo = true;
    }

    // if((result && result.finishedSqFt) || (result && !isEmpty(result.imagesData)) ) { 
    // }
  } catch (error) {
    throw error;
  }

  return {
    foundInfo,
    imagesData,
    finishedSqFt,
    bedrooms,
    bathrooms
  }

}


export const createCleaningAreasList = (list) => {
  let createdList = [];

  if(list && list.length){
    createdList = list.map((cL) =>({
      _id:cL._id.value,
      size:cL.size,
      count:cL.count,
    })) 
  }

  return createdList;
}


export const createCarpetAddOns = (list) => {
  let createdList = [];

  if(list && list.length){
    createdList = list.reduce((list, cA) => {
      if(!isEmpty(cA) && !isEmpty(cA._id)){
        list.push({
          _id:cA._id.value,
          count:cA.count,
        })
      }

      return list;
    },[])
  }

  return createdList;
}