import React, { Component } from "react";
import AddTierForm from "../components/TierFormModal";
import axios from "utils/http";
import { ZOK_TIER_EXISTS } from "../variables/errorCode";
import { reduxForm } from "redux-form";
import { setMsgAndShow } from "../store/actions/popup";
import { connect } from "react-redux";


class AddTierFormContainer extends Component { 

  state = {
    processing:false
  }

  closeForm = () => {
    this.props.reset();
    this.props.closeModal();
  }
  submitData = async (formValues) => {
    try {
      this.setState({
        processing:true,
      })
      const dataToSend = {
        ...formValues,
      }

      await axios.post(`${process.env.REACT_APP_API_WITH_PREFIX}admin/tiers/add`,{...dataToSend});
      this.props.setMsgAndShow("Tier added successfully")
      this.setState({
        processing:false
      })
      this.closeForm();
      if(this.props.onSuccess){
        this.props.onSuccess();
      }

    } catch (error) {
      const { response } = error;
      let errorMessage = "Unable to add tier!";
      if(response.status = 409 && response.data && response.data.code === ZOK_TIER_EXISTS){
        errorMessage = "Tier already exists!";
      } else if(response && response.data && response.data.message){
        errorMessage = response.data.message;
      }
      
      this.setState({
        processing:false,
      })

      this.props.setMsgAndShow(errorMessage);
    }
  }
  render(){
    return (
      <AddTierForm 
        {...this.props}
        processing={this.state.processing}
        submitData={this.submitData}
        closeForm={this.closeForm}
        title="Add Tier"
      />
    )
  }
}


const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
})


const WithForm = reduxForm({
  form: "AddTierForm",
  destroyOnUnmount: true,

})(AddTierFormContainer);


export default connect(undefined,mapDispatchToProps)(WithForm);