import React, { PureComponent } from "react";
import axios from "../utils/http";
import ManagerFormModal from "../components/ManagerFormModal";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { setMsgAndShow } from "../store/actions/popup";


class AddManagerFormModalContainer extends PureComponent {
  state = {
    load:false,

  }


  closeModal = () => {
    this.props.reset();
    this.props.handleModalClose();
  }

  submitData = async (data) => {
    try {
      this.setState({
        load:true,
      })

      const formData = {...data};
      delete formData.confirmPassword;
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}admin/managers/add`,{...formData});
      const { manager } = res.data;
      if(this.props.onRequestSuccess){
        this.props.onRequestSuccess(manager);
      }

      this.setState({
        load:false,
      })

      this.closeModal();
    } catch (error) {
      console.log("ERRRRR", error);
      let errorMessageToShow = "Sorry an error occurred!";
      const { response }= error;
      if(response && response.data && response.data.message){
        errorMessageToShow = response.data.message;
      }
      this.setState({
        load:false
      })
      this.props.setMsgAndShow(errorMessageToShow);
    }
  }

  render(){
    return (
      <ManagerFormModal 
        loadLoader={this.state.load}
        submitData={this.submitData}
        {...this.state}
        {...this.props}
        closeModal={this.closeModal}
      />
    )
  }
}


const WithForm = reduxForm({
  form: "AddManagerForm",
  destroyOnUnmount: true,
})(AddManagerFormModalContainer);

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow:(message) => dispatch(setMsgAndShow(message))
})


export default connect(undefined,mapDispatchToProps)(WithForm);