import React, { Component } from "react";
import EditTierForm from "../components/TierFormModal";
import axios from "utils/http";
import { reduxForm } from "redux-form";
import { setMsgAndShow } from "../store/actions/popup";
import { resetFormData } from "../store/actions/formData";
import { connect } from "react-redux";
import removeProp from "../utils/removeProp";


class EditTierFormContainer extends Component { 

  state = {
    processing:false
  }

  closeForm = () => {
    this.props.reset();
    this.props.closeModal();
    this.props.resetFormData();
  }
  submitData = async (formValues) => {
    try {
      this.setState({
        processing:true,
      })


      const { _id:tierId, ...restValues } = formValues;

      removeProp(restValues,["createdat","isDeleted"]);
      await axios.put(`${process.env.REACT_APP_API_WITH_PREFIX}admin/tiers/edit/${tierId}`,{...restValues});
      this.props.setMsgAndShow("Tier updated successfully")
      this.setState({
        processing:false
      })
      this.closeForm();
      if(this.props.onSuccess){
        this.props.onSuccess();
      }

    } catch (error) {
      const { response } = error;
      let errorMessage = "Unable to update tier";
      if(response && response.data && response.data.message){
          errorMessage = response.data.message;
      }
        
      this.setState({
        processing:false,
      })

      this.props.setMsgAndShow(errorMessage);
    }
  }
  render(){
    return (
      <EditTierForm 
        {...this.props}
        processing={this.state.processing}
        submitData={this.submitData}
        closeForm={this.closeForm}
        title="Edit Tier"
        mode="edit"
      />
    )
  }
}



const mapStateToProps = (state) => {
  return {
    initialValues: state.formData.formData
  }
}


const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  resetFormData:() => dispatch(resetFormData())
})


const WithForm = reduxForm({
  form: "EditTierForm",
  destroyOnUnmount: true,
  enableReinitialize:true,
})(EditTierFormContainer);


export default connect(mapStateToProps,mapDispatchToProps)(WithForm);
