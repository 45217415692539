import React from "react";
import Grid from "../../core/components/Grid";
import FabButton from "core/components/FabButton";
import EventsCalender from "../EventCalender";
import Loader from "../ProgressCircle";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    // flexGrow: 1,
    // flexDirection:"column",
    // display: "flex",
  },
}

const EventsDashboard = ({
  classes,
  events,
  handleDateChange,
  handleDateRangeChange,
  handleViewChange,
  gettingList,
  openDetailsModal,
  selectSlot,
  openAddForm
}) => {
  return (
    <Grid className={classes.root}>
      {(gettingList) && <Loader/>}
      <EventsCalender 
        // selectedDate={selectedDate} 
        eventList={events} 
        titleAccessor="name"
        startAccessor="startTime"
        endAccessor="endTime"
        
        handleDateChange={handleDateChange}
        handleDateRangeChange={handleDateRangeChange}
        handleViewChange={handleViewChange}
        handleEventSelect={openDetailsModal}
        selectSlot={selectSlot}
        // eventStyleSetter={eventStyleSetter}
        // card
      />
      <FabButton  onClick={openAddForm} />
    </Grid>
  )
}


export default withStyles(styles)(EventsDashboard);