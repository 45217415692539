import React from "react";
import { MainLayout } from "../layouts";
import ContractorsEarnings from "../containers/ContractorEarningsContainer";




const ContractorsEarningsPage = (props) => {
  return (
    <MainLayout pageName="Tiers">
      <ContractorsEarnings {...props}/>
    </MainLayout>
  )
}



export default ContractorsEarningsPage;