import React from "react";
import { Form } from "redux-form";
import Dialog from "core/components/Dialog";
import DialogActions from "core/components/DialogActions";
import DialogContent from "core/components/DialogContent";
import DialogTitle from "core/components/DialogTitle";
import ZTypography from "core/components/Typography";
import ZButton from "core/components/Button";
import Loader from "../ProgressCircle";
import { MaterialTextField, FormInputField } from "../FormField";
import { required, matchPasswords } from "../FormField/Validations";




const ChangePasswordModal = ({
  loadLoader,
  isModalOpen,
  closeModal,
  handleSubmit,
  submitData,
}) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      fullWidth
    >
      {loadLoader &&  <Loader position="fixed"/> }

      <Form
        onSubmit={handleSubmit(submitData)}
      >
        <DialogTitle disableTypography>
          <ZTypography color="secondary" gradient bold fontSize={25}>
            Change Password
        </ZTypography>
        </DialogTitle>
        <DialogContent>

          <FormInputField
            name="currPass"
            label="Current Password"
            type="password"
            shrink
            component={MaterialTextField}
            placeholder="Enter current password"
            validate={[required]}
            disableUnderline
          />

          <FormInputField
            name="password"
            label="New Password"
            type="password"
            shrink
            component={MaterialTextField}
            placeholder="Enter new password"
            validate={[required]}
            disableUnderline
          />

          <FormInputField
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            shrink
            component={MaterialTextField}
            placeholder="Confirm Password"
            disableUnderline
            validate={[required, matchPasswords]}
          />
        </DialogContent>
        <DialogActions>
          <ZButton type="submit" color="primary" gradient>
            Submit
        </ZButton>
          <ZButton onClick={closeModal}>Cancel</ZButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}



export default ChangePasswordModal;