import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

const defaultOptions = {
  title : "Successful",
  text : "",
  html : "",
  type : "success",
  position : "center",
  timer : null,
  allowEnterKey : false,
  showConfirmButton : false,
  showCloseButton : true,
  showCancelButton : false,
  onBeforeOpen : null,
  onOpen : null,
  onClose : null,
  onAfterClose : null
}

export default function(options) {
  const finalOptions = {
    ...defaultOptions,
    ...options
  }

  let { title, type } = finalOptions;
  if(!options.title && type === "success") title = "Successful";
  if(!options.title && type === "error") title = "Error";
  if(type === 'question' || type === 'confirm') {
    finalOptions.showConfirmButton = true;
    finalOptions.confirmButtonText = "Yes"
    finalOptions.showCancelButton = true;
    finalOptions.cancelButtonText = "No"
  }

  return MySwal.fire({
    ...finalOptions,
    title,
    type
  })
}