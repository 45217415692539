import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Magnify } from "mdi-material-ui";
import IconButton from '@material-ui/core/IconButton';
import { Close } from "mdi-material-ui";

const styles = theme => ({
  searchBoxCont:{
    width: "100%",
    maxWidth: 500,
    height: 40,
    borderRadius: 25,
    boxShadow: "0 3px 11px 0 rgba(189, 159, 189, 0.11)",
    backgroundColor: "#ffffff",
    paddingLeft:"25px !important",
    paddingRight: "15px !important",
    fontFamily: "Montserrat",
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    alignContent:"center"
  },
  searchBox:{
    border:"none",
    fontSize: 12,
    outline:"none",
    color: "#a8b2b9",
    flex:1,
    "&::placeholder":{
      color: "#a8b2b9",
    }
  },
  searchIcon:{
    height: 16,
    width:16,
    color:"#a8b2b9"
  },

  clearSearchCont:{
    height: 24,
    width: 24,
    color: "#a8b2b9"
  },

  clearSearchIcon:{
    height : 24,
    width : 19
  }
})


const SearchBar = ({ 
  placeholder, 
  classes, 
  onChange,
  query, 
  inputRef, 
  onClear,
  ...restProps
}) => {

  return (
    <Grid item className={classes.searchBoxCont}>

      <input 
        placeholder={placeholder} 
        className={classes.searchBox} 
        onChange={onChange}
       
        ref={inputRef} 
      />
      { 
        query 
        ? <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClear}
            classes={{
              root : classes.clearSearchCont,
            }}
          >
            <Close classes={{
              root: classes.clearSearchIcon
            }} />
          </IconButton>
        : <Magnify className={classes.searchIcon}/>
      } 

    </Grid>
  )
} 

export default withStyles(styles)(SearchBar);