import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import jobsReducer from "./jobsReducer";
import appReducer from "./appReducer";
import adminReducer from "./adminReducer";
// import employeeReducer from "./employeeReducer";
import todosReducer from "./todosReducer";
import popupReducer from "./popupReducer";
import contractorModalReducer from "./contractorModalReducer"; 
import formDataReducer from "./formDataReducer";
import eventFormReducer from "./eventFormReducer";
const rootReducer = combineReducers({
  form,
  app:appReducer,
  jobs: jobsReducer,
  admin: adminReducer,
  todos: todosReducer,
  popup: popupReducer,
  formData:formDataReducer,
  contModal: contractorModalReducer,
  eventForm:eventFormReducer,
  // employee: employeeReducer
});

export default rootReducer;