export const getOptionsList = (listToIterate, labelPropName, valuePropName) => {
  const optionList = [];
  if (listToIterate.length > 0) {
    listToIterate.forEach(item =>
      optionList.push({
        ...item,
        label: item[labelPropName],
        value: item[valuePropName]
      })
    );
  }
  return optionList;
};
