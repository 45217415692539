import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      light: "#77e9cf",
      main: "#55e4c3",
      dark: "#3b9f88",
      contrastText: "#fff"
    },
    secondary: {
      light: "#7c33c3",
      main: "#5c00b5",
      dark: "#40007e",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiButton: {
      root:{
        
        textTransform: "capitalize",
      },
      contained:{
        boxShadow:"none"
      }
    },
    MuiTypography:{
      body1:{
        color:"#4a4a4a"
      }
    }
  }
})