import { ADD_NEW_JOB, REMOVE_JOB, SET_JOBS, UPDATE_JOB } from "../types";
import _ from "lodash";

const defaultState = {
  newJobs:[],
  acceptedJobs:[],
  todaysJobs:[],
  allJobs:[]
}

 
const reducer = (state = defaultState, action) => {
  let finalState = null;


  switch(action.type){
    case ADD_NEW_JOB:
    finalState = _.cloneDeep(state);
    finalState[action.jobsType].push(action.payload);
    break;

    case REMOVE_JOB:
    finalState = _.cloneDeep(state);
    finalState[action.jobsType] = finalState[action.jobsType].filter((job) => job._id !== action.payload)
    break;

    case SET_JOBS:
    finalState = _.cloneDeep(state);
    finalState[action.jobsType] = [
      ...action.payload
    ]
    break;

    case UPDATE_JOB:
    finalState = _.cloneDeep(state);
    finalState[action.jobsType] = finalState[action.jobsType].map((job) => {
      if(job._id === action.jobId) {
        return {
          ...job,
          ...action.payload
        }
      }
      return job
    })
    break;
    default:
    finalState = state;
    break;
  }


  return finalState;
}


export default reducer;